import { useTranslation } from 'react-i18next';
import useConfigBiogenLincConfig from './BiogenLincConfigGroup.config';

const useBiogenLincConfig = () => {
  const { t } = useTranslation();
  const {
    isVisibleOnBiogenLincConfig,
    eventPromotionalityConfig,
    prcValidationsConfig,
    isSwitzerlandSpecific,
    eventMarket,
  } = useConfigBiogenLincConfig();

  return {
    isVisibleOnBiogenLincConfig,
    eventPromotionalityConfig,
    prcValidationsConfig,
    isSwitzerlandSpecific,
    eventMarket,
    t,
  };
};

export default useBiogenLincConfig;
