import { EventStatus } from 'generated/event.types';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { EventPromotionalityEnum } from 'modules/events/basics/enums/events.enums';
import { Event, EventStateType } from 'modules/events/basics/types/events.types';
import { PhysicalMeetingFromDB, PhysicalMeetingInput } from 'modules/events/basics/types/physicalMeeting.types';
import {
  transformAgenda,
  transformInputAssets,
  transformInputBiogenLincConfig,
  transformInputSession,
  transformSpeakers,
  transformUserGroups,
} from 'modules/events/basics/utils/eventsInput.transformers';
import { transformOutputSession } from 'modules/events/basics/utils/eventsOutput.transformers';
import { normalizeValueToString } from 'modules/events/groups/EmailNotesGroup/EmailNotesGroup.transformer';
import eventState from 'modules/events/states/events.states';
import { YodaFieldValue } from 'yoda-ui/yodaForm/yodaForm.types';

// FROM BO TO BE
export const physicalMeetingInputTransformer = (data: YodaFieldValue, formValues: YodaFieldValue) => {
  if (eventState.createEvent._id && eventState.createEvent.status) {
    const isPublishedEvent = eventState.createEvent.status === EventStatus.published;
    const assets = transformInputAssets(data);
    const biogenLincConfig = transformInputBiogenLincConfig(data, formValues);
    const speakers = transformSpeakers(data);
    const agenda = transformAgenda(data);
    const userGroups = transformUserGroups(data);
    const session = transformInputSession(formValues);

    const physicalMeetingInput: PhysicalMeetingInput = {
      id: eventState.createEvent._id,
      ...(data[commonFields.title] !== undefined && data[commonFields.title] ? { title: data[commonFields.title] } : {}),
      ...(data[commonFields.emailContentfulUrl] !== undefined ? { contentfulUrl: data[commonFields.emailContentfulUrl] } : {}),
      ...(data[commonFields.sessionLocale] !== undefined && data[commonFields.sessionLocale] ? {
        locale: data[commonFields.sessionLocale],
      } : {}),
      ...(data[commonFields.userGroups] ? { userGroups } : null),
      ...(data[commonFields.disclaimerText] !== undefined ? { disclaimerText: data[commonFields.disclaimerText] } : {}),
      ...(data[commonFields.footerDisclaimerText] !== undefined ? { footerDisclaimerText: data[commonFields.footerDisclaimerText] } : {}),
      ...(data[commonFields.sessionLocation] !== undefined ? { eventLocation: data[commonFields.sessionLocation] } : {}),
      ...(data[commonFields.sessionSeats] !== undefined ? { seats: parseInt(data[commonFields.sessionSeats], 10) } : {}),
      ...(data[commonFields.registrationEndDate] !== undefined ? { registrationEndDate: data[commonFields.registrationEndDate] } : {}),
      enableDeclineModal: data[commonFields.enableDeclineModal],
      ...(formValues[commonFields.productInformationButtonLabel] !== undefined ? {
        productInformationButtonLabel: formValues[commonFields.productInformationButtonLabel],
      } : {}),
      ...(data[commonFields.productInformationButtonLink] !== undefined ? {
        productInformationButtonLink: data[commonFields.productInformationButtonLink],
      } : {}),
      ...(data[commonFields.speakerSupportPhoneNumber] !== undefined ? {
        speakerSupportPhoneNumber: data[commonFields.speakerSupportPhoneNumber],
      } : {}),
      ...(data[commonFields.collectParticipantPhoneNumber] !== undefined ? {
        collectParticipantPhoneNumber: data[commonFields.collectParticipantPhoneNumber],
      } : {}),
      ...(data[commonFields.collectPhoneNumberMandatory] !== undefined ? {
        collectPhoneNumberMandatory: data[commonFields.collectPhoneNumberMandatory],
      } : {}),
      ...(data[commonFields.collectPhoneNumberUserConsent] !== undefined ? {
        collectPhoneNumberUserConsent: data[commonFields.collectPhoneNumberUserConsent],
      } : {}),
      ...(data[commonFields.agendaFileTitle] !== undefined ? { agendaFileTitle: data[commonFields.agendaFileTitle] } : {}),
      ...(data[commonFields.agendaFileDescription] !== undefined
        ? { agendaFileDescription: data[commonFields.agendaFileDescription] } : {}),
      ...(data[commonFields.emailHeaderNote] !== undefined ? { emailHeaderNote: data[commonFields.emailHeaderNote] } : {}),
      ...(data[commonFields.emailHeaderNoteEmailTypes] !== undefined
        ? { emailHeaderNoteEmailTypes: data[commonFields.emailHeaderNoteEmailTypes] } : {}),
      ...(data[commonFields.emailFooterNote] !== undefined ? { emailFooterNote: data[commonFields.emailFooterNote] } : {}),
      ...(data[commonFields.emailFooterNoteEmailTypes] !== undefined
        ? { emailFooterNoteEmailTypes: data[commonFields.emailFooterNoteEmailTypes] } : {}),
      ...(data[commonFields.emailFooterDisclaimerText] !== undefined
        ? { emailFooterDisclaimerText: data[commonFields.emailFooterDisclaimerText] } : {}),
      ...(data[commonFields.biogenLogo] !== undefined ? { biogenLogo: data[commonFields.biogenLogo] } : {}),
      ...(data[commonFields.buttonColor] !== undefined ? { buttonColor: data[commonFields.buttonColor] } : {}),
      ...(data[commonFields.emailPrcCode] !== undefined ? { emailPrcCode: data[commonFields.emailPrcCode] } : {}),
      ...(data[commonFields.cxSurveyActivation] !== undefined ? { cxSurveyActivation: data[commonFields.cxSurveyActivation] } : {}),
      ...(data[commonFields.cxSurveyId] !== undefined ? { cxSurveyId: data[commonFields.cxSurveyId] } : {}),
      ...(data[commonFields.cxSurveyUseCaseName] !== undefined ? { cxSurveyUseCaseName: data[commonFields.cxSurveyUseCaseName] } : {}),
      ...(data[commonFields.teamContactCheckbox] !== undefined ? { teamContactCheckbox: data[commonFields.teamContactCheckbox] } : {}),
      ...(data[commonFields.teamContactEmail] !== undefined ? { teamContactEmail: data[commonFields.teamContactEmail] } : {}),
      ...(data[commonFields.teamContactDescription] !== undefined
        ? { teamContactDescription: data[commonFields.teamContactDescription] } : {}),
      ...(data[commonFields.cxSurveyUseCaseCategory] !== undefined ? { cxSurveyUseCaseCategory:
        data[commonFields.cxSurveyUseCaseCategory] } : {}),
      ...(data[commonFields.invitationEmailMayaId] !== undefined
        ? { invitationEmailMayaId: data[commonFields.invitationEmailMayaId] } : {}),
      ...(data[commonFields.invitationEmailUTM] !== undefined
        ? { invitationEmailUTM: data[commonFields.invitationEmailUTM] } : {}),
      ...(data[commonFields.confirmationEmailMayaId] !== undefined
        ? { confirmationEmailMayaId: data[commonFields.confirmationEmailMayaId] } : {}),
      ...(data[commonFields.confirmationEmailUTM] !== undefined
        ? { confirmationEmailUTM: data[commonFields.confirmationEmailUTM] } : {}),
      ...(data[commonFields.reminder48hMayaId] !== undefined ? { reminder48hMayaId: data[commonFields.reminder48hMayaId] } : {}),
      ...(data[commonFields.reminder48hUTM] !== undefined ? { reminder48hUTM: data[commonFields.reminder48hUTM] } : {}),
      ...(data[commonFields.reminder24hMayaId] !== undefined ? { reminder24hMayaId: data[commonFields.reminder24hMayaId] } : {}),
      ...(data[commonFields.reminder24hUTM] !== undefined ? { reminder24hUTM: data[commonFields.reminder24hUTM] } : {}),
      ...(data[commonFields.reminder12hMayaId] !== undefined ? { reminder12hMayaId: data[commonFields.reminder12hMayaId] } : {}),
      ...(data[commonFields.reminder12hUTM] !== undefined ? { reminder12hUTM: data[commonFields.reminder12hUTM] } : {}),
      ...(data[commonFields.reminder1hMayaId] !== undefined ? { reminder1hMayaId: data[commonFields.reminder1hMayaId] } : {}),
      ...(data[commonFields.reminder1hUTM] !== undefined ? { reminder1hUTM: data[commonFields.reminder1hUTM] } : {}),
      ...(data[commonFields.cxSurveyMayaId] !== undefined ? { cxSurveyMayaId: data[commonFields.cxSurveyMayaId] } : {}),
      ...(data[commonFields.cxSurveyUTM] !== undefined ? { cxSurveyUTM: data[commonFields.cxSurveyUTM] } : {}),
      ...(data[commonFields.participantsSupportPhoneNumber] !== undefined
        ? { participantsSupportPhoneNumber: data[commonFields.participantsSupportPhoneNumber] } : {}),
      ...(!isPublishedEvent && data[commonFields.coorganizers] ? { coOrganizers: data[commonFields.coorganizers] } : {}),
      ...(data[commonFields.description] !== undefined ? { description: data[commonFields.description] } : {}),
      ...(data[commonFields.product] ? { product: data[commonFields.product] } : {}),
      ...(data[commonFields.program] ? { program: data[commonFields.program] } : {}),
      ...(!isPublishedEvent ? session : null),
      ...(!isPublishedEvent ? speakers : null),
      ...(!isPublishedEvent && data[commonFields.testimonials] ? { testimonials: data[commonFields.testimonials] } : null),
      ...(data[commonFields.agenda] ? { agenda } : null),
      ...(assets),
      ...(biogenLincConfig),
      ...(data[commonFields.showNavigationBarActions] !== undefined)
        ? { showNavigationBarActions: data[commonFields.showNavigationBarActions] }
        : {},
      ...(data[commonFields.showNavigationBarElements] !== undefined)
        ? { showNavigationBarElements: data[commonFields.showNavigationBarElements] }
        : {},
      ...(data[commonFields.showFooterNavItems] !== undefined)
        ? { showFooterNavItems: data[commonFields.showFooterNavItems] }
        : {},
      ...(data[commonFields.reminderNotificationEmails]
        ? { reminderNotificationEmails: data[commonFields.reminderNotificationEmails].map(normalizeValueToString) } : {}),
      ...(data[commonFields.reminderNotificationPhoneNumbers]
        ? { reminderNotificationPhoneNumbers: data[commonFields.reminderNotificationPhoneNumbers].map(normalizeValueToString) } : {}),
    };

    return physicalMeetingInput;
  }
  return undefined;
};

// FROM BE TO BO
export const physicalMeetingTransformer = (event: Event) => {
  const createEventState: EventStateType['createEvent'] = {
    ...event,
    data: {
      ...event.data,
      session: transformOutputSession(event.data?.session),
      cardImage: event.data?.assets?.cardImage || [],
      programLogo: event.data?.assets?.programLogo || [],
      agendaFile: event.data?.assets?.agendaFile || [],
      teaser: event.data?.assets?.teaser || [],
      isVisibleOnBiogenLinc: event.data?.biogenLincConfig?.isVisibleOnBiogenLinc,
      isSwitzerlantSpecific: event.data?.biogenLincConfig?.isSwitzerlandSpecific,
      enableGuestRegistration: event.data?.biogenLincConfig?.enableGuestRegistration,
      enableGuestRegistrationCountryDropdown: event.data?.biogenLincConfig?.enableGuestRegistrationCountryDropdown,
      enableDeclineModal: (event.data as PhysicalMeetingFromDB)?.enableDeclineModal,
      checkConsent: event.data?.biogenLincConfig?.checkConsent,
      isPublic: event.data?.biogenLincConfig?.isPublic ? EventPromotionalityEnum.public : EventPromotionalityEnum.gated,
      prcCodeEventDetails: event.data?.biogenLincConfig?.prcCodeEventDetails,
      participants: event.data?.participants,
      showSendInvitations: event.data?.biogenLincConfig?.showSendInvitations,
      reminderNotificationEmails: event.data?.reminderNotificationEmails,
      reminderNotificationPhoneNumbers: event.data?.reminderNotificationPhoneNumbers,
    },
  };
  return createEventState;
};
