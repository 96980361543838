import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { participantFragment } from '../fragments/commonData.fragments';
import { errorToast } from 'basics/utils/toast';
import { RegisterParticipantInput } from 'generated/event.types';
import { Participant } from 'modules/events/basics/types/events.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export type UseCheckInParticipantMutationResponse = {
  checkInParticipant: Participant;
};

export type UseCheckInParticipantMutation = [
  (participant: RegisterParticipantInput) => Promise<Participant | null>,
  MutationResult<UseCheckInParticipantMutationResponse>,
];
export const checkInParticipantMutationCall = gql`
mutation checkInParticipantRegistration($participant: RegisterParticipantInput!) {
    checkInParticipant(participant: $participant) {
     ${participantFragment}
    }
}`;

export const useCheckInParticipantMutation = (): UseCheckInParticipantMutation => {
  const { t } = useTranslation();
  const [checkInRegistrationMutation, state] = useMutation<UseCheckInParticipantMutationResponse>(checkInParticipantMutationCall);

  const executeCheckInRegistrationMutation = useCallback(async (participant) => {
    try {
      const { data } = await checkInRegistrationMutation({
        variables: { participant },
      });
      if (!data?.checkInParticipant) {
        errorToast(t('errors_standard'));
        return null;
      }
      return data?.checkInParticipant;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error);
        Logger.logError({
          error,
          customContext,
          transactionName: 'checkInParticipant.ts - useCheckInParticipantMutation',
        });
        errorToast(t('errors_standard'));
      }
      return null;
    }
  }, [checkInRegistrationMutation, t]);

  return [executeCheckInRegistrationMutation, state];
};
