import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { YodaColors, YodaFontSize, YodaSpacing, fromUnitsToRem } from 'yoda-ui/yodaTheme';

export const manageSpeakersDialogStyle: SxProps<Theme> = {
  '& .MuiDialog-paper': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '25px',
  },
};

export const manageSpeakersDialogTitleStyle: SxProps<Theme> = {
  marginBottom: fromUnitsToRem(YodaSpacing.large),
  fontSize: YodaFontSize.xLarge,
  color: YodaColors.gray5,
};

