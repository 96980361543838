import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { errorToast } from 'basics/utils/toast';
import { RegisterParticipantInput } from 'generated/event.types';
import { ErrorMessage } from 'modules/events/basics/enums/events.enums';
import { Participant } from 'modules/events/basics/types/events.types';
import { participantFragment } from 'modules/events/graphql/fragments/commonData.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export type UseAcceptParticipantMutationResponse = {
  acceptParticipant: Participant;
};

export type UseAcceptParticipantMutation = [
  (participant: RegisterParticipantInput) => Promise<Participant | null>,
  MutationResult<UseAcceptParticipantMutationResponse>,
];
export const addParticipantMutationCall = gql`
mutation acceptParticipant($participant: RegisterParticipantInput!) {
    acceptParticipant(participant: $participant) {
     ${participantFragment}
    }
}`;

export const useAcceptParticipantMutation = (): UseAcceptParticipantMutation => {
  const { t } = useTranslation();
  const [acceptParticipantMutation, state] = useMutation<UseAcceptParticipantMutationResponse>(addParticipantMutationCall);

  const executeAcceptParticipantMutation = useCallback(async (participant) => {
    try {
      const { data } = await acceptParticipantMutation({
        variables: { participant },
      });
      if (!data?.acceptParticipant) {
        errorToast(t('errors_standard'));
        return null;
      }
      return data?.acceptParticipant;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error);
        Logger.logError({
          error,
          customContext,
          transactionName: 'acceptParticipant.ts - useAcceptParticipantMutation',
        });

        if (customContext?.message === ErrorMessage.ZOOM_TOO_MANY_REQUESTS) {
          errorToast(t('error_zoom_too_many_requests'));
        } else {
          errorToast(t('errors_standard'));
        }
      }
      return null;
    }
  }, [acceptParticipantMutation, t]);

  return [executeAcceptParticipantMutation, state];
};
