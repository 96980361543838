import { Component, FC } from 'react';
import Box from 'yoda-ui/components/Box';
import { YodaFontSize, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

type FieldCardTitleProps = {
  title?: Component | string;
};

const FieldCardTitle: FC<FieldCardTitleProps> = ({ title }) => {
  if (title) {
    return (
      <Box fontSize={ YodaFontSize.small } paddingBottom={ YodaSpacing.xxxSmall } fontWeight={ YodaFontWeight.light } >
        { title }
      </Box>
    );
  }
  return null;
};

export default FieldCardTitle;
