import { Box } from '@mui/material';
import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import useParticipantsItemChangeStatusModal from './ParticipantsItemChangeStatusModal.hooks';
import { RegisterParticipantInput } from 'generated/event.types';
import { Participant } from 'modules/events/basics/types/events.types';
import ConfirmationDialog from 'yoda-ui/components/Dialogs/ConfirmationDialog';
import Loader from 'yoda-ui/components/Loader';
import { YodaColors } from 'yoda-ui/yodaTheme';

type ParticipantsItemChangeStatusModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  participant: Participant;
  confirmActionHandler: ((registerParticipantInput: Partial<RegisterParticipantInput>) => Promise<boolean>) | undefined;
  title: string;
};

const ParticipantsItemChangeStatusModal: FC<ParticipantsItemChangeStatusModalProps> = ({ open, setOpen, participant,
  confirmActionHandler, title }) => {
  const {
    handleAction,
    submitLoading,
  } = useParticipantsItemChangeStatusModal({ setOpen, participant, confirmActionHandler });
  const { t } = useTranslation();

  return (open ? (
    <ConfirmationDialog
      dialogTitle={ title }
      onConfirm={ handleAction }
      onDismiss={ () => setOpen(false) }
      onConfirmLabel={ submitLoading ? <Loader center size={ 1 } color={ YodaColors.inherit } /> : t('actions_confirm_label') }
      onDismissLabel={ t('actions_cancel_label') }>
      <Box display='flex' flexDirection="column" justifyContent="center" textAlign="center">
        <Box>{ t('register_partipant_email_label') }: { participant.email } </Box>
        <Box>{ t('register_partipant_firstname_label') }: { participant.firstName } </Box>
        <Box>{ t('register_partipant_lastname_label') }: { participant.lastName } </Box>
        <Box>{ t('register_partipant_mdmid_label') }: { participant.mdmId } </Box>
      </Box>
    </ConfirmationDialog>) : null
  );
};

export default ParticipantsItemChangeStatusModal;
