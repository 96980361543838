import { YodaColors } from 'yoda-ui/yodaTheme';

export const NavLink = {
  flexGrow: 1,
  padding: '12px 0',
  fontWeight: 300,
  textAlign: 'left',
  textTransform: 'initial',
  color: YodaColors.gray5,
};

export const NavLinkHead = {
  ...NavLink,
  color: YodaColors.textSecondary,
};

export const NavLinkActive = {
  ...NavLink,
  fontWeight: 500,
  color: YodaColors.primaryBlue,
};

export const ItemIcon = {
  width: '22px',
  height: '22px',
  marginRight: '4px',
  marginLeft: '-4px',
};

export const ItemText = {
  marginTop: 0,
  marginBottom: 0,
};
