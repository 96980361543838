import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import useBiogenLincConfig from './BiogenLincConfigGroup.hook';
import CardForm from 'components/CardForm';
import CardSwitch from 'components/CardSwitch';
import { Market } from 'generated/event.types';
import { EventPromotionalityEnum } from 'modules/events/basics/enums/events.enums';
import PrcBlock from 'modules/events/components/PrcBlock';
import useCommunicationStep from 'modules/events/routes/CreateOnlineMeeting/Steps/CommunicationStep/CommunicationStep.hook';
import Box from 'yoda-ui/components/Box';
import CheckBoxYoda from 'yoda-ui/components/Form/CheckBoxYoda';
import RadioGroup from 'yoda-ui/components/Form/RadioGroup';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const BiogenLincConfigGroup: FC = () => {
  const { isVisibleOnBiogenLinc, isPublic, isGuestRegistrationEnabled } = useCommunicationStep();
  const {
    isVisibleOnBiogenLincConfig,
    eventPromotionalityConfig,
    prcValidationsConfig,
    isSwitzerlandSpecific,
    eventMarket,
    t,
  } = useBiogenLincConfig();

  const flags = useFlags();

  return (
    <Box>
      <Box paddingBottom={ YodaSpacing.small }>
        <CardSwitch { ...isVisibleOnBiogenLincConfig } />
      </Box>
      {
        isVisibleOnBiogenLinc && (
          <>
            <Box paddingBottom={ YodaSpacing.small }>
              <CardForm title={ eventPromotionalityConfig.formGroupConfig.title }>
                <RadioGroup { ...eventPromotionalityConfig.radioGroupConfig } />
                {
                  isPublic === EventPromotionalityEnum.public
                 && (<CheckBoxYoda { ...eventPromotionalityConfig.enableGuestRegistration } />)
                }

                {
                  isGuestRegistrationEnabled && (
                    <Box display="flex" flexDirection="column">
                      {
                        eventMarket === Market.com
                          && <CheckBoxYoda { ...eventPromotionalityConfig.enableGuestRegistrationCountryDropdown } />
                      }
                      <CheckBoxYoda { ...eventPromotionalityConfig.checkConsent } />
                    </Box>
                  )
                }

                {
                  isPublic === EventPromotionalityEnum.gated && eventPromotionalityConfig.enableDeclineModal && (
                    <Box display="flex" flexDirection="column">
                      <CheckBoxYoda { ...eventPromotionalityConfig.enableDeclineModal } />
                    </Box>
                  )
                }
              </CardForm>
            </Box>
          </>
        )
      }
      <Box paddingBottom={ YodaSpacing.small }>
        <CardForm title={ t('prcValidation_title') }>
          <Box marginBottom={ YodaSpacing.large }>
            <PrcBlock { ...prcValidationsConfig.eventDetails } />
          </Box>
        </CardForm>
      </Box>

      {
        eventMarket === Market.com && flags.eventsBiogenlinc5399SwissMarketIntoTheBo && (
          <Box paddingBottom={ YodaSpacing.small }>
            <CardSwitch { ...isSwitzerlandSpecific } />
          </Box>
        )
      }

    </Box>
  );
};

export default BiogenLincConfigGroup;
