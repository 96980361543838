import { pick } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Speaker } from 'modules/events/basics/types/speaker.types';
import { transformSpeakerToPossibleSpeaker } from 'modules/events/components/Speakers/Speakers.transformer';
import useLazyGetSpeakerById from 'modules/events/graphql/queries/getSpeakerByIdLazy';
import { PossibleSpeaker } from 'modules/events/groups/SpeakersGroup/SpeakersGroup.types';

import eventState from 'modules/events/states/events.states';

const useSpeakers = () => {
  const { t } = useTranslation();

  const transformToState = (speaker: Speaker) => pick(speaker, ['id', 'firstName', 'lastName', 'email']);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedSpeaker, setSelectedSpeaker] = useState<PossibleSpeaker | undefined>(undefined);

  const [showManageSpeakersModal, setShowManageSpeakersModal] = useState(false);

  const { queryGetSpeakerById } = useLazyGetSpeakerById();

  const handleEditSpeaker = async (speakerId: string) => {
    const speakerResponse = await queryGetSpeakerById({ variables: { id: speakerId } });
    if (speakerResponse.data.getSpeakerById) {
      setSelectedSpeaker(transformSpeakerToPossibleSpeaker(speakerResponse.data.getSpeakerById, eventState.createEvent._id));
      setOpenEditModal(true);
    }
  };

  const onManageSpeakersModalOpen = () => {
    setShowManageSpeakersModal(true);
  };

  const onManageSpeakersModalClose = () => {
    setShowManageSpeakersModal(false);
  };

  const transformSpeakerToCard = (speaker: Speaker) => ({
    id: speaker.id,
    property1: speaker.firstName,
    property2: speaker.lastName,
    ...(speaker?.email ? { property3: `(${speaker.email})` } : {}),
  });

  return {
    transformSpeakerToCard,
    transformToState,
    t,
    showManageSpeakersModal,
    onManageSpeakersModalOpen,
    onManageSpeakersModalClose,
    openEditModal,
    setOpenEditModal,
    handleEditSpeaker,
    selectedSpeaker,
  };
};

export default useSpeakers;
