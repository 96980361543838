import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { succesToast, errorToast } from 'basics/utils/toast';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export type UseSendEventInvitationMutationResponse = {
  sendEventInvitation: boolean;
};

export type UseSendEventInvitationMutation = [
  (eventId: string,) => Promise<boolean>,
  MutationResult<UseSendEventInvitationMutationResponse>,
];

export const sendEventInvitationMutationCall = gql`
mutation sendEventInvitation($eventId: String!) {
  sendEventInvitation(eventId: $eventId)
}`;

export const useSendEventInvitationMutation = (): UseSendEventInvitationMutation => {
  const { t } = useTranslation();
  const [
    sendEventInvitationMutation,
    state,
  ] = useMutation<UseSendEventInvitationMutationResponse>(sendEventInvitationMutationCall);

  const executeMutation = useCallback(async (eventId) => {
    try {
      const { data } = await sendEventInvitationMutation({
        variables: { eventId },
      });
      if (data?.sendEventInvitation) {
        succesToast(t('event_invitations_sent_label'));
      } else {
        errorToast(t('event_invitations_already_sent_label'));
      }
      return !!data?.sendEventInvitation;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error);
        Logger.logError({
          error,
          customContext,
          transactionName: 'sendInvitation.ts - useSendEventInvitationtMutation',
        });
        errorToast(t('errors_standard'));
      }
      return false;
    }
  }, [sendEventInvitationMutation, t]);

  return [executeMutation, state];
};
