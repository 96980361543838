import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import useRestrictedAccessConfig from '../RestricedAccessGroup/RestrictedAccessGroup.config';
import useInviteesGroupConfig from './InviteesGroup.config';
import { InviteesGroupEnums } from './InviteesGroup.enums';
import { upsertParticipants } from './InviteesGroup.utils';
import { succesToast } from 'basics/utils/toast';
import { HandleFilesExternal, HandleRemoveItemAsync } from 'components/QuickForm/QuickForm.types';
import { AssetTarget, AssetType, RegisterParticipantInput } from 'generated/event.types';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { ParticipantStatus } from 'modules/events/basics/enums/events.enums';
import { EventStateType, Participant } from 'modules/events/basics/types/events.types';
import { HybridMeetingDataState } from 'modules/events/basics/types/hybridMeeting.types';
import { transformParticipantsToQuickForm } from 'modules/events/basics/utils/participants.utils';
import { useClearParticipantStatusMutation } from 'modules/events/graphql/mutations/clearParticipantStatus';
import { useCreateParticipantsFromCSVMutation } from 'modules/events/graphql/mutations/createParticipantsFromCSV';
import { useCancelParticipantMutation } from 'modules/events/graphql/mutations/deleteParticipant';
import eventState from 'modules/events/states/events.states';
import { useYodaForm } from 'yoda-ui/yodaForm';

const useInviteesGroup = () => {
  const { UPLOAD_LIST, PRIVILEGED_LIST } = InviteesGroupEnums;
  const { t } = useTranslation();
  const { useWatchField } = useYodaForm();
  const [runCreateMutation] = useCreateParticipantsFromCSVMutation();
  const [runClearInviteeStatus] = useClearParticipantStatusMutation();
  const [executeCancelRegistrationMutation] = useCancelParticipantMutation();
  const { cardSwitchConfig, uploadInviteesConfig } = useInviteesGroupConfig();
  const { privilegedUsersConfig } = useRestrictedAccessConfig();
  const { createEvent } = useSnapshot(eventState) as EventStateType;
  const isRestrictedAccess = (createEvent?.data as HybridMeetingDataState)?.isRestrictedAccess;

  const showSendInvitationsFieldState = useWatchField(commonFields.showSendInvitations);
  const [selectedTab, setSelectedTab] = useState<InviteesGroupEnums>(UPLOAD_LIST);

  const handleFilesExternal: HandleFilesExternal = async (files, setErrorMessage) => {
    const errorMessage = t('errors_standard');
    if (!createEvent?._id) {
      setErrorMessage(errorMessage);
      return;
    }
    const { size, name } = files[0];
    const response = await runCreateMutation(
      files[0],
      {
        size,
        name,
        assetType: AssetType.document,
        assetTarget: AssetTarget.participantCsv,
      },
      createEvent._id,
      selectedTab === UPLOAD_LIST,
    );
    if (response?.participants?.length && eventState.createEvent.data) {
      const { participants: prevParticipants } = eventState.createEvent.data;
      eventState.createEvent.data.participants = upsertParticipants(prevParticipants || [], response.participants as Participant[]);
    } else {
      setErrorMessage(errorMessage);
    }
  };

  const updateInviteesList = async (updatedParticipant: Participant) => {
    if (eventState?.createEvent?.data?.participants) {
      const indexToUpdate = eventState.createEvent.data.participants.findIndex(
        (participant) => participant.mdmId === updatedParticipant.mdmId,
      );
      if (indexToUpdate !== -1) {
        // Replace the old invitees with the updated one
        eventState.createEvent.data.participants[indexToUpdate] = updatedParticipant;
        uploadInviteesConfig.defaultValue = eventState.createEvent.data.participants.filter((participant) => participant.restricted);
        privilegedUsersConfig.defaultValue = eventState.createEvent.data.participants.filter((participant) => !participant.restricted);
      }
    }
    return selectedTab === UPLOAD_LIST ? uploadInviteesConfig.defaultValue : privilegedUsersConfig.defaultValue;
  };

  const handleRemoveItemAsync: HandleRemoveItemAsync = async (id: string) => {
    let updatedList = null;
    if (createEvent?._id) {
      const selectedParticipant = createEvent.data?.participants?.find((eventParticipant) => eventParticipant.mdmId === id);
      if (!selectedParticipant?.registrationStatusHistory?.rejected) {
        const cancelledParticipant = await executeCancelRegistrationMutation(
          {
            mdmId: id,
            email: selectedParticipant?.email,
            eventId: createEvent._id,
            firstName: selectedParticipant?.firstName,
            lastName: selectedParticipant?.lastName,
          } as RegisterParticipantInput,
        );
        if (!!cancelledParticipant && eventState.createEvent.data) {
          updatedList = await updateInviteesList(cancelledParticipant as Participant);
          if (updatedList) {
            succesToast('Invitee has been cancelled');
          }
        }
      } else {
        const updatedParticipant = await runClearInviteeStatus(
          selectedParticipant.mdmId,
          createEvent._id,
          ParticipantStatus.rejected,
        );
        if (updatedParticipant && eventState.createEvent.data) {
          updatedList = await updateInviteesList(updatedParticipant as Participant);
          if (updatedList) {
            succesToast('Invitee has been invited back');
          }
        }
      }
    }
    return updatedList ? transformParticipantsToQuickForm(updatedList) : undefined;
  };

  const tabsConfig = isRestrictedAccess ? [
    {
      label: t('inviteesGroup_tabsConfig_uploadRestrictedList_label'),
      value: UPLOAD_LIST,
    },
    {
      label: t('inviteesGroup_tabsConfig_uploadPrivilegedList_label'),
      value: PRIVILEGED_LIST,
    },
  ]
    : [
      {
        label: t('inviteesGroup_tabsConfig_uploadList_label'),
        value: UPLOAD_LIST,
      },
    ];

  return {
    cardSwitchConfig,
    privilegedUsersConfig: {
      ...privilegedUsersConfig,
      handleFilesExternal,
      handleRemoveItemAsync,
    },
    uploadInviteesConfig: {
      ...uploadInviteesConfig,
      handleFilesExternal,
      handleRemoveItemAsync,
    },
    openedPanel: showSendInvitationsFieldState?.value || false,
    selectedTab,
    setSelectedTab,
    t,
    tabsConfig,
    isRestrictedAccess,
  };
};

export default useInviteesGroup;
