import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import LoggedOutDialog from './LoggedOutDialog';
import { BACKOFFICE_TITLE } from 'basics/constants/common.constants';
import { SCHEDULE_IMAGE } from 'basics/constants/images.constants';
import LargeContainer from 'components/Layout/SmallContainer';
import useAuth from 'hooks/useAuth';
import Box from 'yoda-ui/components/Box';
import Button from 'yoda-ui/components/Button';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';
import Grid from 'yoda-ui/components/Grid';
import { YodaFontSize, YodaFontWeight, YodaGridCellSize, YodaSpacing } from 'yoda-ui/yodaTheme';

const Login = () => {
  const { t } = useTranslation();
  const { handleLogin, getUserIsIdle } = useAuth();
  const [loggedOutDialogIsOpen, setLoggedOutDialogIsOpen] = useState(false);

  useEffect(() => {
    const isIdle = getUserIsIdle();
    if (isIdle) {
      setLoggedOutDialogIsOpen(true);
    }
  }, [getUserIsIdle]);

  return (
    <LargeContainer
      paddingRight={ YodaSpacing.xLarge }
      paddingLeft={ YodaSpacing.xLarge }
    >
      <LoggedOutDialog
        open={ loggedOutDialogIsOpen }
        setLoggedOutDialogIsOpen={ setLoggedOutDialogIsOpen }
      />
      <Grid container spacing={ YodaSpacing.medium }>
        <Grid item xs={ YodaGridCellSize.half }>
          <Box
            marginTop={ YodaSpacing.medium }
            fontWeight={ YodaFontWeight.bold }
            fontSize={ YodaFontSize.xxxxLarge }
            lineHeight="initial"
          >
            { t('titles_landing', { appName: BACKOFFICE_TITLE }) }
          </Box>
          <Box marginTop={ YodaSpacing.xxLarge } fontSize={ YodaFontSize.large }>
            { t('landing_subtitle') }
          </Box>
          <Box marginTop={ YodaSpacing.large }>
            <Button onClick={ handleLogin } buttonType={ ButtonType.primary }>{ t('actions_login_label') }</Button>
          </Box>
        </Grid>
        <Grid item xs={ YodaGridCellSize.half }>
          <Box height="70%" display="flex">
            <img src={ SCHEDULE_IMAGE } alt="biogen-schedule" />
          </Box>
        </Grid>
      </Grid>
    </LargeContainer>
  );
};

export default Login;
