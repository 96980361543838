import { JoiningChannel, ParticipantStatus } from 'modules/events/basics/enums/events.enums';
import { Participant, RegistrationStatusHistory } from 'modules/events/basics/types/events.types';

type ActiveStatus = {
  key: string;
  value: Date;
};

const filterParticipants = (participants: Participant[], validationFn: Function, sorted = true) => {
  const filtered = participants.filter(({ registrationStatusHistory: status }) => validationFn(status));
  if (sorted) {
    return filtered.sort((a, b) => (a.lastName > b.lastName ? 1 : -1));
  }
  return filtered;
};

// Mostly useful for hybrid event where we need to differentiate physical and online participants when calculating occupied seats
const filterPhysicalParticipants = (participants: Participant[], validationFn: Function, sorted = true) => {
  const filtered = participants
    .filter((participant) => participant.joiningChannel === JoiningChannel.physical)
    .filter(({ registrationStatusHistory: status }) => validationFn(status));
  if (sorted) {
    return filtered.sort((a, b) => (a.lastName > b.lastName ? 1 : -1));
  }
  return filtered;
};

const findParticipantLaterStatus = (registrationStatusHistory: RegistrationStatusHistory): ParticipantStatus | null => {
  const activesStatus = Object.entries(registrationStatusHistory).reduce<ActiveStatus[]>((acc, [key, value]) => {
    return value ? [...acc, { key, value }] : acc;
  }, []);
  const laterStatus: ActiveStatus = activesStatus.sort((a, b) => (a.value < b.value ? 1 : -1))[0];
  return Object.keys(ParticipantStatus).includes(laterStatus?.key) ? laterStatus.key as ParticipantStatus : null;
};

const filterByLaterStatus = (status: RegistrationStatusHistory, targets: ParticipantStatus[] | null, excludes = false) => {
  const laterStatus = findParticipantLaterStatus(status) as ParticipantStatus;
  const included = targets?.includes(laterStatus);
  return excludes ? !included : included;
};

const filterWithNoStatus = (status: RegistrationStatusHistory) => {
  return Object.values(ParticipantStatus).every((statusKey) => status[statusKey] === null);
};

// transform participants to avoid quickform logic problems due to invalid data from Veeva
const transformParticipantsToQuickForm = (participants?: Participant[]) => {
  return participants?.map((participant: Participant) => {
    return {
      ...participant,
      email: participant.email || 'unknown',
      firstName: participant.firstName || 'unknown',
      lastName: participant.lastName || 'unknown',
    };
  });
};

const PATTERN_ACCEPTED = ParticipantStatus.accepted;
const PATTERN_INVITED = ParticipantStatus.invited;
const PATTERN_REGISTERED = ParticipantStatus.invited; // Based on exclusion
const PATTERN_PENDING = ParticipantStatus.waitingList;
const PATTERN_REJECTED = ParticipantStatus.rejected;
const PATTERN_CHECKED_IN = ParticipantStatus.checkedIn;

const FILTER_ACCEPTED = (status: RegistrationStatusHistory) => filterByLaterStatus(status, [PATTERN_ACCEPTED, PATTERN_CHECKED_IN]);
const FILTER_INVITED = (status: RegistrationStatusHistory) => filterByLaterStatus(status, [PATTERN_INVITED]);
const FILTER_NO_STATUS = (status: RegistrationStatusHistory) => filterWithNoStatus(status);
const FILTER_REGISTERED = (status: RegistrationStatusHistory) => filterByLaterStatus(status, [PATTERN_REGISTERED, PATTERN_PENDING], true);
const FILTER_PENDING = (status: RegistrationStatusHistory) => filterByLaterStatus(status, [PATTERN_PENDING]);
const FILTER_REJECTED = (status: RegistrationStatusHistory) => filterByLaterStatus(status, [PATTERN_REJECTED]);

export {
  filterParticipants,
  filterPhysicalParticipants,
  findParticipantLaterStatus,
  FILTER_ACCEPTED,
  FILTER_INVITED,
  FILTER_NO_STATUS,
  FILTER_PENDING,
  FILTER_REGISTERED,
  FILTER_REJECTED,
  transformParticipantsToQuickForm,
};
