import { FC } from 'react';
import EventsListItem from './EventsListItem';
import { Event } from 'modules/events/basics/types/events.types';
import Box from 'yoda-ui/components/Box';

type EventsListProps = {
  events: Event[];
};

const EventsList: FC<EventsListProps> = ({ events }) => (
  <Box>
    {
      events.map((event) => (
        <EventsListItem key={ event._id } event={ event } />
      ))
    }
  </Box>
);

export default EventsList;
