import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { Presence } from 'generated/event.types';
import { PresenceOptions } from 'modules/events/basics/enums/events.enums';
import eventPresenceOptions from 'modules/events/basics/options/eventPresence.options';

export const useEventTypeSelectorConfig = (eventType: PresenceOptions | null) => {
  const { t } = useTranslation();

  const flags = useFlags();

  const eventTypeSelectorConfig = {
    label: t('events_event_type_label'),
    name: 'eventTypeSelector',
    placeholder: t('events_choose_event_type'),
    options: eventPresenceOptions(flags.eventsBiogenlinc4280EnablePhysicalEvents, flags.eventsBiogenlinc4745EnableHybridEvents),
    defaultValue: eventType || Presence.online,
    disableClearable: true,
  };

  return {
    eventTypeSelectorConfig,
  };
};
