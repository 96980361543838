import { array, date, mixed, object, string } from 'yup';
import { TimeZone } from 'basics/enums/timeZoneList.enums';

const timeSlotGroupSchema = {
  session: object()
    .shape({
      dateRange: array().of(date()).required(),
      startTime: string().required(),
      endTime: string().required(),
      timeZone: mixed<TimeZone>().oneOf(Object.values(TimeZone)).required(),
    })
    .required(),
};

export default timeSlotGroupSchema;
