import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import TabTitle from 'components/TabTitle';
import EventTestingGroup from 'modules/events/groups/EventTestingGroup/EventTestingGroup';
import usePublicationStep from 'modules/events/routes/CreateOnlineMeeting/Steps/PublicationStep/PublicationStep.hook';
import Box from 'yoda-ui/components/Box';

const PublicationStep: FC = () => {
  const { t } = usePublicationStep();
  const flag = useFlags();
  return (
    <Box>
      <TabTitle title={ t('event_step_publication') } />
      { flag.eventsBiogenlinc4744EventTesting ? <EventTestingGroup /> : null }
    </Box>
  );
};

export default PublicationStep;
