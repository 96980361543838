import { Dispatch, FC, ReactNode, SetStateAction, createContext, useState } from 'react';
import { PresenceOptions } from 'modules/events/basics/enums/events.enums';

type EventTypeProviderProps = {
  children: ReactNode;
};

type EventTypeContextType = {
  eventType: PresenceOptions;
  setEventType: Dispatch<SetStateAction<PresenceOptions>>;
};

const initialState = {
  eventType: PresenceOptions.all,
  setEventType: () => {},
};

export const EventTypeContext = createContext<EventTypeContextType>(initialState);

export const EventTypeProvider: FC<EventTypeProviderProps> = ({ children }) => {
  const [eventType, setEventType] = useState<PresenceOptions>(PresenceOptions.all);

  const contextValue = {
    eventType,
    setEventType,
  };

  return (
    <EventTypeContext.Provider value={ contextValue }>
      { children }
    </EventTypeContext.Provider>
  );
};
