import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import useCxSurveySchema from './CxSurvey.schema';
import { CxSurveyType } from './CxSurvey.types';
import { SelectOption } from 'basics/options/options.types';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { EventStateType } from 'modules/events/basics/types/events.types';
import { HybridMeetingDataState } from 'modules/events/basics/types/hybridMeeting.types';
import eventState from 'modules/events/states/events.states';

const useCxSurveyConfig = (
  surveysId: SelectOption[] | undefined,
) => {
  const { t } = useTranslation();
  const cxSurveySchema = useCxSurveySchema();
  const snap = useSnapshot(eventState) as EventStateType;
  const cxSurveyConfig: CxSurveyType = {
    cxSurveyActivateJourney: {
      label: t('events_cx_survey_activate_journey'),
      name: commonFields.cxSurveyActivation,
      validation: cxSurveySchema.cxSurveyActivateJourney,
      checked: snap.createEvent.data?.cxSurveyActivation ?? false,
    },
    physicalCxSurveyActivateJourney: {
      label: t('events_cx_survey_activate_journey'),
      name: commonFields.physicalCxSurveyActivation,
      validation: cxSurveySchema.cxSurveyActivateJourney,
      checked: (snap.createEvent.data as HybridMeetingDataState)?.physicalCxSurveyActivation ?? false,
    },
    cxSurveyId: {
      label: t('events_cx_survey_id_label'),
      name: commonFields.cxSurveyId,
      options: surveysId || [],
      validation: cxSurveySchema.cxSurveyId,
      defaultValue: snap.createEvent.data?.cxSurveyId || null,
      placeholder: t('events_cx_survey_id_placeholder'),
      freeOption: true,
    },
    physicalCxSurveyId: {
      label: t('events_cx_survey_id_label'),
      name: commonFields.physicalCxSurveyId,
      options: surveysId || [],
      validation: cxSurveySchema.cxSurveyId,
      defaultValue: (snap.createEvent.data as HybridMeetingDataState)?.physicalCxSurveyId || null,
      placeholder: t('events_cx_survey_id_placeholder'),
      freeOption: true,
    },
    cxSurveyUseCaseName: {
      label: t('events_cx_survey_usecase_name_label'),
      name: commonFields.cxSurveyUseCaseName,
      placeholder: t('events_cx_survey_usecase_name_placeholder'),
      defaultValue: snap.createEvent.data?.cxSurveyUseCaseName,
      required: true,
    },
    physicalCxSurveyUseCaseName: {
      label: t('events_cx_survey_usecase_name_label'),
      name: commonFields.physicalCxSurveyUseCaseName,
      placeholder: t('events_cx_survey_usecase_name_placeholder'),
      defaultValue: (snap.createEvent.data as HybridMeetingDataState)?.physicalCxSurveyUseCaseName,
      required: true,
    },
    cxSurveyUseCaseCategory: {
      label: t('events_cx_survey_usecase_category_label'),
      name: commonFields.cxSurveyUseCaseCategory,
      placeholder: t('events_cx_survey_usecase_category_placeholder'),
      defaultValue: snap.createEvent.data?.cxSurveyUseCaseCategory,
      required: true,
    },
    physicalCxSurveyUseCaseCategory: {
      label: t('events_cx_survey_usecase_category_label'),
      name: commonFields.physicalCxSurveyUseCaseCategory,
      placeholder: t('events_cx_survey_usecase_category_placeholder'),
      defaultValue: (snap.createEvent.data as HybridMeetingDataState)?.physicalCxSurveyUseCaseCategory,
      required: true,
    },
  };

  return {
    cxSurveyConfig,
    cxSurveyValidation: cxSurveySchema.cxSurveyValidation,
  };
};

export default useCxSurveyConfig;
