import { useTranslation } from 'react-i18next';
import { string } from 'yup';

const maxProgramNameLength = 20;

const useAddProgramSchema = () => {
  const { t } = useTranslation();
  const requiredLabel = t('form_errors_required');
  const maxProgramNameMessage = `${t('event_modal_program_max_error_message', { count: maxProgramNameLength })}`;

  return {
    programName: string()
      .required(requiredLabel)
      .max(maxProgramNameLength, maxProgramNameMessage),
  };
};

export default useAddProgramSchema;
