import { FC } from 'react';
import Box from 'yoda-ui/components/Box';
import DialogContent from 'yoda-ui/components/Dialogs/Dialog/DialogContent';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const DialogFormContent: FC = ({ children }) => (
  <DialogContent>
    <Box marginY={ YodaSpacing.large }>
      { children }
    </Box>
  </DialogContent>
);

export default DialogFormContent;
