import { Dispatch, FC, SetStateAction } from 'react';
import DialogFormActions from './DialogFormActions';
import DialogFormContent from './DialogFormContent';
import { ActionButtonProps } from 'components/ActionButton/ActionButton';
import DefaultDialogTitle from 'components/Dialogs/DefaultDialogTitle';
import Box from 'yoda-ui/components/Box';
import Dialog from 'yoda-ui/components/Dialogs/Dialog';
import { YodaBorderRadius, YodaJustifyContent, YodaSpacing } from 'yoda-ui/yodaTheme';

type DialogFormProps = {
  dialogTitle: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onCancel?: () => void;
  cancelButtonConfig: ActionButtonProps;
  submitButtonConfig: ActionButtonProps;
  fullWidth?: boolean;
  justifyActions?: YodaJustifyContent;
};

const DialogForm: FC<DialogFormProps> = ({
  dialogTitle,
  open,
  setOpen,
  children,
  onCancel,
  cancelButtonConfig,
  submitButtonConfig,
  fullWidth,
  justifyActions,
}) => {
  const cancelDialogForm = () => {
    if (onCancel) {
      onCancel();
    }
    setOpen(false);
  };

  return (
    <Dialog
      open={ open }
      maxWidth="md"
      borderRadius={ YodaBorderRadius.medium }
      fullWidth={ fullWidth }
    >
      <Box padding={ YodaSpacing.xLarge }>
        <DefaultDialogTitle cancelDialog={ cancelDialogForm }>{ dialogTitle }</DefaultDialogTitle>
        <DialogFormContent>
          { children }
        </DialogFormContent>
        <DialogFormActions
          cancelButtonConfig={ cancelButtonConfig }
          submitButtonConfig={ submitButtonConfig }
          justifyActions={ justifyActions }
        />
      </Box>
    </Dialog>
  );
};

export default DialogForm;
