import { YodaBorderRadius, YodaColors, YodaFontSize, YodaSpacing } from 'yoda-ui/yodaTheme';

export const ListItem = {
  flexDirection: 'column',
  marginTop: YodaSpacing.xxSmall,
  marginBottom: YodaSpacing.xxSmall,
  borderRadius: YodaBorderRadius.small,
  border: `2px solid ${YodaColors.gray2}`,
  fontSize: YodaFontSize.small,
  color: YodaColors.textPrimary,
};
