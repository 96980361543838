import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { UserData } from 'generated/event.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const createOrUpdateUserDataMutationCall = gql`
  mutation createUserData($userData: UserDataInput!){
    createOrUpdateUserData(userData: $userData){
      market
    }
  }
`;

type ResultType = {
  createOrUpdateUserData: UserData;
};

type UseCreateOrUpdateEventsUserDataMutation = [
  (userData: Partial<UserData>) => Promise<UserData | undefined | null>,
  MutationResult<ResultType>,
];

export const useCreateOrUpdateEventsUserDataMutation = (): UseCreateOrUpdateEventsUserDataMutation => {
  const [createOrUpdateUserData, state] = useMutation<ResultType>(createOrUpdateUserDataMutationCall, {
    fetchPolicy: 'network-only',
  });

  const executeMutation = useCallback(async (userData) => {
    try {
      const { data } = await createOrUpdateUserData({
        variables: {
          userData,
        },
      });
      return data?.createOrUpdateUserData;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error, userData);
        Logger.logError({
          error,
          customContext,
          transactionName: 'createOrUpdateEventsUserData.ts - useCreateOrUpdateEventsUserDataMutation',
        });
      }
      return null;
    }
  }, [createOrUpdateUserData]);

  return [executeMutation, state];
};
