import { gql, useQuery } from '@apollo/client';
import { Market, SurveyInfos } from 'generated/event.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_SURVEYS_ID = gql`
  query getSurveysId (
    $market: Market!,
  ) {
    getSurveysId( market : $market) {
      surveyId,
      usecaseCategory,
      usecaseName
    }
  }
  `;

type ResultType = {
  getSurveysId: SurveyInfos[] | null;
};

const useGetSurveysId = (market: Market | undefined) => {
  const { data, loading, error } = useQuery<ResultType>(GET_SURVEYS_ID, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !market,
    variables: {
      market,
    },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getSurveysId.ts - useGetSurveysId',
    });
  }
  return {
    data: data?.getSurveysId || [],
    loading,
    error,
  };
};

export default useGetSurveysId;
