import { useTranslation } from 'react-i18next';
import useAddProgramSchema from './AddProgram.schema';
import { AddProgramModalConfig } from './AddProgram.types';

const useAddProgramConfig = () => {
  const { t } = useTranslation();
  const addProgramSchema = useAddProgramSchema();
  const addProgramConfig: AddProgramModalConfig = {
    programName: {
      label: t('events_modal_program_input_label'),
      name: 'programName',
      placeholder: t('events_modal_program_input_placeholder'),
      required: true,
      defaultValue: '',
      validation: addProgramSchema.programName,
    },
  };

  return { addProgramConfig };
};

export default useAddProgramConfig;
