import { SelectOption } from 'basics/options/options.types';
import { EventStatusFilterType } from 'contexts/eventStatus';

const eventStatusOptions = (): SelectOption[] => {
  return [
    { id: EventStatusFilterType.draft, label: 'Draft' },
    { id: EventStatusFilterType.published, label: 'Published' },
    { id: EventStatusFilterType.all, label: 'All' },
  ];
};
export default eventStatusOptions;
