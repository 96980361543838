import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { useYodaForm } from 'yoda-ui/yodaForm';

const useCommunicationStep = () => {
  const { useWatchField } = useYodaForm();
  const isVisibleOnBiogenLincField = useWatchField(commonFields.isVisibleOnBiogenLinc);
  const isGuestRegistrationEnabled = useWatchField(commonFields.enableGuestRegistration);
  const isSwitzerlandSpecificField = useWatchField(commonFields.isSwitzerlandSpecific);

  const showSendInvitations = useWatchField(commonFields.showSendInvitations);
  const isPublic = useWatchField(commonFields.isPublic);

  return {
    isVisibleOnBiogenLinc: isVisibleOnBiogenLincField?.value,
    showSendInvitations: showSendInvitations?.value,
    isPublic: isPublic?.value,
    isGuestRegistrationEnabled: isGuestRegistrationEnabled?.value,
    isSwitzerlandSpecificField: isSwitzerlandSpecificField?.value,
  };
};

export default useCommunicationStep;
