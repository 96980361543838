import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { succesToast, warningToast } from 'basics/utils/toast';
import { Speaker, SpeakerInput } from 'modules/events/basics/types/speaker.types';
import { speakerFragment } from 'modules/events/graphql/fragments/speaker.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const deleteSpeakerMutationCall = gql`
mutation deleteSpeakerMutation($speaker: SpeakerInput!) {
    deleteSpeaker(speaker: $speaker) {
        ${speakerFragment}
    }
}
`;

type ResultType = {
  deleteSpeaker: Speaker;
};

type UseDeleteSpeakerMutation = [
  (speakerProfile: SpeakerInput) => Promise<Speaker | undefined | null>,
  MutationResult<ResultType>,
];

export const useDeleteSpeakerMutation = (): UseDeleteSpeakerMutation => {
  const { t } = useTranslation();
  const [deleteSpeaker, state] = useMutation<ResultType>(deleteSpeakerMutationCall);

  const executeMutation = useCallback(async (speakerProfile: SpeakerInput) => {
    try {
      const data = await deleteSpeaker({
        variables: { speaker: speakerProfile },
      });
      succesToast(t('deleteSpeaker_toast_success'));
      return data.data?.deleteSpeaker;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error, speakerProfile);
        Logger.logError({
          error,
          customContext,
          transactionName: 'deleteSpeaker.ts - useDeleteSpeakerMutation',
        });
        if (customContext.description) warningToast(String(customContext.description));
      }
      return null;
    }
  }, [deleteSpeaker, t]);

  return [executeMutation, state];
};
