import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddProgramModal from './AddProgramModal';
import Box from 'yoda-ui/components/Box';
import Button from 'yoda-ui/components/Button';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';
import Icon from 'yoda-ui/components/Icons/Icon';
import { Icons } from 'yoda-ui/components/Icons/Icon/Icon';

const AddProgram: FC = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Box>
      <Button
        startIcon={ <Icon name={ Icons.add } /> }
        buttonType={ ButtonType.secondary }
        onClick={ () => { setOpen(true); } }
      >
        { t('events_modal_program_open_btn_label') }
      </Button>
      <AddProgramModal open={ open } setOpen={ setOpen } />
    </Box>
  );
};

export default AddProgram;
