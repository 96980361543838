import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import InviteesRegistrationPanel from '../../components/InviteesRegistrationPanel';
import LargeContainer from 'components/Layout/LargeContainer';
import { OnlineEvent } from 'generated/event.types';
import EditOnlineSideNav from 'modules/events/components/EditOnlineSideNav/EditOnlineSideNav';
import useEditOnlineSideNavigation from 'modules/events/components/EditOnlineSideNav/EditOnlineSideNav.hook';
import useManageMeeting from 'modules/events/routes/ManageMeeting/ManageMeeting.hook';
import Alert from 'yoda-ui/components/Alert/Alert';
import Box from 'yoda-ui/components/Box';
import Loader from 'yoda-ui/components/Loader';
import { YodaFormProvider } from 'yoda-ui/yodaForm';
import { fromUnitsToRem, YodaSpacing } from 'yoda-ui/yodaTheme';

const ManageMeeting: FC = () => {
  const { loading, yodaFormMethods, event, participants, setParticipants, seats, isRestrictedAccess, reminderSent } = useManageMeeting();
  const { enumActions, selectedPanel } = useEditOnlineSideNavigation();
  const { t } = useTranslation();

  if (loading) {
    return <Box display="flex" flexDirection="column" justifyContent="center" width="100vw"><Loader center /></Box>;
  }

  return (
    <LargeContainer
      columnGap={ fromUnitsToRem(32) }
      display="grid"
      gridTemplateColumns={ `${fromUnitsToRem(132)} 1fr` }
      margin="0 auto"
      paddingRight={ YodaSpacing.xLarge }
      paddingLeft={ YodaSpacing.xLarge }
    >
      <Box marginTop={ YodaSpacing.medium }>
        <EditOnlineSideNav/>
      </Box>
      <YodaFormProvider { ...yodaFormMethods.providerFields }>
        <Box hidden={ selectedPanel !== enumActions.inviteesRegistrations } flexGrow="1" gridColumn="2">
          {
            (
              (event?.data as OnlineEvent)?.biogenLincConfig?.showSendInvitations
                  && !(event?.data as OnlineEvent).invitationSent) ? (<Alert severity={ 'warning' }>
                { t('event_publish_invitation_not_sent_yet_label') }
              </Alert>) : null
          }
          {
            !reminderSent ? (<Alert severity={ 'info' }>
              { t('event_publish_reminder_not_sent_label') }
            </Alert>) : null
          }

          {
            event?._id
          && <InviteesRegistrationPanel
            participants={ participants }
            seats={ seats }
            isRestrictedAccess={ isRestrictedAccess }
            event={ event }
            eventId={ event._id }
            setParticipants={ setParticipants }
            collectMobileNumber={ event.data.collectParticipantPhoneNumber }
          />
          }
        </Box>
      </YodaFormProvider>
    </LargeContainer>
  );
};

export default ManageMeeting;
