import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventStatus } from 'generated/event.types';
import { Event } from 'modules/events/basics/types/events.types';
import { useUnpublishEventMutation } from 'modules/events/graphql/mutations/unpublishEvent';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';
import { useYodaCreateForm } from 'yoda-ui/yodaForm';

const useConfirmationModal = (setOpen: Dispatch<SetStateAction<boolean>>,
  event: Event,
  setCurrentStatus: Dispatch<SetStateAction<EventStatus>>) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();
  const { providerFields } = useYodaCreateForm();
  const [unpublishOnlineEvent] = useUnpublishEventMutation();

  const handleUnpublishEvent = async () => {
    setIsSubmitting(true);
    const updatedStatus = await unpublishOnlineEvent(event._id);
    if (updatedStatus) {
      setCurrentStatus(updatedStatus);
    }
    setIsSubmitting(false);
    setOpen(false);
  };

  const cancelButtonConfig = {
    buttonProps: {
      onClick: () => setOpen(false),
      buttonType: ButtonType.secondary,
    },
    label: t('actions_cancel_label'),
  };

  const submitButtonConfig = {
    buttonProps: {
      onClick: handleUnpublishEvent,
      buttonType: ButtonType.primary,
    },
    label: t('events_confirm_unpublish'),
    loading: isSubmitting,
  };

  return {
    cancelButtonConfig,
    providerFields,
    submitButtonConfig,
    handleUnpublishEvent,
    t,
  };
};

export default useConfirmationModal;
