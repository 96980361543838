import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext, useEffect, useState } from 'react';
import useEventsFilters from './EventsFilters.hook';
import { Order } from 'basics/enums/general.enums';
import { EventTypeContext } from 'contexts/eventType';
import { useCurrentUserState } from 'contexts/user';
import { Market, Presence } from 'generated/event.types';
import { PresenceOptions } from 'modules/events/basics/enums/events.enums';
import { Event } from 'modules/events/basics/types/events.types';
import { sortEvents } from 'modules/events/basics/utils/events.utils';
import useGetFullPrivateEventsCountQuery from 'modules/events/graphql/queries/getFullPrivateEventsCount';
import useLazyGetFullPrivateEvents from 'modules/events/graphql/queries/getLazyFullPrivateEvents';

const usePastEvents = () => {
  const { user } = useCurrentUserState();

  const flags = useFlags();
  const showRsvpEvents = flags.eventsBiogenlinc4973ShowRsvpEvents;

  const eventTypeContext = useContext(EventTypeContext);

  const market = user?.market ? user.market as Market : null;
  const [pastEvents, setPastEvents] = useState<Event[]>([]);
  const [currentMarket, setCurrentMarket] = useState<Market | null>(market);
  const [expanded, setExpanded] = useState<boolean>(false);

  const { data: pastOnlineEventCount } = useGetFullPrivateEventsCountQuery(market, true, Presence.online, showRsvpEvents);
  const { data: pastPhysicalEventCount } = useGetFullPrivateEventsCountQuery(market, true, Presence.physical, showRsvpEvents);
  const { data: pastHybridEventCount } = useGetFullPrivateEventsCountQuery(market, true, Presence.hybrid, showRsvpEvents);
  const pastEventsCount = {
    [PresenceOptions.online]: pastOnlineEventCount,
    [PresenceOptions.physical]: pastPhysicalEventCount,
    [PresenceOptions.hybrid]: pastHybridEventCount,
    [PresenceOptions.all]: pastOnlineEventCount + pastPhysicalEventCount + pastHybridEventCount,
  };

  const {
    queryGetFullPrivateEvents,
    data: events,
    called: pastEventQueryCalled,
    loading: pastEventsLoading,
    error,
  } = useLazyGetFullPrivateEvents();

  useEffect(() => {
    if (currentMarket !== market) {
      setExpanded(false);
    }
  }, [currentMarket, market, pastEvents]);

  useEffect(() => {
    if (pastEventQueryCalled && !pastEventsLoading) {
      setPastEvents(events);
      setCurrentMarket(market);
    }
  }, [currentMarket, events, expanded, market, pastEventQueryCalled, pastEventsLoading]);

  const { filteredData } = useEventsFilters(pastEvents);

  const onExpand = async () => {
    await queryGetFullPrivateEvents({ variables: { source: 'backoffice', market, isPast: true, showRsvpEvents } });
    setExpanded(true);
  };

  return {
    onExpand,
    expanded,
    pastEventsCount: pastEventsCount[eventTypeContext.eventType],
    pastEventsLoading,
    pastEventsData: filteredData ? sortEvents(filteredData, 'data.session.endDate', Order.desc) : [],
    error,
  };
};

export default usePastEvents;
