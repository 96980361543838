import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Presence, RegisterParticipantInput } from 'generated/event.types';
import { Participant } from 'modules/events/basics/types/events.types';
import { FILTER_ACCEPTED, filterParticipants, filterPhysicalParticipants } from 'modules/events/basics/utils/participants.utils';
import { useAcceptParticipantMutation } from 'modules/events/graphql/mutations/acceptParticipant';
import { useCheckInParticipantMutation } from 'modules/events/graphql/mutations/checkInParticipant';
import { useCancelParticipantMutation } from 'modules/events/graphql/mutations/deleteParticipant';
import { useSyncParticipantMutation } from 'modules/events/graphql/mutations/syncParticipant';

const useParticipantsGroup = (
  participants: Participant[],
  eventId: string,
  setParticipants: Dispatch<SetStateAction<Participant[] | null>>,
  seats: number,
  presence?: Presence,
  priviligedList?: boolean,
) => {
  const updateParticipantList = useCallback(
    (updatedParticipant: Participant | null, registerParticipantInput: Partial<RegisterParticipantInput>) => {
      if (updatedParticipant) {
        const newParticipantList = participants.map((participant) => {
          if ((participant.mdmId && participant.mdmId === registerParticipantInput.mdmId)
          || (participant.email && participant.email === registerParticipantInput.email)) {
            return { ...participant, ...updatedParticipant };
          }
          return participant;
        });
        setParticipants(newParticipantList);
        return true;
      }
      return false;
    }, [participants, setParticipants],
  );

  const [executeCancelRegistrationMutation] = useCancelParticipantMutation();
  const cancelRegistrationHandler = useCallback(async (registerParticipantInput: Partial<RegisterParticipantInput>) => {
    const cancelledParticipant = await executeCancelRegistrationMutation(
      { ...registerParticipantInput, eventId } as RegisterParticipantInput,
    );
    return updateParticipantList(cancelledParticipant, registerParticipantInput);
  }, [eventId, executeCancelRegistrationMutation, updateParticipantList]);

  const [executeAcceptParticipantMutation] = useAcceptParticipantMutation();
  const acceptRegistrationHandler = useCallback(async (registerParticipantInput: Partial<RegisterParticipantInput>) => {
    const acceptedParticipant = await executeAcceptParticipantMutation(
      { ...registerParticipantInput, eventId } as RegisterParticipantInput,
    );
    return updateParticipantList(acceptedParticipant, registerParticipantInput);
  }, [eventId, executeAcceptParticipantMutation, updateParticipantList]);

  const [executeSyncParticipantMutation] = useSyncParticipantMutation();
  const syncParticipantHandler = useCallback(async (registerParticipantInput: Partial<RegisterParticipantInput>) => {
    const synchedParticipant = await executeSyncParticipantMutation(
      { ...registerParticipantInput, eventId } as RegisterParticipantInput,
    );
    return updateParticipantList(synchedParticipant, registerParticipantInput);
  }, [eventId, executeSyncParticipantMutation, updateParticipantList]);

  const [executeCheckInParticipantMutation] = useCheckInParticipantMutation();
  const checkInRegistrationHandler = useCallback(async (registerParticipantInput: Partial<RegisterParticipantInput>) => {
    const synchedParticipant = await executeCheckInParticipantMutation(
      { ...registerParticipantInput, eventId } as RegisterParticipantInput,
    );
    return updateParticipantList(synchedParticipant, registerParticipantInput);
  }, [eventId, executeCheckInParticipantMutation, updateParticipantList]);

  const { t } = useTranslation();
  const isHybrid = presence === Presence.hybrid;

  const itemsAcceptedCount = useMemo(
    () => (isHybrid
      ? filterPhysicalParticipants(participants, FILTER_ACCEPTED).length
      : filterParticipants(participants, FILTER_ACCEPTED).length),
    [isHybrid, participants],
  );

  const acceptedLabel = t('participantsGroup_formGroupTitle_accepted_count', {
    count: itemsAcceptedCount,
  });

  const availableSeats = seats - itemsAcceptedCount;
  const availableSeatsLabel = t('participantsGroup_formGroupTitle_remaining_seats', {
    count: availableSeats >= 0 ? availableSeats : 0,
  });

  const groupTitle = seats > 0
    // eslint-disable-next-line max-len
    ? `${t(priviligedList ? 'participantsGroup_formGroupTitle_privileged' : 'participantsGroup_formGroupTitle')} - ${acceptedLabel} / ${availableSeatsLabel}`
    : t('participantsGroup_formGroupTitle');

  const refreshButtonLabel = t('participantsGroup_refreshButtonLabel');

  return {
    acceptRegistrationHandler,
    cancelRegistrationHandler,
    syncParticipantHandler,
    checkInRegistrationHandler,
    groupTitle,
    refreshButtonLabel,
  };
};

export default useParticipantsGroup;
