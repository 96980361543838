import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import useInviteesGroupSchema from '../InviteesGroup/InviteesGroup.schema';
import useRestrictedAccessSchema from './RestrictedAccessGroup.schema';
import { FileExtension } from 'basics/types/files.types';
import { UseFormDropZoneStateSource } from 'components/QuickForm/FormsBucket/FormDropZone/FormDropZone.enums';
import { AssetTarget, AssetType } from 'generated/event.types';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { HybridEventStateType } from 'modules/events/basics/types/events.types';
import { UploadInviteesProps } from 'modules/events/components/UploadInvitees/UploadInvitees.types';
import eventState from 'modules/events/states/events.states';
import { Icons } from 'yoda-ui/components/Icons/Icon';

type UploadPrivilegedUsersConfig = Omit<UploadInviteesProps, 'handleFilesExternal' | 'handleRemoveItemAsync'>;

const useRestrictedAccessConfig = () => {
  const { t } = useTranslation();
  const inviteesGroupSchema = useInviteesGroupSchema();
  const restrictedAccessGroupSchema = useRestrictedAccessSchema();
  const snap = useSnapshot(eventState) as HybridEventStateType;

  const cardSwitchConfig = {
    name: commonFields.isRestrictedAccess,
    title: t('privilegedGroup_cardSwitch_title'),
    defaultValue: snap.createEvent.data?.isRestrictedAccess || false,
    disabled: false,
  };

  const privilegedUsersConfig: UploadPrivilegedUsersConfig = useMemo(
    () => ({
      name: commonFields.participantsWishList,
      formConfig: {
        btnLabel: t('privilegedGroup_tabUpload_btnLabel'),
        btnIcon: Icons.upload,
        maxFileSize: 2048000,
        maxFilesNumber: 0,
        extensionsAllowed: [FileExtension.csv],
        message: t('privilegedGroup_tabUpload_message'),
        label: t('privilegedGroup_tabUpload_label'),
        name: commonFields.participantsWishList,
        assetTarget: AssetTarget.participantCsv,
        assetType: AssetType.document,
        assetsArePublic: true,
        stateSource: UseFormDropZoneStateSource.EXTERNAL,
      },
      listConfig: {
        noItemLabel: t('privilegedGroup_tabUpload_list_noItemLabel'),
        title: t('privilegedGroup_tabUpload_list_title'),
      },
      defaultValue: snap.createEvent.data?.participants?.filter((participant) => !participant.restricted) || [],
      validation: inviteesGroupSchema.participantsWishList,
      disabled: false,
    }),
    [t, snap.createEvent.data?.participants, inviteesGroupSchema.participantsWishList],
  );

  const restrictedDescriptionConfig = {
    label: t('events_restrictedDescription_input_label'),
    name: commonFields.restrictedDescription,
    placeholder: t('events_restrictedDescription_input_placeholder'),
    required: false,
    defaultValue: snap.createEvent.data?.restrictedDescription,
    validation: restrictedAccessGroupSchema.restrictedDescription,
  };

  return {
    cardSwitchConfig,
    privilegedUsersConfig,
    restrictedDescriptionConfig,
  };
};

export default useRestrictedAccessConfig;
