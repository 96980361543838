import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BO_MAYA_CARD, BO_EVENTS_CARD, BO_HOME_BACKGROUND } from 'basics/constants/images.constants';
import KonamiCodeAnimation from 'components/Easter/EasterEgg';
import useHomePage from 'components/HomePage/HomePage.hook';
import {
  cardText,
  cardsImageAndButton,
  homeBannerTitle,
  homeBannerTitleText,
  homeBannerWrapper,
  homeBaseline,
  homeBaselineWrapper,
  navigationCardsWrapper,
} from 'components/HomePage/HomePage.styles';
import LargeContainer from 'components/Layout/LargeContainer';
import Box from 'yoda-ui/components/Box';
import Button from 'yoda-ui/components/Button';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const HomePage: FC = () => {
  const { t } = useTranslation();
  const flags = useFlags();
  const {
    handleNavigateToEvents,
    handleNavigateToMaya,
    user,
  } = useHomePage();

  return (
    <LargeContainer
      flexDirection="column"
      marginBottom={ YodaSpacing.xLarge }
      paddingRight={ YodaSpacing.xLarge }
      paddingLeft={ YodaSpacing.xLarge }
    >
      <Box sx={ homeBannerWrapper }>
        <Box sx={ homeBannerTitle }>
          <Box sx={ homeBannerTitleText }>
            { `Hi ${user?.name ? user?.name : ''} !` }
          </Box>
        </Box>
        <img src={ BO_HOME_BACKGROUND } alt="bo-home-background" style={
          {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: '-1',
          }
        } />
      </Box>
      {
        flags.backofficeBiogenlinc000EnableEasterEgg
          ? <KonamiCodeAnimation /> : null
      }
      <Box sx={ homeBaselineWrapper }>
        <Box sx={ homeBaseline }>
          { t('home_select_product') }
        </Box>
        <Box sx={ homeBaseline }></Box>
      </Box>
      <Box sx={ navigationCardsWrapper }>
        <Box sx={ { ...cardsImageAndButton, marginRight: YodaSpacing.xSmall } }>
          <img src={ BO_MAYA_CARD } alt="bo-maya-card" />
          <Box sx={ cardText }>
            <Box>
              { t('home_maya_title') }
            </Box>
            <Box>
              { t('home_maya_baseline') }
            </Box>
          </Box>
          <Button
            buttonType={ ButtonType.home }
            onClick={ handleNavigateToMaya }
          >
            { t('home_maya_card_action') }
          </Button>
        </Box>
        <Box sx={ cardsImageAndButton }>
          <img src={ BO_EVENTS_CARD } alt="bo-events-card" />
          <Box sx={ cardText }>
            <Box>
              { t('home_events_title') }
            </Box>
            <Box>
              { t('home_events_baseline') }
            </Box>
          </Box>
          <Button
            buttonType={ ButtonType.home }
            onClick={ handleNavigateToEvents }
          >
            { t('home_events_card_action') }
          </Button>
        </Box>
      </Box>

    </LargeContainer>
  );
};

export default HomePage;
