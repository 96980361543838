import { useContext } from 'react';
import { EventDateContext } from 'contexts/eventDate';
import { EventStatusContext } from 'contexts/eventStatus';
import { EventTypeContext } from 'contexts/eventType';
import { Presence } from 'generated/event.types';
import { PresenceOptions } from 'modules/events/basics/enums/events.enums';
import { Event } from 'modules/events/basics/types/events.types';

const isolateDate = (date: Date) => {
  return new Date(date).setHours(0, 0, 0, 0);
};

const useEventsFilters = (data: Event[] | undefined) => {
  const eventTypeContext = useContext(EventTypeContext);
  const eventStatusContext = useContext(EventStatusContext);
  const eventDateContext = useContext(EventDateContext);

  const filterItem = (item: Event) => {
    if (item.data.session) {
      const { startDate, endDate } = item.data.session;
      const isEventPresenceMatch = (
        eventTypeContext.eventType === PresenceOptions.all || (eventTypeContext.eventType as unknown as Presence) === item.presence
      );
      const isEventStatusMatch = (eventStatusContext.eventStatus === 'all'
        ? true : item.status.toString() === eventStatusContext.eventStatus);
      let isDateInRange: boolean;
      if (eventDateContext.date[0] && eventDateContext.date[1]) {
        if (startDate && endDate) {
          isDateInRange = isolateDate(startDate) >= isolateDate(eventDateContext.date[0])
          && isolateDate(endDate) <= isolateDate(eventDateContext.date[1]);
        } else {
          isDateInRange = false;
        }
      } else {
        isDateInRange = true;
      }
      return (isEventPresenceMatch && isEventStatusMatch && isDateInRange);
    }

    return true;
  };

  const filteredItems = data?.filter(filterItem);

  return {
    filteredData: filteredItems,
  };
};

export default useEventsFilters;
