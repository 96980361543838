import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FormGroup from 'components/FormGroup';
import { CommonGroups } from 'modules/events/basics/enums/commonForms.enums';

import SessionInformation from 'modules/events/components/SessionInformation';
import useSessionInformationGroup from 'modules/events/groups/SessionInformationGroup/SessionInformationGroup.hook';

const SessionInformationGroup: FC = () => {
  const { t } = useTranslation();
  const { isPublished } = useSessionInformationGroup();

  return (
    <FormGroup title={ t('events_session_information_card_title') } groupName={ CommonGroups.sessionInformation }>
      <SessionInformation
        disabled={ isPublished }
      />
    </FormGroup>
  );
};

export default SessionInformationGroup;
