import { FC, useState } from 'react';
import Box from 'yoda-ui/components/Box';
import Collapse from 'yoda-ui/components/Collapse';
import CollapseIcon from 'yoda-ui/components/Icons/CollapseIcon';
import Icon from 'yoda-ui/components/Icons/Icon';
import { Icons } from 'yoda-ui/components/Icons/Icon/Icon';
import Paper from 'yoda-ui/components/Paper';
import { useYodaForm } from 'yoda-ui/yodaForm';
import { YodaBorderRadius, YodaColors, YodaFontSize, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

type FormGroupProps = {
  title: string;
  groupName: string;
  collapsible?: boolean;
};

const FormGroup: FC<FormGroupProps> = ({ title, children, groupName, collapsible = false }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const { useWatchGroup } = useYodaForm();
  const groupWatch = useWatchGroup(groupName);
  const isGroupInValid = groupWatch && !groupWatch.isValid && groupWatch.showError;

  return (
    <Paper margin={ 0 } borderRadius={ YodaBorderRadius.medium }>
      <Box
        display="flex"
        flexDirection="row"
        marginY={ YodaSpacing.medium }
        paddingTop={ YodaSpacing.medium }
        paddingX={ YodaSpacing.large }
        fontSize={ YodaFontSize.xLarge }
        fontWeight={ YodaFontWeight.medium }
        onClick={ handleClick }
        justifyContent="space-between"
      >
        { title }
        <Box>
          { isGroupInValid && <Icon name={ Icons.errorOutline } style={ { color: YodaColors.red } } /> }
          {
            collapsible && <CollapseIcon
              sx={ { marginTop: -YodaSpacing.xxSmall } }
              expand={ open }
              onClick={ handleClick }
              aria-expanded={ open }
              aria-label="show more"
              data-testid="collapse-icon"
            />
          }
        </Box>
      </Box>
      <Collapse
        style={ { borderTop: `1px solid ${YodaColors.gray1}` } }
        in={ collapsible ? open : true }
        timeout="auto"
      >
        <Box padding={ YodaSpacing.large }>
          { children }
        </Box>
      </Collapse>
    </Paper>
  );
};

export default FormGroup;
