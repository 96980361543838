import { array, object, string, number } from 'yup';

export const maxTestimonyLength = 200;
const useAgendaGroupSchema = () => {
  return {
    agenda: array()
      .nullable(true)
      .of(object().shape({
        dateRange: string().required(),
        description: string(),
        endTime: string().required(),
        startTime: string().required(),
        title: string().required(),
      })),
    agendaFile: array()
      .nullable(true)
      .of(object().shape({
        id: string().required(),
        size: number().required(),
        name: string().required(),
        url: string().required(),
      })),
    agendaFileTitle: string()
      .nullable(true),
    agendaFileDescription: string()
      .nullable(true),
  };
};

export default useAgendaGroupSchema;
