import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { emailTypeOptions, typesKeys } from 'basics/options/emailType.options';
import { FileExtension } from 'basics/types/files.types';
import { AssetTarget, AssetType } from 'generated/event.types';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { EventStateType } from 'modules/events/basics/types/events.types';
import useGetAllCepsLazyQuery from 'modules/events/graphql/queries/getAllCepsLazy';
import useEmailNotesSchema from 'modules/events/groups/EmailNotesGroup/EmailNotesGroup.schema';
import {
  normalizeValueToSelectOption,
  transformStringsToSelectOptions,
} from 'modules/events/groups/EmailNotesGroup/EmailNotesGroup.transformer';
import { EmailNotesType } from 'modules/events/groups/EmailNotesGroup/EmailNotesGroup.types';
import eventState from 'modules/events/states/events.states';
import { Icons } from 'yoda-ui/components/Icons/Icon/Icon';
import { useYodaForm } from 'yoda-ui/yodaForm';

const useEmailNotesConfig = (
  selectTargetListOnChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void,
) => {
  const { t } = useTranslation();
  const emailNotesSchema = useEmailNotesSchema();
  const snap = useSnapshot(eventState) as EventStateType;

  const radioConfigs = [
    {
      label: t('events_email_biogen_two_logos_option_label'),
      key: '2',
      value: '2',
      disabled: false,
    },
    {
      label: t('events_email_biogen_one_logo_option_label'),
      key: '1',
      value: '1',
      disabled: false,
    },
    {
      label: t('events_email_biogen_no_logo_option_label'),
      key: '0',
      value: '0',
      disabled: false,
    },
  ];

  const yodaMethods = useYodaForm();
  const { setValue, setFormIsDirty } = yodaMethods;
  const MAX_TAGS_COUNT = 5;

  const limitMaxTagCount = (fieldName: string, value: string[]) => {
    const normalizedValues = value.map(normalizeValueToSelectOption);
    const finalValues = normalizedValues.length > MAX_TAGS_COUNT ? normalizedValues.splice(0, MAX_TAGS_COUNT) : normalizedValues;
    setValue(fieldName, finalValues);
    setFormIsDirty(true);
  };

  const limitMaxEmailTagCount = (event: ChangeEvent<HTMLInputElement>, value: string[]) => {
    limitMaxTagCount(commonFields.reminderNotificationEmails, value);
  };

  const limitMaxPhoneNumberTagCount = (event: ChangeEvent<HTMLInputElement>, value: string[]) => {
    limitMaxTagCount(commonFields.reminderNotificationPhoneNumbers, value);
  };

  const emailNotesConfig: EmailNotesType = {
    emailHeaderNote: {
      label: t('events_email_header_note_label'),
      name: commonFields.emailHeaderNote,
      placeholder: t('events_email_header_note_placeholder'),
      required: false,
      defaultValue: snap.createEvent.data?.emailHeaderNote,
      validation: emailNotesSchema.emailHeaderNote,
    },
    emailHeaderNoteEmailTypes: {
      label: t('events_email_header_note_email_types_label'),
      placeholder: t('events_email_note_email_types_placeholder'),
      name: commonFields.emailHeaderNoteEmailTypes,
      options: emailTypeOptions,
      defaultValue: snap.createEvent.data?.emailHeaderNoteEmailTypes || typesKeys,
      required: true,
      disableClearable: true,
      validation: emailNotesSchema.emailType,
    },
    emailFooterNote: {
      label: t('events_email_footer_note_label'),
      name: commonFields.emailFooterNote,
      placeholder: t('events_email_footer_note_placeholder'),
      required: false,
      defaultValue: snap.createEvent.data?.emailFooterNote,
      validation: emailNotesSchema.emailFooterNote,
    },
    emailFooterNoteEmailTypes: {
      label: t('events_email_footer_note_email_types_label'),
      placeholder: t('events_email_note_email_types_placeholder'),
      name: commonFields.emailFooterNoteEmailTypes,
      options: emailTypeOptions,
      defaultValue: snap.createEvent.data?.emailFooterNoteEmailTypes || typesKeys,
      required: true,
      disableClearable: true,
      validation: emailNotesSchema.emailType,
    },
    emailFooterDisclaimerText: {
      label: t('events_email_footer_disclaimer_text_label'),
      name: commonFields.emailFooterDisclaimerText,
      placeholder: t('events_email_footer_disclaimer_text_placeholder'),
      required: false,
      defaultValue: snap.createEvent.data?.emailFooterDisclaimerText,
      validation: emailNotesSchema.emailFooterDisclaimerText,
    },
    emailContentfulUrl: {
      label: t('events_email_contentful_url_label'),
      name: commonFields.emailContentfulUrl,
      placeholder: t('events_email_contentful_url_placeholder'),
      required: false,
      defaultValue: snap.createEvent?.contentfulUrl,
      validation: emailNotesSchema?.emailContentfulUrl,
    },
    programLogo: {
      name: commonFields.programLogo,
      cardImageFormConfig: {
        btnLabel: t('events_modal_program_image_dropzone_action_label'),
        btnIcon: Icons.upload,
        maxFileSize: 2048000,
        maxFilesNumber: 1,
        extensionsAllowed: [FileExtension.jpg, FileExtension.jpeg, FileExtension.png],
        message: t('events_modal_program_image_dropzone_message'),
        label: t('events_modal_program_image_dropzone_action_label'),
        name: commonFields.programLogo,
        assetTarget: AssetTarget.programLogo,
        assetType: AssetType.image,
        assetsArePublic: true,
      },
      dimensions: {
        maxHeight: 80,
        maxWidth: 80,
        minHeight: 80,
        minWidth: 80,
      },
      listConfig: {
        noItemLabel: t('asset_upload_no_image_label'),
        title: t('asset_upload_image_title'),
      },
      validation: emailNotesSchema?.programLogo,
      defaultValue: snap.createEvent.data?.programLogo || [],
    },
    emailPrcCode: {
      label: t('events_email_prcValidation_field_label'),
      name: commonFields.emailPrcCode,
      placeholder: t('events_email_prcValidation_field_placeholder'),
      defaultValue: snap.createEvent.data?.emailPrcCode,
      validation: emailNotesSchema?.emailPrcCode,
    },
    biogenLogo: {
      selectTargetList: {
        title: t('events_email_biogen_logo_title'),
        name: commonFields.biogenLogo,
        radioConfigs,
        row: true,
        defaultValue: snap.createEvent.data?.biogenLogo || '2',
        validation: emailNotesSchema.biogenLogo,
        onChange: selectTargetListOnChange,
      },
    },
    CEPsearch: {
      label: t('events_email_cep_search_title'),
      lazyQueryHook: useGetAllCepsLazyQuery,
      placeholder: t('events_email_cep_search_placeholder'),
    },
    invitationEmailMayaId: {
      label: t('events_email_maya_id_label'),
      name: commonFields.invitationEmailMayaId,
      placeholder: t('events_email_maya_id_placeholder'),
      defaultValue: snap.createEvent.data?.invitationEmailMayaId,
      validation: emailNotesSchema?.mayaId,
    },
    invitationEmailUTM: {
      label: t('events_email_maya_utm_label'),
      name: commonFields.invitationEmailUTM,
      placeholder: t('events_email_maya_utm_placeholder'),
      defaultValue: snap.createEvent.data?.invitationEmailUTM,
      validation: emailNotesSchema?.UTM,
    },
    confirmationEmailMayaId: {
      label: t('events_email_maya_id_label'),
      name: commonFields.confirmationEmailMayaId,
      placeholder: t('events_email_maya_id_placeholder'),
      defaultValue: snap.createEvent.data?.confirmationEmailMayaId,
      validation: emailNotesSchema?.mayaId,
    },
    confirmationEmailUTM: {
      label: t('events_email_maya_utm_label'),
      name: commonFields.confirmationEmailUTM,
      placeholder: t('events_email_maya_utm_placeholder'),
      defaultValue: snap.createEvent.data?.confirmationEmailUTM,
      validation: emailNotesSchema?.UTM,
    },
    reminder48hMayaId: {
      label: t('events_email_maya_id_label'),
      name: commonFields.reminder48hMayaId,
      placeholder: t('events_email_maya_id_placeholder'),
      defaultValue: snap.createEvent.data?.reminder48hMayaId,
      validation: emailNotesSchema?.mayaId,
    },
    reminder48hUTM: {
      label: t('events_email_maya_utm_label'),
      name: commonFields.reminder48hUTM,
      placeholder: t('events_email_maya_utm_placeholder'),
      defaultValue: snap.createEvent.data?.reminder48hUTM,
      validation: emailNotesSchema?.UTM,
    },
    reminder24hMayaId: {
      label: t('events_email_maya_id_label'),
      name: commonFields.reminder24hMayaId,
      placeholder: t('events_email_maya_id_placeholder'),
      defaultValue: snap.createEvent.data?.reminder24hMayaId,
      validation: emailNotesSchema?.mayaId,
    },
    reminder24hUTM: {
      label: t('events_email_maya_utm_label'),
      name: commonFields.reminder24hUTM,
      placeholder: t('events_email_maya_utm_placeholder'),
      defaultValue: snap.createEvent.data?.reminder24hUTM,
      validation: emailNotesSchema?.UTM,
    },
    reminder12hMayaId: {
      label: t('events_email_maya_id_label'),
      name: commonFields.reminder12hMayaId,
      placeholder: t('events_email_maya_id_placeholder'),
      defaultValue: snap.createEvent.data?.reminder12hMayaId,
      validation: emailNotesSchema?.mayaId,
    },
    reminder12hUTM: {
      label: t('events_email_maya_utm_label'),
      name: commonFields.reminder12hUTM,
      placeholder: t('events_email_maya_utm_placeholder'),
      defaultValue: snap.createEvent.data?.reminder12hUTM,
      validation: emailNotesSchema?.UTM,
    },
    reminder1hMayaId: {
      label: t('events_email_maya_id_label'),
      name: commonFields.reminder1hMayaId,
      placeholder: t('events_email_maya_id_placeholder'),
      defaultValue: snap.createEvent.data?.reminder1hMayaId,
      validation: emailNotesSchema?.mayaId,
    },
    reminder1hUTM: {
      label: t('events_email_maya_utm_label'),
      name: commonFields.reminder1hUTM,
      placeholder: t('events_email_maya_utm_placeholder'),
      defaultValue: snap.createEvent.data?.reminder1hUTM,
      validation: emailNotesSchema?.UTM,
    },
    cxSurveyMayaId: {
      label: t('events_email_maya_id_label'),
      name: commonFields.cxSurveyMayaId,
      placeholder: t('events_email_maya_id_placeholder'),
      defaultValue: snap.createEvent.data?.cxSurveyMayaId,
      validation: emailNotesSchema?.mayaId,
    },
    cxSurveyUTM: {
      label: t('events_email_maya_utm_label'),
      name: commonFields.cxSurveyUTM,
      placeholder: t('events_email_maya_utm_placeholder'),
      defaultValue: snap.createEvent.data?.cxSurveyUTM,
      validation: emailNotesSchema?.UTM,
    },
    teamContactCheckbox: {
      label: t('events_email_team_contact_checbox_label'),
      name: commonFields.teamContactCheckbox,
      checked: snap.createEvent.data?.teamContactCheckbox,
    },
    teamContactEmail: {
      label: t('events_email_team_contact_email_label'),
      name: commonFields.teamContactEmail,
      placeholder: t('events_email_team_contact_email_placeholder'),
      type: 'email',
      defaultValue: snap.createEvent.data?.teamContactEmail || t('events_email_team_contact_default_email'),
      validation: emailNotesSchema.contactTeamMail,
    },
    teamContactDescription: {
      label: t('events_email_team_contact_description_label'),
      name: commonFields.teamContactDescription,
      defaultValue: snap.createEvent.data?.teamContactDescription || t('events_email_team_contact_default_description'),
      placeholder: t('events_email_team_contact_description_placeholder'),
    },
    reminderNotificationEmails: {
      label: t('events_reminder_not_sent_notification_emails_label'),
      name: commonFields.reminderNotificationEmails,
      placeholder: t('events_reminder_not_sent_notification_emails_placeholder'),
      defaultValue: transformStringsToSelectOptions(snap.createEvent.data?.reminderNotificationEmails),
      options: [],
      required: false,
      freeOption: true,
      validation: emailNotesSchema.reminderNotificationEmails,
      clearOnBlur: true,
      onChange: limitMaxEmailTagCount,
    },
    reminderNotificationPhoneNumbers: {
      label: t('events_reminder_not_sent_notification_phone_numbers_label'),
      name: commonFields.reminderNotificationPhoneNumbers,
      placeholder: t('events_reminder_not_sent_notification_phone_numbers_placeholder'),
      defaultValue: transformStringsToSelectOptions(snap.createEvent.data?.reminderNotificationPhoneNumbers),
      options: [],
      required: false,
      freeOption: true,
      clearOnBlur: true,
      validation: emailNotesSchema.reminderNotificationPhoneNumbers,
      onChange: limitMaxPhoneNumberTagCount,
    },
  };

  return {
    emailNotesConfig,
  };
};

export default useEmailNotesConfig;
