import InfoIcon from '@mui/icons-material/Info';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { FC, useState } from 'react';
import { InformativeTooltipProps } from './InformativeTooltip.types';

const InformativeTooltip: FC<InformativeTooltipProps> = ({
  title,
  imageUrl,
}) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <ClickAwayListener onClickAway={ handleTooltipClose }>
        <Tooltip title={
          <>
            <a href={ imageUrl } target="blank">
              <img width='100px' height='100px' src={ imageUrl }></img>
            </a>
            <p style={ { whiteSpace: 'pre-line' } }> { title } </p>
          </>
        }
        placement="top-end"
        PopperProps={
          {
            disablePortal: true,
          }
        }
        onClose={ handleTooltipClose }
        open={ open }
        disableFocusListener
        disableHoverListener
        disableTouchListener
        onClick={ () => window.open(imageUrl) }>
          <IconButton onClick={ handleTooltipOpen }>
            <InfoIcon/>
          </IconButton>
        </Tooltip>
      </ClickAwayListener>
    </>
  );
};

export default InformativeTooltip;
