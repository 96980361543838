import { getUTCDate } from 'basics/utils/dates/dates.utils';
import { Session } from 'modules/events/basics/types/eventData.types';
import { TimeSlot } from 'modules/events/basics/types/events.types';

export const transformTimeSlotToSession = (timeSlot?: TimeSlot): Session | undefined => {
  if (timeSlot) {
    const { dateRange: [startDate, endDate], startTime, endTime, timeZone } = timeSlot;
    return ({
      startDate: getUTCDate(startDate || undefined, startTime, timeZone) || new Date(),
      endDate: getUTCDate(endDate || undefined, endTime, timeZone) || new Date(),
      timeZone,
    });
  }
  return undefined;
};
