import { YodaColors, YodaFontSize, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

export const homeBannerWrapper = {
  boxSizing: 'border-box',
  marginLeft: -YodaSpacing.xLarge,
  marginRight: -YodaSpacing.xLarge,
  position: 'relative',
};

export const homeBannerTitle = {
  minHeight: '300px',
  paddingTop: '100px',
  paddingBottom: '100px',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
};

export const homeBaselineWrapper = {
  display: 'flex',
  justifyContent: 'space-around',
};

export const homeBaseline = {
  marginTop: YodaSpacing.xxxxLarge,
  width: '560px',
  fontSize: YodaFontSize.xxLarge,
  color: YodaColors.gray5,
  fontWeight: YodaFontWeight.medium,
};

export const homeBannerTitleText = {
  fontSize: YodaFontSize.xxxxLarge,
  color: YodaColors.white,
  paddingRight: YodaSpacing.xLarge,
  paddingLeft: YodaSpacing.xxxxLarge,
  marginLeft: YodaSpacing.xxxxLarge,
  maxWidth: '40%',
};

export const navigationCardsWrapper = {
  display: 'flex',
  justifyContent: 'space-around',
  marginTop: YodaSpacing.xxxLarge,
};

export const cardText = {
  '& .MuiBox-root:first-of-type': {
    position: 'absolute',
    bottom: 160,
    left: 32,
    fontSize: YodaFontSize.xxLarge,
    fontWeight: YodaFontWeight.medium,
    color: YodaColors.white,
  },

  '& .MuiBox-root:last-of-type': {
    position: 'absolute',
    bottom: 119,
    left: 32,
    fontSize: YodaFontSize.large,
    fontWeight: YodaFontWeight.medium,
    color: YodaColors.white,
  },
};

export const cardsImageAndButton = {
  position: 'relative',

  '& img': {
    width: '560px',
  },

  '& .MuiButton-root': {
    position: 'absolute',
    bottom: 39,
    left: 32,
  },
};

