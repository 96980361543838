import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  createOnlineMeetingWithIdRoute,
  homeRoute,
  loginRoute,
  eventsRoute,
  createPhysicalMeetingWithIdRoute,
  manageMeetingWithIdRoute,
  createHybridMeetingWithIdRoute,
} from 'basics/constants/routes.constants';
import HomePage from 'components/HomePage/HomePage';
import IdleTimerDialog from 'components/IdleTimerDialog';
import CreateHybridMeeting from 'modules/events/routes/CreateHybridMeeting';
import CreateOnlineMeeting from 'modules/events/routes/CreateOnlineMeeting';
import CreatePhysicalMeeting from 'modules/events/routes/CreatePhysicalMeeting';
import Events from 'modules/events/routes/Events';
import ManageMeeting from 'modules/events/routes/ManageMeeting';
import NotFound from 'routes/AuthenticatedApp/NotFound';

const AuthenticatedApp: FC = () => {
  const flags = useFlags();

  return (
    <>
      <IdleTimerDialog />
      <Routes>
        <Route path="*" element={ <NotFound /> } />
        <Route path={ loginRoute } element={ <Navigate to="/" replace /> } />
        {
          flags.enableEvents && (
            <>
              <Route path={ homeRoute } element={ <HomePage /> } />
              <Route path={ eventsRoute } element={ <Events /> } />
              <Route path={ createOnlineMeetingWithIdRoute } element={ <CreateOnlineMeeting /> } />
              <Route path={ createPhysicalMeetingWithIdRoute } element={ <CreatePhysicalMeeting /> } />
              <Route path={ createHybridMeetingWithIdRoute } element={ <CreateHybridMeeting /> } />
              <Route path={ manageMeetingWithIdRoute } element={ <ManageMeeting /> } />
            </>
          )
        }
      </Routes>
    </>
  );
};

export default AuthenticatedApp;
