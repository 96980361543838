import { SyntheticEvent, useContext } from 'react';
import { SelectOption } from 'basics/options/options.types';
import { EventTypeContext } from 'contexts/eventType';
import { PresenceOptions } from 'modules/events/basics/enums/events.enums';
import { useEventTypeSelectorConfig } from 'modules/events/components/EventTypeSelector/EventTypeSelector.config';

const useEventTypeSelector = () => {
  const eventTypeContext = useContext(EventTypeContext);
  const { eventTypeSelectorConfig } = useEventTypeSelectorConfig(eventTypeContext.eventType);

  const handleEventTypeChange = (event: SyntheticEvent<Element, Event>, value: SelectOption) => {
    if (value?.id) {
      eventTypeContext.setEventType(value.id as PresenceOptions);
    }
  };

  return {
    handleEventTypeChange,
    eventTypeSelectorConfig,
    eventType: eventTypeContext.eventType,
  };
};

export default useEventTypeSelector;
