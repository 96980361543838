import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { PossibleSpeaker } from 'modules/events/groups/SpeakersGroup/SpeakersGroup.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_LAZY_SPEAKER_BY_ID = gql`
  query getSpeakerById($id: String!) {
    getSpeakerById(id: $id) {
        id: _id
        title
        firstName
        lastName
        email
        personalInfosVisibility {
            eventId
            showTitle
            showImage
            showEmail
            showAffiliation
            showSpecialty
        }
        profile {
            default {
            affiliation
            specialty
            }
        }
        image {
            id: _id
            name
            url
            size
        }
    }
  }
`;

type UseLazyGetSpeakerById = () => {
  queryGetSpeakerById: Function;
  loading: boolean;
  data?: PossibleSpeaker | null;
  error?: ApolloError;
  called: boolean;
};

const useLazyGetSpeakerById: UseLazyGetSpeakerById = () => {
  const [queryGetSpeakerById, { loading, data, error, called }] = useLazyQuery(GET_LAZY_SPEAKER_BY_ID, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getLazySpeakerById.ts - useLazyGetSpeakerById',
    });
  }

  return {
    queryGetSpeakerById,
    loading,
    data: data?.getSpeakerById,
    error,
    called,
  };
};

export default useLazyGetSpeakerById;
