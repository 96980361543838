import { FC } from 'react';
import CardHeader from 'components/CardHeader';
import Box from 'yoda-ui/components/Box';
import SwitchYoda from 'yoda-ui/components/Form/SwitchYoda';
import Paper from 'yoda-ui/components/Paper';
import { YodaBorderRadius, YodaColors, YodaSpacing } from 'yoda-ui/yodaTheme';

type CardSwitchProps = {
  defaultValue?: boolean;
  name: string;
  text?: string;
  title: string;
  disabled?: boolean;
  onclick?: Function;
};

const CardSwitch: FC<CardSwitchProps> = ({
  name,
  defaultValue = false,
  title,
  text,
  disabled = false,
  onclick = () => {},
}) => (
  <Paper margin={ 0 } borderRadius={ YodaBorderRadius.medium }>
    <CardHeader title={ title }>
      <Box>
        <SwitchYoda name={ name } defaultValue={ defaultValue } disabled={ disabled } onChange={ onclick } />
      </Box>
    </CardHeader>
    {
      text && (
        <Box
          width="70%"
          color={ YodaColors.gray4 }
          paddingLeft={ YodaSpacing.large }
          paddingBottom={ YodaSpacing.medium }
        >
          { text }
        </Box>
      )
    }
  </Paper>
);

export default CardSwitch;
