import { useTranslation } from 'react-i18next';
import { array, string, number, object } from 'yup';

const usePrimaryInformationsSchema = () => {
  const { t } = useTranslation();
  const maxDescriptionLength = 4000;
  const maxLengthDescriptionMessage = `${t('events_description_input_max_length_error_message', { count: maxDescriptionLength })}`;

  const maxTitleLength = 250;
  const maxLengthTitleMessage = `${t('events_title_input_max_length_error_message', { count: maxTitleLength })}`;

  return {
    title: string()
      .required()
      .max(maxTitleLength, maxLengthTitleMessage),
    description: string()
      .nullable(true)
      .max(maxDescriptionLength, maxLengthDescriptionMessage),
    product: string().required(),
    program: array()
      .nullable(true)
      .of(object().shape({
        id: string().required(),
        programName: string().required(),
      })),
    cardImage: array()
      .nullable(true)
      .of(object().shape({
        id: string().required(),
        size: number().required(),
        name: string().required(),
        url: string().required(),
      })),
  };
};

export default usePrimaryInformationsSchema;
