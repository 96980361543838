import { ListItemButton } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultDialogTitle from 'components/Dialogs/DefaultDialogTitle';
import { getEditRouteFromEvent } from 'modules/events/basics/utils/events.utils';
import EditSpeakerProfileModal from 'modules/events/components/EditSpeakerProfile/EditSpeakerProfileModal';
import GridList from 'modules/events/components/GridList/GridList';
import useManageSpeakers from 'modules/events/components/ManageSpeakers/ManageSpeakers.hook';
import { manageSpeakersDialogStyle } from 'modules/events/components/ManageSpeakers/ManageSpeakers.style';
import { ActionButton, ButtonVariant } from 'yoda-ui/components/BiogenLincDS/Buttons';
import Box from 'yoda-ui/components/Box';
import ConfirmationDialog from 'yoda-ui/components/Dialogs/ConfirmationDialog';
import Dialog from 'yoda-ui/components/Dialogs/Dialog';
import TextYoda from 'yoda-ui/components/Form/TextYoda/TextYoda';
import { Icons } from 'yoda-ui/components/Icons/Icon';
import List from 'yoda-ui/components/List';
import ListItem from 'yoda-ui/components/List/ListItem';
import { YodaFormProvider } from 'yoda-ui/yodaForm';
import { YodaColors, YodaSpacing } from 'yoda-ui/yodaTheme';

type ManageSpeakersProps = {
  onClose: () => void;
  isOpen: boolean;
};

const ManageSpeakers: FC<ManageSpeakersProps> = ({ onClose, isOpen = false }) => {
  const { yodaFormMethods,
    formConfig,
    listConfig,
    data,
    loading,
    onPageChange,
    onPageSizeChange,
    count,
    openCreateSpeakerModal,
    setOpenCreateSpeakerModal,
    updateSpeakerList,
    speakersSearchQuery,
    selectedSpeaker,
    onCreateSpeaker,
    confirmDeleteSpeakerModalOpen,
    onCloseConfirmDeleteSpeakerModal,
    onDeleteSpeakerConfirm } = useManageSpeakers(isOpen);
  const { t } = useTranslation();
  return (<Dialog
    open={ isOpen }
    onClose={ onClose }
    maxWidth="md"
    minWidth="50%"
    minHeight="350px"
    sx={ manageSpeakersDialogStyle }
  >
    <DefaultDialogTitle cancelDialog={ onClose }>{ t('event_manage_speakers_title') }</DefaultDialogTitle>
    <YodaFormProvider { ...yodaFormMethods.providerFields }>
      <Box marginBottom={ YodaSpacing.large }>
        <TextYoda { ...formConfig.speakerSearch } />
      </Box>
    </YodaFormProvider>
    <Box>
      <ActionButton
        label={ t('events_modal_speakerprofile_open_btn_label') }
        icon={ Icons.add }
        onClick={ onCreateSpeaker }
        variant={ ButtonVariant.primary }
      />
      <EditSpeakerProfileModal
        open={ openCreateSpeakerModal }
        setOpen={ setOpenCreateSpeakerModal }
        onSpeakerSave={ () => { updateSpeakerList(speakersSearchQuery); } }
        selectedSpeaker={ selectedSpeaker || undefined }
      />
    </Box>

    <Box >
      <GridList
        loading={ loading }
        columnConfig={ listConfig.columnConfig }
        rows={ data ?? undefined }
        onPageChange={ onPageChange }
        onPageSizeChange={ onPageSizeChange }
        rowCountState={ count }
        disableColumnMenu={ true }
        onCellClick={ listConfig.handleCellClick }
      />
    </Box>

    {
      confirmDeleteSpeakerModalOpen && selectedSpeaker ? (
        <ConfirmationDialog
          dialogTitle={ t('event_manage_speakers_delete_speaker_confirmation_title') }
          onConfirm={ onDeleteSpeakerConfirm }
          onConfirmLabel={ t('actions_confirm_label') }
          onDismiss={ onCloseConfirmDeleteSpeakerModal }
          onDismissLabel={ t('actions_cancel_label') }
        >

          {
            selectedSpeaker.relatedEvents.length > 0 ? (
              <Box>
                { t('event_manage_speakers_speaker_used_in_events') }
                <List>
                  {
                    selectedSpeaker.relatedEvents.map((event) => {
                      return (
                        <ListItem sx={
                          {
                            py: 0,
                          }
                        } key={ event._id }>
                          <ListItemButton
                            sx={
                              {
                                color: YodaColors.primaryBlue,
                              }
                            }
                            component="a"
                            href={ getEditRouteFromEvent(event) }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            { event.title }
                          </ListItemButton>
                        </ListItem>

                      );
                    })
                  }
                </List>
              </Box>
            ) : null
          }

        </ConfirmationDialog>
      ) : null
    }

  </Dialog>);
};

export default ManageSpeakers;
