import * as Sentry from '@sentry/react';
import {
  createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CurrentUser } from 'basics/types/user.types';
import { isLocal } from 'config/config';
import useLazyGetEventsUserData from 'modules/events/graphql/queries/getEventsUserDataLazy';
import OktaClient from 'services/okta';

// `undefined` as loading user
// `null` as NOT logged in user
// `CurrentUser` as logged in user

type CurrentUserState = {
  currentUser?: CurrentUser | null;
  loading?: boolean;
};

type CurrentUserContext = {
  currentUserData: CurrentUserState;
  setCurrentUserData: Dispatch<SetStateAction<CurrentUserState>>;
};

const initialState = {
  currentUserData: {
    currentUser: undefined,
    loading: false,
  },
  setCurrentUserData: () => {},
};

export const CurrentUserStateContext = createContext<CurrentUserContext>(initialState);

type CurrentUserProviderProps = {
  children: ReactNode;
};

type UseCurrentUserState = () => {
  user: CurrentUser | null | undefined;
  userLoading: boolean;
};

export const useCurrentUserState: UseCurrentUserState = () => {
  const context = useContext(CurrentUserStateContext);
  const { currentUserData: { currentUser, loading } } = context;
  return {
    user: currentUser,
    userLoading: !!loading,
  };
};

export const CurrentUserProvider: FC<CurrentUserProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { queryGetEventsUserData } = useLazyGetEventsUserData();

  const [currentUserData, setCurrentUserData] = useState<CurrentUserState>(
    {
      currentUser: undefined,
      loading: true,
    },
  );

  async function checkUser() {
    const userInfos = await OktaClient.getUserInfo();

    if (!userInfos?.user && currentUserData.currentUser !== undefined) {
      setCurrentUserData({ currentUser: undefined, loading: false });
    }
  }

  useEffect(() => {
    OktaClient.watchAuth(async (userInfos, comesFromLogin, redirectTo) => {
      if (userInfos?.user) {
        const { user } = userInfos;
        try {
          const eventsUserData = await queryGetEventsUserData();
          setCurrentUserData(
            { currentUser: {
              ...user,
              market: eventsUserData.data?.getUserData?.market,
            },
            loading: false },
          );
        } catch (error) {
          setCurrentUserData({ currentUser: { ...user, market: null, country: null, dashboardFilters: null }, loading: false });
        }

        if (!isLocal) {
          Sentry.setUser({ user: user.id, email: user.email });
        }

        if (comesFromLogin) {
          navigate(redirectTo || '/', { replace: true });
        }
      } else {
        setCurrentUserData({ currentUser: undefined, loading: false });
      }
    });

    window.addEventListener('storage', () => {
      const oktaToken = window.localStorage.getItem('okta_token.token');
      if (!oktaToken || !JSON.parse(oktaToken).accessToken) {
        setCurrentUserData({ currentUser: undefined, loading: false });
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    checkUser();
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const contextValue = {
    currentUserData,
    setCurrentUserData,
  };
  return (
    <CurrentUserStateContext.Provider value={ contextValue }>
      { children }
    </CurrentUserStateContext.Provider>
  );
};
