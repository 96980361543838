import { assetFragment } from 'basics/graphql/fragments/commonData.fragments';
import {
  agendaFragment,
  biogenLincConfigFragment,
  participantFragment,
  providerFragment,
  sessionFragment,
  testimonialFragment,
} from 'modules/events/graphql/fragments/commonData.fragments';
import { userFragment } from 'modules/events/graphql/fragments/event.fragments';
import { speakerFragment } from 'modules/events/graphql/fragments/speaker.fragments';

export const hybridEventDataFragment = `
  __typename
  coOrganizers {
    ${userFragment}
  }
  session {
    ${sessionFragment}
  }
  product
  program
  provider {
    ${providerFragment}
  }
  assets {
    cardImage {
      ${assetFragment}
    }
    programLogo {
      ${assetFragment}
    }
    agendaFile {
      ${assetFragment}
    }
  }
  speakers {
    ${speakerFragment}
  }
  testimonials {
    ${testimonialFragment}
  }
  agenda {
    ${agendaFragment}
  }
  biogenLincConfig {
    ${biogenLincConfigFragment}
  }
  participants {
    ${participantFragment}
  }
  userGroups
  recordingOptinLabel
  disclaimerText
  footerDisclaimerText
  productInformationButtonLabel
  productInformationButtonLink
  participantsSupportPhoneNumber
  speakerSupportPhoneNumber
  collectParticipantPhoneNumber
  agendaFileTitle
  agendaFileDescription
  collectPhoneNumberMandatory
  collectPhoneNumberUserConsent
  invitationSent
  enableZoomStreaming
  emailHeaderNote
  emailHeaderNoteEmailTypes
  emailFooterNote
  emailFooterNoteEmailTypes
  emailFooterDisclaimerText
  cxSurveyActivation
  cxSurveyId
  cxSurveyUseCaseName
  cxSurveyUseCaseCategory
  physicalCxSurveyActivation
  physicalCxSurveyId
  physicalCxSurveyUseCaseName
  physicalCxSurveyUseCaseCategory
  biogenLogo
  buttonColor
  emailPrcCode
  invitationEmailMayaId
  invitationEmailUTM
  confirmationEmailMayaId
  confirmationEmailUTM
  reminder48hMayaId
  reminder48hUTM
  reminder24hMayaId
  reminder24hUTM
  reminder12hMayaId
  reminder12hUTM
  reminder1hMayaId
  reminder1hUTM
  cxSurveyMayaId
  cxSurveyUTM
  eventLocation
  seats
  registrationEndDate
  isRestrictedAccess
  restrictedDescription
  showNavigationBarActions
  showNavigationBarElements
  showFooterNavItems
  screenshotPdfUrl
  teamContactCheckbox
  teamContactEmail
  teamContactDescription
  reminderNotificationEmails
  reminderNotificationPhoneNumbers
`;
