export const sessionFragment = `
  startDate
  endDate
  timeZone
`;

export const providerFragment = `
  host
  participantUrl
  organizerUrl
  licenseId
  providedId
`;

export const testimonialFragment = `
  id
  author
  testimony
`;

export const agendaFragment = `
  title
  description
  session {
    startDate
    endDate
    timeZone
  }
`;

export const biogenLincConfigFragment = `
  isPublic
  isVisibleOnBiogenLinc
  isSwitzerlandSpecific
  prcCodeEventDetails
  showSendInvitations
  enableGuestRegistration
  enableGuestRegistrationCountryDropdown
  checkConsent
  slug
`;

export const registrationStatusHistory = `
  invited
  requested
  accepted
  rejected
  waitingList
  checkedIn
`;

export const participantFragment = `
  id: mdmId
  mdmId
  firstName
  lastName
  email
  phone: telephone
  collectedPhoneNumber
  institution
  specialty
  doctorId
  zoomCallLink: uniqueLink
  registrationStatusHistory {
    ${registrationStatusHistory}
  }
  registrationEmailSent
  invitationEmailSent
  reminder1week
  reminder48h
  reminder24h
  reminder12h
  reminder1h
  reminderSMS
  cxEmailSent
  externalEventParticipantStatus
  attendedZoom
  attendedZoomDuration
  restricted
  joiningChannel
  veevaSyncStatus
  lastVeevaSync
  lastVeevaSyncError
  guestCountryCode
`;
