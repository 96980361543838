import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { succesToast, errorToast } from 'basics/utils/toast';
import { EventStatus } from 'generated/event.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const unpublishEventMutationCall = gql`
mutation unpublishEventMutation($eventId: String!) {
  unpublishOnlineEvent(eventId: $eventId)
}`;

type ResultType = {
  unpublishOnlineEvent: EventStatus;
};

type UseUnpublishEventMutation = [
  (eventId: string) => Promise<EventStatus | undefined | null>,
  MutationResult<ResultType>,
];

export const useUnpublishEventMutation = (): UseUnpublishEventMutation => {
  const { t } = useTranslation();
  const [unpublishOnlineEvent, state] = useMutation<ResultType>(unpublishEventMutationCall);
  const executeMutation = useCallback(async (eventId) => {
    try {
      const { data } = await unpublishOnlineEvent({
        variables: { eventId },
      });
      succesToast(t('toast_unpublish_event_success'));

      return data?.unpublishOnlineEvent;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error, eventId);
        Logger.logError({
          error,
          customContext,
          transactionName: 'unpublishOnlineEvent.ts - useUnpublishEventMutation',
        });
      }
      errorToast(t('toast_unpublish_event_error'));
      return null;
    }
  }, [unpublishOnlineEvent, t]);

  return [executeMutation, state];
};
