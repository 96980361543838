import { FC } from 'react';

import DialogFormContent from 'components/DialogForm/DialogFormContent';
import CardImage from 'modules/events/components/CardImage';

import useEditSpeakerProfile from 'modules/events/components/EditSpeakerProfile/EditSpeakerProfile.hook';
import { EditSpeakerProfileModalConfig } from 'modules/events/components/EditSpeakerProfile/EditSpeakerProfile.types';
import { PossibleSpeaker } from 'modules/events/groups/SpeakersGroup/SpeakersGroup.types';
import Box from 'yoda-ui/components/Box';
import Divider from 'yoda-ui/components/Divider';
import TextYoda from 'yoda-ui/components/Form/TextYoda';
import { YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

type SpeakerProfileProps = {
  speakerProfileConfig: EditSpeakerProfileModalConfig;
  selectedSpeaker?: PossibleSpeaker;
};

const SpeakerProfile: FC<SpeakerProfileProps> = ({
  speakerProfileConfig,
  selectedSpeaker,
}) => {
  const { handleDropImage, addSpeakerProfileConfig } = useEditSpeakerProfile(selectedSpeaker);

  return (
    <DialogFormContent>
      <Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box width="48%">
            <TextYoda { ...speakerProfileConfig.title } />
          </Box>
          <Box width="48%">
            <TextYoda { ...speakerProfileConfig.firstName } />
          </Box>
        </Box>
        <Box paddingTop={ YodaSpacing.large }>
          <TextYoda { ...speakerProfileConfig.lastName } />
        </Box>
      </Box>
      <Box fontWeight={ YodaFontWeight.light } paddingTop={ YodaSpacing.large }>
        <CardImage
          handleFiles={ handleDropImage }
          { ...addSpeakerProfileConfig.image }
        />
      </Box>
      <Box paddingBottom={ YodaSpacing.large } paddingTop={ YodaSpacing.large }>
        <TextYoda { ...speakerProfileConfig.email } />
      </Box>
      <Divider />
      <Box paddingTop={ YodaSpacing.large }>
        <TextYoda { ...speakerProfileConfig.affiliation } />
      </Box>
      <Box paddingTop={ YodaSpacing.large }>
        <TextYoda { ...speakerProfileConfig.specialty } />
      </Box>
    </DialogFormContent>
  );
};

export default SpeakerProfile;
