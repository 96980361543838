import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ParticipantsTabs } from './ParticipantsList.enums';
import { Participant } from 'modules/events/basics/types/events.types';
import {
  FILTER_ACCEPTED,
  FILTER_INVITED,
  FILTER_REGISTERED,
  FILTER_PENDING,
  filterParticipants,
} from 'modules/events/basics/utils/participants.utils';

const useParticipantsList = (participants: Participant[] = [], e2eTestMode = false) => {
  const { t } = useTranslation();
  const { INVITED, REGISTERED, WAITING } = ParticipantsTabs;

  const [selectedTab, setSelectedTab] = useState<ParticipantsTabs>(REGISTERED);

  const itemsAcceptedCount = useMemo(
    () => filterParticipants(participants, FILTER_ACCEPTED).length,
    [participants],
  );

  const itemsInvited = useMemo(
    () => filterParticipants(participants, FILTER_INVITED),
    [participants],
  );

  const itemsRegistered = useMemo(
    () => filterParticipants(participants, FILTER_REGISTERED),
    [participants],
  );

  const itemsPending = useMemo(
    () => filterParticipants(participants, FILTER_PENDING),
    [participants],
  );

  const tabsConfig = [
    {
      label: `${t('participantsList_tabsLabel_registered')} [${itemsAcceptedCount}/${itemsRegistered.length}]`,
      value: REGISTERED,
      onClick: () => setSelectedTab(REGISTERED),
    },
  ];

  if (!e2eTestMode) {
    tabsConfig.push({
      label: `${t('participantsList_tabsLabel_invited')} [${itemsInvited.length}]`,
      value: INVITED,
      onClick: () => setSelectedTab(INVITED),
    },
    {
      label: `${t('participantsList_tabsLabel_waiting')} [${itemsPending.length}]`,
      value: WAITING,
      onClick: () => setSelectedTab(WAITING),
    });
  }

  return {
    itemsInvited,
    itemsRegistered,
    itemsPending,
    selectedTab,
    tabsConfig,
  };
};

export default useParticipantsList;
