import { FC } from 'react';
import useEventStatusSelector from './EventStatusSelector.hook';
import Box from 'yoda-ui/components/Box';
import SelectYoda from 'yoda-ui/components/Form/SelectYoda';
import { useYodaCreateForm, YodaFormProvider } from 'yoda-ui/yodaForm';

export const EventStatusSelector: FC = () => {
  const { eventStatusSelectorConfig, handleEventStatusChange } = useEventStatusSelector();
  const yodaFormMethods = useYodaCreateForm({ setIsValidOptional: true });

  return (
    <YodaFormProvider { ...yodaFormMethods.providerFields }>
      <Box marginRight={ '20px' } width={ '200px' }>
        <SelectYoda disableClearable={ true } { ...eventStatusSelectorConfig } onChange={ handleEventStatusChange } />
      </Box>
    </YodaFormProvider>
  );
};
