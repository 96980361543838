import useCxSurvey from './CxSurvey.hook';
import CardForm from 'components/CardForm';
import Box from 'yoda-ui/components/Box';
import CheckBoxYoda from 'yoda-ui/components/Form/CheckBoxYoda';
import SelectYoda from 'yoda-ui/components/Form/SelectYoda';
import TextYoda from 'yoda-ui/components/Form/TextYoda';
import { YodaFontSize, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

const CxSurvey = () => {
  const {
    t,
    isHybridType,
    disableCxSurveyInfos,
    disablePhysicalCxSurveyInfos,
    cxSurveyConfig,
    clearOption,
  } = useCxSurvey();
  return (
    <CardForm title={ 'CX Survey' }
      tooltipTitle={ t('events_cx_survey_tooltip') }
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        marginBottom={ YodaSpacing.small }
      >
      </Box>
      {
        isHybridType
          && <>
            <Box fontSize={ YodaFontSize.medium } fontWeight={ YodaFontWeight.medium }>
              { t('events_email_online_cx_survey_label') }
            </Box>
          </>
      }
      <Box paddingTop={ YodaSpacing.none } marginBottom={ YodaSpacing.small }>
        <CheckBoxYoda { ...cxSurveyConfig.cxSurveyActivateJourney } />
      </Box>
      <Box>
        <Box fontSize={ YodaFontSize.medium } fontWeight={ YodaFontWeight.medium } marginBottom={ YodaSpacing.small }>
          { t('events_define_cx_survey_title') }
        </Box>
        <Box marginBottom={ YodaSpacing.small }>
          <SelectYoda { ...cxSurveyConfig.cxSurveyId } clearOption={ clearOption }></SelectYoda>
        </Box>
        <Box marginBottom={ YodaSpacing.small }>
          <TextYoda { ...cxSurveyConfig.cxSurveyUseCaseName } disabled={ disableCxSurveyInfos }/>
        </Box>
        <Box marginBottom={ YodaSpacing.small }>
          <TextYoda { ...cxSurveyConfig.cxSurveyUseCaseCategory } disabled={ disableCxSurveyInfos } />
        </Box>
      </Box>
      {
        isHybridType
          && <>
            <Box paddingTop={ YodaSpacing.small } fontSize={ YodaFontSize.medium } fontWeight={ YodaFontWeight.medium }>
              { t('events_email_physical_cx_survey_label') }
            </Box>
            <Box paddingTop={ YodaSpacing.none } marginBottom={ YodaSpacing.small }>
              <CheckBoxYoda { ...cxSurveyConfig.physicalCxSurveyActivateJourney } />
            </Box>
            <Box fontSize={ YodaFontSize.medium } fontWeight={ YodaFontWeight.medium } marginBottom={ YodaSpacing.small }>
              { t('events_define_physical_cx_survey_title') }
            </Box>
            <Box>
              <Box marginBottom={ YodaSpacing.small }>
                <SelectYoda { ...cxSurveyConfig.physicalCxSurveyId } clearOption={ clearOption }></SelectYoda>
              </Box>
              <Box marginBottom={ YodaSpacing.small }>
                <TextYoda { ...cxSurveyConfig.physicalCxSurveyUseCaseName }
                  disabled={ disablePhysicalCxSurveyInfos }/>
              </Box>
              <Box marginBottom={ YodaSpacing.small }>
                <TextYoda { ...cxSurveyConfig.physicalCxSurveyUseCaseCategory }
                  disabled={ disablePhysicalCxSurveyInfos } />
              </Box>
            </Box>
          </>
      }
    </CardForm>
  );
};

export default CxSurvey;
