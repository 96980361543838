import { useTranslation } from 'react-i18next';
import { array, number, object, string } from 'yup';

const useEditSpeakerProfileSchema = () => {
  const { t } = useTranslation();
  const requiredLabel = t('form_errors_required');

  return {
    title: string(),
    firstName: string().required(requiredLabel),
    lastName: string().required(requiredLabel),
    email: string(),
    affiliation: string(),
    specialty: string(),
    image: array()
      .nullable(true)
      .of(object().shape({
        size: number().required(),
        name: string().required(),
        url: string().required(),
      })),
  };
};

export default useEditSpeakerProfileSchema;
