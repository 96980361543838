import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import useCxSurveyConfig from './CxSurvey.config';
import { Presence } from 'generated/event.types';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { HybridMeetingDataState } from 'modules/events/basics/types/hybridMeeting.types';
import useGetGlobalSurveyData from 'modules/events/graphql/queries/getGlobalSurveyData';
import useGetSurveysId from 'modules/events/graphql/queries/getSurveysId';
import eventState from 'modules/events/states/events.states';
import { useYodaForm } from 'yoda-ui/yodaForm';

const useCxSurvey = () => {
  const { t } = useTranslation();
  const { setValue, useWatchField, setFieldValidation, triggerFieldValidation } = useYodaForm();
  const snap = useSnapshot(eventState);
  const { data } = useGetSurveysId(snap.createEvent.market);
  const transformedSurveysId = data.map((el) => ({ id: el.surveyId, label: `${el.surveyId}/${el.usecaseName}` }));
  const { cxSurveyConfig, cxSurveyValidation } = useCxSurveyConfig(transformedSurveysId);
  const { data: surveyData } = useGetGlobalSurveyData();
  const {
    surveyId: globalCxSurveyId,
    usecaseName: globalCxSurveyUseCaseName,
    usecaseCategory: globalCxSurveyUseCaseCategory,
  } = surveyData || {};

  const [disableCxSurveyInfos, setDisableCxSurveyInfos] = useState(false);
  const [clearOption, setClearOption] = useState(false);
  const cxSurveyId = useWatchField(commonFields.cxSurveyId);
  const isCxSurveyActiveField = useWatchField(commonFields.cxSurveyActivation);
  const cxSurveyUseCaseNameField = useWatchField(commonFields.cxSurveyUseCaseName);
  const cxSurveyUseCaseCategoryField = useWatchField(commonFields.cxSurveyUseCaseCategory);

  const [disablePhysicalCxSurveyInfos, setDisablePhysicalCxSurveyInfos] = useState(false);
  const physicalCxSurveyId = useWatchField(commonFields.physicalCxSurveyId);
  const isPhysicalCxSurveyActiveField = useWatchField(commonFields.physicalCxSurveyActivation);
  const physicalCxSurveyUseCaseNameField = useWatchField(commonFields.physicalCxSurveyUseCaseName);
  const physicalCxSurveyUseCaseCategoryField = useWatchField(commonFields.physicalCxSurveyUseCaseCategory);

  const isHybridType = snap.createEvent.presence === Presence.hybrid;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const surveyInfo = data.find((el) => el.surveyId === cxSurveyId?.value);
    if (cxSurveyId?.value && surveyInfo) {
      setValue(commonFields.cxSurveyUseCaseName, surveyInfo.usecaseName);
      setValue(commonFields.cxSurveyUseCaseCategory, surveyInfo.usecaseCategory);
      setDisableCxSurveyInfos(true);
    } else if (!cxSurveyId?.value) {
      setValue(commonFields.cxSurveyUseCaseName, snap.createEvent.data?.cxSurveyUseCaseName);
      setValue(commonFields.cxSurveyUseCaseCategory, snap.createEvent.data?.cxSurveyUseCaseCategory);
      setDisableCxSurveyInfos(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cxSurveyId?.value]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const surveyInfo = data.find((el) => el.surveyId === physicalCxSurveyId?.value);
    if (physicalCxSurveyId?.value && surveyInfo) {
      setValue(commonFields.physicalCxSurveyUseCaseName, surveyInfo.usecaseName);
      setValue(commonFields.physicalCxSurveyUseCaseCategory, surveyInfo.usecaseCategory);
      setDisablePhysicalCxSurveyInfos(true);
    } else if (!physicalCxSurveyId?.value) {
      setValue(commonFields.physicalCxSurveyUseCaseName, (snap.createEvent.data as HybridMeetingDataState)?.physicalCxSurveyUseCaseName);
      setValue(
        commonFields.physicalCxSurveyUseCaseCategory,
        (snap.createEvent.data as HybridMeetingDataState)?.physicalCxSurveyUseCaseCategory,
      );
      setDisablePhysicalCxSurveyInfos(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [physicalCxSurveyId?.value]);

  useEffect(() => {
    if (isCxSurveyActiveField?.value && cxSurveyId?.value) {
      setFieldValidation(commonFields.cxSurveyUseCaseName, cxSurveyValidation.required);
      setFieldValidation(commonFields.cxSurveyUseCaseCategory, cxSurveyValidation.required);
    } else {
      setFieldValidation(commonFields.cxSurveyUseCaseName, cxSurveyValidation.notRequired);
      setFieldValidation(commonFields.cxSurveyUseCaseCategory, cxSurveyValidation.notRequired);
    }
    triggerFieldValidation(commonFields.cxSurveyUseCaseName, cxSurveyUseCaseNameField?.value);
    triggerFieldValidation(commonFields.cxSurveyUseCaseCategory, cxSurveyUseCaseCategoryField?.value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cxSurveyUseCaseCategoryField?.value, cxSurveyId?.value, cxSurveyUseCaseNameField?.value, isCxSurveyActiveField?.value]);

  useEffect(() => {
    if (isPhysicalCxSurveyActiveField?.value && physicalCxSurveyId?.value) {
      setFieldValidation(commonFields.physicalCxSurveyUseCaseName, cxSurveyValidation.required);
      setFieldValidation(commonFields.physicalCxSurveyUseCaseCategory, cxSurveyValidation.required);
    } else {
      setFieldValidation(commonFields.physicalCxSurveyUseCaseName, cxSurveyValidation.notRequired);
      setFieldValidation(commonFields.physicalCxSurveyUseCaseCategory, cxSurveyValidation.notRequired);
    }
    triggerFieldValidation(commonFields.physicalCxSurveyUseCaseName, physicalCxSurveyUseCaseNameField?.value);
    triggerFieldValidation(commonFields.physicalCxSurveyUseCaseCategory, physicalCxSurveyUseCaseCategoryField?.value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    physicalCxSurveyUseCaseCategoryField?.value,
    physicalCxSurveyId?.value,
    physicalCxSurveyUseCaseNameField?.value,
    isPhysicalCxSurveyActiveField?.value,
  ]);

  useEffect(() => {
    if (!isCxSurveyActiveField?.value && !snap.createEvent.data?.cxSurveyId) {
      setClearOption(true);
      setValue(commonFields.cxSurveyId, null);
      setValue(commonFields.cxSurveyUseCaseName, null);
      setValue(commonFields.cxSurveyUseCaseCategory, null);
      setDisableCxSurveyInfos(false);
    } else {
      setClearOption(false);
      setValue(commonFields.cxSurveyId, snap.createEvent.data?.cxSurveyId || globalCxSurveyId);
      setValue(commonFields.cxSurveyUseCaseName, snap.createEvent.data?.cxSurveyUseCaseName || globalCxSurveyUseCaseName);
      setValue(commonFields.cxSurveyUseCaseCategory, snap.createEvent.data?.cxSurveyUseCaseCategory || globalCxSurveyUseCaseCategory);
      setDisableCxSurveyInfos(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCxSurveyActiveField?.value]);

  useEffect(() => {
    if (isHybridType) {
      const hybridDataState = snap.createEvent.data as HybridMeetingDataState;
      if (!isPhysicalCxSurveyActiveField?.value && !hybridDataState?.physicalCxSurveyId) {
        setClearOption(true);
        setValue(commonFields.physicalCxSurveyId, null);
        setValue(commonFields.physicalCxSurveyUseCaseName, null);
        setValue(commonFields.physicalCxSurveyUseCaseCategory, null);
        setDisablePhysicalCxSurveyInfos(false);
      } else {
        setClearOption(false);
        setValue(commonFields.physicalCxSurveyId, hybridDataState?.physicalCxSurveyId || globalCxSurveyId);
        setValue(commonFields.physicalCxSurveyUseCaseName, hybridDataState?.physicalCxSurveyUseCaseName || globalCxSurveyUseCaseName);
        setValue(
          commonFields.physicalCxSurveyUseCaseCategory,
          hybridDataState?.physicalCxSurveyUseCaseCategory || globalCxSurveyUseCaseCategory,
        );
        setDisablePhysicalCxSurveyInfos(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPhysicalCxSurveyActiveField?.value]);

  return {
    t,
    isHybridType,
    disableCxSurveyInfos,
    disablePhysicalCxSurveyInfos,
    cxSurveyConfig,
    clearOption,
  };
};

export default useCxSurvey;
