import { formatISO } from 'date-fns';
import { useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { ParticipantsItemCollapseFields } from './ParticipantsItemCollapse.enums';
import { FieldProps } from './ParticipantsItemCollapse.types';
import {
  getShortFormatDateTimeFromIsoString,
  LONG_DAY_NUM_MONTH_LONG_YEAR_SLASHES,
} from 'basics/utils/dates/dates.utils';
import { Market, Presence, Source } from 'generated/event.types';
import { Participant } from 'modules/events/basics/types/events.types';

// Fields ordered by display
const FIELDS = (t: TFunction<'translation'>, eventPresence: Presence, eventSource?: Source): FieldProps[] => ([
  {
    key: ParticipantsItemCollapseFields.mdmId,
    label: t('person_field_mdmId_label'),
  },
  {
    key: ParticipantsItemCollapseFields.accountId,
    label: t('person_field_accountId_label'),
  },
  {
    key: ParticipantsItemCollapseFields.guestCountryCode,
    label: t('person_field_guestCountry_label'),
  },
  {
    key: ParticipantsItemCollapseFields.phone,
    label: t('person_field_phone_label'),
  },
  {
    key: ParticipantsItemCollapseFields.collectedPhoneNumber,
    label: t('person_field_collectedPhoneNumber_label'),
  },
  {
    key: ParticipantsItemCollapseFields.specialty,
    label: t('person_field_specialty_label'),
  },
  {
    key: ParticipantsItemCollapseFields.doctorId,
    label: t('person_field_doctorId_label'),
  },
  {
    key: ParticipantsItemCollapseFields.zoomCallLink,
    label: t('person_field_eventLink_label'),
  },
  {
    key: ParticipantsItemCollapseFields.invitationDate,
    label: t('person_field_invitationDate_label'),
  },
  {
    key: ParticipantsItemCollapseFields.registrationEmailSent,
    label: t('person_field_registrationEmailSent_label'),
  },
  {
    key: ParticipantsItemCollapseFields.invitationEmailSent,
    label: t('person_field_invitationEmailSent_label'),
  },

  ...(eventPresence === Presence.physical || eventPresence === Presence.hybrid ? [{
    key: ParticipantsItemCollapseFields.reminder1week,
    label: t('person_field_reminder1WeekEmailSent_label'),
  }] : []),

  ...(eventPresence === Presence.online || eventPresence === Presence.hybrid ? [{
    key: ParticipantsItemCollapseFields.reminder48h,
    label: t('person_field_reminder1EmailSent_label'),
  }] : []),

  {
    key: ParticipantsItemCollapseFields.reminder24h,
    label: t('person_field_reminder2EmailSent_label'),
  },

  ...(eventPresence === Presence.online || eventPresence === Presence.hybrid ? [{
    key: ParticipantsItemCollapseFields.reminder12h,
    label: t('person_field_reminder3EmailSent_label'),
  }] : []),

  {
    key: ParticipantsItemCollapseFields.reminder1h,
    label: eventPresence === Presence.physical
      ? t('person_field_physical_reminder4EmailSent_label') : t('person_field_reminder4EmailSent_label'),
  },
  {
    key: ParticipantsItemCollapseFields.reminderSMS,
    label: eventPresence === Presence.physical
      ? t('person_field_physical_reminderSMSSent_label') : t('person_field_reminderSMSSent_label'),
  },
  {
    key: ParticipantsItemCollapseFields.cxEmailSent,
    label: t('person_field_cxEmailSent_label'),
  },
  ...(eventSource === Source.veeva ? [{
    key: ParticipantsItemCollapseFields.externalEventParticipantStatus,
    label: t('person_field_veeva_status_label'),
  }] : []),
  ...(eventPresence === Presence.online || eventPresence === Presence.hybrid ? [{
    key: ParticipantsItemCollapseFields.attendedZoom,
    label: t('person_field_attended_zoom_label'),
  }] : []),
  ...(eventPresence === Presence.online || eventPresence === Presence.hybrid ? [{
    key: ParticipantsItemCollapseFields.attendedZoomDuration,
    label: t('person_field_attended_zoom_duration_label'),
  }] : []),
]);

const formatStatusInvitedDate = (date: Date | null | undefined) => {
  if (!date) {
    return null;
  }
  const formatted = formatISO(new Date(date));
  return getShortFormatDateTimeFromIsoString(formatted, LONG_DAY_NUM_MONTH_LONG_YEAR_SLASHES);
};

const formatValue = (field: FieldProps, participant: Participant, t: TFunction<'translation'>) => {
  const { key } = field;
  const {
    invitationDate,
    registrationEmailSent,
    invitationEmailSent,
    reminder1week,
    reminder48h,
    reminder24h,
    reminder12h,
    reminder1h,
    reminderSMS,
    cxEmailSent,
    attendedZoom,
    attendedZoomDuration,
    guestCountryCode,
  } = ParticipantsItemCollapseFields;

  switch (key) {
    case invitationDate:
      return participant.registrationStatusHistory.invited ? formatStatusInvitedDate(participant.registrationStatusHistory.invited) : null;
    case registrationEmailSent:
    case invitationEmailSent:
    case reminder1week:
    case reminder48h:
    case reminder24h:
    case reminder12h:
    case reminder1h:
    case reminderSMS:
    case cxEmailSent:
    case attendedZoom:
      return participant[key] ? t('common_yes') : t('common_no');
    case attendedZoomDuration:
      return participant.attendedZoomDuration
        ? `${Math.ceil(participant.attendedZoomDuration / 60)} ${t('short_minutes_label')}` : t('event_zoom_duration_not_applicable');
    case guestCountryCode:
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return new Intl.DisplayNames(['en'], { type: 'region' }).of(participant[key] as string);
      } catch (err) {
        return participant[key];
      }
    default:
      return participant[key];
  }
};

const filterFields = (fields: FieldProps[], market?: Market | null) => {
  if (market !== Market.com) {
    return fields.filter((field) => field.key !== ParticipantsItemCollapseFields.guestCountryCode);
  }
  return fields;
};

const useParticipantsItemCollapse = (
  participant: Participant,
  eventPresence = Presence.online,
  marketTag?: Market,
  eventSource?: Source,
) => {
  const { t } = useTranslation();

  const fieldsConfig = useMemo(() => {
    const fields = FIELDS(t, eventPresence, eventSource);
    const filteredFields = filterFields(fields, marketTag);
    return filteredFields.map((field) => ({
      ...field,
      value: formatValue(field, participant, t),
    }));
  },
  [t, eventPresence, marketTag, eventSource, participant]);

  return {
    fieldsConfig,
  };
};

export default useParticipantsItemCollapse;
