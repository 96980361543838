import { Chip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { FC, useCallback } from 'react';
import { MultiSelectIdsType, SelectOption } from 'basics/options/options.types';
import FormHelperText from 'yoda-ui/components/Form/FormHelperText';
import { TagStyle } from 'yoda-ui/components/Form/SelectYoda/MultiSelectYoda/MultiSelectYoda.styles';
import { MultiSelectYodaInputProps } from 'yoda-ui/components/Form/SelectYoda/MultiSelectYoda/MultiSelectYoda.types';
import { selectStyle, textFieldStyle } from 'yoda-ui/components/Form/SelectYoda/SelectYoda.styles';
import TextField from 'yoda-ui/components/Form/TextField';
import Paper from 'yoda-ui/components/Paper';
import { findMultipleOptionsByIds } from 'yoda-ui/utils/options.utils';
import { useYodaField } from 'yoda-ui/yodaForm';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const MultiSelectYoda: FC<MultiSelectYodaInputProps> = ({
  defaultValue,
  disableClearable,
  disabled,
  freeOption = false,
  label,
  name,
  onChange,
  options,
  placeholder,
  required,
  validation,
  clearOnBlur = false,
}) => {
  const {
    onChangeFieldSynthetic,
    fieldValue,
    fieldDefaultValue,
    fieldShowError,
    fieldErrorMessage,
  } = useYodaField({ name, defaultValue, onChange, validation });

  // eslint-disable-next-line max-len
  const findCurrentMultiOptionsByIds = useCallback((idsList: MultiSelectIdsType) => findMultipleOptionsByIds(freeOption, options, idsList), [freeOption, options]);

  return (
    <>
      <Autocomplete
        data-testid="multi-select"
        multiple
        PaperComponent={
          ({ children }) => (
            <Paper margin={ YodaSpacing.small }>{ children }</Paper>
          )
        }
        sx={ selectStyle }
        clearOnBlur={ clearOnBlur }
        freeSolo={ freeOption }
        disabled={ disabled }
        options={ options.sort((a, b) => ((a.label > b.label) ? 1 : ((a.label < b.label) ? -1 : 0))) }
        getOptionLabel={ (option) => option.label }
        defaultValue={ findCurrentMultiOptionsByIds(fieldDefaultValue) }
        disableClearable={ disableClearable }
        value={ findCurrentMultiOptionsByIds(fieldValue) }
        onChange={ onChangeFieldSynthetic }
        renderTags={
          (value: readonly SelectOption[], getTagProps) => value.map((option: SelectOption, index: number) => (
            <Chip variant="filled" label={ option.label } { ...getTagProps({ index }) } key={ index } sx={ TagStyle }/>
          ))
        }
        renderInput={
          (params) => (
            <TextField
              { ...params }
              sx={ textFieldStyle }
              label={ label }
              placeholder={ placeholder }
              required={ required }
              error={ fieldShowError }
            />
          )
        }
      />
      { fieldShowError && <FormHelperText error>{ fieldErrorMessage }</FormHelperText> }
    </>
  );
};

export default MultiSelectYoda;
