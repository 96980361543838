import { ApolloError, useLazyQuery } from '@apollo/client';
import { Event } from 'modules/events/basics/types/events.types';
import { GET_FULL_PRIVATE_EVENTS } from 'modules/events/graphql/queries/getFullPrivateEvents';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

type UseLazyGetFullPrivateEvents = () => {
  queryGetFullPrivateEvents: Function;
  loading: boolean;
  data: Event[];
  error?: ApolloError;
  called: boolean;
};

const useLazyGetFullPrivateEvents: UseLazyGetFullPrivateEvents = () => {
  const [queryGetFullPrivateEvents, { loading, data, error, called }] = useLazyQuery(GET_FULL_PRIVATE_EVENTS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getLazyFullPrivateEvents.ts - useLazyGetFullPrivateEvents',
    });
  }

  return {
    queryGetFullPrivateEvents,
    loading,
    data: data?.getFullPrivateEvents || [],
    error,
    called,
  };
};

export default useLazyGetFullPrivateEvents;
