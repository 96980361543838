import { strategicImperativeFragment } from './strategicImperative.fragments';

export const customerInitiativeFragment = `
  _id
  title
  description
  dateStart
  dateEnd
  country
  therapeuticArea
  product
  strategicImperative {
    ${strategicImperativeFragment}
  }
  solutionCoreStory
  functionalOwner
  functionsInvolved
  status
  updated
  veevaSyncStatus
  lastVeevaSync
  lastVeevaSyncError
  isTemplate
  formattedCiId
  year
`;
