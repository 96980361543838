import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { CommonSteps } from 'modules/events/basics/enums/commonForms.enums';
import { StepItem } from 'yoda-ui/components/Stepper/Stepper';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useCreatePhysicalMeetingStepper = (useWatchStep: any) => {
  const { t } = useTranslation();
  const flags = useFlags();
  const watchDetailsStep = useWatchStep(CommonSteps.detailsStep);
  const watchPublicationStep = useWatchStep(CommonSteps.publicationStep);
  const watchCommunicationStep = useWatchStep(CommonSteps.communicationStep);
  const watchEmailConfigurationStep = useWatchStep(CommonSteps.emailConfigurationStep);

  const stepsList: StepItem[] = [
    {
      title: `1. ${t('event_step_details')}`,
      isValid: watchDetailsStep?.isValid || false,
      disabled: false,
    },
    {
      title: `2. ${t('event_step_communication')}`,
      isValid: watchCommunicationStep?.isValid || false,
      disabled: false,
    },

  ];

  if (flags.eventsBiogenlinc4151SectionEmail) {
    stepsList.push({
      title: `3. ${t('event_step_email_configuration')}`,
      isValid: watchEmailConfigurationStep?.isValid || false,
      disabled: false,
    });
  }
  stepsList.push({
    title: `${flags.eventsBiogenlinc4151SectionEmail ? '4' : '3'}. ${t('event_step_publication')}`,
    isValid: watchPublicationStep?.isValid || false,
    disabled: false,
  });

  return stepsList;
};

export default useCreatePhysicalMeetingStepper;
