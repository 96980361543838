import { useTranslation } from 'react-i18next';
import { mixed, string } from 'yup';
import { TimeZone } from 'basics/enums/timeZoneList.enums';
import { TestContextType } from 'basics/types/common.types';
import { testEndDayNotBeforeStartDay, testStartDateIsSet, testStartDateNotInPast } from 'basics/utils/dates/dates.utils';
import { Format, HostProvider, Market, Presence } from 'generated/event.types';
import { EventBiogenBrand } from 'modules/events/basics/enums/biogen.enum';
import { YodaFieldsState } from 'yoda-ui/yodaForm/yodaForm.types';

const maxStringLength = 500;

const useCreateMeetingSchema = () => {
  const { t } = useTranslation();
  const requiredLabel = t('form_errors_required');
  const maxLengthTitleMessage = `${t('event_creation_modal_title_max_error_message', { count: maxStringLength })}`;
  const maxLengthVenueMessage = `${t('event_creation_modal_venue_max_error_message', { count: maxStringLength })}`;

  return {
    title: string()
      .required(requiredLabel)
      .max(maxStringLength, maxLengthTitleMessage),
    market: mixed<Market>()
      .oneOf(Object.values(Market), requiredLabel)
      .required(),
    presenceType: mixed<Presence>()
      .oneOf(Object.values(Presence), requiredLabel)
      .required(),
    eventFormat: mixed<Format>()
      .oneOf(Object.values(Format), requiredLabel)
      .required(),
    product: mixed<EventBiogenBrand>()
      .oneOf(Object.values(EventBiogenBrand), requiredLabel)
      .required(),
    startTime: string()
      .required(requiredLabel),
    endTime: string()
      .required(requiredLabel),
    dateRange: mixed()
      .test({
        name: 'startDate is set',
        message: requiredLabel,
        test: testStartDateIsSet,
      })
      .test({
        name: 'startDate is in future',
        message: t('timeSlotBooking_startDate_errorMessage_future'),
        test: testStartDateNotInPast,
      })
      .test({
        name: 'start date before end date',
        message: t('timeSlotBooking_endDate_errorMessage_earlierThanStart'),
        test: testEndDayNotBeforeStartDay,
      }),
    venue: string().required().max(maxStringLength, maxLengthVenueMessage),
    hostProvider: mixed<HostProvider>()
      .oneOf(Object.values(HostProvider), requiredLabel)
      .required(),
    timeZone: mixed<TimeZone>()
      .oneOf(Object.values(TimeZone), requiredLabel)
      .required(requiredLabel),
    hostProviderLink: string().test({
      name: 'Host Provider link is required',
      message: t('timeSlotBooking_hostProviderLink_errorMessage_isRequired'),
      test: (hostProviderLink, context) => {
        const fieldsState: YodaFieldsState = (context.options as TestContextType)?.fieldsState;
        const validHostProviderLink = !!(hostProviderLink && hostProviderLink.length > 0);
        return (fieldsState.hostProvider?.value === HostProvider.other && validHostProviderLink)
          || fieldsState.hostProvider?.value === HostProvider.zoom;
      },
    }).nullable(true),
  };
};

export default useCreateMeetingSchema;
