import { array, object, string } from 'yup';

const useSpeakersSchema = () => ({
  speakers: array()
    .nullable(true)
    .of(object().shape({
      id: string().required(),
      firstName: string().required(),
      lastName: string().required(),
      email: string().nullable(),
    })),
  speakerSupportPhoneNumber: string()
    .nullable(true),
});

export default useSpeakersSchema;
