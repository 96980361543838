import { ApolloError, gql, useQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { QueryOptionsType } from 'basics/types/common.types';
import { Tactic, TacticFilters } from 'generated/maya.types';
import { tacticFragment } from 'modules/events/graphql/fragments/tactic.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_ALL_TACTICS = gql`
query GetAllTacticsQuery($limit: Int!, $offset: Int!, $filters: TacticFilters) {
  getTactics(limit: $limit, offset: $offset, filters: $filters) {
    tactics {
      ${tacticFragment}
    }
    count
  }
}
`;

type ResultType = {
  getTactics: {
    tactics: Tactic[];
    count: number;
  };
};

type UseGetAllTactics = (queryOptions?: QueryOptionsType, filters?: TacticFilters) => {
  loading: boolean;
  error?: ApolloError;
  data: Tactic[];
  count: number;
};

const defaultTacticsValue: Tactic[] = [];

const useGetAllTacticsQuery: UseGetAllTactics = (queryOptions = { limit: 5000, offset: 0 }, filters = {}) => {
  const { limit, offset } = queryOptions;

  const { data, loading, error } = useQuery<ResultType>(GET_ALL_TACTICS, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
    variables: {
      limit,
      offset,
      filters,
    },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getTacticsByCepId.ts - useGetTacticsByCepIdQuery',
    });
  }

  return {

    data: data?.getTactics.tactics || defaultTacticsValue,
    count: data?.getTactics.count || 0,
    loading,
    error,
  };
};

export default useGetAllTacticsQuery;
