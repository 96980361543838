import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const updateScreenshotPdfUrlMutationCall = gql`
    mutation updateScreenshotPdfUrl($eventId: String!, $pdfUrl: String!) {
      updateScreenshotPdfUrl(eventId: $eventId, pdfUrl: $pdfUrl)
    }`;

type ResultType = {
  updateScreenshotPdfUrl: string | null;
};

type UseUpdateScreenshotPdfUrlMutation = [
  (eventId: string, pdfUrl: string) => Promise<string | null>,
  MutationResult<ResultType>,
];

export const useUpdateScreenshotPdfUrlMutation = (): UseUpdateScreenshotPdfUrlMutation => {
  const [updateScreenshotPdfUrl, state] = useMutation<ResultType>(updateScreenshotPdfUrlMutationCall);

  const executeMutation = useCallback(async (eventId, pdfUrl) => {
    try {
      const data = await updateScreenshotPdfUrl({
        variables: { eventId, pdfUrl },
      });

      return data.data?.updateScreenshotPdfUrl || null;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error, eventId);
        Logger.logError({
          error,
          customContext,
          transactionName: 'updateScreenshotPdfUrl.ts - updateScreenshotPdfUrlMutation',
        });
      }
      return null;
    }
  }, [updateScreenshotPdfUrl]);

  return [executeMutation, state];
};
