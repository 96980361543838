import { useTranslation } from 'react-i18next';
import { FormAgendaConfig } from 'components/QuickForm/QuickForm.types';
import { AgendaConfigField } from 'modules/events/components/Agenda/Agenda.types';

const useAgendaConfig = (
  {
    dateRange,
    minParentTime,
  }: {
    dateRange: string[] | null;
    minParentTime: string;
  },
) => {
  const { t } = useTranslation();

  const formConfig: FormAgendaConfig = {
    [AgendaConfigField.dateRange]: {
      name: AgendaConfigField.dateRange,
      label: t('events.agendaGroup.dateFieldLabel'),
      placeholder: t('common.form.dateFieldPlaceholder'),
      required: true,
      type: 'date',
      shrink: true,
      max: dateRange?.[1] || '',
      min: dateRange?.[0] || '',
    },
    [AgendaConfigField.startTime]: {
      name: AgendaConfigField.startTime,
      label: t('common.form.startTimeFieldLabel'),
      placeholder: t('common.form.timeFieldPlaceholder'),
      required: true,
      type: 'time',
      min: minParentTime,
      max: '23:59',
      step: 300, // time is given in seconds, with a scaling factor of 1000 (since the underlying numeric value is in milliseconds)
      shrink: true,
    },
    [AgendaConfigField.endTime]: {
      name: AgendaConfigField.endTime,
      label: t('common.form.endTimeFieldLabel'),
      placeholder: t('common.form.timeFieldPlaceholder'),
      required: true,
      type: 'time',
      min: minParentTime,
      max: '23:59',
      step: 300, // time is given in seconds, with a scaling factor of 1000 (since the underlying numeric value is in milliseconds)
      shrink: true,
    },
    [AgendaConfigField.title]: {
      name: AgendaConfigField.title,
      label: t('events.agendaGroup.titleFieldLabel'),
      placeholder: t('events.agendaGroup.titleFieldPlaceholder'),
      required: true,
    },
    [AgendaConfigField.description]: {
      name: AgendaConfigField.description,
      label: t('events.agendaGroup.descriptionFieldLabel'),
      placeholder: t('events.agendaGroup.descriptionFieldPlaceholder'),
      multiline: true,
      rows: 5,
    },
  };

  return ({ formConfig });
};

export default useAgendaConfig;
