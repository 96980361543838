import { ApolloError, gql, useQuery } from '@apollo/client';
import { Market, Presence } from 'generated/event.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_FULL_PRIVATE_EVENTS_COUNT = gql`
  query getFullPrivateEventsCount($source: Source, $eventFilters: EventsFilterInput, $showRsvpEvents: Boolean) {
    getFullPrivateEventsCount(source: $source, eventFilters: $eventFilters, showRsvpEvents: $showRsvpEvents)
  }
`;

type ResultType = {
  getFullPrivateEventsCount: number;
};

type UseGetEventsCount = (market: Market | null, isPast: boolean, presence: Presence, showRsvpEvents: boolean) => {
  loading: boolean;
  error?: ApolloError;
  data: number;
};

const useGetFullPrivateEventsCountQuery: UseGetEventsCount = (market, isPast, presence, showRsvpEvents) => {
  const { data, loading, error } = useQuery<ResultType>(GET_FULL_PRIVATE_EVENTS_COUNT, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      source: 'backoffice',
      eventFilters: {
        market,
        isPast,
        presence,
      },
      showRsvpEvents,
    },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getEvents.ts - useGetFullPrivateEventsQuery',
    });
  }

  return {
    data: data?.getFullPrivateEventsCount || 0,
    loading,
    error,
  };
};

export default useGetFullPrivateEventsCountQuery;
