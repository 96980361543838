import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StepButtonsConfig } from 'components/ButtonsBar/ButtonsBar';
import { CommonSteps } from 'modules/events/basics/enums/commonForms.enums';
import {
  CreateHybridMeetingStepsIndex,
  CreateHybridMeetingWithEmailConfigurationStepsIndex,
} from 'modules/events/routes/CreateHybridMeeting/CreateHybridMeeting.types';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';
import { Icons } from 'yoda-ui/components/Icons/Icon';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useCreateHybridMeetingButtons = (hybridMeetingActions: any, isDirty: boolean, readyToPublish = false, isPublished = false) => {
  const { handleSaveData,
    stepIndexer,
    handlePublishEvent,
    publishLoading = false,
    saveAndNext,
    saveAndPrevious,
    updateLoading } = hybridMeetingActions;
  const { t } = useTranslation();
  const flags = useFlags();

  const button = useMemo(() => {
    const firstStepButtons: StepButtonsConfig = {
      secondButton: {
        buttonProps: {
          disabled: updateLoading || !isDirty,
          onClick: handleSaveData,
          buttonType: ButtonType.neutral,
        },
        label: isPublished ? t('save_published_event') : t('toast_draft_saved'),
        hidden: false,
        loading: updateLoading,
      },
      thirdButton: {
        buttonProps: {
          disabled: updateLoading,
          onClick: saveAndNext,
          buttonType: ButtonType.primary,
        },
        label: t('actions_next_label'),
        hidden: false,
        loading: updateLoading,
      },
    };

    const SecondStepsButtons: StepButtonsConfig = {
      firstButton: {
        buttonProps: {
          disabled: updateLoading,
          onClick: saveAndPrevious,
          buttonType: ButtonType.secondary,
          startIconName: Icons.chevronLeft,
        },
        label: t('actions_previous_label'),
        hidden: false,
        loading: updateLoading,
      },
      secondButton: {
        buttonProps: {
          disabled: updateLoading || !isDirty,
          onClick: handleSaveData,
          buttonType: ButtonType.neutral,
        },
        label: isPublished ? t('save_published_event') : t('toast_draft_saved'),
        hidden: false,
        loading: updateLoading,
      },
      thirdButton: {
        buttonProps: {
          disabled: updateLoading,
          onClick: saveAndNext,
          buttonType: ButtonType.primary,
        },
        label: t('actions_next_label'),
        hidden: false,
        loading: updateLoading,
      },
    };

    const ThirdStepsButtons: StepButtonsConfig = {
      firstButton: {
        buttonProps: {
          disabled: updateLoading,
          onClick: saveAndPrevious,
          buttonType: ButtonType.secondary,
          startIconName: Icons.chevronLeft,
        },
        label: t('actions_previous_label'),
        hidden: false,
        loading: updateLoading,
      },
      secondButton: {
        buttonProps: {
          disabled: updateLoading || !isDirty,
          onClick: handleSaveData,
          buttonType: ButtonType.neutral,
        },
        label: isPublished ? t('save_published_event') : t('toast_draft_saved'),
        hidden: false,
        loading: updateLoading,
      },
      thirdButton: {
        buttonProps: {
          disabled: updateLoading,
          onClick: saveAndNext,
          buttonType: ButtonType.primary,
        },
        label: t('actions_next_label'),
        hidden: false,
        loading: updateLoading,
      },
    };

    const lastStepButtons: StepButtonsConfig = {
      firstButton: {
        buttonProps: {
          disabled: updateLoading,
          onClick: saveAndPrevious,
          buttonType: ButtonType.secondary,
          startIconName: Icons.chevronLeft,
        },
        label: t('actions_previous_label'),
        hidden: false,
        loading: updateLoading,
      },
      secondButton: {
        buttonProps: {
          disabled: updateLoading || !isDirty,
          onClick: handleSaveData,
          buttonType: ButtonType.neutral,
        },
        label: isPublished ? t('save_published_event') : t('toast_draft_saved'),
        hidden: false,
        loading: updateLoading,
      },
      thirdButton: {
        buttonProps: {
          disabled: !readyToPublish,
          onClick: handlePublishEvent,
          buttonType: ButtonType.primary,
        },
        label: t('actions_publish_label'),
        loading: publishLoading,
        hidden: false,
      },
    };

    // TODO: Remove this block when epic BIOGENLINC-3524 is finished
    if (!flags.eventsBiogenlinc4151SectionEmail) {
      switch (CreateHybridMeetingStepsIndex[stepIndexer.stepIndex]) {
        case CommonSteps.detailsStep:
          return firstStepButtons;
        case CommonSteps.publicationStep:
          return lastStepButtons;
        case CommonSteps.communicationStep:
          return SecondStepsButtons;
        default:
          return null;
      }
    }

    switch (CreateHybridMeetingWithEmailConfigurationStepsIndex[stepIndexer.stepIndex]) {
      case CommonSteps.detailsStep:
        return firstStepButtons;
      case CommonSteps.publicationStep:
        return lastStepButtons;
      case CommonSteps.communicationStep:
        return SecondStepsButtons;
      case CommonSteps.emailConfigurationStep:
        return ThirdStepsButtons;
      default:
        return null;
    }
  }, [
    updateLoading,
    isDirty,
    handleSaveData,
    isPublished,
    t,
    saveAndNext,
    saveAndPrevious,
    readyToPublish,
    handlePublishEvent,
    publishLoading,
    flags.eventsBiogenlinc4151SectionEmail,
    stepIndexer.stepIndex,
  ]);

  return button;
};

export default useCreateHybridMeetingButtons;
