export const homeRoute = '/';
export const loginRoute = '/login';

export const eventsRoute = '/events';

export const createOnlineMeetingRoute = '/events/createOnlineMeeting';
export const createOnlineMeetingWithIdRoute = `${createOnlineMeetingRoute}/:id`;

export const createPhysicalMeetingRoute = '/events/createPhysicalMeeting';
export const createPhysicalMeetingWithIdRoute = `${createPhysicalMeetingRoute}/:id`;

export const createHybridMeetingRoute = '/events/createHybridMeeting';
export const createHybridMeetingWithIdRoute = `${createHybridMeetingRoute}/:id`;

export const manageMeetingRoute = '/events/manageMeeting';
export const manageMeetingWithIdRoute = `${manageMeetingRoute}/:id`;
