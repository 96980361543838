import { BiogenProduct } from 'basics/enums/biogen.enums';
import { SelectOption } from 'basics/options/options.types';
import { EventBiogenBrand } from 'modules/events/basics/enums/biogen.enum';

const useBiogenBrandsOptions = (BrandEnum: typeof BiogenProduct | typeof EventBiogenBrand): SelectOption[] => {
  const options = Object.keys(BrandEnum)
    .map((brand) => ({
      id: BrandEnum[brand as keyof typeof BrandEnum],
      label: BrandEnum[brand as keyof typeof BrandEnum],
    }));

  return options;
};

export default useBiogenBrandsOptions;
