import { FC } from 'react';
import Box from 'yoda-ui/components/Box';
import Tag from 'yoda-ui/components/Tags/Tag';
import { YodaColors, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

type InfoTagProps = {
  title: string;
  field: string;
};

const InfoTag: FC<InfoTagProps> = ({ title, field }) => (
  <Box marginBottom={ YodaSpacing.medium } fontWeight={ YodaFontWeight.light }>
    { title }
    <Tag bgcolor={ YodaColors.gray1 } color={ YodaColors.gray5 }>{ field }</Tag>
  </Box>
);

export default InfoTag;
