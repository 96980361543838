import { useSnapshot } from 'valtio';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { TimeSlot } from 'modules/events/basics/types/events.types';
import { transformTimeSlotToSession } from 'modules/events/basics/utils/events.transformers';
import { isDraft } from 'modules/events/basics/utils/events.utils';
import { useBookSlotMutation } from 'modules/events/graphql/mutations/bookSlot';
import timeSlotGroupSchema from 'modules/events/groups/TimeSlotGroup/TimeSlotGroup.schema';
import eventState from 'modules/events/states/events.states';
import { useYodaField } from 'yoda-ui/yodaForm';

const useTimeSlot = () => {
  const { data: dataSnap, status } = useSnapshot(eventState.createEvent);

  const isPublished = !isDraft(status);

  const { setValue, fieldValue } = useYodaField({
    name: commonFields.session,
    defaultValue: dataSnap?.session,
    validation: timeSlotGroupSchema.session,
  });

  const [bookSlot, { data, loading }] = useBookSlotMutation();

  const storeSession = async (timeSlot: TimeSlot) => {
    setValue(commonFields.session, timeSlot);
    const session = transformTimeSlotToSession(timeSlot);
    if (eventState.createEvent._id && session) {
      await bookSlot(eventState.createEvent._id, session);
    }
  };

  const handleSave = async (props: TimeSlot) => {
    await storeSession(props);
  };

  return {
    handleSave,
    fieldValue,
    isLoading: loading,
    isInvalid: (data && !data.bookSlot) || false,
    isValid: (data && data.bookSlot) || false,
    isPublished,
  };
};

export default useTimeSlot;
