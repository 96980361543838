import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const GET_SECRET = gql`
query getSecret($key: String!) {
  getSecret(key: $key)
}`;

type ResultType = {
  getSecret: string;
};

export type UseGetSecret = () => {
  queryGetSecret: Function;
  loading: boolean;
  error?: ApolloError;
  data?: string;
};

export const useLazyGetSecretQuery: UseGetSecret = () => {
  const [queryGetSecret, { data, loading, error, called }] = useLazyQuery<ResultType>(GET_SECRET, {
    fetchPolicy: 'cache-and-network',
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getSecret.ts - useGetSecretQuery',
    });
  }

  return {
    queryGetSecret,
    data: data?.getSecret,
    loading,
    error,
    called,
  };
};

export default useLazyGetSecretQuery;
