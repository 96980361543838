import { Speaker } from 'modules/events/basics/types/speaker.types';
import { PossibleSpeaker } from 'modules/events/groups/SpeakersGroup/SpeakersGroup.types';

export const transformSpeakerToPossibleSpeaker = (data: Speaker, currentEventId?: string): PossibleSpeaker => {
  const { id, firstName, lastName, email, profile, title, image, relatedEvents, personalInfosVisibility } = data;
  const label = `${firstName} ${lastName}${email ? ` (${email})` : ''}`;

  const filteredPersonalInfosVisibility = currentEventId && personalInfosVisibility
    ? personalInfosVisibility.filter((personalInfo) => personalInfo.eventId === currentEventId) : undefined;

  return {
    id,
    firstName,
    lastName,
    email,
    label,
    profile,
    title,
    image,
    relatedEvents,
    eventPersonalInfosVisibility: filteredPersonalInfosVisibility && filteredPersonalInfosVisibility?.length > 0
      ? filteredPersonalInfosVisibility[0] : undefined,
  };
};
