import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { warningToast } from 'basics/utils/toast';
import { UserData } from 'generated/event.types';
import { participantFragment } from 'modules/events/graphql/fragments/commonData.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_LAZY_PARTICIPANT_BY_MDMID = gql`
  query getParticipantByMdmId($mdmId: String!) {
    getParticipantByMdmId(mdmId: $mdmId) {
      ${participantFragment}
    }
  }
`;

  type UseLazyGetParticipantByMdmId = () => {
    queryGetParticipantByMdmId: Function;
    loading: boolean;
    data?: UserData | null;
    error?: ApolloError;
    called: boolean;
  };

const useLazyGetParticipantByMdmId: UseLazyGetParticipantByMdmId = () => {
  const { t } = useTranslation();
  const [queryGetParticipantByMdmId, { data, loading, error, called }] = useLazyQuery(GET_LAZY_PARTICIPANT_BY_MDMID, {
    fetchPolicy: 'cache-and-network',
    onError(err) {
      const customContext = extractApolloErrorDetails(err);
      Logger.logError({
        error: err,
        customContext,
        transactionName: 'getParticipantByMdmId.ts - useLazyGetParticipantByMdmId',
      });

      warningToast(err.graphQLErrors[0]?.extensions.description as string || t('errors_standard'));
    },
  });

  return {
    queryGetParticipantByMdmId,
    data: data?.getUserData,
    loading,
    error,
    called,
  };
};

export default useLazyGetParticipantByMdmId;

