import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { succesToast, warningToast } from 'basics/utils/toast';
import { Program, ProgramInput } from 'modules/events/basics/types/program.types';
import { programFragment } from 'modules/events/graphql/fragments/program.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const createProgramMutationCall = gql`
mutation createProgramMutation($createProgram: ProgramInput!) {
    createProgram(program: $createProgram) {
        ${programFragment}
    }
}
`;

type ResultType = {
  createProgram: Program;
};

type UseCreateProgramMutation = [
  (program: ProgramInput) => Promise<Program | undefined | null>,
  MutationResult<ResultType>,
];

export const useCreateProgramMutation = (): UseCreateProgramMutation => {
  const { t } = useTranslation();
  const [createProgram, state] = useMutation<ResultType>(createProgramMutationCall);
  const executeMutation = useCallback(async (program) => {
    try {
      const data = await createProgram({
        variables: { createProgram: program },
      });
      succesToast(t('setProgram_toast_success'));
      return data.data?.createProgram;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error, program);
        Logger.logError({
          error,
          customContext,
          transactionName: 'createProgram.ts - useCreateProgramMutation',
        });
        if (customContext.description) warningToast(String(customContext.description));
      }
      return null;
    }
  }, [createProgram, t]);

  return [executeMutation, state];
};
