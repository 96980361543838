import { OAuthUserInfo } from '@mybiogen/auth-openid';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import Avatar from 'yoda-ui/components/Avatar';
import Box from 'yoda-ui/components/Box';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const getInitials = (str: string) => str
  .split(' ')
  .map((slice) => slice[0].toUpperCase())
  .join('');

const UserAvatar: FC<{ user: OAuthUserInfo }> = ({ user }) => (
  <Box marginRight={ YodaSpacing.large } marginY='auto'>
    <Link to="/">
      <Avatar>{ getInitials(user.name) }</Avatar>
    </Link>
  </Box>
);

export default UserAvatar;
