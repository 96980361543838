import { format } from 'date-fns-tz';
import { SelectOption } from './options.types';
import { defaultTimeZone, timeZoneList } from 'basics/enums/timeZoneList.enums';

export const getTimezoneUtcOffset = (region: string): string => {
  const date = new Date();
  const finalTimezone = region || defaultTimeZone;
  const formatTime = format(date, 'yyyy-MM-dd HH:mm:ssOOOO', { timeZone: `${finalTimezone}` });
  const UTCnumbers = formatTime.split('GMT');
  return `UTC${UTCnumbers.pop()}`;
};

export const createTimezoneOption = (timezone: string): SelectOption => {
  const date = new Date();
  const finalTimezone = timezone || defaultTimeZone;
  const formatTime = format(date, 'yyyy-MM-dd HH:mm:ssOOOO', { timeZone: `${finalTimezone}` });
  const strFormatTime = `${finalTimezone} (${formatTime.substring(formatTime.length - 9)})`;
  return {
    id: finalTimezone,
    label: strFormatTime,
  };
};
export const buildTimeZoneOptions = (): SelectOption[] => {
  const timeZoneOptions: SelectOption[] = [];
  timeZoneList.forEach((timeZone) => {
    timeZoneOptions.push(createTimezoneOption(timeZone));
  });
  return timeZoneOptions;
};

export const timezoneOptions = buildTimeZoneOptions();

export const getOffsetTime = (datetime?: string | number | Date): string => {
  if (!datetime) return String(datetime);

  const dt = new Date(datetime);
  const offset = dt.getTimezoneOffset() / 60;
  const offsetDt = dt.setHours(dt.getHours() + offset);

  return new Date(offsetDt).toISOString();
};
