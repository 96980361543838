import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultDialogTitle from 'components/Dialogs/DefaultDialogTitle';
import { Presence, Provider } from 'generated/event.types';
import { getBaseUrlPerMarket } from 'modules/events/basics/constants/event.constants';
import { Event } from 'modules/events/basics/types/events.types';
import { HybridMeetingFromDB } from 'modules/events/basics/types/hybridMeeting.types';
import { OnlineMeetingFromDB } from 'modules/events/basics/types/onlineMeeting.types';
import { prettifyEventUrl } from 'modules/events/basics/utils/events.utils';
import EventSummary from 'modules/events/components/EventsList/EventSummary';
import LinkItem, { LinkConfig } from 'modules/events/components/EventsList/LinkDialog/LinkItem/LinkItem';
import Box from 'yoda-ui/components/Box';
import Dialog, { DialogProps } from 'yoda-ui/components/Dialogs/Dialog/Dialog';
import DialogContent from 'yoda-ui/components/Dialogs/Dialog/DialogContent';
import { Icons } from 'yoda-ui/components/Icons/Icon';
import { YodaBorderRadius, YodaFontSize, YodaSpacing } from 'yoda-ui/yodaTheme';

export type LinkDialogProps = DialogProps & {
  closeDialog: () => void;
  event: Event;
  providerData?: Provider | null;
};

const dialogStyle = {
  '& .MuiPaper-rounded': {
    maxWidth: 'unset',
    overflow: 'visible',
  },
};

const LinkDialog: FC<LinkDialogProps> = ({ open, closeDialog, event, providerData, ...props }) => {
  const { t } = useTranslation();

  const { title, _id: eventId, market } = event;
  const prettyEventTitle = prettifyEventUrl(`${title}_${eventId}`);

  const linkBaseDomain = getBaseUrlPerMarket(market);

  const eventIdConfig: LinkConfig = {
    title: t('linkDialog_event_id'),
    link: event._id || '',
  };

  const veevaExternalIdConfig: LinkConfig = {
    title: t('linkDialog_external_id'),
    link: event.externalId || '',
  };

  const webAppLinkLConfig: LinkConfig = {
    title: t('linkDialog_web_app_link'),
    link: linkBaseDomain ? `${linkBaseDomain}/events-list/${prettyEventTitle}` : '',
  };

  const linkConfigs = [
    eventIdConfig,
    veevaExternalIdConfig,
    ...(event.data.biogenLincConfig?.isVisibleOnBiogenLinc ? [webAppLinkLConfig] : []),
  ];

  if (event.presence === Presence.online || event.presence === Presence.hybrid) {
    const { licenseId, organizerUrl, participantUrl } = providerData
    || { organizerUrl: '', participantUrl: '' };

    const organizerLinkConfig: LinkConfig = {
      title: t('linkDialog_adminLink_title'),
      link: organizerUrl || '',
      button: {
        icon: Icons.playCircleOutline,
        label: t('linkDialog_launchEventButton_label'),
      },
    };

    const userLinkConfig: LinkConfig = {
      title: t('linkDialog_userLink_title'),
      link: participantUrl || '',
    };

    const organizerProfileConfig: LinkConfig = {
      title: t('linkDialog_adminAccount_title'),
      link: licenseId || '',
    };
    const zoomSessionIdConfig: LinkConfig = {
      title: t('linkDialog_provided_id'),
      link: (event.presence === Presence.online || event.presence === Presence.hybrid)
        ? (event.data as OnlineMeetingFromDB | HybridMeetingFromDB).provider?.providedId
        || '' : '',
    };

    linkConfigs.unshift(organizerProfileConfig, organizerLinkConfig, userLinkConfig, zoomSessionIdConfig);
  }

  return (
    <Dialog open={ open } { ...props } borderRadius={ YodaBorderRadius.medium } onClose={ closeDialog } sx={ dialogStyle }>
      <Box padding={ YodaSpacing.large }>
        <DefaultDialogTitle cancelDialog={ closeDialog }>
          <EventSummary event={ event } titleSize={ YodaFontSize.xLarge }/>
        </DefaultDialogTitle>
        <DialogContent>
          { linkConfigs.map((linkConfig) => (<LinkItem key={ linkConfig.title } linkConfig={ linkConfig } />)) }
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default LinkDialog;
