/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { mixed } from 'yup';
import { testEndDayNotBeforeStartDay } from '../../../../basics/utils/dates/dates.utils';
import { EventDateContext } from 'contexts/eventDate';
import { DateRange } from 'yoda-ui/components/Form/DateRangePicker';

const useEventDatePicker = () => {
  const { t } = useTranslation();
  const eventDateContext = useContext(EventDateContext);

  const setDate = (value: DateRange) => {
    eventDateContext.setDate(value);
  };

  const config = {
    dateRange: {
      validation: mixed()
        .test({
          name: 'start date before end date',
          message: t('timeSlotBooking_endDate_errorMessage_earlierThanStart'),
          test: testEndDayNotBeforeStartDay,
        }),
    },
  };

  return {
    config,
    date: eventDateContext.date,
    setDate,
  };
};

export default useEventDatePicker;
