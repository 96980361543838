import { Dispatch, FC, SetStateAction } from 'react';
import { Event, Participant } from 'modules/events/basics/types/events.types';
import ParticipantsGroup from 'modules/events/groups/ParticipantsGroup';
import RegisterParticipantGroup from 'modules/events/groups/RegisterParticipantGroup';
import Box from 'yoda-ui/components/Box';

type InviteesRegistrationPanelProps = {
  eventId: string;
  participants: Participant[] | null;
  setParticipants: Dispatch<SetStateAction<Participant[] | null>>;
  seats: number;
  isRestrictedAccess?: boolean;
  e2eTestMode?: boolean;
  collectMobileNumber?: boolean;
  refreshList?: () => void;
  event?: Event;
};

const InviteesRegistrationPanel: FC<InviteesRegistrationPanelProps> = (props) => {
  if (!props.participants) {
    return null;
  }

  return (
    <>
      {
        !props?.e2eTestMode && (
          <RegisterParticipantGroup
            eventId={ props.eventId }
            participants={ props.participants }
            setParticipants={ props.setParticipants }
            seats={ props.seats }
            collectMobileNumber={ props.collectMobileNumber }
          />
        )
      }

      <Box flexDirection="column">
        {
          props.isRestrictedAccess
          && <ParticipantsGroup
            seats={ props.seats }
            eventId={ props.eventId }
            participants={ props?.participants.filter((participant) => participant.restricted === false) }
            setParticipants={ props.setParticipants }
            e2eTestMode={ props?.e2eTestMode }
            refreshList={ props?.refreshList }
            event={ props?.event }
            priviligedList={ true }
          />
        }
        <ParticipantsGroup
          seats={ props.isRestrictedAccess ? 0 : props.seats }
          eventId={ props.eventId }
          participants={
            props.isRestrictedAccess
              ? props.participants.filter((participant) => participant.restricted !== false)
              : props.participants
          }
          setParticipants={ props.setParticipants }
          e2eTestMode={ props?.e2eTestMode }
          refreshList={ props?.refreshList }
          event={ props?.event }
        />
      </Box>
    </>

  );
};

export default InviteesRegistrationPanel;
