import { SelectOption } from 'basics/options/options.types';

export const normalizeValueToSelectOption = (value: string | SelectOption): SelectOption => {
  if (typeof value === 'string') {
    return { id: value, label: value };
  }
  return value;
};

export const normalizeValueToString = (value: string | SelectOption): string => {
  if (typeof value === 'string') {
    return value;
  }
  return value.label;
};

export const transformStringsToSelectOptions = (strings?: string[]): SelectOption[] => {
  if (!strings) {
    return [];
  }
  return strings.map(normalizeValueToSelectOption);
};

