const ENVS: { [hostName: string]: string } = {
  localhost: 'local',
  '127.0.0.1': 'local',
  'back.mybiogen.io': 'local',
  'backoffice.dev.mybiogen.io': 'dev',
  'backoffice.stage.mybiogen.io': 'stage',
  'backoffice.biogenlinc.com': 'prod',
};

const DEFAULTS: { [varName: string]: string } = {
  REACT_APP_DEFAULT_LANGUAGE: 'en',
  REACT_APP_HOTJAR_ID: '1952148',
  REACT_APP_HOTJAR_SV: '6',
  REACT_APP_OKTA_CLIENT_ID: '0oasm0i49njWvzuPq0h7',
  REACT_APP_OKTA_CONFIG_URL: 'https://biogen.oktapreview.com/.well-known/openid-configuration',
  REACT_APP_SENTRY_ENABLED: 'false',
  REACT_APP_SENTRY_DSN: 'https://cd1833eab8d648a29aa813cf27d5a939@o442068.ingest.sentry.io/5982268',
  REACT_APP_MAYA_URL: 'https://maya.back.mybiogen.io:3004',
};

const CONFIG: { [env: string]: { [varName: string]: string } } = {
  local: {
    REACT_APP_AWS_ENV: 'dev',
    REACT_APP_CDN_DNS: 'cdn.dev.mybiogen.io',
    REACT_APP_LAUNCHDARKLY_CLIENT_ID: '60dc579ece62f1240b46dccc',
    REACT_APP_OKTA_LOGOUT_REDIRECT_URI: 'https://back.mybiogen.io:3001',
    REACT_APP_OKTA_REDIRECT_URI: 'https://back.mybiogen.io:3001',
    REACT_APP_PUBLIC_MYBIOGEN_GRAPHQL_API_URI: 'https://localhost:4000/graphql',
    REACT_APP_EVENTS_GRAPHQL_URI: 'https://localhost:4003/graphql',
    REACT_APP_MAYA_GRAPHQL_URI: 'https://localhost:4005/graphql',
    REACT_APP_AKAMAI_MIDDLEWARE_URI: 'https://dev.app.biogenlinc.eu',
    REACT_APP_MAYA_URL: 'https://maya.back.mybiogen.io:3004',
  },
  dev: {
    REACT_APP_AWS_ENV: 'dev',
    REACT_APP_CDN_DNS: 'cdn.dev.mybiogen.io',
    REACT_APP_LAUNCHDARKLY_CLIENT_ID: '60dc579ece62f1240b46dccc',
    REACT_APP_OKTA_LOGOUT_REDIRECT_URI: 'https://backoffice.dev.mybiogen.io',
    REACT_APP_OKTA_REDIRECT_URI: 'https://backoffice.dev.mybiogen.io',
    REACT_APP_PUBLIC_MYBIOGEN_GRAPHQL_API_URI: 'https://gql-back.dev.mybiogen.io/graphql',
    REACT_APP_EVENTS_GRAPHQL_URI: 'https://events.dev.mybiogen.io/graphql',
    REACT_APP_MAYA_GRAPHQL_URI: 'https://maya.dev.mybiogen.io/graphql',
    REACT_APP_AKAMAI_MIDDLEWARE_URI: 'https://dev.app.biogenlinc.eu',
    REACT_APP_SENTRY_ENABLED: 'true',
    REACT_APP_MAYA_URL: 'https://maya.backoffice.dev.mybiogen.io',
  },
  stage: {
    REACT_APP_AWS_ENV: 'stage',
    REACT_APP_CDN_DNS: 'cdn.stage.mybiogen.io',
    REACT_APP_LAUNCHDARKLY_CLIENT_ID: '60dc46bd64c60f258d31f67a',
    REACT_APP_OKTA_CLIENT_ID: '0oa1628t22rhpr7I70h8',
    REACT_APP_OKTA_LOGOUT_REDIRECT_URI: 'https://backoffice.stage.mybiogen.io',
    REACT_APP_OKTA_REDIRECT_URI: 'https://backoffice.stage.mybiogen.io',
    REACT_APP_PUBLIC_MYBIOGEN_GRAPHQL_API_URI: 'https://gql-back.preprod.mybiogen.io/graphql',
    REACT_APP_EVENTS_GRAPHQL_URI: 'https://events.stage.mybiogen.io/graphql',
    REACT_APP_MAYA_GRAPHQL_URI: 'https://maya.stage.mybiogen.io/graphql',
    REACT_APP_AKAMAI_MIDDLEWARE_URI: 'https://stg.app.biogenlinc.eu',
    REACT_APP_SENTRY_ENABLED: 'true',
    REACT_APP_MAYA_URL: 'https://maya.backoffice.stage.mybiogen.io',
  },
  prod: {
    REACT_APP_AWS_ENV: 'prod',
    REACT_APP_CDN_DNS: 'cdn.mybiogen.io',
    REACT_APP_LAUNCHDARKLY_CLIENT_ID: '60dc46bd64c60f258d31f67b',
    REACT_APP_OKTA_CLIENT_ID: '0oap54ua3yuU75uru0x7',
    REACT_APP_OKTA_CONFIG_URL: 'https://biogen.okta.com/.well-known/openid-configuration',
    REACT_APP_OKTA_LOGOUT_REDIRECT_URI: 'https://backoffice.biogenlinc.com',
    REACT_APP_OKTA_REDIRECT_URI: 'https://backoffice.biogenlinc.com',
    REACT_APP_PUBLIC_MYBIOGEN_GRAPHQL_API_URI: 'https://gql-back.mybiogen.io/graphql',
    REACT_APP_EVENTS_GRAPHQL_URI: 'https://events.mybiogen.io/graphql',
    REACT_APP_MAYA_GRAPHQL_URI: 'https://maya.mybiogen.io/graphql',
    REACT_APP_AKAMAI_MIDDLEWARE_URI: 'https://app.biogenlinc.eu',
    REACT_APP_SENTRY_ENABLED: 'true',
    REACT_APP_MAYA_URL: 'https://maya.backoffice.biogenlinc.com',
  },
};

const isBrowser = () => typeof window !== 'undefined';
export const CURRENT_ENV = isBrowser() ? ENVS[window.location.hostname] : 'local';

export const isProduction = CURRENT_ENV === 'prod';
export const isStage = CURRENT_ENV === 'stage';
export const isDev = CURRENT_ENV === 'dev';
export const isLocal = CURRENT_ENV === 'local';

export function getConfigVar(varName: string, currentEnv = CURRENT_ENV): string {
  return process.env[varName] || CONFIG[currentEnv][varName] || DEFAULTS[varName];
}
