import EmailNotesGroup from 'modules/events/groups/EmailNotesGroup';
import useEmailConfigurationStep from 'modules/events/routes/CreateHybridMeeting/Steps/EmailConfigurationStep/EmailConfigurationStep.hook';
import Box from 'yoda-ui/components/Box';

const EmailConfigurationStep = () => {
  useEmailConfigurationStep();
  return (
    <Box>
      <EmailNotesGroup />
    </Box>
  );
};

export default EmailConfigurationStep;
