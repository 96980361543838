import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FormGroup from 'components/FormGroup';
import { CommonGroups } from 'modules/events/basics/enums/commonForms.enums';
import UserGroupForm from 'modules/events/components/UserGroup/UserGroup';

const UserManagementGroup: FC = () => {
  const { t } = useTranslation();
  return (
    <FormGroup title={ t('events_userGroup_card_title') } groupName={ CommonGroups.userGroup }>
      <UserGroupForm></UserGroupForm>
    </FormGroup>

  );
};
export default UserManagementGroup;
