import { useTranslation } from 'react-i18next';
import useNavigationConfigGroupConfig from './NavigationConfigGroup.config';
import FormGroup from 'components/FormGroup';
import { CommonGroups } from 'modules/events/basics/enums/commonForms.enums';
import Box from 'yoda-ui/components/Box';
import CheckBoxYoda from 'yoda-ui/components/Form/CheckBoxYoda';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const NavigationConfigGroup = () => {
  const { t } = useTranslation();
  const config = useNavigationConfigGroupConfig();

  return (
    <FormGroup title={ t('navigationConfigGroup_title') } groupName={ CommonGroups.navigationConfig }>
      <Box marginBottom={ YodaSpacing.large }>
        <CheckBoxYoda { ...config.showNavigationBarActions } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <CheckBoxYoda { ...config.showNavigationBarElements } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <CheckBoxYoda { ...config.showFooterNavItems } />
      </Box>
    </FormGroup>
  );
};

export default NavigationConfigGroup;
