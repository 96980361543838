import { useMemo } from 'react';
import { InviteesGroupEnums } from '../InviteesGroup/InviteesGroup.enums';
import useRestrictedAccessGroup from './RestrictedAccessGroup.hook';
import * as styles from './RestrictedAccessGroup.styles';
import CardSwitch from 'components/CardSwitch';
import FormGroup from 'components/FormGroup';
import { CommonGroups } from 'modules/events/basics/enums/commonForms.enums';
import UploadInvitees from 'modules/events/components/UploadInvitees';
import Box from 'yoda-ui/components/Box';
import RichtextYoda from 'yoda-ui/components/Form/RichtextYoda';
import Tabs from 'yoda-ui/components/Tabs';
import Tab from 'yoda-ui/components/Tabs/Tab';
import { YodaColors, YodaSpacing } from 'yoda-ui/yodaTheme';

const { PRIVILEGED_LIST } = InviteesGroupEnums;
export const sTab = {
  flexGrow: 1,
  width: '50%',
  maxWidth: 'initial',
  textTransform: 'initial',
  color: `${YodaColors.textPrimary}`,
};

const RestrictedAccessGroup = () => {
  const {
    cardSwitchConfig,
    restrictedAccessConfig,
    restrictedDescriptionConfig,
    openedPanel,
    selectedTab,
    setSelectedTab,
    t,
    tabsConfig,
  } = useRestrictedAccessGroup();

  const tabs = useMemo(
    () => (tabsConfig.map(({ label, value }) => (
      <Tab
        key={ value }
        label={ label }
        value={ value }
        sx={ styles.Tab }
      />
    ))),
    [tabsConfig],
  );

  const privilegedInviteesPanel = useMemo(
    () => <UploadInvitees { ...restrictedAccessConfig } />,
    [restrictedAccessConfig],
  );

  return (
    <>
      <CardSwitch
        name={ cardSwitchConfig.name }
        title={ cardSwitchConfig.title }
        defaultValue={ cardSwitchConfig.defaultValue }
        disabled={ cardSwitchConfig.disabled }
      />
      {
        openedPanel && (
          <FormGroup title={ t('restrictedGroup_title') } groupName={ CommonGroups.restrictedAccess }>
            <Tabs value={ selectedTab } onChange={ (_, value) => setSelectedTab(value) }>
              { tabs }
            </Tabs>
            <Box hidden={ selectedTab !== PRIVILEGED_LIST }>
              { privilegedInviteesPanel }
            </Box>
            <Box marginTop={ YodaSpacing.large }>
              <RichtextYoda { ...restrictedDescriptionConfig } />
            </Box>
          </FormGroup>
        )
      }
    </>
  );
};

export default RestrictedAccessGroup;
