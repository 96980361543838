import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { eventsRoute } from 'basics/constants/routes.constants';
import useStepIndexer from 'hooks/useStepIndexer';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { Event } from 'modules/events/basics/types/events.types';
import { usePublishPhysicalMeetingMutation } from 'modules/events/graphql/mutations/publishPhysicalMeeting';

import { useUpdatePhysicalMeetingMutation } from 'modules/events/graphql/mutations/updatePhysicalMeeting';
import { createPhysicalMeetingStepIndexer } from 'modules/events/routes/CreatePhysicalMeeting/CreatePhysicalMeeting.states';
import { physicalMeetingInputTransformer } from 'modules/events/routes/CreatePhysicalMeeting/CreatePhysicalMeeting.transformers';

import {
  CreatePhysicalMeetingStepsIndex,
  CreatePhysicalMeetingWithEmailConfigurationStepsIndex,
} from 'modules/events/routes/CreatePhysicalMeeting/CreatePhysicalMeeting.types';
import eventState from 'modules/events/states/events.states';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useCreatePhysicalMeetingActions = (yodaFormMethods: any) => {
  const navigate = useNavigate();
  const stepIndexer = useStepIndexer(createPhysicalMeetingStepIndexer);
  const { stepIndex, moveNextStep, movePreviousStep } = stepIndexer;
  const { getValues, getDirtyValues, formState, setStepShowError, setFormIsDirty } = yodaFormMethods;
  const [publishPhysicalMeeting, { loading: publishLoading }] = usePublishPhysicalMeetingMutation();
  const [updatePhysicalMeeting, { loading: updateLoading }] = useUpdatePhysicalMeetingMutation();
  const flags = useFlags();

  const getPhysicalMeetingFormData = useCallback(() => {
    const formValues = getValues();
    const dataToSave = getDirtyValues();
    return {
      physicalMeetingInput: physicalMeetingInputTransformer(dataToSave, formValues),
      physicalMeetingFormat: dataToSave[commonFields.sessionEventFormat] ?? null,
    };
  }, [getDirtyValues, getValues]);

  const updateEventState = useCallback((updatedEvent?: Event | null) => {
    if (updatedEvent) {
      eventState.createEvent.updatedAt = updatedEvent.updatedAt;
      eventState.createEvent.status = updatedEvent.status;
    }
  }, []);

  const handleSaveData = useCallback(async () => {
    if (formState.isDirty) {
      const physicalMeetingFormData = getPhysicalMeetingFormData();

      if (physicalMeetingFormData.physicalMeetingInput) {
        const updatedMeeting = await updatePhysicalMeeting(
          physicalMeetingFormData.physicalMeetingInput,
          physicalMeetingFormData.physicalMeetingFormat,
        );
        setFormIsDirty(false);
        updateEventState(updatedMeeting);
      }
    }
  }, [formState.isDirty, getPhysicalMeetingFormData, updatePhysicalMeeting, setFormIsDirty, updateEventState]);

  const handlePublishEvent = useCallback(async () => {
    if (formState.isValid) {
      const physicalMeetingFormData = getPhysicalMeetingFormData();
      if (physicalMeetingFormData.physicalMeetingInput) {
        const publishedMeeting = await publishPhysicalMeeting(physicalMeetingFormData.physicalMeetingInput);
        if (publishedMeeting) {
          updateEventState(publishedMeeting);
          navigate(eventsRoute);
        }
      }
    }
  }, [formState.isValid, getPhysicalMeetingFormData, navigate, publishPhysicalMeeting, updateEventState]);

  const setShowError = useCallback((stepIndexVal: number) => {
    const step = flags.eventsBiogenlinc4151SectionEmail
      ? CreatePhysicalMeetingWithEmailConfigurationStepsIndex[stepIndexVal] : CreatePhysicalMeetingStepsIndex[stepIndexVal];
    setStepShowError(true, [step]);
  }, [flags.eventsBiogenlinc4151SectionEmail, setStepShowError]);

  const handleChangeStep = useCallback((stepIndexVal: number) => {
    const previousStep = stepIndexer.previousStep || 0;
    stepIndexer.moveToStep(stepIndexVal);
    handleSaveData();
    setShowError(previousStep);
  }, [handleSaveData, setShowError, stepIndexer]);

  const saveAndNext = useCallback(async () => {
    await handleSaveData();
    setShowError(stepIndex);
    moveNextStep();
  }, [handleSaveData, moveNextStep, setShowError, stepIndex]);

  const saveAndPrevious = useCallback(async () => {
    await handleSaveData();
    setShowError(stepIndex);
    movePreviousStep();
  }, [handleSaveData, movePreviousStep, setShowError, stepIndex]);

  return {
    handleChangeStep,
    handlePublishEvent,
    handleSaveData,
    setShowError,
    publishLoading,
    updateLoading,
    stepIndexer,
    saveAndNext,
    saveAndPrevious,
  };
};

export default useCreatePhysicalMeetingActions;
