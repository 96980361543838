import { SelectOption } from 'basics/options/options.types';
import { Market } from 'generated/event.types';
import { languageListPerMarket } from 'modules/events/basics/constants/language.constants';

const eventLocaleOptions = (market: Market | undefined): SelectOption[] => {
  if (!market) {
    return [];
  }

  return languageListPerMarket[market as string];
};

export default eventLocaleOptions;
