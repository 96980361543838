import { SelectOption } from 'basics/options/options.types';
import { TimeSlot } from 'modules/events/basics/types/events.types';
import { DatePickerYodaInputProps } from 'yoda-ui/components/Form/DatePickerYoda';
import { DateRangePickerProps } from 'yoda-ui/components/Form/DateRangePicker';
import { SelectYodaInputProps } from 'yoda-ui/components/Form/SelectYoda/SelectYoda.types';
import { TextYodaInputProps } from 'yoda-ui/components/Form/TextYoda';

export type SessionInformationProps = {
  values?: TimeSlot;
  disabled: boolean;
};

export enum SessionInformationField {
  dateRange = 'dateRange',
  endTime = 'endTime',
  startTime = 'startTime',
  timeZone = 'timeZone',
  locale = 'locale',
  eventFormat = 'eventFormat',
  location = 'location',
  seats = 'seats',
  registrationEndDate = 'registrationEndDate',
}

export type SessionInformationConfig = {
  dateRange: DateRangePickerProps;
  timeZone: SelectYodaInputProps;
  startTime: SelectYodaInputProps;
  endTime: SelectYodaInputProps;
  eventFormat: SelectYodaInputProps;
  locale: SelectYodaInputProps;
  location: TextYodaInputProps;
  seats: TextYodaInputProps;
  registrationEndDate: DatePickerYodaInputProps;
};

export type UseSessionInformationConfigProps = {
  startTimeOptions: SelectOption[];
  endTimeOptions: SelectOption[];
  values?: TimeSlot;
};

