import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { ApolloSpeakersResponse, PossibleSpeaker } from 'modules/events/groups/SpeakersGroup/SpeakersGroup.types';

import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const GET_POSSIBLE_SPEAKERS = gql`
  query getPossibleSpeakers($limit: Float!, $offset: Float!,$name: String) {
    findSpeakers(limit:$limit, offset: $offset, lookup: $name) {
      count,
      speakers {
        id: _id
        title
        firstName
        lastName
        email
        profile {
          default {
            affiliation
            specialty
          }
        }
        image {
          id: _id
          name
          url
          size
        }
        relatedEvents {
          _id
          title
          presence
          data {
            ...on OnlineEvent {
              session {
                startDate
                endDate
              }
            }
            ...on PhysicalEvent {
              session {
                startDate
                endDate
              }
            }
            ...on HybridEvent {
              session {
                startDate
                endDate
              }
            }
          }
        }
      }
    }
  }
`;

type UseLazyGetPossibleSpeakers = () => {
  called: boolean;
  data: PossibleSpeaker[] | null;
  count: number;
  error?: ApolloError;
  lazyQuery: Function;
  loading: boolean;
};

const cleanApolloData = (data: ApolloSpeakersResponse): null | PossibleSpeaker[] => {
  if (data && data.findSpeakers) {
    return data.findSpeakers.speakers.map((apolloSpeaker) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { id, firstName, lastName, email, profile, title, image, relatedEvents } = apolloSpeaker;
      const label = `${firstName} ${lastName}${email ? ` (${email})` : ''}`;
      return { id, firstName, lastName, email, label, profile, title, image, relatedEvents };
    });
  }
  return null;
};

const useLazyGetPossibleSpeakers: UseLazyGetPossibleSpeakers = () => {
  const [queryPossibleSpeakers, { called, data, error, loading }] = useLazyQuery(GET_POSSIBLE_SPEAKERS, {
    fetchPolicy: 'cache-and-network',
  });

  const lazyQuery = useCallback(async (query: string | null, queryOptions = { limit: 5000, offset: 0 }) => {
    await queryPossibleSpeakers(
      {
        variables: {
          name: query,
          limit: queryOptions.limit,
          offset: queryOptions.offset,
        },
      },
    );
  }, [queryPossibleSpeakers]);

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      customContext,
      error,
      transactionName: 'getPossibleSpeakers.ts - useLazyGetPossibleSpeakers',
    });
  }

  return {
    called,
    data: cleanApolloData(data),
    count: data?.findSpeakers.count || 0,
    error,
    lazyQuery,
    loading,
  };
};

export default useLazyGetPossibleSpeakers;
