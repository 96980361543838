import { orderBy, startCase } from 'lodash';
import {
  createHybridMeetingRoute,
  createOnlineMeetingRoute,
  createPhysicalMeetingRoute,
  manageMeetingRoute,
} from 'basics/constants/routes.constants';
import { Order } from 'basics/enums/general.enums';
import { EventStatus, Presence } from 'generated/event.types';
import { Event } from 'modules/events/basics/types/events.types';

export const getEventType = (event: Event) => (`${event.presence} ${startCase(event.format)}`.toUpperCase());

type SortEvents = (eventsList: Event[], property: string, order: Order) => Event[];

export const sortEvents: SortEvents = (eventsList, property, order) => orderBy(eventsList, property, order);

type SortEventsByDate = (eventsList: Event[], order?: Order) => Event[];

export const sortEventsByCreatedAtDate: SortEventsByDate = (eventsList, order = Order.desc) => sortEvents(eventsList, 'createdAt', order);

export const isDraft = (eventStatus: EventStatus | undefined) => {
  if (!eventStatus) {
    return false;
  }
  return [EventStatus.draft, EventStatus.ready].includes(eventStatus);
};

export const getEditRouteFromEvent = (event: Event): string => {
  const { presence } = event;
  switch (presence) {
    case Presence.physical:
      return `${createPhysicalMeetingRoute}/${event._id}`;
    case Presence.hybrid:
      return `${createHybridMeetingRoute}/${event._id}`;
    case Presence.online:
    default:
      return `${createOnlineMeetingRoute}/${event._id}`;
  }
};

export const getManageEventRoute = (event: Event): string => {
  return `${manageMeetingRoute}/${event._id}`;
};

export const prettifyEventUrl = (url: string) => {
  const urlWithAccentsRemoved = url.normalize('NFD').toLowerCase().replace(/[\u0300-\u036f]/g, '');
  const urlWithSpacesReplaced = urlWithAccentsRemoved.replace(/ /g, '-');
  return urlWithSpacesReplaced.replace(/[^a-z0-9-_]/g, '');
};
