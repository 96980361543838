import Box from 'yoda-ui/components/Box';
import { YodaFontSize, YodaSpacing } from 'yoda-ui/yodaTheme';

const TabTitle = ({ title }: { title: string }) => (
  <Box marginBottom={ YodaSpacing.xxLarge } fontSize={ YodaFontSize.xLarge }>
    { title }
  </Box>
);

export default TabTitle;
