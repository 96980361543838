import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { CurrentUser } from 'basics/types/user.types';
import { useCurrentUserState } from 'contexts/user';
import { Event } from 'modules/events/basics/types/events.types';
import { eventFragment } from 'modules/events/graphql/fragments/event.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const createEventMutationCall = gql`
mutation createEventMutation($createEvent: EventInput!) {
    createEvent(event: $createEvent) {
        ${eventFragment}
    }
}
`;

type ResultType = {
  createEvent: Event;
};

type UseCreateMeetingMutation = [
  (event: Partial<Event>) => Promise<Event | undefined | null>,
  MutationResult<ResultType>,
];

export const transformCurrentUserData = (user?: CurrentUser | null) => {
  if (!user) return null;

  return {
    name: user?.name,
    email: user?.email,
    id: user?.id,
    lastName: user?.lastName,
  };
};

export const useCreateEventMutation = (): UseCreateMeetingMutation => {
  const [createEvent, state] = useMutation<ResultType>(createEventMutationCall);
  const { user } = useCurrentUserState();

  const executeMutation = useCallback(async (event) => {
    try {
      const eventWithOwner = {
        owner: transformCurrentUserData(user),
        ...event,
      };
      const data = await createEvent({
        variables: { createEvent: eventWithOwner },
      });
      return data.data?.createEvent;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error, event);
        Logger.logError({
          error,
          customContext,
          transactionName: 'createEvent.ts - useCreateEventMutation',
        });
      }
      return null;
    }
  }, [createEvent, user]);

  return [executeMutation, state];
};
