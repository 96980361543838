import { Box } from '@mui/material';
import FormGroup from 'components/FormGroup';
import { CommonGroups } from 'modules/events/basics/enums/commonForms.enums';
import useEventTesting from 'modules/events/components/EventTesting/EventTesting.hook';
import InviteesRegistrationPanel from 'modules/events/components/InviteesRegistrationPanel';
import { ActionButton, ButtonVariant, TextButton } from 'yoda-ui/components/BiogenLincDS/Buttons';
import SelectYoda from 'yoda-ui/components/Form/SelectYoda';
import { Icons } from 'yoda-ui/components/Icons/Icon';
import { YodaFormProvider } from 'yoda-ui/yodaForm';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const EventTesting = () => {
  const { t,
    eventTestingConfig,
    providerFields,
    eventId,
    participants,
    setParticipants,
    lastTestAt,
    refreshListFunction,
    event,
    mailinatorUrl,
    pdfUrl,
    screenshotProgress,
    pdfLoading } = useEventTesting();

  return (
    <>
      <YodaFormProvider { ...providerFields }>
        <FormGroup title={ t('event_testing_subsection_title') } groupName={ CommonGroups.testing }>
          <SelectYoda { ...eventTestingConfig.testUserList } />

          <Box display="flex" flexDirection="row" justifyContent="space-between" mt={ YodaSpacing.small }>
            <TextButton { ...eventTestingConfig.generatePdfButton }/>
            <ActionButton
              icon={ Icons.donwload }
              label={
                pdfLoading
                  ? `${t('event_test_screenshot_generating_progress_label',
                    { screenshotProgress: screenshotProgress || 0 })}`
                  : t('event_testing_download_pdf_button')
              }
              href={ pdfUrl || undefined }
              target='_blank'
              variant={ ButtonVariant.primary }
              disabled={ !pdfUrl || pdfLoading }
            />
            <ActionButton
              icon={ Icons.mail }
              label={ t('event_testing_go_to_mailinator') }
              href={ mailinatorUrl }
              target='_blank'
              variant={ ButtonVariant.primary }
              disabled={ !mailinatorUrl }
            />
            <TextButton { ...eventTestingConfig.addPrivilegedUser }/>
            <TextButton { ...eventTestingConfig.testButton }/>
          </Box>

          {
            lastTestAt && (
              <InviteesRegistrationPanel
                participants={ participants || [] }
                seats={ 0 }
                eventId={ eventId as string }
                setParticipants={ setParticipants }
                e2eTestMode={ true }
                event={ event }
                refreshList={ refreshListFunction }
              />)
          }

        </FormGroup>
      </YodaFormProvider>

    </>
  );
};

export default EventTesting;
