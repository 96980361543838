import { FC, useMemo } from 'react';
import ParticipantsItem from './ParticipantsItem';
import { ParticipantsTabs } from './ParticipantsList.enums';
import useParticipantsList from './ParticipantsList.hook';
import * as styles from './ParticipantsList.styles';
import { ParticipantsListProps } from './ParticipantsList.types';
import Box from 'yoda-ui/components/Box';
import List from 'yoda-ui/components/List';
import Tabs from 'yoda-ui/components/Tabs';
import Tab from 'yoda-ui/components/Tabs/Tab';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const ParticipantsList: FC<ParticipantsListProps> = (props) => {
  const { participants,
    cancelRegistrationHandler,
    acceptRegistrationHandler,
    syncParticipantHandler,
    checkInRegistrationHandler,
    event } = props;
  const { INVITED, REGISTERED, WAITING } = ParticipantsTabs;

  const { itemsInvited, itemsRegistered, itemsPending, selectedTab, tabsConfig } = useParticipantsList(participants, props?.e2eTestMode);

  const tabs = useMemo(
    () => tabsConfig.map(({ label, onClick, value }) => (
      <Tab
        key={ value }
        label={ label }
        onClick={ onClick }
        value={ value }
        sx={ styles.Tab }
      />
    )),
    [tabsConfig],
  );

  return (
    <>
      <Box marginBottom={ YodaSpacing.large }>
        <Tabs value={ selectedTab } textColor={ 'inherit' }>
          { tabs }
        </Tabs>
      </Box>
      {
        !!itemsRegistered.length && (
          <Box hidden={ selectedTab !== REGISTERED }>
            <List disablePadding>
              {
                itemsRegistered.map((item) => <ParticipantsItem { ...item }
                  key={ item.email }
                  variant={ REGISTERED }
                  cancelRegistrationHandler={ cancelRegistrationHandler }
                  acceptRegistrationHandler={ acceptRegistrationHandler }
                  syncParticipantHandler={ syncParticipantHandler }
                  checkInRegistrationHandler={ checkInRegistrationHandler }
                  event={ event }
                  e2eTestMode={ props?.e2eTestMode }
                />)
              }
            </List>
          </Box>
        )
      }
      {
        !!itemsInvited.length && !props?.e2eTestMode && (
          <Box hidden={ selectedTab !== INVITED }>
            <List disablePadding>
              {
                itemsInvited.map((item) => <ParticipantsItem { ...item } key={ item.email }
                  variant={ INVITED }
                  event={ event }
                />)
              }
            </List>
          </Box>
        )
      }

      {
        !!itemsPending.length && !props?.e2eTestMode && (
          <Box hidden={ selectedTab !== WAITING }>
            <List disablePadding>
              {
                itemsPending.map((item) => <ParticipantsItem
                  { ...item }
                  key={ item.email }
                  variant={ WAITING }
                  acceptRegistrationHandler={ acceptRegistrationHandler } event={ event }/>)
              }
            </List>
          </Box>
        )
      }
    </>
  );
};

export default ParticipantsList;
