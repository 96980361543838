import { useTranslation } from 'react-i18next';
import { validateImagesDimensions } from 'basics/utils/files/files.validations';
import { HandleFiles } from 'components/QuickForm/QuickForm.types';
import useEditSpeakerProfileConfig from 'modules/events/components/EditSpeakerProfile/EditSpeakerProfile.config';
import { PossibleSpeaker } from 'modules/events/groups/SpeakersGroup/SpeakersGroup.types';

const useEditSpeakerProfile = (selectedSpeaker?: PossibleSpeaker) => {
  const { t } = useTranslation();
  const { addSpeakerProfileConfig } = useEditSpeakerProfileConfig(selectedSpeaker);

  const handleDropImage: HandleFiles = async (files) => {
    const { minWidth, minHeight } = addSpeakerProfileConfig.image.dimensions;
    const dimensionsAreValid = await validateImagesDimensions({
      files,
      dimensions: addSpeakerProfileConfig.image.dimensions,
    });

    return dimensionsAreValid ? { ok: true } : {
      ok: false,
      errorMessage: `${t('cardImage_dimensions_validation_error_message', { imageWidth: minWidth, imageHeight: minHeight })}`,
    };
  };
  return {
    handleDropImage,
    addSpeakerProfileConfig,
  };
};

export default useEditSpeakerProfile;
