import { useTranslation } from 'react-i18next';
import { date, mixed, number, string } from 'yup';
import { TimeZone } from 'basics/enums/timeZoneList.enums';
import { testEndDayNotBeforeStartDay, testStartDateIsSet, testStartDateNotInPast } from 'basics/utils/dates/dates.utils';
import { Format } from 'generated/event.types';
import { SessionInformationField } from 'modules/events/components/SessionInformation/SessionInformation.types';

const useSessionInformationSchema = () => {
  const { t } = useTranslation();
  const requiredLabel = t('form_errors_required');

  return {
    [SessionInformationField.dateRange]: mixed()
      .test({
        name: 'startDate is set',
        message: requiredLabel,
        test: testStartDateIsSet,
      })
      .test({
        name: 'startDate is in future',
        message: t('timeSlotBooking_startDate_errorMessage_future'),
        test: testStartDateNotInPast,
      })
      .test({
        name: 'start date before end date',
        message: t('timeSlotBooking_endDate_errorMessage_earlierThanStart'),
        test: testEndDayNotBeforeStartDay,
      }),
    [SessionInformationField.timeZone]: mixed<TimeZone>()
      .oneOf(Object.values(TimeZone), requiredLabel)
      .required(requiredLabel),
    [SessionInformationField.startTime]: string()
      .required(requiredLabel),
    [SessionInformationField.endTime]: string()
      .required(requiredLabel),
    [SessionInformationField.locale]: string()
      .required(),
    [SessionInformationField.location]: string()
      .required(),
    [SessionInformationField.seats]: number()
      .required(),
    [SessionInformationField.eventFormat]: mixed<Format>()
      .oneOf(Object.values(Format), requiredLabel)
      .required(),
    [SessionInformationField.registrationEndDate]: date().nullable(),
  };
};

export default useSessionInformationSchema;
