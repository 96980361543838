import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ParticipantsItemCollapseFields } from './ParticipantsItemCollapse.enums';
import useParticipantsItemCollapse from './ParticipantsItemCollapse.hook';
import * as styles from './ParticipantsItemCollapse.styles';
import { ParticipantsItemCollapseProps } from './ParticipantsItemCollapse.types';
import FieldCard from 'components/FieldCard';
import FieldCardTitle from 'components/FieldCardTitle';
import { Source } from 'generated/event.types';
import { VeevaSyncStatus } from 'modules/events/basics/enums/events.enums';
import CopyCard from 'modules/events/components/EventsList/LinkDialog/CopyCard';
import VeevaStatusBar from 'modules/events/components/VeevaStatusBar/VeevaStatusBar';
import Collapse from 'yoda-ui/components/Collapse';
import Grid from 'yoda-ui/components/Grid';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const ParticipantsItemCollapse: FC<ParticipantsItemCollapseProps> = (props) => {
  const { t } = useTranslation();
  const { opened, eventPresence, marketTag, eventSource, ...participantData } = props;

  const { fieldsConfig } = useParticipantsItemCollapse(participantData, eventPresence, marketTag, eventSource);

  const fields = useMemo(
    () => fieldsConfig.map((field) => (
      <Grid item xs={ 12 } md={ 4 } key={ field.key }>
        <FieldCardTitle title={ field.label }/>
        {
          field.key === ParticipantsItemCollapseFields.zoomCallLink
            ? field.value && <CopyCard link={ field.value }/>
            : <FieldCard empty={ !field.value }>
              { field.value || t('person_field_notAvailable_label') }
            </FieldCard>
        }
      </Grid>
    )),
    [fieldsConfig, t],
  );

  return (
    <Collapse in={ opened } sx={ styles.Collapse }>

      {
        eventSource === Source.veeva ? (<VeevaStatusBar
          lastVeevaSync={ participantData?.lastVeevaSync }
          lastVeevaSyncErrors={ participantData?.lastVeevaSyncError ? [participantData?.lastVeevaSyncError] : undefined }
          veevaSyncStatus={ participantData?.veevaSyncStatus || VeevaSyncStatus.PENDING }
        />) : null
      }

      <Grid
        container
        paddingTop={ YodaSpacing.medium }
        paddingRight={ YodaSpacing.small }
        paddingLeft={ YodaSpacing.small }
        paddingBottom={ YodaSpacing.small }
        rowGap={ YodaSpacing.none }
        spacing={ YodaSpacing.medium }
      >
        { fields }
      </Grid>
    </Collapse>
  );
};

export default ParticipantsItemCollapse;
