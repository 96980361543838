import { SelectOption } from 'basics/options/options.types';
import { Format, Presence } from 'generated/event.types';

const eventFormatOptions = (eventPresence?: Presence): SelectOption[] => {
  if (!eventPresence) return [];

  switch (eventPresence) {
    case Presence.hybrid:
    case Presence.physical:
      return [
        { id: Format.advisoryBoard, label: 'Advisory board' },
        { id: Format.biogenMeeting, label: 'Biogen meeting' },
        { id: Format.congress, label: 'Congress' },
        { id: Format.peerToPeer, label: 'Peer to peer' },
        { id: Format.symposium, label: 'Symposium' },
      ];
    case Presence.online:
    default:
      return [
        { id: Format.meeting, label: 'Meeting' },
        { id: Format.webinar, label: 'Webinar' },
      ];
  }
};

export default eventFormatOptions;
