import { ApolloProvider } from '@apollo/client';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { HelmetProvider } from 'react-helmet-async';
import { hotjar } from 'react-hotjar';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Routes from './routes';
import ErrorBoundary from 'components/ErrorBoundary';
import Layout from 'components/Layout';
import MetaTags from 'components/MetaTags';
import { getConfigVar, isProduction } from 'config/config';
import { ConfirmationDialogProvider } from 'contexts/confirmation';
import { EventDateProvider } from 'contexts/eventDate';
import { EventStatusProvider } from 'contexts/eventStatus';
import { EventTypeProvider } from 'contexts/eventType';
import { CurrentUserProvider } from 'contexts/user'; // Moved this import down
import 'react-toastify/dist/ReactToastify.css';
import apolloClient from 'services/graphql/client';
import { launchDarklyConfig } from 'services/launchDarkly/launchDarkly.config';
import { initializeSentry } from 'services/sentry/sentry';
import Box from 'yoda-ui/components/Box';
import { yodaTheme, YodaColors } from 'yoda-ui/yodaTheme';

if (isProduction) {
  const HOTJAR_ID = Number(getConfigVar('REACT_APP_HOTJAR_ID'));
  const HOTJAR_SV = Number(getConfigVar('REACT_APP_HOTJAR_SV'));
  hotjar.initialize(HOTJAR_ID, HOTJAR_SV);
}

// Setup Sentry
initializeSentry();

const App = () => (
  <HelmetProvider>
    <BrowserRouter>
      <ApolloProvider client={ apolloClient }>
        <CurrentUserProvider>
          <EventTypeProvider>
            <EventStatusProvider>
              <EventDateProvider>
                <ThemeProvider theme={ yodaTheme }>
                  <StyledEngineProvider injectFirst>
                    <ErrorBoundary>
                      <ConfirmationDialogProvider>
                        <Box
                          display="flex"
                          flexDirection="column"
                          color={ YodaColors.background }
                          height="100vh"
                        >
                          <ToastContainer theme='colored' />

                          <Layout>
                            <Routes />
                          </Layout>

                        </Box>
                      </ConfirmationDialogProvider>
                    </ErrorBoundary>
                  </StyledEngineProvider>
                </ThemeProvider>
              </EventDateProvider>
            </EventStatusProvider>
          </EventTypeProvider>
        </CurrentUserProvider>
      </ApolloProvider>
      <MetaTags />
    </BrowserRouter>
  </HelmetProvider>
);

export default withLDProvider(launchDarklyConfig)(App);
