import { proxy } from 'valtio';
import { EventsSaveRequestStatus } from 'modules/events/basics/enums/events.enums';
import { EventStateType } from 'modules/events/basics/types/events.types';

const eventState = proxy<EventStateType>({
  createEvent: {},
  saveRequestStatus: EventsSaveRequestStatus.last,
  programs: { data: [], loading: true },
});

export default eventState;
