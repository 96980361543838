import { FC, useMemo } from 'react';
import { isValidLink } from 'basics/utils/strings/strings.utils';
import FieldCardTitle from 'components/FieldCardTitle';
import CopyCard from 'modules/events/components/EventsList/LinkDialog/CopyCard';
import { ActionButton } from 'yoda-ui/components/BiogenLincDS/Buttons';
import Box from 'yoda-ui/components/Box';
import { Icons } from 'yoda-ui/components/Icons/Icon';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

export type LinkConfig = {
  title: string;
  link: string;
  button?: {
    icon?: Icons;
    label: string;
  };
};

type LinkItemProps = {
  linkConfig: LinkConfig;
};

const LinkItem: FC<LinkItemProps> = ({ linkConfig: { title, link, button } }) => {
  const href = useMemo(() => (isValidLink(link) ? link : ''), [link]);
  return (
    <Box marginTop={ YodaSpacing.medium } >
      <FieldCardTitle title={ title } />
      <CopyCard link={ link } />
      {
        button && (
          <Box marginTop={ YodaSpacing.xxSmall }>
            <ActionButton label={ button.label } icon={ button.icon } href={ href } disabled={ !link } target="_blank"/>
          </Box>
        )
      }
    </Box>
  );
};

export default LinkItem;
