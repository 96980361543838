import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import { Presence } from 'generated/event.types';
import useTimeSlotBookingConfig from 'modules/events/components/TimeSlotBooking/TimeSlotBooking.config';
import useTimeSlotBooking from 'modules/events/components/TimeSlotBooking/TimeSlotBooking.hook';
import { TimeSlotBookingProps } from 'modules/events/components/TimeSlotBooking/TimeSlotBooking.types';
import Alert from 'yoda-ui/components/Alert';
import { ActionButton, ButtonVariant, TextButton } from 'yoda-ui/components/BiogenLincDS/Buttons';
import Box from 'yoda-ui/components/Box';
import CheckBoxYoda from 'yoda-ui/components/Form/CheckBoxYoda';
import DatePickerYoda from 'yoda-ui/components/Form/DatePickerYoda';
import DateRangePicker from 'yoda-ui/components/Form/DateRangePicker';
import SelectYoda from 'yoda-ui/components/Form/SelectYoda';
import TextYoda from 'yoda-ui/components/Form/TextYoda';
import Grid from 'yoda-ui/components/Grid';
import { Icons } from 'yoda-ui/components/Icons/Icon';
import { YodaFormProvider } from 'yoda-ui/yodaForm';
import { YodaFontSize, YodaFontWeight, YodaGridCellSize, YodaSpacing } from 'yoda-ui/yodaTheme';

const TimeSlotBooking: FC<TimeSlotBookingProps> = ({
  onSave,
  values,
  isInvalid,
  isValid,
  isLoading,
  disabled,
}) => {
  const {
    t,
    isFormValid,
    handleSave,
    startTimeOptions,
    endTimeOptions,
    providerFields,
    isFormDirty,
    alreadyBooked,
    enableBookingButton,
  } = useTimeSlotBooking({ onSave });
  const {
    timeSlotBookingConfig,
    showHostProviderLink,
    handleChangeProvider,
    eventPresence,
  } = useTimeSlotBookingConfig({ startTimeOptions, endTimeOptions, values });

  const flags = useFlags();

  return (
    <>
      <Grid container spacing={ YodaSpacing.large } marginBottom={ '20px' }>
        <Grid item xs={ YodaGridCellSize.small }>
          <Box>
            <SelectYoda { ...timeSlotBookingConfig.hostProvider } onChange={ handleChangeProvider } disabled={ disabled } />
          </Box>
          <Box paddingTop={ YodaSpacing.large } sx={ { display: showHostProviderLink ? 'block' : 'none' } }>
            <TextYoda { ...timeSlotBookingConfig.hostProviderLink } disabled={ disabled } />
          </Box>
          <Box paddingTop={ YodaSpacing.small } sx={
            {
              display: !showHostProviderLink && flags.eventsBiogenlinc4735EnableZoomStreamingCheckbox ? 'block' : 'none',
            }
          }>
            <CheckBoxYoda { ...timeSlotBookingConfig.enableZoomStreaming } disabled={ showHostProviderLink } />
          </Box>
        </Grid>

        <Grid item xs={ YodaGridCellSize.small }>
          <Box >
            <SelectYoda { ...timeSlotBookingConfig.eventFormat } disabled={ disabled } />
          </Box>
        </Grid>

        <Grid item xs={ YodaGridCellSize.small }>
          <Box >
            <SelectYoda { ...timeSlotBookingConfig.locale } disabled={ timeSlotBookingConfig.locale.disabled || disabled } />
          </Box>
        </Grid>

      </Grid>
      <YodaFormProvider { ...providerFields }>
        <Grid container spacing={ YodaSpacing.large }>
          <Grid item xs={ YodaGridCellSize.full }>
            <Box fontSize={ YodaFontSize.large } fontWeight={ YodaFontWeight.medium }>{ t('timeSlotBooking_title') }</Box>
          </Grid>
          <Grid item xs={ YodaGridCellSize.full }>
            <DateRangePicker { ...timeSlotBookingConfig.dateRange } disabled={ disabled || timeSlotBookingConfig.dateRange.disabled } />
          </Grid>
          <Grid item xs={ YodaGridCellSize.small }>
            <SelectYoda { ...timeSlotBookingConfig.timeZone } disabled={ true } />
          </Grid>
          <Grid item xs={ YodaGridCellSize.small }>
            <SelectYoda { ...timeSlotBookingConfig.startTime } disabled={ disabled || timeSlotBookingConfig.startTime.disabled } />
          </Grid>
          <Grid item xs={ YodaGridCellSize.small }>
            <SelectYoda { ...timeSlotBookingConfig.endTime } disabled={ disabled || timeSlotBookingConfig.endTime.disabled } />
          </Grid>
        </Grid>

        <Grid item xs={ YodaGridCellSize.full }>
          <Alert severity={ 'success' } active={ isValid && !isFormDirty } >
            { t('timeSlotBooking_booking_succeed') }
          </Alert>
          <Alert severity={ 'error' } active={ isInvalid && !isFormDirty } >
            { t('timeSlotBooking_booking_error') }
          </Alert>
          <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={ YodaSpacing.small }>
            {
              alreadyBooked
              && <Box display="flex" flexDirection="row" paddingRight={ YodaSpacing.medium }>
                <ActionButton
                  icon={ Icons.refresh }
                  label={ t('timeSlotBooking_bookAgain_label') }
                  onClick={ enableBookingButton }
                  variant={ ButtonVariant.primary }
                />
              </Box>
            }
            <TextButton
              label={ alreadyBooked ? t('timeSlotBooking_alreadyBookedButton_label') : t('timeSlotBooking_bookButton_label') }
              type="submit"
              disabled={ disabled || !isFormValid || showHostProviderLink || alreadyBooked }
              onClick={ handleSave }
              loading={ isLoading }
            />
          </Box>
        </Grid>
      </YodaFormProvider>
      {
        eventPresence === Presence.hybrid && (
          <Grid container spacing={ YodaSpacing.large } marginBottom={ '20px' }>
            <Grid item xs={ YodaGridCellSize.small }>
              <Box>
                <DatePickerYoda
                  { ...timeSlotBookingConfig.registrationEndDate }
                />
              </Box>
            </Grid>
          </Grid>)
      }
    </>
  );
};

export default TimeSlotBooking;
