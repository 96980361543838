import {
  createHybridMeetingRoute,
  createOnlineMeetingRoute,
  createPhysicalMeetingRoute,
} from 'basics/constants/routes.constants';
import { Presence } from 'generated/event.types';
import { Event } from 'modules/events/basics/types/events.types';

const mappedRoutes: { [key: string]: string } = {
  [Presence.online]: createOnlineMeetingRoute,
  [Presence.physical]: createPhysicalMeetingRoute,
  [Presence.hybrid]: createHybridMeetingRoute,
};

export const getRouteByFormatAndPresence = (eventData: Partial<Event>) => {
  const { presence, _id: id } = eventData;
  const routesByPresence = presence ? mappedRoutes[presence] : null;
  if (eventData && presence && routesByPresence) {
    return `${routesByPresence}/${id}`;
  }
  return null;
};
