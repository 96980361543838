import { useEffect, useMemo, useState } from 'react';
import './EasterEgg.styles.css';

const KonamiCodeAnimation = () => {
  const konamiCode = useMemo(
    () => ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a'],
    [],
  );
  const [sequenceCounter, setSequenceCounter] = useState(0);
  const [showSecret, setShowSecret] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const currentKey = event.key;

      if (currentKey === konamiCode[sequenceCounter]) {
        setSequenceCounter((prevCounter) => prevCounter + 1);
      } else {
        setSequenceCounter(0);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [sequenceCounter, konamiCode]);

  useEffect(() => {
    if (sequenceCounter === konamiCode.length) {
      setShowSecret(true);
    }
  }, [sequenceCounter, konamiCode]);

  return (
    <>
      {
        showSecret && (
          <>
            <h3>The Yoda team had a lot of fun developing this website</h3>
            <div id="y-global">
              <div id="y-head">
                <div id="left-eye"></div>
                <div id="right-eye"></div>
                <div id="mouth"></div>
                <div id="left-ear"></div>
                <div id="right-ear"></div>
              </div>
              <div id="cup">
                <div id="steam">~</div>
              </div>
            </div>
          </>
        )
      }
    </>
  );
};

export default KonamiCodeAnimation;
