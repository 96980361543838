import { FC } from 'react';

import useEditSpeakerProfileModal from './EditSpeakerProfileModal.hook';
import DialogForm from 'components/DialogForm';

import { EditSpeakerProfileModalProps } from 'modules/events/components/EditSpeakerProfile/EditSpeakerProfile.types';
import SpeakerProfile from 'modules/events/components/EditSpeakerProfile/EditSpeakerProfileModal/SpeakerProfile';
import Box from 'yoda-ui/components/Box';
import CheckBoxYoda from 'yoda-ui/components/Form/CheckBoxYoda';
import { YodaFormProvider } from 'yoda-ui/yodaForm';

const EditSpeakerProfileModal: FC<EditSpeakerProfileModalProps> = (
  {
    open,
    setOpen,
    onSpeakerSave,
    selectedSpeaker,
    editAttributeVisibility,
  },
) => {
  const {
    addSpeakerProfileConfig,
    cancelButtonConfig,
    providerFields,
    submitButtonConfig,
    t,
  } = useEditSpeakerProfileModal(setOpen, onSpeakerSave, selectedSpeaker);

  return (
    <YodaFormProvider { ...providerFields }>
      <DialogForm
        dialogTitle={
          selectedSpeaker
            ? `${t('events_edit_speakerprofile_form_title')}: ${selectedSpeaker.label}`
            : t('events_add_speakerprofile_form_title')
        }
        open={ open }
        setOpen={ setOpen }
        cancelButtonConfig={ cancelButtonConfig }
        submitButtonConfig={ submitButtonConfig }
      >
        <Box>
          <SpeakerProfile speakerProfileConfig={ addSpeakerProfileConfig } selectedSpeaker={ selectedSpeaker } />
        </Box>
        {
          editAttributeVisibility
            ? (
              <>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Box>
                    <CheckBoxYoda { ...addSpeakerProfileConfig.showTitle } />
                  </Box>
                  <Box>
                    <CheckBoxYoda { ...addSpeakerProfileConfig.showEmail } />
                  </Box>
                  <Box>
                    <CheckBoxYoda { ...addSpeakerProfileConfig.showImage } />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-evenly"
                >
                  <Box>
                    <CheckBoxYoda { ...addSpeakerProfileConfig.showSpecialty } />
                  </Box>
                  <Box>
                    <CheckBoxYoda { ...addSpeakerProfileConfig.showAffiliation } />
                  </Box>
                </Box>
              </>
            ) : null
        }
      </DialogForm>
    </YodaFormProvider>
  );
};

export default EditSpeakerProfileModal;
