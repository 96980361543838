import { Chip } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ParticipantsItemProps } from './ParticipantItem.types';
import useParticipantsItem from './ParticipantsItem.hook';
import * as styles from './ParticipantsItem.styles';
import ParticipantsItemChangeStatusModal from './ParticipantsItemChangeStatusModal/ParticipantsItemChangeStatusModal';
import ParticipantsItemCollapse from './ParticipantsItemCollapse';
import MoreActionsMenu from 'components/MoreActionsMenu';
import { Presence, Source } from 'generated/event.types';
import { ParticipantsTabs } from 'modules/events/components/ParticipantsList/ParticipantsList.enums';
import Box from 'yoda-ui/components/Box';
import CollapseIcon from 'yoda-ui/components/Icons/CollapseIcon';
import Icon, { Icons } from 'yoda-ui/components/Icons/Icon';
import IconButton from 'yoda-ui/components/Icons/IconButton';
import ListItem from 'yoda-ui/components/List/ListItem';
import Tag from 'yoda-ui/components/Tags/Tag';
import { YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

const ParticipantsItem: FC<ParticipantsItemProps> = (props) => {
  const { INVITED, REGISTERED, WAITING } = ParticipantsTabs;
  const { t } = useTranslation();
  const {
    email = '',
    firstName = '',
    lastName = '',
    joiningChannel = t('participantsItem_participation_empty'),
    registrationStatusHistory,
    variant = REGISTERED,
    cancelRegistrationHandler,
    acceptRegistrationHandler,
    syncParticipantHandler,
    checkInRegistrationHandler,
    event,
    veevaSyncStatus,
    e2eTestMode = false,
    ...restParticipant
  } = props;

  const {
    opened,
    tagConfig,
    veevaSyncTagConfig,
    toggleOpened,
    menuItemConfigs,
    acceptModalOpen,
    setAcceptModalOpen,
    cancelModalopen,
    setCancelModalOpen,
    isMoreActionsMenuVisible,
    checkInModalOpen,
    setCheckInModalOpen,
  } = useParticipantsItem(
    registrationStatusHistory,
    email,
    firstName,
    lastName,
    restParticipant.mdmId,
    e2eTestMode,
    event,
    veevaSyncStatus,
    syncParticipantHandler,
    joiningChannel,
  );

  const icons = useMemo(
    () => ({
      [INVITED]: (
        <IconButton>
          <Icon name={ Icons.moreVertical }/>
        </IconButton>
      ),
      [REGISTERED]: (<CollapseIcon expand={ false } onClick={ toggleOpened }/>),
      [WAITING]: (<CollapseIcon expand={ false } onClick={ toggleOpened }/>),
    }),
    [INVITED, REGISTERED, WAITING, toggleOpened],
  );

  return (
    <ListItem sx={ styles.ListItem } disablePadding>
      <Box
        alignItems="center"
        display="flex"
        flexGrow={ 1 }
        paddingBottom="6px"
        paddingTop="6px"
        paddingRight="12px"
        paddingLeft="24px"
        width="calc(100% - 36px)"
      >

        <Box fontWeight={ YodaFontWeight.medium } minWidth="150px">
          { `${lastName} ${firstName}` }
        </Box>
        <Box
          fontWeight={ YodaFontWeight.regular }
          flexGrow={ event?.presence === Presence.hybrid ? '0' : '1' }
          marginRight={ YodaSpacing.medium }
          marginLeft={ YodaSpacing.medium }
        >
          { email }
        </Box>
        {
          event?.presence === Presence.hybrid
          && <Box
            fontWeight={ YodaFontWeight.regular }
            marginLeft={ YodaSpacing.medium }
            flexGrow="1"
          >
            { `${t('participantsItem_participation_type_label')}: ${joiningChannel || t('participantsItem_participation_empty')}` }
          </Box>
        }

        {
          event?.source === Source.veeva ? (
            <Box marginRight={ YodaSpacing.medium }>
              <Chip
                label={ t('veeva') }
                icon={ <Icon name={ veevaSyncTagConfig.icon }/> }
              />
            </Box>) : null
        }

        {
          tagConfig && (
            <Tag
              bgcolor={ tagConfig.bgColor }
              color={ tagConfig.color }
              fontWeight={ YodaFontWeight.medium }
              paddingX={ YodaSpacing.xxSmall }
              paddingY={ YodaSpacing.xxxxSmall }
            >
              { tagConfig.label }
            </Tag>
          )
        }
        <Box display="flex" flexDirection="row" marginLeft={ YodaSpacing.medium }>
          { icons[variant] }
          { isMoreActionsMenuVisible && <MoreActionsMenu menuItemConfigs={ menuItemConfigs }/> }
        </Box>
      </Box>
      {
        (variant === REGISTERED || variant === WAITING) && (
          <ParticipantsItemCollapse
            email={ email }
            firstName={ firstName }
            lastName={ lastName }
            eventSource={ event?.source }
            opened={ opened }
            eventPresence={ event?.presence }
            registrationStatusHistory={ registrationStatusHistory }
            veevaSyncStatus={ veevaSyncStatus }
            marketTag={ event?.market }
            { ...restParticipant }
          />
        )
      }
      <ParticipantsItemChangeStatusModal
        title={ t('participantsItemCancelModal_title') }
        open={ cancelModalopen }
        setOpen={ setCancelModalOpen }
        participant={
          { email,
            firstName,
            lastName,
            registrationStatusHistory,
            ...restParticipant }
        }
        confirmActionHandler={ cancelRegistrationHandler }
      />

      <ParticipantsItemChangeStatusModal
        title={ t('participantsItemAcceptModal_title') }
        open={ acceptModalOpen }
        setOpen={ setAcceptModalOpen }
        participant={
          { email,
            firstName,
            lastName,
            registrationStatusHistory,
            ...restParticipant }
        }
        confirmActionHandler={ acceptRegistrationHandler }
      />

      <ParticipantsItemChangeStatusModal
        title={ t('participantsItemCheckInModal_title') }
        open={ checkInModalOpen }
        setOpen={ setCheckInModalOpen }
        participant={
          { email,
            firstName,
            lastName,
            registrationStatusHistory,
            ...restParticipant }
        }
        confirmActionHandler={ checkInRegistrationHandler }
      />

    </ListItem>
  );
};

export default ParticipantsItem;
