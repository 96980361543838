import { Dispatch, SetStateAction, useState } from 'react';
import { RegisterParticipantInput } from 'generated/event.types';
import { Participant } from 'modules/events/basics/types/events.types';

type UseParticipantsItemChangeStatusModalModalProps = {
  setOpen: Dispatch<SetStateAction<boolean>>;
  participant: Participant;
  confirmActionHandler: ((registerParticipantInput: Partial<RegisterParticipantInput>) => Promise<boolean>) | undefined;
};

const useParticipantsItemChangeStatusModal = ({
  setOpen,
  participant,
  confirmActionHandler,
}: UseParticipantsItemChangeStatusModalModalProps) => {
  const [submitLoading, setSubmitLoading] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  const { id, phone, zoomCallLink, registrationStatusHistory, cxEmailSent, registrationEmailSent, invitationEmailSent,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
    reminder1h, reminder12h, reminder24h, reminder48h, reminder1week, reminderSMS, accountId, attendedZoom, attendedZoomDuration,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
    lastVeevaSync, lastVeevaSyncError, veevaSyncStatus, ...registerParticipantInput } = participant;

  const handleAction = async () => {
    if (confirmActionHandler) {
      setSubmitLoading(true);
      const actionSuccess = await confirmActionHandler(registerParticipantInput);
      if (actionSuccess) {
        setSubmitLoading(false);
        setOpen(false);
      }
    }
  };

  return {
    handleAction,
    submitLoading,
  };
};

export default useParticipantsItemChangeStatusModal;
