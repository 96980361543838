import { SyntheticEvent, useContext } from 'react';
import { useEventStatusSelectorConfig } from './EventStatusSelector.config';
import { SelectOption } from 'basics/options/options.types';
import { EventStatusContext, EventStatusFilterType } from 'contexts/eventStatus';

const useEventStatusSelector = () => {
  const eventStatusContext = useContext(EventStatusContext);
  const { eventStatusSelectorConfig } = useEventStatusSelectorConfig(eventStatusContext.eventStatus);

  const handleEventStatusChange = (event: SyntheticEvent<Element, Event>, value: SelectOption) => {
    if (value?.id) {
      eventStatusContext.setEventStatus(value.id as EventStatusFilterType);
    }
  };

  return {
    handleEventStatusChange,
    eventStatusSelectorConfig,
    eventType: eventStatusContext.eventStatus,
  };
};

export default useEventStatusSelector;
