import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { TimeZone } from 'basics/enums/timeZoneList.enums';
import useMarketOptions from 'basics/options/markets.options';
import { SelectOption } from 'basics/options/options.types';
import { timezoneOptions } from 'basics/options/timezone.options';
import { Format, HostProvider, Presence } from 'generated/event.types';
import { EventBiogenBrand } from 'modules/events/basics/enums/biogen.enum';
import useBiogenBrandsOptions from 'modules/events/basics/options/biogenBrands.options';
import eventFormatOptions from 'modules/events/basics/options/eventFormat.options';
import eventHostProviderOptions from 'modules/events/basics/options/eventHostProvider.options';
import eventPresenceOptions from 'modules/events/basics/options/eventPresence.options';
import useCreateMeetingSchema from 'modules/events/components/CreateEvent/CreateEvent.schema';
import { CreateEventModalConfig } from 'modules/events/components/CreateEvent/CreateEvent.types';

const useCreateEventConfig = (startTimeOptions: SelectOption[], endTimeOptions: SelectOption[], eventPresence: Presence) => {
  const { t } = useTranslation();
  const createMeetingSchema = useCreateMeetingSchema();
  const flags = useFlags();

  const createEventConfig: CreateEventModalConfig = {
    title: {
      label: t('event_creation_modal_title_field_label'),
      name: 'title',
      placeholder: '',
      required: true,
      defaultValue: '',
      validation: createMeetingSchema.title,
    },
    market: {
      label: t('event_creation_modal_market_field_label'),
      name: 'market',
      placeholder: t('event_creation_modal_market_field_placeholder'),
      options: useMarketOptions(),
      required: true,
      defaultValue: '',
      validation: createMeetingSchema.market,
      disableClearable: true,
    },
    presenceType: {
      label: t('event_creation_modal_presence_type_field_label'),
      name: 'presence',
      placeholder: t('event_creation_modal_presence_type_field_placeholder'),
      options: eventPresenceOptions(flags.eventsBiogenlinc4280EnablePhysicalEvents, flags.eventsBiogenlinc4745EnableHybridEvents),
      required: true,
      defaultValue: Presence.online,
      validation: createMeetingSchema.presenceType,
      disableClearable: true,
    },
    eventFormat: {
      label: t('event_creation_modal_event_format_field_label'),
      name: 'format',
      placeholder: t('event_creation_modal_event_format_field_placeholder'),
      options: eventFormatOptions(eventPresence || Presence.online),
      required: true,
      defaultValue: eventPresence === Presence.online || eventPresence === undefined ? Format.meeting : Format.advisoryBoard,
      validation: createMeetingSchema.eventFormat,
      disableClearable: true,
    },
    product: {
      label: t('events_product_input_label'),
      name: 'product',
      placeholder: t('events_product_input_placeholder'),
      options: useBiogenBrandsOptions(EventBiogenBrand),
      required: true,
      defaultValue: EventBiogenBrand.ADUCANUMAB,
      validation: createMeetingSchema.product,
      disableClearable: true,
    },
    dateRange: {
      startText: t('event_creation_startDate_venue_field_label'),
      endText: t('event_creation_endDate_venue_field_label'),
      name: 'dateRange',
      disablePast: true,
      validation: createMeetingSchema.dateRange,
      startRequired: true,
      endRequired: true,
    },
    startTime: {
      label: t('event_creation_startTime_venue_field_label'),
      name: 'startTime',
      placeholder: t('actions_select_placeholder'),
      options: startTimeOptions,
      required: true,
      disableClearable: true,
      validation: createMeetingSchema.startTime,
    },
    endTime: {
      label: t('event_creation_endTime_venue_field_label'),
      name: 'endTime',
      placeholder: t('actions_select_placeholder'),
      options: endTimeOptions,
      required: true,
      disableClearable: true,
      validation: createMeetingSchema.endTime,
    },
    timeZone: {
      label: t('event_creation_timezone_field_label'),
      name: 'timeZone',
      options: timezoneOptions,
      required: true,
      defaultValue: TimeZone.UTC,
      validation: createMeetingSchema.timeZone,
    },
    venue: {
      label: t('event_creation_modal_venue_field_label'),
      name: 'venue',
      placeholder: '',
      required: true,
      disabled: eventPresence === Presence.online || eventPresence === undefined,
      defaultValue: eventPresence === Presence.online || eventPresence === undefined ? 'online' : '',
      validation: createMeetingSchema.venue,
    },
    hostProvider: {
      label: t('events_host_provider_input_label'),
      name: 'hostProvider',
      options: eventHostProviderOptions,
      required: true,
      disabled: eventPresence === Presence.physical,
      defaultValue: HostProvider.zoom,
      validation: createMeetingSchema.hostProvider,
      disableClearable: true,
    },
  };

  return { createEventConfig };
};

export default useCreateEventConfig;
