import Box from 'yoda-ui/components/Box';
import TextYoda from 'yoda-ui/components/Form/TextYoda';
import { YodaFieldValidation } from 'yoda-ui/yodaForm/yodaForm.types';
import { YodaFontSize, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

type PrcBlockProps = {
  headerConfig: {
    title: string;
  };
  fieldConfig: {
    name: string;
    label: string;
    placeholder: string;
    validation: YodaFieldValidation;
  };
};

const PrcBlock = ({ fieldConfig, headerConfig: { title } }: PrcBlockProps) => {
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        marginBottom={ YodaSpacing.medium }
      >
        <Box fontSize={ YodaFontSize.medium } fontWeight={ YodaFontWeight.medium }>
          { title }
        </Box>
      </Box>
      <TextYoda { ...fieldConfig } />
    </>
  );
};

export default PrcBlock;
