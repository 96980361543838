import { YodaFieldValue } from 'yoda-ui/yodaForm/yodaForm.types';

export const transformSpeakerProfile = (data: YodaFieldValue) => {
  const imageToDB = data.image?.map((item: { id: string }) => item.id);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  const { showImage, showEmail, showTitle, showAffiliation, showSpecialty, ...filteredData } = data;

  return filteredData.image ? { ...filteredData, image: imageToDB } : filteredData;
};

export const transformPersonalInfosVisibility = (data: YodaFieldValue, eventId?: string) => {
  if (eventId) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
    const { showImage, showEmail, showTitle, showAffiliation, showSpecialty, ...filteredData } = data;

    return {
      eventId,
      showTitle: data.showTitle,
      showImage: data.showImage,
      showEmail: data.showEmail,
      showAffiliation: data.showAffiliation,
      showSpecialty: data.showSpecialty,
    };
  }

  return undefined;
};

