import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { UserData } from 'generated/event.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_LAZY_USER_DATA = gql`
  query getUserData {
    getUserData {
      market
    }
  }
`;

  type UseLazyGetEventsUserData = () => {
    queryGetEventsUserData: Function;
    loading: boolean;
    data?: UserData | null;
    error?: ApolloError;
    called: boolean;
  };

const useLazyGetEventsUserData: UseLazyGetEventsUserData = () => {
  const [queryGetEventsUserData, { data, loading, error, called }] = useLazyQuery(GET_LAZY_USER_DATA, {
    fetchPolicy: 'cache-and-network',
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getEventsUserDataLazy.ts - useLazyGetEventsUserData',
    });
  }

  return {
    queryGetEventsUserData,
    data: data?.getUserData,
    loading,
    error,
    called,
  };
};

export default useLazyGetEventsUserData;

