import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import {
  LONG_DAY_NUM_MONTH_SHORT_YEAR,
  getShortFormatDateTimeFromIsoString,
  HOURS_MINUTES_SECONDS,
} from 'basics/utils/dates/dates.utils';
import { EventsSaveRequestStatus } from 'modules/events/basics/enums/events.enums';
import eventState from 'modules/events/states/events.states';
import { Icons } from 'yoda-ui/components/Icons/Icon/Icon';
import { AlertSeverity } from 'yoda-ui/yodaTheme';

type StatusMap = {
  severity: AlertSeverity;
  label: string;
  icon: Icons;
  lastSaved?: { date: string; time: string };
};

const useStatusBanner = () => {
  const { t } = useTranslation();
  const { createEvent, saveRequestStatus } = useSnapshot(eventState);
  const { updatedAt } = createEvent;

  const statusMap: Record<EventsSaveRequestStatus, StatusMap> = {
    [EventsSaveRequestStatus.loading]: {
      severity: AlertSeverity.info,
      label: t('status_banner_save_saving'),
      icon: Icons.loop,
    },
    [EventsSaveRequestStatus.success]: {
      severity: AlertSeverity.success,
      label: t('status_banner_save_success'),
      icon: Icons.checkCircle,
    },
    [EventsSaveRequestStatus.last]: {
      severity: AlertSeverity.info,
      label: `${t('status_banner_last_saved')}\u00A0`,
      icon: Icons.schedule,
      lastSaved: updatedAt ? {
        date: getShortFormatDateTimeFromIsoString(updatedAt, LONG_DAY_NUM_MONTH_SHORT_YEAR),
        time: getShortFormatDateTimeFromIsoString(updatedAt, HOURS_MINUTES_SECONDS),
      } : undefined,
    },
    [EventsSaveRequestStatus.error]: {
      severity: AlertSeverity.error,
      label: t('status_banner_save_fail'),
      icon: Icons.errorOutline,
    },
  };

  return {
    customStatus: saveRequestStatus ? statusMap[saveRequestStatus] : statusMap[EventsSaveRequestStatus.last],
  };
};

export default useStatusBanner;
