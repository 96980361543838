import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UploadInviteesProps } from './UploadInvitees.types';
import QuickForm from 'components/QuickForm';
import { FormShape, ListShape } from 'components/QuickForm/QuickForm.enums';
import { Participant } from 'generated/event.types';
import { transformParticipantsToQuickForm } from 'modules/events/basics/utils/participants.utils';
import Typography from 'yoda-ui/components/Typography';
import { YodaFontSize, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

const UploadInvitees: FC<UploadInviteesProps> = (props) => {
  const {
    defaultValue,
    formConfig,
    handleFilesExternal,
    handleRemoveItemAsync,
    listConfig,
    name,
    validation,
    disabled,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <Typography
        fontSize={ YodaFontSize.medium }
        fontWeight={ YodaFontWeight.light }
        marginY={ YodaSpacing.large }
        paragraph
      >
        { !disabled ? t('invitees_description') : null }
      </Typography>
      <QuickForm
        name={ name }
        form={ { shape: FormShape.formAsset, formConfig: { handleFiles: handleFilesExternal, ...formConfig } } }
        handleRemoveItemAsync={ handleRemoveItemAsync }
        list={ { shape: ListShape.listInvitee, listConfig } }
        transformToCard={ (item: Participant) => item }
        defaultValue={ transformParticipantsToQuickForm(defaultValue) }
        validation={ validation }
        disabled={ disabled }
      />
    </>
  );
};

export default UploadInvitees;
