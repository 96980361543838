import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Box } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useEventDatePicker from './EventDatePicker.hook';
import DateRangePicker from 'yoda-ui/components/Form/DateRangePicker/DateRangePicker';
import { YodaFormProvider, useYodaCreateForm } from 'yoda-ui/yodaForm';

export const EventDatePicker: FC = () => {
  const { t } = useTranslation();
  const { config, setDate, date } = useEventDatePicker();
  const yodaFormMethods = useYodaCreateForm({ setIsValidOptional: true });

  return (
    <LocalizationProvider dateAdapter={ AdapterDateFns }>
      <YodaFormProvider { ...yodaFormMethods.providerFields }>
        <Box marginRight={ '20px' } width={ '400px' } display={ 'flex' }>
          <DateRangePicker
            name="date-picker-filters"
            onChange={ setDate }
            validation={ config.dateRange.validation }
            startText={ t('timeSlotBooking_startDate_label') }
            endText={ t('timeSlotBooking_endDate_label') }
            startRequired={ false }
            endRequired={ false }
            defaultValue={ date }
          />
        </Box>
      </YodaFormProvider>
    </LocalizationProvider>
  );
};
