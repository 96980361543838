import { FC } from 'react';
import AgendaGroup from 'modules/events/groups/AgendaGroup';
import NavigationConfigGroup from 'modules/events/groups/NavigationConfigGroup/NavigationConfigGroup';
import OptionalInformationsGroup from 'modules/events/groups/OptionalInformationsGroup';
import PrimaryInformationsGroup from 'modules/events/groups/PrimaryInformationsGroup';
import SessionInformationGroup from 'modules/events/groups/SessionInformationGroup';
import SpeakersGroup from 'modules/events/groups/SpeakersGroup';
import UserManagementGroup from 'modules/events/groups/UserManagementGroup';
import useDetailsStep from 'modules/events/routes/CreatePhysicalMeeting/Steps/DetailsStep/DetailsStep.hook';
import Box from 'yoda-ui/components/Box';

const DetailsStep: FC = () => {
  useDetailsStep();
  return (
    <Box>
      <SessionInformationGroup />
      <NavigationConfigGroup/>
      <UserManagementGroup />
      <PrimaryInformationsGroup />
      <SpeakersGroup />
      <OptionalInformationsGroup />
      <AgendaGroup />
    </Box>
  );
};

export default DetailsStep;
