import { useTranslation } from 'react-i18next';
import { EventStatusFilterType } from 'contexts/eventStatus';
import eventStatusOptions from 'modules/events/basics/options/eventStatus.options';

export const useEventStatusSelectorConfig = (eventStatus: EventStatusFilterType) => {
  const { t } = useTranslation();

  const eventStatusSelectorConfig = {
    label: t('events_status_label'),
    name: 'eventStatusSelector',
    options: eventStatusOptions(),
    placeholder: t('events_choose_event_status'),
    defaultValue: eventStatus,
  };

  return {
    eventStatusSelectorConfig,
  };
};
