import { debounce } from 'lodash';
import { ChangeEvent, useEffect, useMemo, useState, useCallback } from 'react';
import { PAGINATION_PAGE_SIZE } from 'modules/events/basics/constants/list.constants';
import useManageSpeakersConfig from 'modules/events/components/ManageSpeakers/ManageSpeakers.config';
import { useDeleteSpeakerMutation } from 'modules/events/graphql/mutations/deleteSpeaker';
import useLazyGetPossibleSpeakers from 'modules/events/graphql/queries/getPossibleSpeakers';
import { PossibleSpeaker } from 'modules/events/groups/SpeakersGroup/SpeakersGroup.types';
import { useYodaCreateForm } from 'yoda-ui/yodaForm';

const useManageSpeakers = (isOpen: boolean) => {
  const yodaFormMethods = useYodaCreateForm({ setIsValidOptional: true });

  const { lazyQuery, data, count, loading, called } = useLazyGetPossibleSpeakers();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGINATION_PAGE_SIZE);
  const [speakersSearchQuery, setSpeakersSearchQuery] = useState<string | null>(null);
  const [openCreateSpeakerModal, setOpenCreateSpeakerModal] = useState(false);
  const [selectedSpeaker, setSelectedSpeaker] = useState<PossibleSpeaker | null>(null);
  const [confirmDeleteSpeakerModalOpen, setConfirmDeleteSpeakerModalOpen] = useState(false);
  const [deleteSpeaker] = useDeleteSpeakerMutation();

  const queryOptions = useMemo(
    () => ({
      limit: pageSize,
      offset: page * pageSize,
    }),
    [page, pageSize],
  );

  const onPageChange = async (newPage: number) => {
    setPage(newPage);
    await lazyQuery(speakersSearchQuery, { limit: pageSize, offset: newPage * pageSize });
  };

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  useEffect(() => {
    const fetchData = async () => {
      await lazyQuery(null, queryOptions);
    };
    if (isOpen && !loading && !called) {
      fetchData();
    }
  }, [called, data, isOpen, lazyQuery, loading, queryOptions]);

  const updateSpeakerList = useCallback(async (query: string | null) => {
    await lazyQuery(query, queryOptions);
  }, [lazyQuery, queryOptions]);

  useEffect(() => {
    if (speakersSearchQuery !== null) {
      updateSpeakerList(speakersSearchQuery);
    }
  }, [speakersSearchQuery, updateSpeakerList]);

  const onSpearkerSearchFieldChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPage(0);
    setSpeakersSearchQuery(event.target.value);
  };
  const debouncedOnSpearkerSearchFieldChange = debounce(onSpearkerSearchFieldChange, 700);
  const onEditSpeaker = useCallback((speaker: PossibleSpeaker) => {
    setSelectedSpeaker(speaker);
    setOpenCreateSpeakerModal(true);
  }, [setSelectedSpeaker, setOpenCreateSpeakerModal]);

  const onCreateSpeaker = useCallback(() => {
    setSelectedSpeaker(null);
    setOpenCreateSpeakerModal(true);
  }, [setSelectedSpeaker, setOpenCreateSpeakerModal]);

  const onDeleteSpeaker = useCallback((speaker: PossibleSpeaker) => {
    setSelectedSpeaker(speaker);
    setConfirmDeleteSpeakerModalOpen(true);
  }, []);

  const onCloseConfirmDeleteSpeakerModal = useCallback(() => {
    setSelectedSpeaker(null);
    setConfirmDeleteSpeakerModalOpen(false);
  }, []);

  const { formConfig, listConfig } = useManageSpeakersConfig(
    debouncedOnSpearkerSearchFieldChange,
    onEditSpeaker,
    onDeleteSpeaker,
  );

  const onDeleteSpeakerConfirm = useCallback(async () => {
    if (selectedSpeaker) {
      await deleteSpeaker({
        firstName: selectedSpeaker?.firstName,
        lastName: selectedSpeaker?.lastName,
        id: selectedSpeaker?.id,
      });
      updateSpeakerList(speakersSearchQuery);
    }
    onCloseConfirmDeleteSpeakerModal();
  }, [deleteSpeaker, onCloseConfirmDeleteSpeakerModal, selectedSpeaker, speakersSearchQuery, updateSpeakerList]);

  return {
    data,
    loading,
    yodaFormMethods,
    formConfig,
    listConfig,
    onPageChange,
    onPageSizeChange,
    count,
    openCreateSpeakerModal,
    setOpenCreateSpeakerModal,
    updateSpeakerList,
    speakersSearchQuery,
    selectedSpeaker,
    onCreateSpeaker,
    confirmDeleteSpeakerModalOpen,
    onCloseConfirmDeleteSpeakerModal,
    onDeleteSpeakerConfirm,
  };
};

export default useManageSpeakers;
