import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { isDraft } from 'modules/events/basics/utils/events.utils';
import eventState from 'modules/events/states/events.states';

const usePublicationStep = () => {
  const { status } = useSnapshot(eventState.createEvent);
  const isPublished = !isDraft(status);

  const { t } = useTranslation();
  return {
    t,
    isPublished,
  };
};

export default usePublicationStep;
