import { FC } from 'react';
import ActionButton from 'components/ActionButton';
import { ActionButtonProps } from 'components/ActionButton/ActionButton';
import Box from 'yoda-ui/components/Box';
import DialogActions from 'yoda-ui/components/Dialogs/Dialog/DialogActions';
import { YodaJustifyContent } from 'yoda-ui/yodaTheme';

type DialogFormActionsProps = {
  cancelButtonConfig: ActionButtonProps;
  submitButtonConfig: ActionButtonProps;
  justifyActions?: YodaJustifyContent;
};

const DialogFormActions: FC<DialogFormActionsProps> = ({
  cancelButtonConfig,
  submitButtonConfig,
  justifyActions = YodaJustifyContent.flexEnd,
}) => (
  <Box display="flex" justifyContent={ justifyActions }>
    <DialogActions>
      <ActionButton { ...cancelButtonConfig } />
      <ActionButton { ...submitButtonConfig }/>
    </DialogActions>
  </Box>
);

export default DialogFormActions;
