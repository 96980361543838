import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { Market } from 'generated/event.types';

const useMarketOptions = () => {
  const { t } = useTranslation();

  const marketsToFilter = [Market.uk, Market.ch];

  const marketKeys = Object.keys(Market).filter((market) => !marketsToFilter.includes(market as Market));
  const marketValues = Object.values(Market).filter((market) => !marketsToFilter.includes(market as Market));
  const flags = useFlags();
  return marketValues.map((value, index) => {
    const marketLabel = value === Market.com && flags.eventsBiogenlinc5399SwissMarketIntoTheBo
      ? t('market_label_com_ch') : t(`market_label_${value}`);

    return { id: marketKeys[index], label: marketLabel };
  });
};

export default useMarketOptions;
