import { useSnapshot } from 'valtio';
import { isDraft } from 'modules/events/basics/utils/events.utils';
import eventState from 'modules/events/states/events.states';

const useSessionInformationGroup = () => {
  const { status } = useSnapshot(eventState.createEvent);

  const isPublished = !isDraft(status);

  return {
    isPublished,
  };
};

export default useSessionInformationGroup;
