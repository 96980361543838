import { ColorOption } from './EmailNotesGroup.types';

export const colorOptions: ColorOption[] = [
  {
    colorHex: '#122035',
    colorLabel: 'BiogenLinc BG',
  },
  {
    colorHex: '#1F49E0',
    colorLabel: 'BiogenLinc 1',
  },
  {
    colorHex: '#F5F2EC',
    colorLabel: 'BiogenLinc 2',
  },
  {
    colorHex: '#223867',
    colorLabel: 'Spinraza BG',
  },
  {
    colorHex: '#028CA6',
    colorLabel: 'Spinraza 1',
  },
  {
    colorHex: '#EF3D33',
    colorLabel: 'Spinraza 2',
  },
  {
    colorHex: '#007EB5',
    colorLabel: 'Tysabri BG',
  },
  {
    colorHex: '#009CDE',
    colorLabel: 'Tysabri 1',
  },
  {
    colorHex: '#FF671F',
    colorLabel: 'Tysabri 2',
  },
  {
    colorHex: '#80276C',
    colorLabel: 'Tecfidera BG',
  },
  {
    colorHex: '#78BE20',
    colorLabel: 'Tecfidera 2',
  },
  {
    colorHex: '#003865',
    colorLabel: 'Vumerity BG',
  },
  {
    colorHex: '#007FA3',
    colorLabel: 'Vumerity 1',
  },
  {
    colorHex: '#CE0058',
    colorLabel: 'Vumerity 2',
  },
  {
    colorHex: '#522398',
    colorLabel: 'Avonex BG',
  },
  {
    colorHex: '#C60C30',
    colorLabel: 'Avonex 2',
  },
  {
    colorHex: '#0076D6',
    colorLabel: 'Plegridy BG',
  },
  {
    colorHex: '#F28420',
    colorLabel: 'Plegridy 1',
  },
  {
    colorHex: '#007257',
    colorLabel: 'Plegridy 2',
  },
  {
    colorHex: '#387C2B',
    colorLabel: 'Fampyra BG',
  },
  {
    colorHex: '#E36F1E',
    colorLabel: 'Fampyra 2',
  },
  {
    colorHex: '#4A4C9A',
    colorLabel: 'Benepali BG',
  },
  {
    colorHex: '#00C4B4',
    colorLabel: 'Benepali 2',
  },
  {
    colorHex: '#00873A',
    colorLabel: 'Imraldi BG',
  },
  {
    colorHex: '#009D4E',
    colorLabel: 'Imraldi 1',
  },
  {
    colorHex: '#0032A0',
    colorLabel: 'Imraldi 2',
  },
  {
    colorHex: '#D53F1F',
    colorLabel: 'Flixabi BG',
  },
  {
    colorHex: '#E65400',
    colorLabel: 'Flixabi 1',
  },
  {
    colorHex: '#00205C',
    colorLabel: 'Flixabi 2',
  },
  {
    colorHex: '#2573BA',
    colorLabel: 'Byooviz BG',
  },
  {
    colorHex: '#5AB4E4',
    colorLabel: 'Byooviz 1',
  },
  {
    colorHex: '#FAA807',
    colorLabel: 'Byooviz 2',
  },
];
