import { Dispatch, FC, SetStateAction } from 'react';
import ActionButton from 'components/ActionButton';
import FormGroup from 'components/FormGroup';
import { CommonGroups } from 'modules/events/basics/enums/commonForms.enums';
import { Event, Participant } from 'modules/events/basics/types/events.types';
import ParticipantsList from 'modules/events/components/ParticipantsList';
import useParticipantsGroup from 'modules/events/groups/ParticipantsGroup/ParticipantsGroupt.hook';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';
import { Icons } from 'yoda-ui/components/Icons/Icon';

type ParticipantsGroupProps = {
  participants: Participant[];
  eventId: string;
  setParticipants: Dispatch<SetStateAction<Participant[] | null>>;
  seats: number;
  e2eTestMode?: boolean;
  refreshList?: () => void;
  event?: Event;
  priviligedList?: boolean;
};

const ParticipantsGroup: FC<ParticipantsGroupProps> = (props) => {
  const { participants, seats, e2eTestMode, refreshList, event, priviligedList } = props;
  const {
    acceptRegistrationHandler,
    cancelRegistrationHandler,
    syncParticipantHandler,
    checkInRegistrationHandler,
    groupTitle,
    refreshButtonLabel,
  } = useParticipantsGroup(
    props.participants,
    props.eventId,
    props.setParticipants,
    seats,
    event?.presence,
    priviligedList,
  );
  if (!participants) {
    return null;
  }

  return (
    <FormGroup
      title={ groupTitle }
      groupName={ CommonGroups.invitees }
      collapsible={ false }
    >

      <ParticipantsList
        participants={ props.participants }
        cancelRegistrationHandler={ cancelRegistrationHandler }
        acceptRegistrationHandler={ acceptRegistrationHandler }
        syncParticipantHandler={ syncParticipantHandler }
        checkInRegistrationHandler={ checkInRegistrationHandler }
        event={ event }
        e2eTestMode={ e2eTestMode } />

      {
        e2eTestMode && refreshList
      && (<ActionButton buttonProps={
        {
          disabled: false,
          onClick: refreshList,
          buttonType: ButtonType.secondary,
          startIconName: Icons.refresh,
        }
      } label={ refreshButtonLabel } />)
      }
    </FormGroup>
  );
};

export default ParticipantsGroup;
