import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import LinkDialog from '../LinkDialog';
import MoreActionsMenu from 'components/MoreActionsMenu';
import { Presence } from 'generated/event.types';
import { Event } from 'modules/events/basics/types/events.types';
import ConfirmationModal from 'modules/events/components/EventsList/ConfirmationModal/ConfirmationModal';
import useEventsListItem from 'modules/events/components/EventsList/EventsListItem/EventsListItem.hook';
import EventSummary from 'modules/events/components/EventsList/EventSummary';
import Box from 'yoda-ui/components/Box';
import Paper from 'yoda-ui/components/Paper';
import Tag from 'yoda-ui/components/Tags/Tag';
import { YodaBorderRadius, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

type EventsListItemProps = {
  event: Event;
};

const EventsListItem: FC<EventsListItemProps> = ({ event }) => {
  const {
    eventStatusBadgeMap,
    eventEditRoute,
    menuItemConfigs,
    linkDialogIsOpen,
    unpublishConfirmationIsOpen,
    setUnpublishConfirmationIsOpen,
    closeLinkDialog,
    currentStatus,
    setCurrentStatus,
    providerData,
  } = useEventsListItem({ event });
  const flags = useFlags();

  const enableEventEdition = event.presence !== Presence.physical || flags.eventsBiogenlinc4280EnablePhysicalEvents;

  return enableEventEdition ? (
    <Box marginBottom={ YodaSpacing.medium } >
      <Paper margin={ YodaSpacing.none } borderRadius={ YodaBorderRadius.medium } >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          padding={ YodaSpacing.medium }
        >
          <Link to={ eventEditRoute }>
            <EventSummary event={ event } />
          </Link>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Tag
              fitContent
              paddingY={ YodaSpacing.xxSmall }
              paddingX={ YodaSpacing.small }
              bgcolor={ eventStatusBadgeMap[currentStatus].bgColor }
              color={ eventStatusBadgeMap[currentStatus].color }
              fontWeight={ YodaFontWeight.medium }
            >
              { eventStatusBadgeMap[currentStatus].label }
            </Tag>
            <Box marginLeft={ YodaSpacing.small }>
              <MoreActionsMenu menuItemConfigs={ menuItemConfigs } />
            </Box>
          </Box>
        </Box>
      </Paper>
      <LinkDialog open={ linkDialogIsOpen } closeDialog={ closeLinkDialog } event={ event } providerData={ providerData }/>
      <ConfirmationModal open={ unpublishConfirmationIsOpen }
        setOpen={ setUnpublishConfirmationIsOpen }
        event={ event }
        setCurrentStatus={ setCurrentStatus }/>
    </Box>
  ) : null;
};

export default EventsListItem;
