import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import useEventsFilters from './EventsFilters.hook';
import { Order } from 'basics/enums/general.enums';
import { useCurrentUserState } from 'contexts/user';
import { Market } from 'generated/event.types';
import { initLaunchDarkly } from 'helpers/launchDarkly';
import { sortEvents } from 'modules/events/basics/utils/events.utils';
import useGetFullPrivateEventsQuery from 'modules/events/graphql/queries/getFullPrivateEvents';
import eventState from 'modules/events/states/events.states';

const useEvents = (isPast: boolean) => {
  const { user } = useCurrentUserState();
  const market = user?.market ? user.market as Market : null;
  const [showManageSpeakersModal, setShowManageSpeakersModal] = useState(false);

  const onManageSpeakersModalOpen = () => {
    setShowManageSpeakersModal(true);
  };

  const onManageSpeakersModalClose = () => {
    setShowManageSpeakersModal(false);
  };

  const ldClient = useLDClient();
  if (user && user?.market) {
    initLaunchDarkly(ldClient, user, user?.market);
  }
  const flags = useFlags();
  const showRsvpEvents = flags.eventsBiogenlinc4973ShowRsvpEvents;

  const { data, loading, error } = useGetFullPrivateEventsQuery(market, isPast, showRsvpEvents);

  useEffect(() => {
    eventState.createEvent = {};
  }, []);

  const { filteredData } = useEventsFilters(data);

  return {
    data: filteredData ? sortEvents(filteredData, 'data.session.endDate', Order.asc) : [],
    loading,
    error,
    onManageSpeakersModalOpen,
    onManageSpeakersModalClose,
    showManageSpeakersModal,
  };
};

export default useEvents;
