import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EditOnlineSideNavAction } from './EditOnlineSideNav.enums';
import { eventsRoute } from 'basics/constants/routes.constants';

const useEditOnlineSideNavigation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [selectedPanel, setSelectedPanel] = useState(Object.values(EditOnlineSideNavAction)[0]);

  const sideNavBackConfig = useMemo(() => {
    return {
      onClick: () => navigate(eventsRoute),
      text: t('editEvent_sidePanelMenuItem_dashboard'),
    };
  }, [navigate, t]);

  const sideNavConfigs = useMemo(() => {
    const { inviteesRegistrations } = EditOnlineSideNavAction;
    return [
      {
        key: inviteesRegistrations,
        onClick: () => setSelectedPanel(inviteesRegistrations),
        selected: selectedPanel === inviteesRegistrations,
        text: t('editEvent_sidePanelMenuItem_inviteesRegistrations'),
      },
    ];
  }, [selectedPanel, setSelectedPanel, t]);

  return {
    enumActions: EditOnlineSideNavAction,
    selectedPanel,
    sideNavBackConfig,
    sideNavConfigs,
  };
};

export default useEditOnlineSideNavigation;
