import { useLDClient } from 'launchdarkly-react-client-sdk';
import { LAUNCH_DARKLY_COMMON_USER_KEY } from 'basics/constants/common.constants';
import { CurrentUser } from 'basics/types/user.types';
import { Market } from 'generated/event.types';

export const initLaunchDarkly = (
  ldClient: ReturnType<typeof useLDClient>,
  userData: CurrentUser,
  market: Market,
) => {
  if (ldClient) {
    ldClient.waitUntilReady().then(() => ldClient.identify({
      key: LAUNCH_DARKLY_COMMON_USER_KEY,
      lastName: userData.lastName,
      firstName: userData.name,
      email: userData.email,
      custom: {
        market,
      },
    }));
  }
};
