import { useTranslation } from 'react-i18next';
import { string, array, object, number, mixed } from 'yup';
import * as yup from 'yup';
import { typesKeys } from 'basics/options/emailType.options';

export const maxNoteLength = 4000;
export const maxColorLength = 7;
export const maxProgramLogoUrlLength = 200;
const useEmailNotesSchema = () => {
  const { t } = useTranslation();

  const maxProgramLogoUrlMessage = `${t('event_modal_program_logo_url_max_error_message', { count: maxProgramLogoUrlLength })}`;
  return {
    emailHeaderNote: string()
      .max(maxNoteLength)
      .nullable(true),
    emailFooterNote: string()
      .max(maxNoteLength)
      .nullable(true),
    emailFooterDisclaimerText: string()
      .max(maxNoteLength)
      .nullable(true),
    emailType: array()
      .of(mixed().oneOf(typesKeys))
      .min(1)
      .required(),
    emailContentfulUrl: string()
      .url('please provide a valid URL')
      .nullable(),
    biogenLogo: string()
      .oneOf(['0', '1', '2'])
      .nullable(false),
    buttonColor: string()
      .max(maxColorLength)
      .nullable(true),
    programLogo: array()
      .nullable(true)
      .of(object().shape({
        id: string().required(),
        size: number().required(),
        name: string().required(),
        url: string().required().max(maxProgramLogoUrlLength, maxProgramLogoUrlMessage),
      })),
    emailPrcCode: string()
      .nullable(true),
    mayaId: string()
      .nullable(true),
    UTM: string()
      .nullable(true),
    contactTeamMail: string().lowercase().matches(
      // eslint-disable-next-line max-len
      /^$|(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, `${t('events_email_team_contact_invalid_format')}`,
    ),
    reminderNotificationEmails: yup.array().nullable(true).of(
      yup.mixed().test(
        'email test',
        t('event_reminder_notification_email_invalid_format'),
        (value) => {
          if (typeof value === 'string') {
            return yup.string().email().isValidSync(value);
          }
          return yup.object({
            id: yup.string().required(),
            label: yup.string().email().required(),
          }).isValidSync(value);
        },
      ),
    ),
    reminderNotificationPhoneNumbers: yup.array().nullable(true).of(
      yup.mixed().test(
        'phone number test',
        t('event_reminder_notification_phone_number_invalid_format'),
        (value) => {
          if (typeof value === 'string') {
            return yup.string().matches(/^\+(?:[0-9]●?){6,14}[0-9]$/, 'phone number invalid format').isValidSync(value);
          }
          return yup.object({
            id: yup.string().required(),
            label: yup.string().matches(/^\+(?:[0-9]●?){6,14}[0-9]$/, 'phone number invalid format').required(),
          }).isValidSync(value);
        },
      ),
    ),
  };
};

export default useEmailNotesSchema;
