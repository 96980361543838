export enum CreateHybridMeetingStepsIndex {
  detailsStep = 0,
  communicationStep = 1,
  publicationStep = 2,
}

// TODO: rename this one to CreateHybridMeetingStepsIndex when epic BIOGENLINC-3524 is finished
export enum CreateHybridMeetingWithEmailConfigurationStepsIndex {
  detailsStep = 0,
  communicationStep = 1,
  emailConfigurationStep = 2,
  publicationStep = 3,
}
