import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { Provider } from 'generated/event.types';
import { providerFragment } from 'modules/events/graphql/fragments/commonData.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const updateZoomDataMutationCall = gql`
    mutation updateZoomData($eventId: String!) {
        updateZoomData(eventId: $eventId) {
          ${providerFragment}
        }
    }`;

type ResultType = {
  updateZoomData: Provider | null;
};

type UseUpdateZoomDataMutation = [
  (eventId: string) => Promise<Provider | null>,
  MutationResult<ResultType>,
];

export const useUpdateZoomDataMutation = (): UseUpdateZoomDataMutation => {
  const [updateZoomData, state] = useMutation<ResultType>(updateZoomDataMutationCall);

  const executeMutation = useCallback(async (eventId) => {
    try {
      const data = await updateZoomData({
        variables: { eventId },
      });

      return data.data?.updateZoomData || null;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error, eventId);
        Logger.logError({
          error,
          customContext,
          transactionName: 'updateZoomData.ts - useUpdateZoomDataMutation',
        });
      }
      return null;
    }
  }, [updateZoomData]);

  return [executeMutation, state];
};
