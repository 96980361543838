import { Dispatch, FC, SetStateAction } from 'react';
import useConfirmationModal from './ConfirmationModal.hook';
import DialogForm from 'components/DialogForm';
import { EventStatus } from 'generated/event.types';
import { Event } from 'modules/events/basics/types/events.types';
import { YodaFormProvider } from 'yoda-ui/yodaForm';
import { YodaJustifyContent } from 'yoda-ui/yodaTheme';

type ConfirmationModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  event: Event;
  setCurrentStatus: Dispatch<SetStateAction<EventStatus>>;
};

const ConfirmationModal: FC<ConfirmationModalProps> = ({ open, setOpen, event, setCurrentStatus }) => {
  const {
    cancelButtonConfig,
    providerFields,
    submitButtonConfig,
    t,
  } = useConfirmationModal(setOpen, event, setCurrentStatus);

  return (
    <YodaFormProvider { ...providerFields }>
      <DialogForm
        dialogTitle={ t('event_unpublish_confirmation_modal_title') }
        open={ open }
        setOpen={ setOpen }
        cancelButtonConfig={ cancelButtonConfig }
        submitButtonConfig={ submitButtonConfig }
        justifyActions={ YodaJustifyContent.center }
      >
      </DialogForm>
    </YodaFormProvider>
  );
};

export default ConfirmationModal;
