import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { succesToast, errorToast } from 'basics/utils/toast';
import { Format } from 'generated/event.types';
import { Event } from 'modules/events/basics/types/events.types';

import { HybridMeetingInput } from 'modules/events/basics/types/hybridMeeting.types';
import { eventFragment } from 'modules/events/graphql/fragments/event.fragments';
import { hybridEventDataFragment } from 'modules/events/graphql/fragments/hybridEventData.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const updateHybridMeetingMutationCall = gql`
mutation updateHybridMeetingMutation($hybridEventInput: HybridEventInput!, $hybridEventFormat: Format) {
  updateHybridEvent(hybridEventInput: $hybridEventInput, hybridEventFormat: $hybridEventFormat) {
    ${eventFragment}
    source
    data {
      ...on HybridEvent {
        ${hybridEventDataFragment}
      }
    }
  }
}
`;

type ResultType = {
  updateHybridEvent: Event;
};

type UseUpdateHybridMeetingMutation = [
  (hybridEventInput: HybridMeetingInput, hybridEventFormat?: Format) => Promise<Event | undefined | null>,
  MutationResult<ResultType>,
];

export const useUpdateHybridMeetingMutation = (): UseUpdateHybridMeetingMutation => {
  const { t } = useTranslation();
  const [updateHybridMeeting, state] = useMutation<ResultType>(updateHybridMeetingMutationCall);
  const executeMutation = useCallback(async (hybridEventInput, hybridEventFormat = null) => {
    try {
      const data = await updateHybridMeeting({
        variables: { hybridEventInput, hybridEventFormat },
      });
      succesToast(t('toast_draft_saved'));

      return data.data?.updateHybridEvent;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error, hybridEventInput);
        Logger.logError({
          error,
          customContext,
          transactionName: 'updateHybridMeeting.ts - useUpdateHybridMeetingMutation',
        });
      }
      errorToast(t('toast_draft_saved_error'));
      return null;
    }
  }, [updateHybridMeeting, t]);

  return [executeMutation, state];
};
