import { Alert } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import useCreateOnlineMeeting from './CreateOnlineMeeting.hook';
import { CreateOnlineMeetingStepsIndex, CreateOnlineMeetingWithEmailConfigurationStepsIndex } from './CreateOnlineMeeting.types';
import DetailsStep from './Steps/DetailsStep/DetailsStep';
import PublicationStep from './Steps/PublicationStep/PublicationStep';
import { eventsRoute } from 'basics/constants/routes.constants';
import ButtonsBar from 'components/ButtonsBar';
import {
  FormLayoutContainer,
  FormLayoutMidPanelHeader,
  FormLayoutRightPanel,
  FormLayoutMidPanel,
  FormLayoutMidPanelContent,
} from 'components/FormLayout';
import LargeContainer from 'components/Layout/LargeContainer';
import { EventStatus, OnlineEvent } from 'generated/event.types';
import SideFormDetails from 'modules/events/components/SideFormDetails';
import CommunicationStep from 'modules/events/routes/CreateOnlineMeeting/Steps/CommunicationStep';
import EmailConfigurationStep from 'modules/events/routes/CreateOnlineMeeting/Steps/EmailConfigurationStep/EmailConfigurationStep';
import Box from 'yoda-ui/components/Box';
import Icon from 'yoda-ui/components/Icons/Icon';
import { Icons } from 'yoda-ui/components/Icons/Icon/Icon';
import LinkButton from 'yoda-ui/components/Links/LinkButton/LinkButton';
import Loader from 'yoda-ui/components/Loader';
import Stepper from 'yoda-ui/components/Stepper/Stepper';
import { YodaFormProvider } from 'yoda-ui/yodaForm';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const CreateOnlineMeeting: FC = () => {
  const flags = useFlags();
  const {
    t,
    handleClickHome,
    handleChangeStep,
    showLoader,
    yodaFormMethods,
    stepIndexer,
    id,
    buttonsConfig,
    stepsList,
    event,
  } = useCreateOnlineMeeting();

  if (!id) {
    return <Navigate to={ eventsRoute } replace />;
  }

  if (showLoader) {
    return <Box display="flex" flexDirection="column" justifyContent="center" width="100vw"><Loader center /></Box>;
  }

  // TODO: keep this block when epic BIOGENLINC-3524 is finished
  const stepsWithEmailConfiguration = (
    <>
      <Box hidden={ stepIndexer.stepIndex !== CreateOnlineMeetingWithEmailConfigurationStepsIndex.detailsStep }>
        <DetailsStep />
      </Box>
      <Box hidden={ stepIndexer.stepIndex !== CreateOnlineMeetingWithEmailConfigurationStepsIndex.communicationStep }>
        <CommunicationStep />
      </Box>
      <Box hidden={ stepIndexer.stepIndex !== CreateOnlineMeetingWithEmailConfigurationStepsIndex.emailConfigurationStep }>
        <EmailConfigurationStep />
      </Box>
      <Box hidden={ stepIndexer.stepIndex !== CreateOnlineMeetingWithEmailConfigurationStepsIndex.publicationStep }>
        <PublicationStep />
      </Box>
    </>
  );

  // TODO: remove this block when epic BIOGENLINC-3524 is finished
  const stepsConfiguration = (
    <>
      <Box hidden={ stepIndexer.stepIndex !== CreateOnlineMeetingStepsIndex.detailsStep }>
        <DetailsStep />
      </Box>
      <Box hidden={ stepIndexer.stepIndex !== CreateOnlineMeetingStepsIndex.communicationStep }>
        <CommunicationStep />
      </Box>
      <Box hidden={ stepIndexer.stepIndex !== CreateOnlineMeetingStepsIndex.publicationStep }>
        <PublicationStep />
      </Box>
    </>
  );

  return (
    <LargeContainer
      paddingRight={ YodaSpacing.xLarge }
      paddingLeft={ YodaSpacing.xLarge }
    >
      <YodaFormProvider { ...yodaFormMethods.providerFields }>
        <FormLayoutContainer>
          <FormLayoutMidPanel >
            <FormLayoutMidPanelHeader>
              <Box marginBottom={ YodaSpacing.medium }>
                <LinkButton startIcon={ <Icon name={ Icons.chevronLeft } /> } onClick={ handleClickHome }>
                  { `${t('home_link')}` }
                </LinkButton>
              </Box>

              <Stepper
                handleChangeStep={ handleChangeStep }
                stepsList={ stepsList }
                stepIndex={ stepIndexer.stepIndex }
              />
            </FormLayoutMidPanelHeader>
            <FormLayoutMidPanelContent>
              {
                (event?.status === EventStatus.published && (event?.data as OnlineEvent)?.biogenLincConfig?.showSendInvitations
                  && !(event?.data as OnlineEvent).invitationSent) ? (
                    <Alert severity={ 'warning' }>
                      { t('event_publish_invitation_not_sent_yet_label') }
                    </Alert>
                  ) : null
              }

              { flags.eventsBiogenlinc4151SectionEmail ? stepsWithEmailConfiguration : stepsConfiguration }

              { buttonsConfig && <ButtonsBar { ...buttonsConfig } /> }
            </FormLayoutMidPanelContent>
          </FormLayoutMidPanel>
          <FormLayoutRightPanel >
            <SideFormDetails />
          </FormLayoutRightPanel>
        </FormLayoutContainer>
      </YodaFormProvider>
    </LargeContainer>
  );
};

export default CreateOnlineMeeting;
