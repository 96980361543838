import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { succesToast, errorToast } from 'basics/utils/toast';
import { Format } from 'generated/event.types';
import { Event } from 'modules/events/basics/types/events.types';
import { PhysicalMeetingInput } from 'modules/events/basics/types/physicalMeeting.types';
import { eventFragment } from 'modules/events/graphql/fragments/event.fragments';
import { physicalEventDataFragment } from 'modules/events/graphql/fragments/physicalEventData.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const updatePhysicalMeetingMutationCall = gql`
mutation updatePhysicalMeetingMutation($physicalEventInput: PhysicalEventInput!, $physicalEventFormat: Format) {
  updatePhysicalEvent(physicalEventInput: $physicalEventInput, physicalEventFormat: $physicalEventFormat) {
    ${eventFragment}
    source
    data {
      ...on PhysicalEvent {
        ${physicalEventDataFragment}
      }
    }
  }
}
`;

type ResultType = {
  updatePhysicalEvent: Event;
};

type UseUpdatePhysicalMeetingMutation = [
  (physicalEventInput: PhysicalMeetingInput, physicalEventFormat?: Format) => Promise<Event | undefined | null>,
  MutationResult<ResultType>,
];

export const useUpdatePhysicalMeetingMutation = (): UseUpdatePhysicalMeetingMutation => {
  const { t } = useTranslation();
  const [updatePhysicalMeeting, state] = useMutation<ResultType>(updatePhysicalMeetingMutationCall);
  const executeMutation = useCallback(async (physicalEventInput, physicalEventFormat = null) => {
    try {
      const data = await updatePhysicalMeeting({
        variables: { physicalEventInput, physicalEventFormat },
      });
      succesToast(t('toast_draft_saved'));

      return data.data?.updatePhysicalEvent;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error, physicalEventInput);
        Logger.logError({
          error,
          customContext,
          transactionName: 'updatePhysicalMeeting.ts - useUpdatePhysicalMeetingMutation',
        });
      }
      errorToast(t('toast_draft_saved_error'));
      return null;
    }
  }, [updatePhysicalMeeting, t]);

  return [executeMutation, state];
};
