import { YodaBorderRadius, YodaColors } from 'yoda-ui/yodaTheme';

export const List = {
  paddingTop: '12px',
  paddingRight: '24px',
  paddingBottom: '12px',
  paddingLeft: '24px',
  border: `1px solid ${YodaColors.gray2}`,
  borderRadius: YodaBorderRadius.medium,
};
