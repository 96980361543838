import { gql, useQuery } from '@apollo/client';
import { SurveyInfos } from 'generated/event.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_GLOBAL_SURVEY_DATA = gql`
  query getGlobalSurveyData {
    getGlobalSurveyData {
      surveyId,
      usecaseCategory,
      usecaseName
    }
  }
  `;

type ResultType = {
  getGlobalSurveyData: SurveyInfos;
};

const useGetGlobalSurveyData = () => {
  const { data, loading, error } = useQuery<ResultType>(GET_GLOBAL_SURVEY_DATA, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getGlobalSurveyData.ts - getGlobalSurveyData',
    });
  }
  return {
    data: data?.getGlobalSurveyData,
    loading,
    error,
  };
};

export default useGetGlobalSurveyData;
