import { useTranslation } from 'react-i18next';
import useMarketOptions from 'basics/options/markets.options';

export const useCountrySelectorConfig = (market: string | null) => {
  const { t } = useTranslation();

  const countrySelectorConfig = {
    label: t('events_my_market_label'),
    name: 'countrySelector',
    placeholder: t('events_choose_market_label'),
    options: useMarketOptions(),
    defaultValue: market,
  };

  return {
    countrySelectorConfig,
  };
};
