import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { succesToast, errorToast } from 'basics/utils/toast';
import { Event } from 'modules/events/basics/types/events.types';
import { OnlineMeetingInput } from 'modules/events/basics/types/onlineMeeting.types';
import { eventFragment } from 'modules/events/graphql/fragments/event.fragments';
import { onlineEventDataFragment } from 'modules/events/graphql/fragments/onlineEventData.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const publishOnlineMeetingMutationCall = gql`
  mutation publishOnlineMeetingMutation($onlineEventInput: OnlineEventInput!) {
    publishOnlineEvent(onlineEventInput: $onlineEventInput) {
      ${eventFragment}
      source
      data {
        ...on OnlineEvent {
          ${onlineEventDataFragment}
        }
      }
    }
  }
`;

type ResultType = {
  publishOnlineEvent: Event;
};

type UsePublishOnlineMeetingMutation = [
  (onlineEventInput: OnlineMeetingInput) => Promise<Event | undefined | null>,
  MutationResult<ResultType>,
];

export const usePublishOnlineMeetingMutation = (): UsePublishOnlineMeetingMutation => {
  const { t } = useTranslation();
  const [publishOnlineMeeting, state] = useMutation<ResultType>(publishOnlineMeetingMutationCall);
  const executeMutation = useCallback(async (onlineEventInput) => {
    try {
      const data = await publishOnlineMeeting({
        variables: { onlineEventInput },
      });
      succesToast(t('toast_publish_event_success'));
      return data.data?.publishOnlineEvent;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error, onlineEventInput);
        Logger.logError({
          error,
          customContext,
          transactionName: 'publishOnlineMeeting.ts - usePublishOnlineMeetingMutation',
        });
      }
      errorToast(t('toast_publish_event_fail'));
      return null;
    }
  }, [publishOnlineMeeting, t]);

  return [executeMutation, state];
};
