import { FC } from 'react';
import { Presence } from 'generated/event.types';
import useSessionInformationConfig from 'modules/events/components/SessionInformation/SessionInformation.config';
import useSessionInformation from 'modules/events/components/SessionInformation/SessionInformation.hook';
import { SessionInformationProps } from 'modules/events/components/SessionInformation/SessionInformation.types';
import Box from 'yoda-ui/components/Box';
import DatePickerYoda from 'yoda-ui/components/Form/DatePickerYoda';
import DateRangePicker from 'yoda-ui/components/Form/DateRangePicker';
import SelectYoda from 'yoda-ui/components/Form/SelectYoda';
import TextYoda from 'yoda-ui/components/Form/TextYoda/TextYoda';
import Grid from 'yoda-ui/components/Grid';
import { YodaFontSize, YodaFontWeight, YodaGridCellSize, YodaSpacing } from 'yoda-ui/yodaTheme';

const SessionInformation: FC<SessionInformationProps> = () => {
  const { t, startTimeOptions, endTimeOptions } = useSessionInformation();
  const {
    sessionConfig,
    eventPresence,
  } = useSessionInformationConfig({ startTimeOptions, endTimeOptions });

  return (
    <>
      {
        eventPresence === Presence.physical && (
          <Grid container spacing={ YodaSpacing.large } marginBottom={ '20px' }>
            <Grid item xs={ YodaGridCellSize.small }>
              <Box >
                <SelectYoda { ...sessionConfig.eventFormat } />
              </Box>
            </Grid>
            <Grid item xs={ YodaGridCellSize.small }>
              <Box >
                <SelectYoda { ...sessionConfig.locale } disabled={ sessionConfig.locale.disabled } />
              </Box>
            </Grid>
          </Grid>
        )
      }
      <Grid container spacing={ YodaSpacing.large } marginBottom={ '20px' }>
        <Grid item xs={ YodaGridCellSize.small }>
          <Box >
            <TextYoda { ...sessionConfig.location } />
          </Box>
        </Grid>
        <Grid item xs={ YodaGridCellSize.small }>
          <Box >
            <TextYoda { ...sessionConfig.seats } />
          </Box>
        </Grid>
      </Grid>

      {
        eventPresence === Presence.physical && (
          <>
            <Grid container spacing={ YodaSpacing.large }>
              <Grid item xs={ YodaGridCellSize.full }>
                <Box fontSize={ YodaFontSize.large } fontWeight={ YodaFontWeight.medium }>{ t('timeSlotBooking_title') }</Box>
              </Grid>
              <Grid item xs={ YodaGridCellSize.full }>
                <DateRangePicker { ...sessionConfig.dateRange } />
              </Grid>
              <Grid item xs={ YodaGridCellSize.small }>
                <SelectYoda { ...sessionConfig.timeZone } disabled={ true } />
              </Grid>
              <Grid item xs={ YodaGridCellSize.small }>
                <SelectYoda { ...sessionConfig.startTime } />
              </Grid>
              <Grid item xs={ YodaGridCellSize.small }>
                <SelectYoda { ...sessionConfig.endTime } />
              </Grid>
            </Grid>
            <Grid container spacing={ YodaSpacing.large } marginBottom={ '20px' } marginTop={ '20px' }>
              <Grid item xs={ YodaGridCellSize.small }>
                <Box>
                  <DatePickerYoda
                    { ...sessionConfig.registrationEndDate } />
                </Box>
              </Grid>
            </Grid>
          </>
        )
      }
    </>

  );
};

export default SessionInformation;
