export enum CommonSteps {
  communicationStep = 'communicationStep',
  detailsStep = 'detailsStep',
  emailConfigurationStep = 'emailConfigurationStep',
  publicationStep = 'publicationStep',
}

export enum CommonGroups {
  agenda = 'agenda',
  restrictedAccess = 'restrictedAccess',
  biogenLincConfig = 'biogenLincConfig',
  coorganizers = 'coorganizers',
  optionalInformations = 'optionalInformations',
  invitees = 'invitees',
  primaryInformations = 'primaryInformations',
  speakers = 'speakers',
  timeSlot = 'timeSlot',
  userGroup = 'userGroup',
  sessionInformation = 'sessionInformation',
  registerParticipant = 'registerParticipant',
  emailNotes = 'emailNotes',
  testing = 'testing',
  navigationConfig = 'navigationConfig',
  teamContactCheckbox = 'teamContactCheckbox',
  teamContactEmail = 'teamContactEmail',
  teamContactDescription = 'teamContactDescription',
}
