export enum PresenceFormat {
  hybrid_congress = 'hybrid_congress',
  online_congress = 'online_congress',
  online_meeting = 'online_meeting',
  physical_congress = 'physical_congress',
}

export enum EventPromotionalityEnum {
  gated = 'gated',
  public = 'public',
}

export enum EventsSaveRequestStatus {
  error = 'Error',
  last = 'Last',
  loading = 'Loading',
  success = 'Success',
}

export enum ParticipantStatus {
  accepted = 'accepted',
  invited = 'invited',
  requested = 'requested',
  rejected = 'rejected',
  waitingList = 'waitingList',
  checkedIn = 'checkedIn',
}

export enum DeclinedParticipantStatus {
  declined = 'declined',
}

export type ExtendedParticipantStatus = ParticipantStatus | DeclinedParticipantStatus;

export enum Presence {
  hybrid = 'hybrid',
  online = 'online',
  physical = 'physical',
}

export enum JoiningChannel {
  online = 'online',
  physical = 'physical',
}

export enum EmailType {
  invitation = 'Invitation',
  confirmation = 'Confirmation',
  reminder_1 = 'Reminder 1',
  reminder_2 = 'Reminder 2',
  reminder_3 = 'Reminder 3',
  cx = 'CX',
}

export enum ErrorMessage {
  ACCOUNT_EMAIL_MISMATCH = 'ACCOUNT_EMAIL_MISMATCH',
  ZOOM_TOO_MANY_REQUESTS = 'ZOOM_TOO_MANY_REQUESTS',
}

export enum VeevaSyncStatus{
  PENDING = 'Pending',
  SYNC = 'Sync',
  ERROR = 'Error',
  DELETED = 'Deleted',
}

export enum PresenceOptions {
  all = 'all',
  hybrid = 'hybrid',
  online = 'online',
  physical = 'physical',
}
