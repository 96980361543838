import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { transformPersonalInfosVisibility, transformSpeakerProfile } from './EditSpeakerProfileModal.transformers';
import useEditSpeakerProfileConfig from 'modules/events/components/EditSpeakerProfile/EditSpeakerProfile.config';
import { useCreateOrUpdateSpeakerMutation } from 'modules/events/graphql/mutations/createOrUpdateSpeaker';
import { PossibleSpeaker } from 'modules/events/groups/SpeakersGroup/SpeakersGroup.types';
import eventState from 'modules/events/states/events.states';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';
import { useYodaCreateForm } from 'yoda-ui/yodaForm';

const useEditSpeakerProfileModal = (
  setOpen: Dispatch<SetStateAction<boolean>>,
  onSpeakerSave?: () => void,
  selectedSpeaker?: PossibleSpeaker,
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [createSpeakerProfile] = useCreateOrUpdateSpeakerMutation();

  const { t } = useTranslation();
  const {
    getValues,
    providerFields,
    resetForm,
    useWatchForm,
  } = useYodaCreateForm();
  const { isValid } = useWatchForm();
  const { addSpeakerProfileConfig } = useEditSpeakerProfileConfig(selectedSpeaker);

  const getTransformSpeakerWithImage = useCallback(() => {
    const formValues = getValues();
    return transformSpeakerProfile(formValues);
  }, [getValues]);

  const cancelEditSpeakerForm = () => {
    resetForm();
    setOpen(false);
  };

  const handleSaveSpeakerProfileForm = useCallback(async () => {
    setIsSubmitting(true);
    const speakerWithImage = getTransformSpeakerWithImage();
    const formValues = getValues();
    const personalInfosVisibility = transformPersonalInfosVisibility(formValues, eventState?.createEvent?._id);

    const speakerProfileData = await createSpeakerProfile({
      ...(selectedSpeaker && { id: selectedSpeaker.id }),
      ...speakerWithImage,
      ...(personalInfosVisibility && { personalInfosVisibility }),
    });
    if (onSpeakerSave) {
      onSpeakerSave();
    }
    setIsSubmitting(false);

    if (speakerProfileData) {
      resetForm();
      setOpen(false);
    }
  }, [createSpeakerProfile, getTransformSpeakerWithImage, getValues, onSpeakerSave, resetForm, selectedSpeaker, setOpen]);

  const cancelButtonConfig = {
    buttonProps: {
      buttonType: ButtonType.secondary,
      onClick: cancelEditSpeakerForm,
    },
    label: t('actions_cancel_label'),
  };

  const submitButtonConfig = {
    buttonProps: {
      buttonType: ButtonType.primary,
      disabled: !isValid,
      onClick: handleSaveSpeakerProfileForm,
    },
    label: t('actions_save_label'),
    loading: isSubmitting,
  };

  return {
    addSpeakerProfileConfig,
    cancelButtonConfig,
    providerFields,
    submitButtonConfig,
    t,
  };
};

export default useEditSpeakerProfileModal;
