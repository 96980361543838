import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { TagColorsMap } from './ParticipantItem.types';
import { Market, Presence, RegisterParticipantInput, Source } from 'generated/event.types';
import { testUserList } from 'modules/events/basics/constants/eventTesting.constants';
import { DeclinedParticipantStatus, JoiningChannel, ParticipantStatus, VeevaSyncStatus } from 'modules/events/basics/enums/events.enums';
import { RegistrationStatusHistory, Event } from 'modules/events/basics/types/events.types';
import { findParticipantLaterStatus } from 'modules/events/basics/utils/participants.utils';
import { getE2EVipLink } from 'modules/events/components/EventTesting/EventTesting.utils';
import { Icons } from 'yoda-ui/components/Icons/Icon';
import { YodaColors } from 'yoda-ui/yodaTheme';

const tagColorsMap = (t: TFunction<'translation'>): TagColorsMap => ({
  [ParticipantStatus.accepted]: {
    label: t('participant_status_confirmed'),
    bgColor: YodaColors.greenSuccessLight,
    color: YodaColors.greenSuccessDark,
  },
  [ParticipantStatus.checkedIn]: {
    label: t('participant_status_checked_in'),
    bgColor: YodaColors.green,
    color: YodaColors.white,
  },
  [ParticipantStatus.invited]: {
    label: t('participant_status_invited'),
    bgColor: YodaColors.gray2,
    color: YodaColors.gray8,
  },
  [ParticipantStatus.requested]: {
    label: t('participant_status_pending'),
    bgColor: YodaColors.orangeWarningLight,
    color: YodaColors.orangeWarningDark,
  },
  [ParticipantStatus.rejected]: {
    label: t('participant_status_rejected'),
    bgColor: YodaColors.redErrorLight,
    color: YodaColors.redErrorDark,
  },
  [ParticipantStatus.waitingList]: {
    label: t('participant_status_waiting_list'),
    bgColor: YodaColors.orangeWarningLight,
    color: YodaColors.orangeWarningDark,
  },
  [DeclinedParticipantStatus.declined]: {
    label: t('participant_status_declined'),
    bgColor: YodaColors.redErrorLight,
    color: YodaColors.redErrorDark,
  },
});

export const veevaSyncStatusIconMap = {
  [VeevaSyncStatus.ERROR]: {
    icon: Icons.errorOutline,
  },
  [VeevaSyncStatus.SYNC]: {
    icon: Icons.taskAlt,
  },
  [VeevaSyncStatus.PENDING]: {
    icon: Icons.hourglass,
  },
  [VeevaSyncStatus.DELETED]: {
    icon: Icons.deletedForever,
  },
};

const useParticipantsItem = (
  registrationStatusHistory: RegistrationStatusHistory,
  userEmail: string,
  firstName: string,
  lastName: string,
  mdmId: string,
  e2eTestMode: boolean,
  event?: Event,
  veevaSyncStatus?: VeevaSyncStatus,
  syncParticipantHandler?: (registerParticipantInput: Partial<RegisterParticipantInput>) => Promise<boolean>,
  joiningChannel?: JoiningChannel,
) => {
  const { t } = useTranslation();
  const flags = useFlags();

  const [opened, setOpened] = useState(false);
  const [cancelModalopen, setCancelModalOpen] = useState(false);
  const [acceptModalOpen, setAcceptModalOpen] = useState(false);
  const [checkInModalOpen, setCheckInModalOpen] = useState(false);

  const [isMoreActionsMenuVisible, setMoreActionMenuVisibile] = useState(false);

  const participantCurrentStatus = findParticipantLaterStatus(registrationStatusHistory);

  const getUserVipLink = useCallback(async (eventStatus: string) => {
    const selectedTestUser = testUserList.find((user) => {
      return user.email === userEmail;
    });

    if (event && selectedTestUser) {
      const market = event?.data?.biogenLincConfig?.isSwitzerlandSpecific ? Market.ch : event?.market;
      const e2eUrl = await getE2EVipLink(
        selectedTestUser, event?.title, event?._id, market, flags.backofficeBiogenlinc5313GenerateVipLinks,
      );
      if (e2eUrl) {
        const vipLink = new URL(e2eUrl);
        vipLink.searchParams.append('status', eventStatus);
        window.open(vipLink.toString(), '_blank');
      }
    }
  }, [event, userEmail, flags.backofficeBiogenlinc5313GenerateVipLinks]);

  const menuItemConfigs = useMemo(() => {
    const menu = [
      {
        key: t('participantsItem_moreActionsMenu_participantCancellation_label'),
        label: t('participantsItem_moreActionsMenu_participantCancellation_label'),
        onClick: () => { setCancelModalOpen(true); },
        hideItem: ParticipantStatus.rejected === participantCurrentStatus || ParticipantStatus.waitingList === participantCurrentStatus,
      },
      {
        key: t('participantsItem_moreActionsMenu_acceptParticipant_label'),
        label: t('participantsItem_moreActionsMenu_acceptParticipant_label'),
        onClick: () => { setAcceptModalOpen(true); },
        hideItem: ParticipantStatus.waitingList !== participantCurrentStatus,
      },

      {
        key: t('participantsItem_moreActionsMenu_testOngoingEvent_label'),
        label: t('participantsItem_moreActionsMenu_testOngoingEvent_label'),
        onClick: () => { getUserVipLink('ongoing'); },
        hideItem: !e2eTestMode,
      },
      {
        key: t('participantsItem_moreActionsMenu_testEndedEvent_label'),
        label: t('participantsItem_moreActionsMenu_testEndedEvent_label'),
        onClick: () => { getUserVipLink('ended'); },
        hideItem: !e2eTestMode,
      },
      {
        key: t('participantsItem_moreActionsMenu_registerAgainParticipant_label'),
        label: t('participantsItem_moreActionsMenu_registerAgainParticipant_label'),
        onClick: () => { setAcceptModalOpen(true); },
        hideItem: ParticipantStatus.rejected !== participantCurrentStatus,
      },
      {
        key: t('participantsItem_moreActionsMenu_checkInParticipant_label'),
        label: t('participantsItem_moreActionsMenu_checkInParticipant_label'),
        onClick: () => { setCheckInModalOpen(true); },
        hideItem: event?.presence === Presence.online
         || (event?.presence === Presence.hybrid && joiningChannel !== JoiningChannel.physical)
          || ParticipantStatus.accepted !== participantCurrentStatus,
      },

      {
        key: t('participantsItem_moreActionsMenu_syncParticipant_label'),
        label: t('participantsItem_moreActionsMenu_syncParticipant_label'),
        onClick: () => {
          if (syncParticipantHandler) {
            syncParticipantHandler({ mdmId, email: userEmail, eventId: event?._id, firstName, lastName });
          }
        },
        hideItem: !syncParticipantHandler || e2eTestMode || event?.source !== Source.veeva,
      },
    ];
    setMoreActionMenuVisibile(menu.some((item) => !item.hideItem));
    return menu;
  }, [
    e2eTestMode,
    event?._id,
    event?.presence,
    event?.source,
    firstName,
    getUserVipLink,
    joiningChannel,
    lastName,
    mdmId,
    participantCurrentStatus,
    syncParticipantHandler,
    t,
    userEmail,
  ]);

  const toggleOpened = () => {
    setOpened((prev) => !prev);
  };

  const veevaSyncTagConfig = useMemo(
    () => {
      return veevaSyncStatus ? veevaSyncStatusIconMap[veevaSyncStatus] : veevaSyncStatusIconMap[VeevaSyncStatus.PENDING];
    },
    [veevaSyncStatus],
  );

  const tagConfig = useMemo(
    () => {
      const isDeclined = !!registrationStatusHistory.rejected && !registrationStatusHistory.accepted;

      const status = isDeclined ? DeclinedParticipantStatus.declined : findParticipantLaterStatus(registrationStatusHistory);
      return status ? tagColorsMap(t)[status] : null;
    },
    [registrationStatusHistory, t],
  );

  return {
    opened,
    tagConfig,
    veevaSyncTagConfig,
    toggleOpened,
    menuItemConfigs,
    cancelModalopen,
    setCancelModalOpen,
    acceptModalOpen,
    setAcceptModalOpen,
    isMoreActionsMenuVisible,
    checkInModalOpen,
    setCheckInModalOpen,
  };
};

export default useParticipantsItem;
