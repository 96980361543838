import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { TimeSlotBookingField, UseTimeSlotBooking } from './TimeSlotBooking.types';
import { buildDateRangePickerEndTimeOptions, buildDateRangePickerStartTimeOptions } from 'basics/options/time.options';
import { OnlineEventStateType, TimeSlot } from 'modules/events/basics/types/events.types';
import eventState from 'modules/events/states/events.states';
import { useYodaCreateForm, useYodaForm } from 'yoda-ui/yodaForm';

const useTimeSlotBooking = ({ onSave }: UseTimeSlotBooking) => {
  const { t } = useTranslation();
  const { setFormIsDirty: setParentFormIsDirty } = useYodaForm();

  const { formState, fieldsState, useWatchForm, getValues, useWatchField, setValue, providerFields, setFormIsDirty } = useYodaCreateForm();
  const dateRange = useWatchField(TimeSlotBookingField.dateRange)?.value;
  const startTime = useWatchField(TimeSlotBookingField.startTime)?.value;
  const { isValid: isFormValid, isDirty: isFormDirty } = useWatchForm();
  const eventStateValue = useSnapshot(eventState) as OnlineEventStateType;
  const [alreadyBooked, setAlreadyBooked] = useState(!!eventStateValue.createEvent.data?.providerLicense);
  const realStartTime = eventStateValue.createEvent.data?.session?.startTime;
  const realEndTime = eventStateValue.createEvent.data?.session?.endTime;

  const startTimeOptions = useMemo(() => (
    buildDateRangePickerStartTimeOptions({ dateRange, startTime, realTime: realStartTime, setValue, fieldsState })
  ), [dateRange, setValue, startTime, realStartTime, fieldsState]);

  const endTimeOptions = useMemo(() => (
    buildDateRangePickerEndTimeOptions({ dateRange, startTime, realTime: realEndTime, setValue, fieldsState })
  ),
  [dateRange, startTime, realEndTime, setValue, fieldsState]);

  const handleSave = useCallback(() => {
    if (onSave) {
      const { dateRange: [startDate, endDate], ...values } = getValues() as TimeSlot;
      onSave({
        dateRange: [startDate, endDate || startDate],
        ...values,
      });
      setFormIsDirty(false);
      setParentFormIsDirty(true);
      setAlreadyBooked(true);
    }
  }, [getValues, onSave, setFormIsDirty, setParentFormIsDirty]);

  const enableBookingButton = () => {
    setAlreadyBooked(false);
  };

  useEffect(() => {
    setAlreadyBooked(!!eventStateValue.createEvent.data?.providerLicense);
  }, [eventStateValue.createEvent.data?.providerLicense]);

  return {
    t,
    formState,
    fieldsState,
    isFormValid,
    handleSave,
    startTimeOptions,
    endTimeOptions,
    providerFields,
    isFormDirty,
    alreadyBooked,
    enableBookingButton,
  };
};

export default useTimeSlotBooking;
