import { TextFieldBasis } from 'components/QuickForm/QuickForm.types';

type UseTestimonial = {
  testimonialFormConfig: {
    title: string;
    authorField: TextFieldBasis;
    testimonyField: TextFieldBasis & { multiline: boolean; rows: number };
  };
};

export const maxDisplayedTestimonyLength = 100;

const useTestimonials = ({ testimonialFormConfig }: UseTestimonial) => {
  const {
    title,
    authorField: firstFieldConfig,
    testimonyField: secondFieldConfig,
  } = testimonialFormConfig;

  const formConfig = {
    title,
    firstFieldConfig,
    secondFieldConfig,
  };

  const transformToCard = (item: { id: string; author: string; testimony: string }) => {
    const { author, testimony } = item;
    let displayedTestimony = testimony;
    if (testimony.length >= maxDisplayedTestimonyLength) {
      displayedTestimony = `${testimony.slice(0, maxDisplayedTestimonyLength)}...`;
    }
    return {
      id: item.id,
      property1: author,
      property2: displayedTestimony,
    };
  };

  return {
    formConfig,
    transformToCard,
  };
};

export default useTestimonials;
