import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { buildDateRangePickerEndTimeOptions, buildDateRangePickerStartTimeOptions } from 'basics/options/time.options';
import { warningToast } from 'basics/utils/toast';
import useCreateEventConfig from 'modules/events/components/CreateEvent/CreateEvent.config';
import transformToEvent from 'modules/events/components/CreateEvent/CreateEventModal/CreateEventModal.transformer';
import { CreateEventFormValues } from 'modules/events/components/CreateEvent/CreateEventModal/CreateEventModal.type';
import { getRouteByFormatAndPresence } from 'modules/events/components/CreateEvent/CreateEventModal/CreateEventModal.utils';
import { useCreateEventMutation } from 'modules/events/graphql/mutations/createEvent';
import { onlineMeetingTransformer } from 'modules/events/routes/CreateOnlineMeeting/CreateOnlineMeeting.transformers';
import eventState from 'modules/events/states/events.states';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';
import { useYodaCreateForm } from 'yoda-ui/yodaForm';

const useCreateEventModal = (setOpen: Dispatch<SetStateAction<boolean>>) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    getValues,
    providerFields,
    resetForm,
    useWatchForm,
    useWatchField,
    setValue,
    fieldsState,
  } = useYodaCreateForm();
  const [createEvent] = useCreateEventMutation();
  const { isValid } = useWatchForm();

  const dateRange = useWatchField('dateRange')?.value;
  const startTime = useWatchField('startTime')?.value;
  const eventPresence = useWatchField('presence')?.value;

  const startTimeOptions = useMemo(() => (
    buildDateRangePickerStartTimeOptions({
      dateRange,
      startTime,
      realTime: new Date().toISOString(),
      setValue,
      fieldsState,
    })
  ), [dateRange, setValue, startTime, fieldsState]);

  const endTimeOptions = useMemo(() => (
    buildDateRangePickerEndTimeOptions(
      {
        dateRange,
        startTime,
        realTime: new Date().toISOString(),
        setValue,
        fieldsState,
      },
    )
  ),
  [dateRange, startTime, setValue, fieldsState]);

  const { createEventConfig } = useCreateEventConfig(startTimeOptions, endTimeOptions, eventPresence);

  const cancelCreateEventForm = () => {
    resetForm();
    setOpen(false);
  };

  const handleSaveCreateEventForm = async () => {
    setIsSubmitting(true);
    const formValues = getValues();

    const createEventInput = transformToEvent(formValues as CreateEventFormValues);

    const eventData = await createEvent(createEventInput);
    setIsSubmitting(false);
    if (eventData) {
      eventState.createEvent = onlineMeetingTransformer(eventData);
      resetForm();
      const editEventRoute = getRouteByFormatAndPresence(eventData);
      if (editEventRoute) {
        navigate(editEventRoute);
      }
    } else {
      warningToast(t('errors_standard'));
    }
  };

  const cancelButtonConfig = {
    buttonProps: {
      onClick: cancelCreateEventForm,
      buttonType: ButtonType.secondary,
    },
    label: t('actions_cancel_label'),
  };

  const submitButtonConfig = {
    buttonProps: {
      onClick: handleSaveCreateEventForm,
      buttonType: ButtonType.primary,
      disabled: !isValid,
    },
    label: t('event_creation_modal_create_button_label'),
    loading: isSubmitting,
  };

  return {
    cancelButtonConfig,
    createEventConfig,
    submitButtonConfig,
    providerFields,
    t,
    eventPresence,
  };
};

export default useCreateEventModal;
