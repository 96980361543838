import { Box } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import useEventTypeSelector from 'modules/events/components/EventTypeSelector/EventTypeSelector.hook';
import SelectYoda from 'yoda-ui/components/Form/SelectYoda';
import { useYodaCreateForm, YodaFormProvider } from 'yoda-ui/yodaForm';

export const EventTypeSelector: FC = () => {
  const { eventTypeSelectorConfig, handleEventTypeChange } = useEventTypeSelector();
  const yodaFormMethods = useYodaCreateForm({ setIsValidOptional: true });
  const flags = useFlags();
  return flags.eventsBiogenlinc4280EnablePhysicalEvents || flags.eventsBiogenlinc4745EnableHybridEvents ? (
    <YodaFormProvider { ...yodaFormMethods.providerFields }>
      <Box marginRight={ '20px' } width={ '200px' }>
        <SelectYoda { ...eventTypeSelectorConfig } onChange={ handleEventTypeChange } />
      </Box>
    </YodaFormProvider>
  ) : null;
};
