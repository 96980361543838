import useFlags from 'launchdarkly-react-client-sdk/lib/useFlags';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BACKOFFICE_TITLE } from 'basics/constants/common.constants';
import LargeContainer from 'components/Layout/LargeContainer';
import { useCurrentUserState } from 'contexts/user';
import { CountrySelector as EventsCountrySelector } from 'modules/events/components/CountrySelector/CountrySelector';
import CreateEvent from 'modules/events/components/CreateEvent';
import { EventDatePicker } from 'modules/events/components/EventDatePicker/EventDatePicker';
import EventsList from 'modules/events/components/EventsList';
import { EventStatusSelector } from 'modules/events/components/EventStatusSelector/EventStatusSelector';
import { EventTypeSelector } from 'modules/events/components/EventTypeSelector/EventTypeSelector';
import ManageSpeakers from 'modules/events/components/ManageSpeakers/ManageSpeakers';
import useEvents from 'modules/events/routes/Events/Events.hook';
import usePastEvents from 'modules/events/routes/Events/PastEvents.hook';
import { ActionButton, ButtonVariant } from 'yoda-ui/components/BiogenLincDS/Buttons';
import Box from 'yoda-ui/components/Box';
import { Icons } from 'yoda-ui/components/Icons/Icon';
import Loader from 'yoda-ui/components/Loader';
import Paper from 'yoda-ui/components/Paper';
import { YodaBorderRadius, YodaColors, YodaFontSize, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

const Events: FC = () => {
  const { t } = useTranslation();
  const { data, loading, onManageSpeakersModalOpen, onManageSpeakersModalClose, showManageSpeakersModal } = useEvents(false);
  const { user, userLoading } = useCurrentUserState();
  const { pastEventsCount, pastEventsData, expanded, onExpand, pastEventsLoading } = usePastEvents();
  const flags = useFlags();

  return (
    <LargeContainer
      flexDirection="column"
      marginBottom={ YodaSpacing.xLarge }
      paddingRight={ YodaSpacing.xLarge }
      paddingLeft={ YodaSpacing.xLarge }
    >
      <Box maxWidth="70%">
        <Box fontSize={ YodaFontSize.xxxxLarge } color={ YodaColors.gray5 }>
          { t('titles_home', { appName: BACKOFFICE_TITLE, userName: user?.name || '' }) }
        </Box>
        <Box
          marginTop={ YodaSpacing.small }
          marginBottom={ YodaSpacing.medium }
          fontSize={ YodaFontSize.xLarge }
          color={ YodaColors.gray4 }
        >
          { t('home_description') }
        </Box>

        {
          flags.eventsBiogenlinc4427ManageSpeakers ? (
            <>
              <ActionButton
                icon={ Icons.campaign }
                label={ t('event_manage_speakers_title') }
                variant={ ButtonVariant.primary }
                onClick={ onManageSpeakersModalOpen }
              />
              <ManageSpeakers isOpen={ showManageSpeakersModal } onClose={ onManageSpeakersModalClose }></ManageSpeakers>
            </>
          ) : null
        }

      </Box>

      <Box marginY={ YodaSpacing.xxLarge } width="100%">
        <Box display="flex" width="100%" flexDirection="row" justifyContent="space-between">
          <Box fontSize={ YodaFontSize.xLarge }>
            { t('event_list_title') }
          </Box>
        </Box>

        <Box display="flex" width="100%" alignItems="center" justifyContent="center">
          <EventDatePicker />
          <EventStatusSelector />
          <EventTypeSelector></EventTypeSelector>
          { !userLoading && user ? <EventsCountrySelector user={ user } /> : null }
        </Box>

        {
          flags.eventsBiogenlinc5767UsMarket ? (
            <Box display="flex" justifyContent="end" width="100%">
              <CreateEvent />
            </Box>
          ) : null
        }
      </Box>
      {
        loading && (
          <Box display="flex" flexDirection="column" justifyContent="center" width="100vw" marginBottom="20px">
            <Loader center />
          </Box>
        )
      }
      {
        data && data.length > 0
          ? <EventsList events={ data } />
          : !loading && (<Box display="flex" justifyContent="center">{ t('events_no_events_result_label') }</Box>)
      }
      {
        flags.eventsShowPastEvents3525 && (
          <Box marginY={ YodaSpacing.xxLarge } width="100%">
            <Box display="flex" width="100%" flexDirection="row" justifyContent="space-between">
              <Box fontSize={ YodaFontSize.xLarge }>
                { t('past_event_list_title') }
              </Box>
            </Box>
          </Box>
        )
      }
      {
        pastEventsCount > 0 && !expanded && !pastEventsLoading && flags.eventsShowPastEvents3525 && (
          <Box display="flex" justifyContent="center" width="100%" height="100%" marginBottom="20px" maxHeight="72px" bgcolor="white">
            <Paper margin={ YodaSpacing.none } borderRadius={ YodaBorderRadius.medium }>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                padding={ YodaSpacing.medium }
              >
                <Box
                  onClick={ onExpand }
                  style={ { cursor: 'pointer' } }
                  fontWeight={ YodaFontWeight.medium }
                >
                  { t('past_event_load_more', { count: pastEventsCount }) }
                </Box>
              </Box>
            </Paper>
          </Box>
        )
      }
      {
        pastEventsLoading && flags.eventsShowPastEvents3525 && (
          <Box display="flex" flexDirection="column" justifyContent="center" width="100vw" marginBottom="20px">
            <Loader center />
          </Box>
        )
      }
      {
        expanded && pastEventsData.length > 0 && !pastEventsLoading && flags.eventsShowPastEvents3525 && (
          <EventsList events={ pastEventsData } />
        )
      }
      {
        pastEventsCount === 0 && flags.eventsShowPastEvents3525 && (
          <Box display="flex" justifyContent="center">{ t('events_no_past_events_result_label') }</Box>
        )
      }
    </LargeContainer>
  );
};
export default Events;
