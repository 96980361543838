import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { QueryOptionsType } from 'basics/types/common.types';
import { CustomerEngagementPlanning } from 'generated/maya.types';
import { CepStatus } from 'modules/events/basics/enums/maya.enums';
import { ApolloCEPsResponse, PossibleCEP } from 'modules/events/groups/EmailNotesGroup/EmailNotesGroup.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_ALL_CEP = gql`
query GetCustomerEngagementPlanningsQuery($limit: Int!, $offset: Int!, $filters: CepFilter) {
  getCustomerEngagementPlannings(limit: $limit, offset: $offset, filters: $filters) {
    customerEngagementPlannings {
      _id
      title,
      cepId,
      formattedCepId,
      status,
    }
    count
  }
}
`;

type ResultType = {
  getCustomerEngagementPlannings: {
    customerEngagementPlannings: CustomerEngagementPlanning[];
    count: number;
  };
};

type UseGetCepsLazy = (filters?: unknown, queryOptions?: QueryOptionsType) => {
  lazyQuery: Function;
  loading: boolean;
  error?: ApolloError;
  data: PossibleCEP[] | null;
  count: number;
};

const cleanApolloData = (data?: ApolloCEPsResponse): null | PossibleCEP[] => {
  if (data) {
    return data.customerEngagementPlannings.map((apolloCEP) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { _id, formattedCepId, title } = apolloCEP;
      const label = `${title} (${formattedCepId})`;
      return { id: _id, label };
    });
  }
  return null;
};

const useGetAllCepsLazyQuery: UseGetCepsLazy = () => {
  const [queryGetAllCeps, { called, data, error, loading }] = useLazyQuery<ResultType>(GET_ALL_CEP, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });

  const lazyQuery = useCallback(async (query: string) => {
    await queryGetAllCeps(
      {
        variables: {
          filters: { search: query, status: CepStatus.APPROVED },
          limit: 30,
          offset: 0,
        },
      },
    );
  }, [queryGetAllCeps]);

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getAllCustomerEngagementPlannings.ts - useGetAllCustomerEngagementPlanningQuery',
    });
  }

  return {
    called,
    lazyQuery,
    loading,
    error,
    data: cleanApolloData(data?.getCustomerEngagementPlannings) || [],
    count: data?.getCustomerEngagementPlannings.count || 0,
  };
};

export default useGetAllCepsLazyQuery;
