import { GridColDef, GridEventListener, GridRenderCellParams } from '@mui/x-data-grid';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import GridListAction from 'modules/events/components/GridListAction/GridListAction';
import { PossibleSpeaker } from 'modules/events/groups/SpeakersGroup/SpeakersGroup.types';
import { TextYodaInputProps } from 'yoda-ui/components/Form/TextYoda';

type ManageSpeakersFormConfigType = {
  speakerSearch: TextYodaInputProps;
};

const useManageSpeakersConfig = (
  onSpearkerSearchFieldChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  onEditSpeaker: (speaker: PossibleSpeaker) => void,
  onDeleteSpeaker: (speaker: PossibleSpeaker) => void,
) => {
  const { t } = useTranslation();

  const formConfig: ManageSpeakersFormConfigType = {
    speakerSearch: {
      label: t('event_manage_speakers_search_label'),
      name: 'speakerSearch',
      placeholder: t('event_manage_speakers_search_placeholder'),
      required: false,
      onChange: onSpearkerSearchFieldChange,
      defaultValue: '',
    },
  };

  const listFieldConfig: GridColDef[] = [
    {
      field: 'firstName',
      headerName: t('event_manage_speakers_list_first_name'),
      flex: 0.3,
      sortable: false,
      cellClassName: 'gridlist-cell-bold',
    },
    {
      field: 'lastName',
      headerName: t('event_manage_speakers_list_last_name'),
      flex: 0.3,
      sortable: false,
      cellClassName: 'gridlist-cell-bold',
    },
    {
      field: 'email',
      headerName: t('event_manage_speakers_list_email'),
      flex: 0.3,
      sortable: false,
      cellClassName: 'gridlist-cell-bold',
    },
    {
      field: 'usedInEvents',
      headerName: t('event_manage_speakers_list_used'),
      renderCell: (params: GridRenderCellParams) => {
        const speaker = params.row as PossibleSpeaker;
        return speaker.relatedEvents.length ? 'Yes' : 'No';
      },
      align: 'right',
      headerAlign: 'right',
      flex: 0.1,
    },
    {
      field: 'action',
      headerName: '',
      disableColumnMenu: true,
      hideSortIcons: true,
      renderCell: (params: GridRenderCellParams) => {
        const actions = [
          {
            label: t('actions_edit_speaker'),
            callback: () => { onEditSpeaker(params.row as PossibleSpeaker); },
          },
          {
            label: t('actions_delete_speaker'),
            callback: () => { onDeleteSpeaker(params.row as PossibleSpeaker); },
          },
        ];

        return (
          <GridListAction
            actions={ actions }
          />
        );
      },
      align: 'right',
      headerAlign: 'right',
      flex: 0.1,
    },
  ];

  const handleCellClick: GridEventListener<'cellClick'> = (params) => {
    if (params.field !== 'action') {
      onEditSpeaker(params.row as PossibleSpeaker);
    }
  };

  const listConfig = {
    columnConfig: listFieldConfig,
    handleCellClick,
  };

  return {
    formConfig,
    listConfig,
  };
};

export default useManageSpeakersConfig;
