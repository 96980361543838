import { ChangeEvent, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { ButtonColorSelectType } from './EmailNotesGroup.types';
import { colorOptions } from './EmailNotesGroup.utils';
import { useLazyGetSecretQuery } from 'basics/graphql/queries/getSecret';
import { SelectOption } from 'basics/options/options.types';
import { validateImagesDimensions } from 'basics/utils/files/files.validations';
import { HandleFiles } from 'components/QuickForm/QuickForm.types';
import { getConfigVar } from 'config/config';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import useEmailNotesConfig from 'modules/events/groups/EmailNotesGroup/EmailNotesGroup.config';
import eventState from 'modules/events/states/events.states';
import { useYodaForm } from 'yoda-ui/yodaForm';

const useEmailNotes = () => {
  const { t } = useTranslation();
  const snap = useSnapshot(eventState);
  const [selectedLogo, setSelectedLogo] = useState('2');
  const [buttonColorValue, setButtonColorValue] = useState(snap.createEvent.data?.buttonColor);
  const [infoModalIsOpen, setInfoModalIsOpen] = useState(false);
  const [selectedCEP, setSelectedCEP] = useState<SelectOption | null>(null);
  const { queryGetSecret } = useLazyGetSecretQuery();
  const eventPresence = snap.createEvent.presence;
  useEffect(() => {
    setSelectedLogo(snap.createEvent.data?.biogenLogo !== undefined ? snap.createEvent.data?.biogenLogo : '2');
  }, [snap.createEvent.data?.biogenLogo]);

  const selectTargetListOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedLogo(event.target.value);
  };

  const cepChanged = <T extends SelectOption>(value: T | null) => {
    setSelectedCEP(value);
  };

  const createNewCEP = async () => {
    const { data: { getSecret: cepSourceId } } = await queryGetSecret({ variables: { key: 'MAYA_CEP_SOURCE_ID' } });
    const eventId = snap.createEvent._id;
    // eslint-disable-next-line max-len
    const mayaFormUrl = `${getConfigVar('REACT_APP_MAYA_URL')}/customerEngagementPlanning/duplicate?cepSourceId=${cepSourceId}&eventId=${eventId}`;
    window.open(mayaFormUrl);
    setInfoModalIsOpen(true);
  };

  const { emailNotesConfig } = useEmailNotesConfig(selectTargetListOnChange);
  const { setValue, setFormIsDirty } = useYodaForm();

  const handleDropImage: HandleFiles = async (files) => {
    const { minWidth, minHeight } = emailNotesConfig.programLogo.dimensions;
    const dimensionsAreValid = await validateImagesDimensions({
      files,
      dimensions: emailNotesConfig.programLogo.dimensions,
    });

    return dimensionsAreValid ? { ok: true } : {
      ok: false,
      errorMessage: `${t('cardImage_dimensions_validation_error_message', { imageWidth: minWidth, imageHeight: minHeight })}`,
    };
  };

  const buttonColorConfig: ButtonColorSelectType = useMemo(() => {
    return {
      name: commonFields.buttonColor,
      value: buttonColorValue || '#1F49E0',
      options: colorOptions,
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snap.createEvent.data, buttonColorValue]);

  // Custom function to handle non-yoda Select MUI input mechanism
  const changeColorValue = (newValue: string) => {
    setValue(buttonColorConfig.name, newValue);
    setButtonColorValue(newValue);
    setFormIsDirty(true);
  };

  // UseEffect usage for some render scenarios where first undefined value is kept on Select MUI field
  useEffect(() => {
    setButtonColorValue(snap.createEvent.data?.buttonColor);
  }, [snap.createEvent.data?.buttonColor]);

  return {
    createNewCEP,
    cepChanged,
    changeColorValue,
    emailNotesConfig,
    buttonColorConfig,
    eventPresence,
    handleDropImage,
    selectedCEP,
    selectedLogo,
    infoModalIsOpen,
    setInfoModalIsOpen,
    t,
  };
};

export default useEmailNotes;
