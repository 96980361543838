import { Buffer } from 'buffer';
import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { StreamableBuffer } from 'generated/event.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const EXPORT_DATA = gql`
query($eventId: String!) {
  exportEventParticipantData(eventId: $eventId) {
    buffer
  }
}
`;

export type ResultType = {
  exportEventParticipantData: StreamableBuffer;
};

type UseExportData = (onCompleteCallback: (data: ResultType) => void) => {
  data: unknown;
  error?: ApolloError;
  exportData: Function;
  loading: boolean;
  refetch: Function;
};

const useExportEventParticipantDataQuery: UseExportData = (onCompleteCallback) => {
  const [exportData, { data, loading, error, refetch }] = useLazyQuery<ResultType>(EXPORT_DATA, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: onCompleteCallback,
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      customContext,
      error,
      transactionName: 'exportData.ts - useExportDataQuery',
    });
  }

  return {
    data: data?.exportEventParticipantData?.buffer ? Buffer.from(data.exportEventParticipantData.buffer, 'base64') : null,
    error,
    exportData,
    loading,
    refetch,
  };
};

export default useExportEventParticipantDataQuery;
