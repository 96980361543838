import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Presence } from 'generated/event.types';
import { Participant } from 'modules/events/basics/types/events.types';
import { HybridMeetingDataState, HybridMeetingFromDB } from 'modules/events/basics/types/hybridMeeting.types';
import { PhysicalMeetingFromDB } from 'modules/events/basics/types/physicalMeeting.types';
import useLazyGetFullPrivateEventById from 'modules/events/graphql/queries/getLazyFullPrivateEventById';
import { useYodaCreateForm } from 'yoda-ui/yodaForm';

const useManageMeeting = () => {
  const [participants, setParticipants] = useState<Participant[] | null>(null);
  const { id } = useParams();

  const { queryGetFullPrivateEventById, data: event, error, loading } = useLazyGetFullPrivateEventById();
  const yodaFormMethods = useYodaCreateForm({ setIsValidOptional: true });

  useEffect(() => {
    if (id) {
      queryGetFullPrivateEventById({ variables: { eventId: id } });
    }
  }, [id, queryGetFullPrivateEventById]);

  useEffect(() => {
    if (event) {
      setParticipants(event.data.participants || null);
    }
  }, [event]);
  const seats = event?.presence === Presence.physical || event?.presence === Presence.hybrid
    ? (event?.data as PhysicalMeetingFromDB | HybridMeetingFromDB).seats || 0
    : 0;

  const isRestrictedAccess = event?.presence === Presence.hybrid ? (event?.data as HybridMeetingDataState).isRestrictedAccess : false;

  const reminderSent = participants?.some((participant) => {
    return !!participant.reminder12h
    || !!participant.reminder1h
    || !!participant.reminder24h
    || !!participant.reminder48h
    || !!participant.reminder1week;
  });

  return {
    error,
    event,
    loading,
    yodaFormMethods,
    participants,
    seats,
    setParticipants,
    isRestrictedAccess,
    reminderSent,
  };
};

export default useManageMeeting;
