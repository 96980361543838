import { FC } from 'react';
import InformativeTooltip from 'modules/events/components/InformativeTooltip';
import Box from 'yoda-ui/components/Box';
import { YodaFontSize, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

type CardHeaderProps = {
  title: string;
  tooltipTitle?: string;
  tooltipImageUrl?: string;
};

const CardHeader: FC<CardHeaderProps> = ({ children, title, tooltipTitle, tooltipImageUrl }) => (
  <Box
    display="flex"
    alignItems={ tooltipTitle && tooltipImageUrl ? 'center' : '' }
    flexDirection="row"
    paddingBottom={ YodaSpacing.xxSmall }
    paddingX={ YodaSpacing.large }
    paddingY={ YodaSpacing.medium }
    fontSize={ YodaFontSize.xLarge }
    fontWeight={ YodaFontWeight.medium }
    justifyContent={ tooltipTitle && tooltipImageUrl ? '' : 'space-between' }
  >
    { title }
    {
      tooltipTitle && tooltipImageUrl
      && <InformativeTooltip title={ tooltipTitle } imageUrl={ tooltipImageUrl } />
    }
    { children }
  </Box>
);

export default CardHeader;
