import { boolean, mixed, string } from 'yup';
import { EventPromotionalityEnum } from 'modules/events/basics/enums/events.enums';

const biogenLincConfigSchema = {
  isVisibleOnBiogenLinc: boolean(),
  isPublic: mixed<EventPromotionalityEnum>()
    .oneOf(Object.values(EventPromotionalityEnum)),
  prcCodeEventDetails: string().required(),
};

export const getBiogenLincConfigSchema = () => ({
  isVisibleOnBiogenLinc: boolean(),
  isPublic: mixed<EventPromotionalityEnum>()
    .oneOf(Object.values(EventPromotionalityEnum)),
  prcCodeEventDetails: string().required(),
});

export default biogenLincConfigSchema;
