import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const GET_S3_FILE = gql`
query getS3File($title: String!) {
  getS3File(title: $title)
}`;

type ResultType = {
  getS3File: string;
};

export type UseGetS3File = () => {
  queryGetS3File: Function;
  loading: boolean;
  error?: ApolloError;
  data?: string;
};

export const useLazyGetS3FileQuery: UseGetS3File = () => {
  const [queryGetS3File, { data, loading, error, called }] = useLazyQuery<ResultType>(GET_S3_FILE, {
    fetchPolicy: 'cache-and-network',
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getEventScreenshot.ts - useGetS3FileQuery',
    });
  }

  return {
    queryGetS3File,
    data: data?.getS3File,
    loading,
    error,
    called,
  };
};

export default useLazyGetS3FileQuery;
