import { TimeZone } from 'basics/enums/timeZoneList.enums';
import { getShortFormatDateTimeFromIsoString, HOURS_MINUTES } from 'basics/utils/dates/dates.utils';
import { Provider, Session } from 'modules/events/basics/types/eventData.types';
import { TimeSlot } from 'modules/events/basics/types/events.types';

export const transformOutputProvider = (provider?: Provider) => ({
  provider: provider?.host,
  providerLink: provider?.participantUrl,
  providerLicense: provider?.licenseId,
});

export const transformOutputSession = (sessionStored?: Session) => {
  if (!sessionStored) {
    return undefined;
  }

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const sessionState: TimeSlot = {
    dateRange: [new Date(sessionStored.startDate), new Date(sessionStored.endDate)],
    timeZone: userTimezone as TimeZone,
    startTime: getShortFormatDateTimeFromIsoString(new Date(sessionStored.startDate).toISOString(), HOURS_MINUTES),
    endTime: getShortFormatDateTimeFromIsoString(new Date(sessionStored.endDate).toISOString(), HOURS_MINUTES),
  };
  return sessionState;
};
