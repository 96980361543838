import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultDialogTitle from 'components/Dialogs/DefaultDialogTitle';
import Box from 'yoda-ui/components/Box';
import Dialog from 'yoda-ui/components/Dialogs/Dialog';
import DialogContent from 'yoda-ui/components/Dialogs/Dialog/DialogContent';
import { YodaBorderRadius, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

type LoggedOutDialogProps = {
  open: boolean;
  setLoggedOutDialogIsOpen: Dispatch<SetStateAction<boolean>>;
};

const LoggedOutDialog: FC<LoggedOutDialogProps> = ({ open, setLoggedOutDialogIsOpen }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={ open }
      borderRadius={ YodaBorderRadius.medium }
      sx={ { padding: YodaSpacing.xxLarge } }
    >
      <DefaultDialogTitle
        cancelDialog={ () => setLoggedOutDialogIsOpen(false) }
      />
      <Box marginBottom={ YodaSpacing.xxxxLarge }>
        <DialogContent fontWeight={ YodaFontWeight.light }>
          { t('loggedOutDialog_content') }
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default LoggedOutDialog;
