import { SelectOption } from 'basics/options/options.types';
import { TimeSlot } from 'modules/events/basics/types/events.types';
import { CheckBoxYodaProps } from 'yoda-ui/components/Form/CheckBoxYoda';
import { DatePickerYodaInputProps } from 'yoda-ui/components/Form/DatePickerYoda';
import { DateRangePickerProps } from 'yoda-ui/components/Form/DateRangePicker';
import { SelectYodaInputProps } from 'yoda-ui/components/Form/SelectYoda/SelectYoda.types';
import { TextYodaInputProps } from 'yoda-ui/components/Form/TextYoda';

export type TimeSlotBookingProps = {
  onSave?: (values: TimeSlot) => void ;
  values?: TimeSlot;
  isLoading: boolean;
  isValid: boolean;
  isInvalid: boolean;
  disabled: boolean;
};

export enum TimeSlotBookingField {
  dateRange = 'dateRange',
  endTime = 'endTime',
  startTime = 'startTime',
  timeZone = 'timeZone',
  hostProvider = 'hostProvider',
  hostProviderLink = 'hostProviderLink',
  eventFormat = 'eventFormat',
  enableZoomStreaming = 'enableZoomStreaming',
  locale = 'locale',
  registrationEndDate = 'registrationEndDate',
}

export type TimeSlotBookingConfig = {
  dateRange: DateRangePickerProps;
  timeZone: SelectYodaInputProps;
  startTime: SelectYodaInputProps;
  endTime: SelectYodaInputProps;
  eventFormat: SelectYodaInputProps;
  hostProvider: SelectYodaInputProps;
  hostProviderLink: TextYodaInputProps;
  enableZoomStreaming: CheckBoxYodaProps;
  locale: SelectYodaInputProps;
  registrationEndDate: DatePickerYodaInputProps;
};

export type UseTimeSlotBooking = Pick<TimeSlotBookingProps, 'onSave'>;

export type UseTimeSlotBookingConfigProps = {
  startTimeOptions: SelectOption[];
  endTimeOptions: SelectOption[];
  values?: TimeSlot;
};

