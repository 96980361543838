import { Alert } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import useCreateHybridMeeting from './CreateHybridMeeting.hook';
import { CreateHybridMeetingStepsIndex, CreateHybridMeetingWithEmailConfigurationStepsIndex } from './CreateHybridMeeting.types';
import DetailsStep from './Steps/DetailsStep/DetailsStep';
import PublicationStep from './Steps/PublicationStep/PublicationStep';
import { eventsRoute } from 'basics/constants/routes.constants';
import ButtonsBar from 'components/ButtonsBar';
import {
  FormLayoutContainer,
  FormLayoutMidPanelHeader,
  FormLayoutRightPanel,
  FormLayoutMidPanel,
  FormLayoutMidPanelContent,
} from 'components/FormLayout';
import LargeContainer from 'components/Layout/LargeContainer';
import { EventStatus, HybridEvent } from 'generated/event.types';
import SideFormDetails from 'modules/events/components/SideFormDetails';
import CommunicationStep from 'modules/events/routes/CreateHybridMeeting/Steps/CommunicationStep';
import EmailConfigurationStep from 'modules/events/routes/CreateHybridMeeting/Steps/EmailConfigurationStep/EmailConfigurationStep';
import Box from 'yoda-ui/components/Box';
import Icon from 'yoda-ui/components/Icons/Icon';
import { Icons } from 'yoda-ui/components/Icons/Icon/Icon';
import LinkButton from 'yoda-ui/components/Links/LinkButton/LinkButton';
import Loader from 'yoda-ui/components/Loader';
import Stepper from 'yoda-ui/components/Stepper/Stepper';
import { YodaFormProvider } from 'yoda-ui/yodaForm';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const CreateHybridMeeting: FC = () => {
  const flags = useFlags();
  const {
    t,
    handleClickHome,
    handleChangeStep,
    showLoader,
    yodaFormMethods,
    stepIndexer,
    id,
    buttonsConfig,
    stepsList,
    event,
  } = useCreateHybridMeeting();

  if (!id) {
    return <Navigate to={ eventsRoute } replace />;
  }

  if (showLoader) {
    return <Box display="flex" flexDirection="column" justifyContent="center" width="100vw"><Loader center /></Box>;
  }

  // TODO: keep this block when epic BIOGENLINC-3524 is finished
  const stepsWithEmailConfiguration = (
    <>
      <Box hidden={ stepIndexer.stepIndex !== CreateHybridMeetingWithEmailConfigurationStepsIndex.detailsStep }>
        <DetailsStep />
      </Box>
      <Box hidden={ stepIndexer.stepIndex !== CreateHybridMeetingWithEmailConfigurationStepsIndex.communicationStep }>
        <CommunicationStep />
      </Box>
      <Box hidden={ stepIndexer.stepIndex !== CreateHybridMeetingWithEmailConfigurationStepsIndex.emailConfigurationStep }>
        <EmailConfigurationStep />
      </Box>
      <Box hidden={ stepIndexer.stepIndex !== CreateHybridMeetingWithEmailConfigurationStepsIndex.publicationStep }>
        <PublicationStep />
      </Box>
    </>
  );

  // TODO: remove this block when epic BIOGENLINC-3524 is finished
  const stepsConfiguration = (
    <>
      <Box hidden={ stepIndexer.stepIndex !== CreateHybridMeetingStepsIndex.detailsStep }>
        <DetailsStep />
      </Box>
      <Box hidden={ stepIndexer.stepIndex !== CreateHybridMeetingStepsIndex.communicationStep }>
        <CommunicationStep />
      </Box>
      <Box hidden={ stepIndexer.stepIndex !== CreateHybridMeetingStepsIndex.publicationStep }>
        <PublicationStep />
      </Box>
    </>
  );

  return (
    <LargeContainer
      paddingRight={ YodaSpacing.xLarge }
      paddingLeft={ YodaSpacing.xLarge }
    >
      <YodaFormProvider { ...yodaFormMethods.providerFields }>
        <FormLayoutContainer>
          <FormLayoutMidPanel >
            <FormLayoutMidPanelHeader>
              <Box marginBottom={ YodaSpacing.medium }>
                <LinkButton startIcon={ <Icon name={ Icons.chevronLeft } /> } onClick={ handleClickHome }>
                  { `${t('home_link')}` }
                </LinkButton>
              </Box>

              <Stepper
                handleChangeStep={ handleChangeStep }
                stepsList={ stepsList }
                stepIndex={ stepIndexer.stepIndex }
              />
            </FormLayoutMidPanelHeader>
            <FormLayoutMidPanelContent>
              {
                (event?.status === EventStatus.published && (event?.data as HybridEvent)?.biogenLincConfig?.showSendInvitations
                  && !(event?.data as HybridEvent).invitationSent) ? (
                    <Alert severity={ 'warning' }>
                      { t('event_publish_invitation_not_sent_yet_label') }
                    </Alert>
                  ) : null
              }

              { flags.eventsBiogenlinc4151SectionEmail ? stepsWithEmailConfiguration : stepsConfiguration }

              { buttonsConfig && <ButtonsBar { ...buttonsConfig } /> }
            </FormLayoutMidPanelContent>
          </FormLayoutMidPanel>
          <FormLayoutRightPanel >
            <SideFormDetails />
          </FormLayoutRightPanel>
        </FormLayoutContainer>
      </YodaFormProvider>
    </LargeContainer>
  );
};

export default CreateHybridMeeting;
