import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { FileExtension } from 'basics/types/files.types';
import { FormAgendaConfig } from 'components/QuickForm/QuickForm.types';
import { AssetTarget, AssetType } from 'generated/event.types';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { EventStateType } from 'modules/events/basics/types/events.types';
import useAgendaGroupSchema from 'modules/events/groups/AgendaGroup/AgendaGroup.schema';
import { AgendaGroupConfig } from 'modules/events/groups/AgendaGroup/AgendaGroup.types';
import eventState from 'modules/events/states/events.states';
import { Icons } from 'yoda-ui/components/Icons/Icon';

const useAgendaGroupConfig = ({ agendaFormConfig }: { agendaFormConfig: FormAgendaConfig }) => {
  const { t } = useTranslation();
  const {
    createEvent: { data: eventSnapData },
  } = useSnapshot(eventState) as EventStateType;
  const { agendaFile, agendaFileTitle, agendaFileDescription } = eventSnapData
    || { agendaFile: [], agendaFileTitle: '', agendaFileDescription: '' };

  const agendaGroupSchema = useAgendaGroupSchema();

  const formConfig: AgendaGroupConfig = {
    agenda: {
      defaultValue: eventSnapData?.agenda || [],
      formConfig: agendaFormConfig,
      listConfig: {
        noItemLabel: t('events.agendaGroupEmpty'),
        title: '',
      },
      name: commonFields.agenda,
      validation: agendaGroupSchema.agenda,
      disabled: false,
    },
    agendaFile: {
      name: commonFields.agendaFile,
      cardImageFormConfig: {
        btnLabel: t('events_agenda_file_dropzone_action_label'),
        btnIcon: Icons.upload,
        maxFileSize: 5242880,
        maxFilesNumber: 1,
        extensionsAllowed: [FileExtension.pdf],
        message: t('events_agenda_file_dropzone_message'),
        label: t('events_agenda_file_form_title'),
        name: commonFields.agendaFile,
        assetTarget: AssetTarget.agendaFile,
        assetType: AssetType.document,
        assetsArePublic: true,
      },
      listConfig: {
        noItemLabel: t('asset_no_agenda_file_uploaded_label'),
        title: t('asset_uploaded_agenda_file_title'),
      },
      validation: agendaGroupSchema?.agendaFile,
      defaultValue: agendaFile || [],
    },
    agendaFileTitle: {
      label: t('events_agenda_file_title_label'),
      name: commonFields.agendaFileTitle,
      placeholder: t('events_agenda_file_title_placeholder'),
      defaultValue: agendaFileTitle || '',
      validation: agendaGroupSchema?.agendaFileTitle,
    },
    agendaFileDescription: {
      label: t('events_agenda_file_description_label'),
      name: commonFields.agendaFileDescription,
      placeholder: t('events_agenda_file_description_placeholder'),
      defaultValue: agendaFileDescription || '',
      validation: agendaGroupSchema?.agendaFileDescription,
    },
  };

  return ({ formConfig });
};

export default useAgendaGroupConfig;
