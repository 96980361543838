import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { errorToast, succesToast } from 'basics/utils/toast';
import { Participant } from 'modules/events/basics/types/events.types';
import { participantFragment } from 'modules/events/graphql/fragments/commonData.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export type UseSyncAllParticipantsMutationResponse = {
  syncAllParticipants: Partial<Participant[]> | [];
};

export type UseSyncAllParticipantsMutation = [
  (eventId?: string) => Promise<{ participants?: Partial<Participant[]> | [] } | null>,
  MutationResult<UseSyncAllParticipantsMutationResponse>,
];
export const syncAllParticipantsMutationCall = gql`
mutation syncAllParticipants($eventId: String!) {
    syncAllParticipants(eventId: $eventId) {
      ${participantFragment}
    }
}`;

export const useSyncAllParticipantsMutation = (): UseSyncAllParticipantsMutation => {
  const { t } = useTranslation();
  const [syncAllParticipantsMutation, state] = useMutation<UseSyncAllParticipantsMutationResponse>(syncAllParticipantsMutationCall);

  const executeSyncAllParticipantsMutation = useCallback(async (eventId) => {
    try {
      if (!eventId) {
        errorToast(t('errors_standard'));
        return null;
      }
      const { data } = await syncAllParticipantsMutation({
        variables: { eventId },
      });

      if (data?.syncAllParticipants) {
        succesToast(t('events_participant_sync_success'));
      } else {
        errorToast(t('events_participant_sync_failed'));
      }

      return {
        participants: data?.syncAllParticipants || [],
      };
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error);
        Logger.logError({
          error,
          customContext,
          transactionName: 'syncAllParticipants.ts - useSyncAllParticipantsMutation',
        });
        errorToast(t('errors_standard'));
      }
      return null;
    }
  }, [syncAllParticipantsMutation, t]);

  return [executeSyncAllParticipantsMutation, state];
};
