import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { UserGroupConfig } from './UserGroup.types';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { EventStateType } from 'modules/events/basics/types/events.types';
import eventState from 'modules/events/states/events.states';

const useUserGroup = () => {
  const { t } = useTranslation();
  const snap = useSnapshot(eventState) as EventStateType;
  const userGroupConfig: UserGroupConfig = {
    userGroups: {
      label: t('events_user_group_management_label'),
      placeholder: t('events_user_group_management_placeholder'),
      options: [],
      name: commonFields.userGroups,
      defaultValue: snap.createEvent.data?.userGroups || null,
      required: false,
      freeOption: true,
    },
  };
  return {
    userGroupConfig,
  };
};

export default useUserGroup;
