import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { succesToast, errorToast } from 'basics/utils/toast';
import { Event } from 'modules/events/basics/types/events.types';
import { PhysicalMeetingInput } from 'modules/events/basics/types/physicalMeeting.types';
import { eventFragment } from 'modules/events/graphql/fragments/event.fragments';
import { physicalEventDataFragment } from 'modules/events/graphql/fragments/physicalEventData.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const publishPhysicalMeetingMutationCall = gql`
  mutation publishPhysicalMeetingMutation($physicalEventInput: PhysicalEventInput!) {
    publishPhysicalEvent(physicalEventInput: $physicalEventInput) {
      ${eventFragment}
      source
      data {
        ...on PhysicalEvent {
          ${physicalEventDataFragment}
        }
      }
    }
  }
`;

type ResultType = {
  publishPhysicalEvent: Event;
};

type UsePublishPhysicalMeetingMutation = [
  (physicalEventInput: PhysicalMeetingInput) => Promise<Event | undefined | null>,
  MutationResult<ResultType>,
];

export const usePublishPhysicalMeetingMutation = (): UsePublishPhysicalMeetingMutation => {
  const { t } = useTranslation();
  const [publishPhysicalMeeting, state] = useMutation<ResultType>(publishPhysicalMeetingMutationCall);
  const executeMutation = useCallback(async (physicalEventInput) => {
    try {
      const data = await publishPhysicalMeeting({
        variables: { physicalEventInput },
      });
      succesToast(t('toast_publish_event_success'));
      return data.data?.publishPhysicalEvent;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error, physicalEventInput);
        Logger.logError({
          error,
          customContext,
          transactionName: 'publishPhysicalMeeting.ts - usePublishPhysicalMeetingMutation',
        });
      }
      errorToast(t('toast_publish_event_fail'));
      return null;
    }
  }, [publishPhysicalMeeting, t]);

  return [executeMutation, state];
};
