import { FC } from 'react';
import useTestimonials from './Testimonials.hook';
import QuickForm from 'components/QuickForm';
import { FormShape, ListShape } from 'components/QuickForm/QuickForm.enums';
import { ListConfig, TextFieldBasis } from 'components/QuickForm/QuickForm.types';
import { Testimonial } from 'modules/events/basics/types/eventData.types';
import { YodaFieldValidation } from 'yoda-ui/yodaForm/yodaForm.types';

type TestimonialsProps = {
  name: string;
  testimonialFormConfig: {
    title: string;
    authorField: TextFieldBasis;
    testimonyField: TextFieldBasis & { multiline: boolean; rows: number };
  };
  defaultValue?: Testimonial[];
  listConfig: ListConfig;
  validation: YodaFieldValidation;
  disabled: boolean;
};

const Testimonials: FC<TestimonialsProps> = ({
  name,
  testimonialFormConfig,
  listConfig,
  defaultValue = [],
  disabled = false,
}) => {
  const { formConfig, transformToCard } = useTestimonials({ testimonialFormConfig });

  return (
    <QuickForm
      name={ name }
      form={ { shape: FormShape.form2FieldsColumn, formConfig } }
      list={ { shape: ListShape.listColumn, listConfig } }
      transformToCard={ transformToCard }
      defaultValue={ defaultValue }
      disabled={ disabled }
    />
  );
};

export default Testimonials;
