import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAddProgramConfig from 'modules/events/components/AddProgram/AddProgram.config';
import { useCreateProgramMutation } from 'modules/events/graphql/mutations/createProgram';
import eventState from 'modules/events/states/events.states';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';
import { useYodaCreateForm } from 'yoda-ui/yodaForm';

const useAddProgramModal = (setOpen: Dispatch<SetStateAction<boolean>>) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { addProgramConfig } = useAddProgramConfig();
  const { t } = useTranslation();
  const { providerFields, getValues, resetForm, useWatchForm } = useYodaCreateForm();
  const { isValid } = useWatchForm();
  const [createProgram] = useCreateProgramMutation();

  const handleAddProgram = async () => {
    setIsSubmitting(true);
    const formValues = getValues();
    const programData = await createProgram({ id: formValues.programId, programName: formValues.programName });
    setIsSubmitting(false);

    if (programData) {
      eventState.programs.data.push({ ...programData });
      resetForm();
      setOpen(false);
    }
  };

  const cancelButtonConfig = {
    buttonProps: {
      onClick: () => setOpen(false),
      buttonType: ButtonType.secondary,
    },
    label: t('actions_cancel_label'),
  };

  const submitButtonConfig = {
    buttonProps: {
      onClick: handleAddProgram,
      disabled: !isValid,
      buttonType: ButtonType.primary,
    },
    label: t('events_add_program_form_submit_label'),
    loading: isSubmitting,
  };

  return {
    addProgramConfig,
    cancelButtonConfig,
    providerFields,
    submitButtonConfig,
    handleAddProgram,
    t,
  };
};

export default useAddProgramModal;
