import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useRegisterParticipantGroupSchema from './RegisterParticipantGroup.schema';
import { buildTimeZoneOptions } from 'basics/options/timezone.options';
import { Presence } from 'generated/event.types';
import { RegisterParticipantGroupConfigType } from 'modules/events/groups/RegisterParticipantGroup/RegisterParticipantGroup.types';

const useRegisterParticipantGroupConfig = () => {
  const { t } = useTranslation();
  const timezoneOptions = buildTimeZoneOptions();
  const [isPrivilegedParticipant, setIsPrivilegedParticipant] = useState(false);
  const [isPriviligedParticipantDisabled, setIsPriviligedParticipantDisabled] = useState(false);
  const joiningChannelOptions = [
    { id: Presence.online, label: Presence.online },
    { id: Presence.physical, label: Presence.physical },
  ];
  const registerParticipantSchema = useRegisterParticipantGroupSchema();
  const registerParticipantGroup: RegisterParticipantGroupConfigType = {
    participantMdmid: {
      label: t('event_register_participant_mdmid_label'),
      name: 'participantMdmid',
      placeholder: t('event_register_participant_mdmid_placeholder'),
      required: true,
      validation: registerParticipantSchema.participantMdmid,
    },
    participantTimezone: {
      label: t('event_register_participant_timezone_label'),
      name: 'participantTimezone',
      placeholder: t('event_register_participant_timezone_placeholder'),
      options: timezoneOptions,
      required: true,
      defaultValue: '',
      validation: registerParticipantSchema.participantTimezone,
    },
    participantMobilePhoneNumber: {
      label: t('event_register_participant_mobile_phone_number_label'),
      name: 'participantMobilePhoneNumber',
      placeholder: t('event_register_participant_mobile_phone_number_placeholder'),
      required: false,
      defaultValue: '',
      validation: registerParticipantSchema.participantMobilePhoneNumber,
    },
    participantJoiningChannel: {
      label: t('event_register_participant_joining_channel_label'),
      name: 'participantJoiningChannel',
      placeholder: t('event_register_participant_joining_channel_placeholder'),
      options: joiningChannelOptions,
      required: true,
      defaultValue: '',
      validation: registerParticipantSchema.participantJoiningChannel,
    },
    isPrivilegedParticipant: {
      label: t('event_register_participant_is_priviliged_label'),
      name: 'isPrivilegedParticipant',
      checked: isPrivilegedParticipant,
      disabled: isPriviligedParticipantDisabled,
    },
  };

  return {
    registerParticipantGroup,
    setIsPrivilegedParticipant,
    setIsPriviligedParticipantDisabled,
  };
};

export default useRegisterParticipantGroupConfig;
