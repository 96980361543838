/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, FC, ReactNode, SetStateAction, createContext, useState } from 'react';
import { DateRange } from 'yoda-ui/components/Form/DateRangePicker/DateRangePicker.types';

type EventDateProviderProps = {
  children: ReactNode;
};

type EventDateContextType = {
  date: DateRange;
  setDate: Dispatch<SetStateAction<DateRange>>;
};

const initialState = {
  date: [null, null] as DateRange,
  setDate: () => {},
};

export const EventDateContext = createContext<EventDateContextType>(initialState);

export const EventDateProvider: FC<EventDateProviderProps> = ({ children }) => {
  const [date, setDate] = useState<DateRange>([null, null]);

  const contextValue = {
    date,
    setDate,
  };

  return (
    <EventDateContext.Provider value={ contextValue }>
      { children }
    </EventDateContext.Provider>
  );
};
