import { useTranslation } from 'react-i18next';
import { string } from 'yup';

const useRestrictedAccessSchema = () => {
  const { t } = useTranslation();
  const maxDescriptionLength = 4000;
  const maxLengthDescriptionMessage = `
    ${t('events_restrictedDescription_input_max_length_error_message', { count: maxDescriptionLength })}
  `;

  return {
    restrictedDescription: string()
      .nullable(true)
      .max(maxDescriptionLength, maxLengthDescriptionMessage),
  };
};

export default useRestrictedAccessSchema;
