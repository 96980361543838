import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { succesToast, errorToast } from 'basics/utils/toast';
import { TestUserInput } from 'generated/event.types';
import { Event } from 'modules/events/basics/types/events.types';
import { eventFragment } from 'modules/events/graphql/fragments/event.fragments';
import { hybridEventDataFragment } from 'modules/events/graphql/fragments/hybridEventData.fragments';
import { onlineEventDataFragment } from 'modules/events/graphql/fragments/onlineEventData.fragments';
import { physicalEventDataFragment } from 'modules/events/graphql/fragments/physicalEventData.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const testEventMutationCall = gql`
  mutation testEventMutation($eventId: String!, $selectedTestUser: TestUserInput!) {
    testEvent(eventId: $eventId, testUser: $selectedTestUser) {
      ${eventFragment}
      source
      data {
        ...on OnlineEvent {
          ${onlineEventDataFragment}
        }
        ...on PhysicalEvent {
          ${physicalEventDataFragment}
        }
        ...on HybridEvent {
          ${hybridEventDataFragment}
        }
      }
    }
  }
`;

type ResultType = {
  testedOnlineEvent: Event;
};

type UseTestEventMutation = [
  (eventId: string, selectedTestUser: TestUserInput) => Promise<Event | undefined | null>,
  MutationResult<ResultType>,
];

export const useTestEventMutation = (): UseTestEventMutation => {
  const { t } = useTranslation();
  const [testEvent, state] = useMutation<ResultType>(testEventMutationCall);
  const executeMutation = useCallback(async (eventId, selectedTestUser) => {
    try {
      const data = await testEvent({
        variables: { eventId, selectedTestUser },
      });
      succesToast(t('toast_test_event_success'));
      return data.data?.testedOnlineEvent;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error, eventId);
        Logger.logError({
          error,
          customContext,
          transactionName: 'testEvent.ts - useTestEventMutation',
        });
      }
      errorToast(t('toast_test_event_fail'));
      return null;
    }
  }, [testEvent, t]);

  return [executeMutation, state];
};
