import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { eventsRoute } from 'basics/constants/routes.constants';
import useStepIndexer from 'hooks/useStepIndexer';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { Event } from 'modules/events/basics/types/events.types';
import { usePublishHybridMeetingMutation } from 'modules/events/graphql/mutations/publishHybridMeeting';
import { useUpdateHybridMeetingMutation } from 'modules/events/graphql/mutations/updateHybridMeeting';
import { createHybridMeetingStepIndexer } from 'modules/events/routes/CreateHybridMeeting/CreateHybridMeeting.states';
import { hybridMeetingInputTransformer }
  from 'modules/events/routes/CreateHybridMeeting/CreateHybridMeeting.transformers';
import {
  CreateHybridMeetingStepsIndex,
  CreateHybridMeetingWithEmailConfigurationStepsIndex,
} from 'modules/events/routes/CreateHybridMeeting/CreateHybridMeeting.types';
import eventState from 'modules/events/states/events.states';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useCreateHybridMeetingActions = (yodaFormMethods: any) => {
  const navigate = useNavigate();
  const stepIndexer = useStepIndexer(createHybridMeetingStepIndexer);
  const { stepIndex, moveNextStep, movePreviousStep } = stepIndexer;
  const { getValues, getDirtyValues, formState, setStepShowError, setFormIsDirty } = yodaFormMethods;
  const [publishHybridMeeting, { loading: publishLoading }] = usePublishHybridMeetingMutation();
  const [updateHybridMeeting, { loading: updateLoading }] = useUpdateHybridMeetingMutation();
  const flags = useFlags();

  const getHybridMeetingFormData = useCallback(() => {
    const formValues = getValues();
    const dataToSave = getDirtyValues();
    return {
      hybridMeetingInput: hybridMeetingInputTransformer(dataToSave, formValues),
      hybridMeetingFormat: dataToSave[commonFields.eventFormat] ?? null,
    };
  }, [getDirtyValues, getValues]);

  const updateEventState = useCallback((updatedEvent?: Event | null) => {
    if (updatedEvent) {
      eventState.createEvent.updatedAt = updatedEvent.updatedAt;
      eventState.createEvent.status = updatedEvent.status;
    }
  }, []);

  const handleSaveData = useCallback(async () => {
    if (formState.isDirty) {
      const hybridMeetingFormData = getHybridMeetingFormData();
      if (hybridMeetingFormData.hybridMeetingInput) {
        const updatedMeeting = await updateHybridMeeting(
          hybridMeetingFormData.hybridMeetingInput,
          hybridMeetingFormData.hybridMeetingFormat,
        );
        setFormIsDirty(false);
        updateEventState(updatedMeeting);
      }
    }
  }, [formState.isDirty, getHybridMeetingFormData, updateHybridMeeting, setFormIsDirty, updateEventState]);

  const handlePublishEvent = useCallback(async () => {
    if (formState.isValid) {
      const hybridMeetingFormData = getHybridMeetingFormData();
      if (hybridMeetingFormData.hybridMeetingInput) {
        const publishedMeeting = await publishHybridMeeting(hybridMeetingFormData.hybridMeetingInput);
        if (publishedMeeting) {
          updateEventState(publishedMeeting);
          navigate(eventsRoute);
        }
      }
    }
  }, [formState.isValid, getHybridMeetingFormData, navigate, publishHybridMeeting, updateEventState]);

  const setShowError = useCallback((stepIndexVal: number) => {
    const step = flags.eventsBiogenlinc4151SectionEmail
      ? CreateHybridMeetingWithEmailConfigurationStepsIndex[stepIndexVal] : CreateHybridMeetingStepsIndex[stepIndexVal];
    setStepShowError(true, [step]);
  }, [flags.eventsBiogenlinc4151SectionEmail, setStepShowError]);

  const handleChangeStep = useCallback((stepIndexVal: number) => {
    const previousStep = stepIndexer.previousStep || 0;
    stepIndexer.moveToStep(stepIndexVal);
    handleSaveData();
    setShowError(previousStep);
  }, [handleSaveData, setShowError, stepIndexer]);

  const saveAndNext = useCallback(async () => {
    await handleSaveData();
    setShowError(stepIndex);
    moveNextStep();
  }, [handleSaveData, moveNextStep, setShowError, stepIndex]);

  const saveAndPrevious = useCallback(async () => {
    await handleSaveData();
    setShowError(stepIndex);
    movePreviousStep();
  }, [handleSaveData, movePreviousStep, setShowError, stepIndex]);

  return {
    handleChangeStep,
    handlePublishEvent,
    handleSaveData,
    setShowError,
    publishLoading,
    updateLoading,
    stepIndexer,
    saveAndNext,
    saveAndPrevious,
  };
};

export default useCreateHybridMeetingActions;
