import { Dispatch, FC, SetStateAction } from 'react';
import DefaultDialogTitle from 'components/Dialogs/DefaultDialogTitle';
import Box from 'yoda-ui/components/Box';
import Button from 'yoda-ui/components/Button';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';
import Dialog from 'yoda-ui/components/Dialogs/Dialog';
import DialogContent from 'yoda-ui/components/Dialogs/Dialog/DialogContent';
import { YodaBorderRadius, YodaFontWeight, YodaJustifyContent, YodaSpacing } from 'yoda-ui/yodaTheme';

type InfoModalProps = {
  infoModalIsOpen: boolean;
  setInfoModalIsOpen: Dispatch<SetStateAction<boolean>>;
  message: string;
  buttonLabel: string;
};

const InfoModal: FC<InfoModalProps> = ({ infoModalIsOpen, setInfoModalIsOpen, message, buttonLabel }) => {
  return (
    <Dialog
      open={ infoModalIsOpen }
      borderRadius={ YodaBorderRadius.medium }
      sx={ { padding: YodaSpacing.xxLarge } }
      minWidth='30%'
    >
      <DefaultDialogTitle
        cancelDialog={ () => setInfoModalIsOpen(false) }
      />
      <Box marginBottom={ YodaSpacing.xxxxLarge } justifyItems={ YodaJustifyContent.center }>
        <DialogContent fontWeight={ YodaFontWeight.medium }>
          { message }
        </DialogContent>
        <Box paddingTop={ YodaSpacing.large }>
          <Button buttonType={ ButtonType.primary } onClick={ () => setInfoModalIsOpen(false) }>
            { buttonLabel }
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default InfoModal;
