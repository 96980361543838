import { Box } from '@mui/material';
import { FC } from 'react';
import useUserGroup from './UserGroup.hook';
import MultiSelectYoda from 'yoda-ui/components/Form/SelectYoda/MultiSelectYoda';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const UserGroup: FC = () => {
  const { userGroupConfig } = useUserGroup();
  return (

    <Box paddingBottom={ YodaSpacing.small }>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        marginBottom={ YodaSpacing.small }
      >
      </Box>
      <Box>
        <Box marginBottom={ YodaSpacing.small }>
          <MultiSelectYoda { ...userGroupConfig.userGroups }></MultiSelectYoda>
        </Box>
      </Box>
    </Box>
  );
};
export default UserGroup;
