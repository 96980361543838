import { FC } from 'react';
import CardHeader from 'components/CardHeader';
import Box from 'yoda-ui/components/Box';
import Paper from 'yoda-ui/components/Paper';
import { YodaBorderRadius, YodaColors, YodaSpacing } from 'yoda-ui/yodaTheme';

type CardFormProps = {
  title: string;
  tooltipTitle?: string;
  tooltipImageUrl?: string;
};

const CardForm: FC<CardFormProps> = ({ children, title, tooltipTitle, tooltipImageUrl }) => (
  <Paper margin={ 0 } borderRadius={ YodaBorderRadius.medium }>
    <Box>
      <CardHeader title={ title } tooltipTitle={ tooltipTitle } tooltipImageUrl={ tooltipImageUrl }/>
      <Box
        style={ { borderTop: `1px solid ${YodaColors.gray1}` } }
        paddingX={ YodaSpacing.large }
        paddingY={ YodaSpacing.medium }
      >
        { children }
      </Box>
    </Box>
  </Paper>
);

export default CardForm;
