import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { Session } from 'modules/events/basics/types/eventData.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const bookSlotMutationCall = gql`
    mutation bookSlot($eventId: String!, $session: SessionInput!) {
        bookSlot(eventId: $eventId, session: $session)
    }`;

type ResultType = {
  bookSlot: boolean;
};

type UseBookSlotMutation = [
  (eventId: string, session: Session) => Promise<boolean | null>,
  MutationResult<ResultType>,
];

export const useBookSlotMutation = (): UseBookSlotMutation => {
  const [bookSlot, state] = useMutation<ResultType>(bookSlotMutationCall);

  const executeMutation = useCallback(async (eventId, session) => {
    try {
      const data = await bookSlot({
        variables: { eventId, session },
      });
      return data.data?.bookSlot || false;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error, eventId);
        Logger.logError({
          error,
          customContext,
          transactionName: 'bookSlot.ts - useCreateEventMutation',
        });
      }
      return null;
    }
  }, [bookSlot]);

  return [executeMutation, state];
};
