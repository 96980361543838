import { Dispatch, FC, ReactNode, SetStateAction, createContext, useState } from 'react';

export enum EventStatusFilterType {
  draft = 'draft',
  published = 'published',
  all = 'all',
}

type EventStatusProviderProps = {
  children: ReactNode;
};

type EventStatusContextType = {
  eventStatus: EventStatusFilterType;
  setEventStatus: Dispatch<SetStateAction<EventStatusFilterType>>;
};

const initialState = {
  eventStatus: EventStatusFilterType.all,
  setEventStatus: () => {},
};

export const EventStatusContext = createContext<EventStatusContextType>(initialState);

export const EventStatusProvider: FC<EventStatusProviderProps> = ({ children }) => {
  const [eventStatus, setEventStatus] = useState<EventStatusFilterType>(EventStatusFilterType.all);

  const contextValue = {
    eventStatus,
    setEventStatus,
  };

  return (
    <EventStatusContext.Provider value={ contextValue }>
      { children }
    </EventStatusContext.Provider>
  );
};
