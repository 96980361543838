import { getConfigVar } from 'config/config';
import { Market, Participant } from 'generated/event.types';
import { previewDomainLinks } from 'modules/events/basics/constants/event.constants';
import { testUserList } from 'modules/events/basics/constants/eventTesting.constants';
import { TestUser } from 'modules/events/basics/types/eventTesting.types';
import { prettifyEventUrl } from 'modules/events/basics/utils/events.utils';

export const callGenerateVipLinkAPI = async (selectedTestUser: TestUser, eventTitle: string, eventId: string, market: Market) => {
  const apiUrl = `${getConfigVar('REACT_APP_AKAMAI_MIDDLEWARE_URI')}/akamai-proxy/generate-vip-link`;

  const prettyEventTitle = prettifyEventUrl(`${eventTitle}_${eventId}`);
  const previewLink = new URL(`${previewDomainLinks[market as keyof typeof previewDomainLinks]}${prettyEventTitle}`);
  previewLink.searchParams.append('fastAccess', 'bl2022');

  const requestBody = {
    mid: selectedTestUser.mdmid,
    email: selectedTestUser.email,
    redirect_uri: previewLink.toString(),
    ui_locales: '',
    country_code: market === Market.com ? 'US' : market.toUpperCase(),
  };

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch VIP link');
    }

    const responseData = await response.json();

    return responseData.data;
  } catch (error) {
    throw new Error('Error during fetching VIP link');
  }
};

const prepareInternalVipLink = async (selectedTestUser: TestUser, eventTitle: string, eventId: string, market: Market) => {
  const prettyEventTitle = prettifyEventUrl(`${eventTitle}_${eventId}`);
  const previewLink = new URL(`${previewDomainLinks[market as keyof typeof previewDomainLinks]}${prettyEventTitle}`);
  previewLink.searchParams.append('fastAccess', 'bl2022');

  return `${selectedTestUser.vipLink}${previewLink.toString()}`;
};

export const getE2EVipLink = async (
  selectedTestUser: TestUser, eventTitle: string, eventId: string, market: Market, externalFetch: boolean,
) => {
  const vipLink = externalFetch
    ? await callGenerateVipLinkAPI(selectedTestUser, eventTitle, eventId, market)
    : await prepareInternalVipLink(selectedTestUser, eventTitle, eventId, market);

  return `${vipLink}`;
};

export const isTestUser = (participant: Participant) => {
  return !!testUserList.find(
    (user) => user.email === participant.email || user.mdmid === participant.mdmId,
  );
};

export const filterTestUsers = (participants?: Participant[]) => {
  if (!participants) {
    return [];
  }
  return participants.filter((participant) => isTestUser(participant));
};
