import useEditOnlineSideNav from './EditOnlineSideNav.hook';
import SecondaryNav from 'components/SecondaryNav';

const EditOnlineSideNav = () => {
  const { sideNavBackConfig, sideNavConfigs } = useEditOnlineSideNav();

  return (
    <SecondaryNav
      goBackOnClick={ sideNavBackConfig.onClick }
      goBackText={ sideNavBackConfig.text }
      items={ sideNavConfigs }
    />
  );
};

export default EditOnlineSideNav;
