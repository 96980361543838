import { SyntheticEvent, useContext } from 'react';
import { SelectOption } from 'basics/options/options.types';
import { CurrentUser } from 'basics/types/user.types';
import { CurrentUserStateContext } from 'contexts/user';
import { Market } from 'generated/event.types';
import { useCountrySelectorConfig } from 'modules/events/components/CountrySelector/CountrySelector.config';
import { useCreateOrUpdateEventsUserDataMutation } from 'modules/events/graphql/mutations/createOrUpdateEventsUserData';

export const useCountrySelector = (user?: CurrentUser) => {
  const { setCurrentUserData } = useContext(CurrentUserStateContext);
  const market = user?.market || null;
  const { countrySelectorConfig } = useCountrySelectorConfig(market);
  const [createOrUpdateEventsUserData] = useCreateOrUpdateEventsUserDataMutation();

  const handleCountryChange = (event: SyntheticEvent<Element, Event>, value: SelectOption) => {
    if (value?.id) {
      createOrUpdateEventsUserData({ market: value.id as Market });

      setCurrentUserData((prevState) => {
        return {
          ...prevState,
          currentUser: {
            ...prevState.currentUser,
            market: value.id,
          } as CurrentUser,
        };
      });
    }
  };

  return {
    countrySelectorConfig,
    handleCountryChange,
  };
};
