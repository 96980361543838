import { FC, useState } from 'react';
import FormMapper from './FormsBucket/FormMapper';
import List from './List';
import ListDragAndDrop from './ListDragAndDrop/ListDragAndDrop';
import useQuickForm from './QuickForm.hook';
import { QuickFormProps } from './QuickForm.types';
import { Participant } from 'generated/event.types';
import Box from 'yoda-ui/components/Box';
import { YodaFieldValue } from 'yoda-ui/yodaForm/yodaForm.types';

const QuickForm: FC<QuickFormProps> = ({
  name,
  form,
  handleRemoveItemAsync,
  handleEditItem,
  list,
  transformToCard,
  transformToState = (item: YodaFieldValue) => item,
  defaultValue,
  validation,
  disabled = false,
}) => {
  const { fieldErrorMessage, fieldState, setFieldState, handleRemoveItem } = useQuickForm({
    name,
    validation,
    defaultValue,
  });

  const [listLoading, setListLoading] = useState(false);

  // Allow to do some async logic before modifying the state
  const computedHandleRemoveItem = async (id: string) => {
    if (handleRemoveItemAsync) {
      setListLoading(true);
      const updatedItemsList = await handleRemoveItemAsync(id);
      setFieldState(updatedItemsList ? updatedItemsList as Participant[] : fieldState);
      setListLoading(false);
    } else {
      handleRemoveItem(id);
    }
  };

  return (
    <>
      <FormMapper
        form={ form }
        setFieldState={ setFieldState }
        setListLoading={ setListLoading }
        fieldState={ fieldState }
        transformToState={ transformToState }
        disabled={ disabled }
      />
      {
        list && transformToCard && list.dragAndDrop && (<ListDragAndDrop
          list={ list }
          loading={ listLoading }
          setFieldState={ setFieldState }
          fieldState={ fieldState }
          handleRemoveItem={ computedHandleRemoveItem }
          handleEditItem={ handleEditItem }
          transformToCard={ transformToCard }
          disabled={ disabled }
        />)
      }
      {
        list && transformToCard && !list.dragAndDrop && (<List
          list={ list }
          loading={ listLoading }
          setFieldState={ setFieldState }
          fieldState={ fieldState }
          handleRemoveItem={ computedHandleRemoveItem }
          transformToCard={ transformToCard }
          disabled={ disabled }
        />)
      }
      {
        fieldErrorMessage && (
          <Box>{ fieldErrorMessage }</Box>
        )
      }
    </>
  );
};

export default QuickForm;
