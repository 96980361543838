import { CommonGroups, CommonSteps } from 'modules/events/basics/enums/commonForms.enums';

export const commonFields = {
  collectParticipantPhoneNumber: `${CommonSteps.detailsStep}.${CommonGroups.optionalInformations}.collectParticipantPhoneNumber`,
  collectPhoneNumberMandatory: `${CommonSteps.detailsStep}.${CommonGroups.optionalInformations}.collectPhoneNumberMandatory`,
  collectPhoneNumberUserConsent: `${CommonSteps.detailsStep}.${CommonGroups.optionalInformations}.collectPhoneNumberUserConsent`,
  coorganizers: `${CommonSteps.detailsStep}.${CommonGroups.coorganizers}.coOrganizers`,
  hostProvider: `${CommonSteps.detailsStep}.${CommonGroups.timeSlot}.hostProvider`,
  hostProviderLink: `${CommonSteps.detailsStep}.${CommonGroups.timeSlot}.hostProviderLink`,
  eventFormat: `${CommonSteps.detailsStep}.${CommonGroups.timeSlot}.eventFormat`,
  locale: `${CommonSteps.detailsStep}.${CommonGroups.timeSlot}.locale`,
  session: `${CommonSteps.detailsStep}.${CommonGroups.timeSlot}.session`,
  enableZoomStreaming: `${CommonSteps.detailsStep}.${CommonGroups.timeSlot}.enableZoomStreaming`,
  registrationEndDate: `${CommonSteps.detailsStep}.${CommonGroups.timeSlot}.registrationEndDate`,
  sessionInformation: `${CommonSteps.detailsStep}.${CommonGroups.sessionInformation}.session`,
  sessionEventFormat: `${CommonSteps.detailsStep}.${CommonGroups.sessionInformation}.eventFormat`,
  sessionLocale: `${CommonSteps.detailsStep}.${CommonGroups.sessionInformation}.locale`,
  sessionLocation: `${CommonSteps.detailsStep}.${CommonGroups.sessionInformation}.location`,
  sessionSeats: `${CommonSteps.detailsStep}.${CommonGroups.sessionInformation}.seats`,
  sessionDateRange: `${CommonSteps.detailsStep}.${CommonGroups.sessionInformation}.dateRange`,
  sessionTimezone: `${CommonSteps.detailsStep}.${CommonGroups.sessionInformation}.timeZone`,
  sessionStartTime: `${CommonSteps.detailsStep}.${CommonGroups.sessionInformation}.startTime`,
  sessionEndTime: `${CommonSteps.detailsStep}.${CommonGroups.sessionInformation}.endTime`,
  userGroups: `${CommonSteps.detailsStep}.${CommonGroups.userGroup}.userGroups`,
  title: `${CommonSteps.detailsStep}.${CommonGroups.primaryInformations}.title`,
  description: `${CommonSteps.detailsStep}.${CommonGroups.primaryInformations}.description`,
  cardImage: `${CommonSteps.detailsStep}.${CommonGroups.primaryInformations}.cardImage`,
  product: `${CommonSteps.detailsStep}.${CommonGroups.primaryInformations}.product`,
  program: `${CommonSteps.detailsStep}.${CommonGroups.primaryInformations}.program`,
  speakerSupportPhoneNumber: `${CommonSteps.detailsStep}.${CommonGroups.speakers}.speakerSupportPhoneNumber`,
  speakers: `${CommonSteps.detailsStep}.${CommonGroups.speakers}.speakers`,
  teaser: `${CommonSteps.detailsStep}.${CommonGroups.optionalInformations}.teaser`,
  testimonials: `${CommonSteps.detailsStep}.${CommonGroups.optionalInformations}.testimonials`,
  disclaimerText: `${CommonSteps.detailsStep}.${CommonGroups.optionalInformations}.disclaimerText`,
  footerDisclaimerText: `${CommonSteps.detailsStep}.${CommonGroups.optionalInformations}.footerDisclaimerText`,
  productInformationButtonLabel: `${CommonSteps.detailsStep}.${CommonGroups.optionalInformations}.productInformationButtonLabel`,
  productInformationButtonLink: `${CommonSteps.detailsStep}.${CommonGroups.optionalInformations}.productInformationButtonLink`,
  recordingOptinLabel: `${CommonSteps.detailsStep}.${CommonGroups.optionalInformations}.recordingOptinLabel`,
  participantsSupportPhoneNumber: `${CommonSteps.detailsStep}.${CommonGroups.optionalInformations}.participantsSupportPhoneNumber`,
  agenda: `${CommonSteps.detailsStep}.${CommonGroups.agenda}.agenda`,
  agendaFile: `${CommonSteps.detailsStep}.${CommonGroups.agenda}.agendaFile`,
  agendaFileTitle: `${CommonSteps.detailsStep}.${CommonGroups.agenda}.agendaFileTitle`,
  agendaFileDescription: `${CommonSteps.detailsStep}.${CommonGroups.agenda}.agendaFileDescription`,
  isRestrictedAccess: `${CommonSteps.detailsStep}.${CommonGroups.restrictedAccess}.isRestrictedAccess`,
  restrictedDescription: `${CommonSteps.detailsStep}.${CommonGroups.restrictedAccess}.restrictedDescription`,
  isVisibleOnBiogenLinc: `${CommonSteps.communicationStep}.${CommonGroups.biogenLincConfig}.isVisibleOnBiogenLinc`,
  isSwitzerlandSpecific: `${CommonSteps.communicationStep}.${CommonGroups.biogenLincConfig}.isSwitzerlandSpecific`,
  isPublic: `${CommonSteps.communicationStep}.${CommonGroups.biogenLincConfig}.isPublic`,
  enableGuestRegistration: `${CommonSteps.communicationStep}.${CommonGroups.biogenLincConfig}.enableGuestRegistration`,
  enableGuestRegistrationCountryDropdown:
    `${CommonSteps.communicationStep}.${CommonGroups.biogenLincConfig}.enableGuestRegistrationCountryDropdown`,
  enableDeclineModal: `${CommonSteps.communicationStep}.${CommonGroups.biogenLincConfig}.enableDeclineModal`,
  checkConsent: `${CommonSteps.communicationStep}.${CommonGroups.biogenLincConfig}.checkConsent`,
  prcCodeEventDetails: `${CommonSteps.communicationStep}.${CommonGroups.biogenLincConfig}.prcCodeEventDetails`,
  showSendInvitations: `${CommonSteps.communicationStep}.${CommonGroups.biogenLincConfig}.showSendInvitations`,
  participantsWishList: `${CommonSteps.communicationStep}.${CommonGroups.invitees}.participantsWishList`,
  emailHeaderNote: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.emailHeaderNote`,
  emailHeaderNoteEmailTypes: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.emailHeaderNoteEmailTypes`,
  emailFooterNote: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.emailFooterNote`,
  emailFooterNoteEmailTypes: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.emailFooterNoteEmailTypes`,
  emailFooterDisclaimerText: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.emailFooterDisclaimerText`,
  emailContentfulUrl: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.emailContentfulUrl`,
  biogenLogo: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.biogenLogo`,
  buttonColor: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.buttonColor`,
  programLogo: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.programLogo`,
  emailPrcCode: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.emailPrcCode`,
  CEPSearch: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.CEPSearch`,
  cxSurveyActivation: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.cxSurveyActivation`,
  cxSurveyId: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.cxSurveyId`,
  cxSurveyUseCaseName: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.cxSurveyUseCaseName`,
  cxSurveyUseCaseCategory: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.cxSurveyUseCaseCategory`,
  physicalCxSurveyActivation: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.physicalCxSurveyActivation`,
  physicalCxSurveyId: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.physicalCxSurveyId`,
  physicalCxSurveyUseCaseName: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.physicalCxSurveyUseCaseName`,
  physicalCxSurveyUseCaseCategory: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.physicalCxSurveyUseCaseCategory`,
  invitationEmailMayaId: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.invitationEmailMayaId`,
  invitationEmailUTM: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.invitationEmailUTM`,
  confirmationEmailMayaId: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.confirmationEmailMayaId`,
  confirmationEmailUTM: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.confirmationEmailUTM`,
  reminder48hMayaId: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.reminder48hMayaId`,
  reminder48hUTM: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.reminder48hUTM`,
  reminder24hMayaId: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.reminder24hMayaId`,
  reminder24hUTM: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.reminder24hUTM`,
  reminder12hMayaId: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.reminder12hMayaId`,
  reminder12hUTM: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.reminder12hUTM`,
  reminder1hMayaId: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.reminder1hMayaId`,
  reminder1hUTM: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.reminder1hUTM`,
  cxSurveyMayaId: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.cxSurveyMayaId`,
  cxSurveyUTM: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.cxSurveyUTM`,
  teamContactCheckbox: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.teamContactCheckbox`,
  teamContactEmail: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.teamContactEmail`,
  teamContactDescription: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.teamContactDescription`,
  reminderNotificationEmails: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.reminderNotificationEmails`,
  reminderNotificationPhoneNumbers: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.reminderNotificationPhoneNumbers`,
  testUserList: `${CommonSteps.publicationStep}.${CommonGroups.testing}.testUserList`,
  showNavigationBarActions: `${CommonSteps.detailsStep}.${CommonGroups.navigationConfig}.showNavigationBarActions`,
  showNavigationBarElements: `${CommonSteps.detailsStep}.${CommonGroups.navigationConfig}.showNavigationBarElements`,
  showFooterNavItems: `${CommonSteps.detailsStep}.${CommonGroups.navigationConfig}.showFooterNavItems`,
};
