import { FC } from 'react';
import QuickForm from 'components/QuickForm';
import { FormShape, ListShape } from 'components/QuickForm/QuickForm.enums';
import useAgenda from 'modules/events/components/Agenda/Agenda.hook';
import { AgendaConfigType } from 'modules/events/components/Agenda/Agenda.types';

const Agenda: FC<AgendaConfigType> = ({ defaultValue, formConfig, listConfig, name, disabled }) => {
  const { transformToCard, transformToState } = useAgenda();

  return (
    <QuickForm
      defaultValue={ defaultValue }
      form={ { shape: FormShape.formAgenda, formConfig } }
      list={ { shape: ListShape.listColumn, listConfig } }
      name={ name }
      transformToCard={ transformToCard }
      transformToState={ transformToState }
      disabled={ disabled }
    />
  );
};

export default Agenda;
