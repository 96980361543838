import { TestUser } from 'modules/events/basics/types/eventTesting.types';

/* eslint-disable max-len */
export const testUserList: TestUser[] = [
  {
    email: 'biibtest1_eboe2ej@mailinator.com',
    mdmid: '943785098278734879',
    firstName: 'Biib',
    lastName: 'Test',
    vipLink: 'https://app.biogenlinc.eu/autologin?mid=943785098278734879&biib_asset_code=other-fr-biogenlinc&ui_locales=fr-FR&country_code=FR&email=biibtest1_eboe2ej@mailinator.com&utm_medium=email&utm_source=approved-email&utm_campaign=FR-standard-vip&redirect_uri=',
  },
  {
    email: 'biibtest2_eboe2ej@mailinator.com',
    mdmid: '943785087499570207',
    firstName: 'Biib',
    lastName: 'Test',
    vipLink: 'https://app.biogenlinc.eu/autologin?mid=943785087499570207&biib_asset_code=other-fr-biogenlinc&ui_locales=fr-FR&country_code=FR&email=biibtest2_eboe2ej@mailinator.com&utm_medium=email&utm_source=approved-email&utm_campaign=FR-standard-vip&redirect_uri=',
  },
  {
    email: 'biibtest3_eboe2ej@mailinator.com',
    mdmid: '943785089740770335',
    firstName: 'Biib',
    lastName: 'Test',
    vipLink: 'https://app.biogenlinc.eu/autologin?mid=943785089740770335&biib_asset_code=other-fr-biogenlinc&ui_locales=fr-FR&country_code=FR&email=biibtest3_eboe2ej@mailinator.com&utm_medium=email&utm_source=approved-email&utm_campaign=FR-standard-vip&redirect_uri=',
  },
  {
    email: 'biibtest4_eboe2ej@mailinator.com',
    mdmid: '943789578352987167',
    firstName: 'Biib',
    lastName: 'Test',
    vipLink: 'https://app.biogenlinc.eu/autologin?mid=943789578352987167&biib_asset_code=other-fr-biogenlinc&ui_locales=fr-FR&country_code=FR&email=biibtest4_eboe2ej@mailinator.com&utm_medium=email&utm_source=approved-email&utm_campaign=FR-standard-vip&redirect_uri=',
  },
  {
    email: 'biibtest5_eboe2ej@mailinator.com',
    mdmid: '943784742617943071',
    firstName: 'Biib',
    lastName: 'Test',
    vipLink: 'https://app.biogenlinc.eu/autologin?mid=943784742617943071&biib_asset_code=other-fr-biogenlinc&ui_locales=fr-FR&country_code=FR&email=biibtest5_eboe2ej@mailinator.com&utm_medium=email&utm_source=approved-email&utm_campaign=FR-standard-vip&redirect_uri=',
  },
];

