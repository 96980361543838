import { SelectOption } from 'basics/options/options.types';
import { PresenceOptions } from 'modules/events/basics/enums/events.enums';

const eventPresenceOptions = (enablePhysical = false, enableHybrid = false): SelectOption[] => {
  return [
    { id: PresenceOptions.all, label: 'All' },
    { id: PresenceOptions.online, label: 'Online' },
    ...(enablePhysical ? [{ id: PresenceOptions.physical, label: 'Physical' }] : []),
    ...(enableHybrid ? [{ id: PresenceOptions.hybrid, label: 'Hybrid' }] : []),
  ];
};
export default eventPresenceOptions;
