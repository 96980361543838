import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { errorToast, succesToast } from 'basics/utils/toast';
import { RegisterParticipantInput } from 'generated/event.types';
import { VeevaSyncStatus } from 'modules/events/basics/enums/events.enums';
import { Participant } from 'modules/events/basics/types/events.types';
import { participantFragment } from 'modules/events/graphql/fragments/commonData.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export type UseSyncParticipantMutationResponse = {
  syncParticipant: Participant;
};

export type UseSyncParticipantMutation = [
  (participant: RegisterParticipantInput) => Promise<Participant | null>,
  MutationResult<UseSyncParticipantMutationResponse>,
];
export const syncParticipantMutationCall = gql`
mutation syncParticipant($participant: RegisterParticipantInput!) {
    syncParticipant(participant: $participant) {
     ${participantFragment}
    }
}`;

export const useSyncParticipantMutation = (): UseSyncParticipantMutation => {
  const { t } = useTranslation();
  const [syncParticipantMutation, state] = useMutation<UseSyncParticipantMutationResponse>(syncParticipantMutationCall);

  const executeSyncParticipantMutation = useCallback(async (participant) => {
    try {
      const { data } = await syncParticipantMutation({
        variables: { participant },
      });
      if (!data?.syncParticipant) {
        errorToast(t('errors_standard'));
        return null;
      }

      switch (data?.syncParticipant?.veevaSyncStatus) {
        case VeevaSyncStatus.SYNC:
          succesToast(t('events_participant_sync_success'));
          break;
        default:
          errorToast(t('events_participant_sync_failed'));
      }

      return data?.syncParticipant;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error);
        Logger.logError({
          error,
          customContext,
          transactionName: 'syncParticipant.ts - useSyncParticipantMutation',
        });
        errorToast(t('errors_standard'));
      }
      return null;
    }
  }, [syncParticipantMutation, t]);

  return [executeSyncParticipantMutation, state];
};
