import { useTranslation } from 'react-i18next';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import useOptionalInformationsConfig from 'modules/events/groups/OptionalInformationsGroup/OptionalInformationsGroup.config';
import { useYodaForm } from 'yoda-ui/yodaForm';

const useOptionalInformations = () => {
  const { t } = useTranslation();
  const { optionalInformationsConfig } = useOptionalInformationsConfig();
  const { useWatchField } = useYodaForm();
  const isCollectParticipantPhoneNumberEnabled = useWatchField(commonFields.collectParticipantPhoneNumber);

  return {
    isCollectParticipantPhoneNumberEnabled: isCollectParticipantPhoneNumberEnabled?.value,
    optionalInformationsConfig,
    t,
  };
};

export default useOptionalInformations;
