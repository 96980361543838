import { assetFragment } from 'basics/graphql/fragments/commonData.fragments';

export const speakerFragment = `
  id: _id
  title
  firstName
  lastName
  email
  profile {
    default {
      affiliation
      specialty
    }
  }
  image {
    ${assetFragment}
  }
`;
