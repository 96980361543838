import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';

import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { EventStateType } from 'modules/events/basics/types/events.types';

import useLazyGetPossibleSpeakers from 'modules/events/graphql/queries/getPossibleSpeakers';

import useSpeakersSchema from 'modules/events/groups/SpeakersGroup/SpeakersGroup.schema';
import { SpeakersType } from 'modules/events/groups/SpeakersGroup/SpeakersGroup.types';
import eventState from 'modules/events/states/events.states';

const useSpeakersConfig = () => {
  const { t } = useTranslation();
  const speakersSchema = useSpeakersSchema();
  const snap = useSnapshot(eventState) as EventStateType;

  const speakersConfig: SpeakersType = {
    speakers: {
      defaultValue: snap.createEvent.data?.speakers || [],
      listConfig: {
        noItemLabel: t('speakers_list_empty'),
        title: t('speakers_form_list_title'),
      },
      name: commonFields.speakers,
      speakersFormConfig: {
        label: t('meeting_field_speaker_add'),
        lazyQueryHook: useLazyGetPossibleSpeakers,
        placeholder: t('speaker_search_label'),
      },
      validation: speakersSchema.speakers,
      disabled: false,
    },
    speakerSupportPhoneNumber: {
      label: t('events_speaker_support_phone_number_label'),
      name: commonFields.speakerSupportPhoneNumber,
      placeholder: t('events_speaker_support_phone_number_placeholder'),
      required: false,
      defaultValue: snap.createEvent.data?.speakerSupportPhoneNumber,
      validation: speakersSchema.speakerSupportPhoneNumber,
      disabled: false,
    },

  };

  return { speakersConfig };
};

export default useSpeakersConfig;
