import { TimeZone } from 'basics/enums/timeZoneList.enums';
import { SelectOption } from 'basics/options/options.types';
import { getUTCDate } from 'basics/utils/dates/dates.utils';
import { AgendaEntry, AssetTarget, HostProvider } from 'generated/event.types';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { EventPromotionalityEnum } from 'modules/events/basics/enums/events.enums';
import { AssetsSentToDB } from 'modules/events/basics/types/eventData.types';
import { Speaker } from 'modules/events/basics/types/speaker.types';
import { YodaFieldValue } from 'yoda-ui/yodaForm/yodaForm.types';

export const transformInputAssets = (data: YodaFieldValue) => {
  const assetsFields = [
    commonFields.cardImage,
    commonFields.programLogo,
    commonFields.teaser,
    commonFields.agendaFile,
  ];

  const initialAcc: AssetsSentToDB = {};

  const assets = assetsFields.reduce((acc, current) => {
    const target = current.split('.').at(-1) as AssetTarget;
    if (data[current]) {
      acc[target] = data[current]?.map((item: { id: string }) => item.id);
    }
    return acc;
  }, initialAcc);
  return Object.keys(assets).length ? { assets } : {};
};

export const transformInputProvider = (data: YodaFieldValue) => {
  const hostProvider = data[commonFields.hostProvider];
  const hostProviderLink = data[commonFields.hostProviderLink];

  return {
    provider: {
      host: hostProvider,
      ...(hostProvider !== HostProvider.zoom ? { participantUrl: hostProviderLink } : {}),
      ...(hostProvider !== HostProvider.zoom ? { organizerUrl: hostProviderLink } : {}),
    },
  };
};

export const transformInputSession = (data: YodaFieldValue) => {
  const dateRange = data[commonFields.sessionDateRange];
  const startTime = data[commonFields.sessionStartTime];
  const endTime = data[commonFields.sessionEndTime];
  const timeZone = data[commonFields.sessionTimezone];

  if (timeZone && endTime && startTime && dateRange) {
    const [startDate, endDate] = dateRange;
    return {
      session: {
        startDate: getUTCDate(startDate || undefined, startTime, timeZone) || new Date(),
        endDate: getUTCDate(endDate || undefined, endTime, timeZone) || new Date(),
        timeZone: TimeZone.UTC,
      },
    };
  }
  return undefined;
};

export const transformInputBiogenLincConfig = (dataToSave: YodaFieldValue, formValues: YodaFieldValue) => {
  const {
    isVisibleOnBiogenLinc,
    isPublic,
    prcCodeEventDetails,
    showSendInvitations,
    enableGuestRegistration,
    enableGuestRegistrationCountryDropdown,
    checkConsent,
    isSwitzerlandSpecific,
  } = commonFields;

  const { public: publicEnum } = EventPromotionalityEnum;
  const isVisibleOnBiogenLincCurrent = formValues[isVisibleOnBiogenLinc];
  const showSendInvitationsCurrent = formValues[showSendInvitations];
  const isSwitzerlandSpecificCurrent = formValues[isSwitzerlandSpecific];

  const isVisibleOnBiogenLincHasChanged = dataToSave[isVisibleOnBiogenLinc] !== undefined;
  const isPublisHasChanged = dataToSave[isPublic] !== undefined;
  const prcCodeEventDetailsHasChanged = dataToSave[prcCodeEventDetails] !== undefined;
  const showSendInvitationsHasChanged = dataToSave[showSendInvitations] !== undefined;
  const enableGuestRegistrationHasChanged = dataToSave[enableGuestRegistration] !== undefined;
  const enableGuestRegistrationCountryDropdownHasChanged = dataToSave[enableGuestRegistrationCountryDropdown] !== undefined;
  const checkConsentHasChanged = dataToSave[checkConsent] !== undefined;
  const isSwitzerlandSpecificHasChanged = dataToSave[isSwitzerlandSpecific] !== undefined;
  const biogenLincConfigHasChanged = isVisibleOnBiogenLincHasChanged
    || isPublisHasChanged
    || prcCodeEventDetailsHasChanged
    || enableGuestRegistrationHasChanged
    || enableGuestRegistrationCountryDropdownHasChanged
    || showSendInvitationsHasChanged
    || isSwitzerlandSpecificHasChanged
    || checkConsentHasChanged;

  const enableGuestRegistrationToSave = formValues[enableGuestRegistration] || false;
  const enableGuestRegistrationCountryDropdownToSave = formValues[enableGuestRegistrationCountryDropdown] || false;
  const checkConsentHasChangedToSave = formValues[checkConsent] || false;

  const prcCodeEventDetailsToSave = prcCodeEventDetailsHasChanged
    ? dataToSave[prcCodeEventDetails]
    : formValues[prcCodeEventDetails];

  if (!biogenLincConfigHasChanged) {
    return {};
  }

  if (!isVisibleOnBiogenLincCurrent) {
    return {
      biogenLincConfig: {
        isVisibleOnBiogenLinc: false,
        isPublic: false,
        enableGuestRegistration: false,
        checkConsent: false,
        prcCodeEventDetails: prcCodeEventDetailsToSave,
        showSendInvitations: showSendInvitationsCurrent,
        isSwitzerlandSpecific: isSwitzerlandSpecificCurrent,
      },
    };
  }

  return {
    biogenLincConfig: {
      isVisibleOnBiogenLinc: true,
      isPublic: isPublisHasChanged ? dataToSave[isPublic] === publicEnum : formValues[isPublic] === publicEnum,
      enableGuestRegistration: enableGuestRegistrationToSave,
      enableGuestRegistrationCountryDropdown: enableGuestRegistrationCountryDropdownToSave,
      checkConsent: checkConsentHasChangedToSave,
      prcCodeEventDetails: prcCodeEventDetailsToSave,
      showSendInvitations: showSendInvitationsCurrent,
      isSwitzerlandSpecific: isSwitzerlandSpecificCurrent,
    },
  };
};

export const transformSpeakers = (data: YodaFieldValue) => {
  const speakers = data[commonFields.speakers];
  if (speakers) {
    const transformation = speakers.map((speaker: Speaker) => speaker.id);
    return {
      speakers: transformation,
    };
  }
  return {};
};

export const transformAgenda = (data: YodaFieldValue) => {
  const agenda = data[commonFields.agenda];
  return (agenda && agenda.length > 0)
    ? data[commonFields.agenda].map((agendaEntry: AgendaEntry) => {
      const { title, description, session } = agendaEntry;
      session.timeZone = TimeZone.UTC;
      return {
        title,
        description,
        session,
      };
    }) : null;
};

export const transformUserGroups = (data: YodaFieldValue) => {
  const userGroups = data[commonFields.userGroups];
  return (userGroups && userGroups.length > 0)
    ? userGroups.map((group: SelectOption | string) => (typeof group === 'string' ? group : group.id)) : null;
};
