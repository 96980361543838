import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { Market } from 'generated/event.types';
import { commonFields } from 'modules/events//basics/constants/commonFormFields.constants';
import eventState from 'modules/events//states/events.states';
import { EventStateType } from 'modules/events/basics/types/events.types';

const useNavigationConfigGroupConfig = () => {
  const { t } = useTranslation();
  const snap = useSnapshot(eventState) as EventStateType;
  const { market, data, isRsvpType } = snap.createEvent;
  const initVisibility = !(market === Market.com && isRsvpType);

  return {
    showNavigationBarActions: {
      label: t('navigationConfigGroup_showNavigationBarActions_label'),
      name: commonFields.showNavigationBarActions,
      checked: data?.showNavigationBarActions ?? initVisibility,
    },
    showNavigationBarElements: {
      label: t('navigationConfigGroup_showNavigationBarElements_label'),
      name: commonFields.showNavigationBarElements,
      checked: data?.showNavigationBarElements ?? initVisibility,
    },
    showFooterNavItems: {
      label: t('navigationConfigGroup_showFooterNavItems_label'),
      name: commonFields.showFooterNavItems,
      checked: data?.showFooterNavItems ?? initVisibility,
    },
  };
};

export default useNavigationConfigGroupConfig;
