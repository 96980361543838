import { FC } from 'react';
import { CreateEventModalConfig } from 'modules/events/components/CreateEvent/CreateEvent.types';
import Box from 'yoda-ui/components/Box';
import DateRangePicker from 'yoda-ui/components/Form/DateRangePicker';
import SelectYoda from 'yoda-ui/components/Form/SelectYoda';
import TextYoda from 'yoda-ui/components/Form/TextYoda';
import Grid from 'yoda-ui/components/Grid';
import { YodaGridCellSize, YodaSpacing } from 'yoda-ui/yodaTheme';

type CreateEventDialogContentProps = {
  createEventConfig: CreateEventModalConfig;
};

const CreateEventDialogContent: FC<CreateEventDialogContentProps> = ({
  createEventConfig,
}) => (
  <>
    <Box paddingTop={ YodaSpacing.small }>
      <TextYoda { ...createEventConfig.title } />
    </Box>

    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <Box paddingTop={ YodaSpacing.large } width="48%">
        <SelectYoda { ...createEventConfig.presenceType } />
      </Box>

      <Box paddingTop={ YodaSpacing.large } width="48%">
        <SelectYoda { ...createEventConfig.eventFormat } />
      </Box>
    </Box>

    <Box paddingTop={ YodaSpacing.large } width="48%">
      <SelectYoda { ...createEventConfig.hostProvider } />
    </Box>

    <Grid container spacing={ YodaSpacing.large } marginTop={ YodaSpacing.medium }>
      <Grid item xs={ YodaGridCellSize.full }>
        <DateRangePicker { ...createEventConfig.dateRange } />
      </Grid>
      <Grid item xs={ YodaGridCellSize.small }>
        <SelectYoda { ...createEventConfig.startTime } />
      </Grid>
      <Grid item xs={ YodaGridCellSize.small }>
        <SelectYoda { ...createEventConfig.endTime } />
      </Grid>
      <Grid item xs={ YodaGridCellSize.small }>
        <SelectYoda { ...createEventConfig.timeZone } />
      </Grid>
    </Grid>

    <Box paddingTop={ YodaSpacing.large } marginTop={ YodaSpacing.medium }>
      <SelectYoda { ...createEventConfig.market } />
    </Box>
    <Box paddingTop={ YodaSpacing.large }>
      <SelectYoda { ...createEventConfig.product } />
    </Box>

    <Box paddingTop={ YodaSpacing.large }>
      <TextYoda { ...createEventConfig.venue } />
    </Box>

  </>

);

export default CreateEventDialogContent;
