import { useTranslation } from 'react-i18next';
import { string, boolean } from 'yup';

const useCxSurveySchema = () => {
  const { t } = useTranslation();
  const requiredLabel = t('form_errors_required');
  return {
    cxSurveyActivateJourney: boolean().required(),
    cxSurveyId: string().nullable().test(
      'cxSurveyId regex',
      t('events_cx_survey_id_error_message'),
      (value) => {
        if (!value || value === undefined) {
          return true;
        }
        return value.startsWith('SV_');
      },
    ),
    cxSurveyValidation: {
      required: string().nullable().required(requiredLabel),
      notRequired: string().nullable().notRequired(),
    },
  };
};

export default useCxSurveySchema;
