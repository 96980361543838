import { useTranslation } from 'react-i18next';
import { EditSpeakerProfileModalConfig } from './EditSpeakerProfile.types';
import { FileExtension } from 'basics/types/files.types';
import { AssetTarget, AssetType } from 'generated/event.types';
import useEditSpeakerProfileSchema from 'modules/events/components/EditSpeakerProfile/EditSpeakerProfile.schema';
import { PossibleSpeaker } from 'modules/events/groups/SpeakersGroup/SpeakersGroup.types';
import { Icons } from 'yoda-ui/components/Icons/Icon';

const useEditSpeakerProfileConfig = (
  selectedSpeaker?: PossibleSpeaker,
) => {
  const { t } = useTranslation();
  const addSpeakerProfileSchema = useEditSpeakerProfileSchema();

  const addSpeakerProfileConfig: EditSpeakerProfileModalConfig = {
    title: {
      defaultValue: selectedSpeaker?.title || '',
      label: t('person_field_title_label'),
      name: 'title',
      placeholder: t('person_field_title_placeholder'),
      required: false,
      validation: addSpeakerProfileSchema.title,
    },
    firstName: {
      defaultValue: selectedSpeaker?.firstName || '',
      label: t('person_field_firstName_label'),
      name: 'firstName',
      placeholder: t('person_field_firstName_placeholder'),
      required: true,
      validation: addSpeakerProfileSchema.firstName,
    },
    lastName: {
      defaultValue: selectedSpeaker?.lastName || '',
      label: t('person_field_lastName_label'),
      name: 'lastName',
      placeholder: t('person_field_lastName_placeholder'),
      required: true,
      validation: addSpeakerProfileSchema.lastName,
    },
    email: {
      defaultValue: selectedSpeaker?.email || '',
      label: t('person_field_email_label'),
      name: 'email',
      placeholder: t('person_field_email_placeholder'),
      required: false,
      validation: addSpeakerProfileSchema.email,
    },
    affiliation: {
      defaultValue: selectedSpeaker?.profile?.default?.affiliation || '',
      label: t('speaker_profile_affiliation_label'),
      name: 'affiliation',
      placeholder: t('speaker_profile_affiliation_placeholder'),
      required: false,
      validation: addSpeakerProfileSchema.affiliation,
    },
    specialty: {
      defaultValue: selectedSpeaker?.profile?.default?.specialty || '',
      label: t('speaker_profile_specialty_label'),
      name: 'specialty',
      placeholder: t('speaker_profile_specialty_placeholder'),
      required: false,
      validation: addSpeakerProfileSchema.specialty,
    },
    image: {
      cardImageFormConfig: {
        btnLabel: t('speaker_profile_image_dropzone_action_label'),
        btnIcon: Icons.upload,
        maxFileSize: 2048000,
        maxFilesNumber: 1,
        extensionsAllowed: [FileExtension.jpg, FileExtension.jpeg, FileExtension.png],
        message: t('speaker_profile_image_dropzone_message'),
        label: t('speaker_profile_image_dropzone_action_label'),
        name: 'image',
        assetTarget: AssetTarget.speakerImage,
        assetType: AssetType.image,
        assetsArePublic: true,
      },
      dimensions: {
        maxHeight: 80,
        maxWidth: 80,
        minHeight: 80,
        minWidth: 80,
      },
      listConfig: {
        noItemLabel: t('asset_upload_no_image_label'),
        title: t('asset_upload_image_title'),
      },
      name: 'image',
      validation: addSpeakerProfileSchema.image,
      defaultValue: selectedSpeaker?.image || [],
    },
    showTitle: {
      label: t('events_speaker_show_title_visibility_label'),
      checked: selectedSpeaker?.eventPersonalInfosVisibility?.showTitle ?? true,
      name: 'showTitle',
    },
    showEmail: {
      label: t('events_speaker_show_email_visibility_label'),
      checked: selectedSpeaker?.eventPersonalInfosVisibility?.showEmail ?? true,
      name: 'showEmail',
    },
    showImage: {
      label: t('events_speaker_show_image_visibility_label'),
      checked: selectedSpeaker?.eventPersonalInfosVisibility?.showImage ?? true,
      name: 'showImage',
    },
    showSpecialty: {
      label: t('events_speaker_show_specialty_visibility_label'),
      checked: selectedSpeaker?.eventPersonalInfosVisibility?.showSpecialty ?? true,
      name: 'showSpecialty',
    },
    showAffiliation: {
      label: t('events_speaker_show_affiliation_visibility_label'),
      checked: selectedSpeaker?.eventPersonalInfosVisibility?.showAffiliation ?? true,
      name: 'showAffiliation',
    },
  };

  return { addSpeakerProfileConfig };
};

export default useEditSpeakerProfileConfig;
