import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useCreateOnlineMeetingActions from './CreateOnlineMeeting.actions.hook';
import { onlineMeetingTransformer } from './CreateOnlineMeeting.transformers';
import useCreateOnlineMeetingButtons from './CreateOnlineMeetingButtons.hook';
import useCreateOnlineMeetingStepper from './CreateOnlineMeetingStepper.hook';
import { eventsRoute } from 'basics/constants/routes.constants';
import { warningToast } from 'basics/utils/toast';
import { EventStatus, Presence } from 'generated/event.types';
import { isDraft } from 'modules/events/basics/utils/events.utils';
import useLazyGetFullPrivateEventById from 'modules/events/graphql/queries/getLazyFullPrivateEventById';
import eventState from 'modules/events/states/events.states';
import { useYodaCreateForm } from 'yoda-ui/yodaForm';

const useCreateOnlineMeeting = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const yodaFormMethods = useYodaCreateForm({ setIsValidOptional: true });
  const onlineMeetingActions = useCreateOnlineMeetingActions(yodaFormMethods);
  const { queryGetFullPrivateEventById, data: event, error, loading } = useLazyGetFullPrivateEventById();

  if (event && event.presence !== Presence.online) {
    warningToast(t('errors_standard'));
    navigate(eventsRoute);
  }

  const { isDirty } = yodaFormMethods.useWatchForm();

  const buttonsConfig = useCreateOnlineMeetingButtons(
    onlineMeetingActions,
    isDirty,
    yodaFormMethods.formState.isValid && event?.status === EventStatus.ready,
    !isDraft(event?.status),
  );
  const stepsList = useCreateOnlineMeetingStepper(yodaFormMethods.useWatchStep);

  useEffect(() => {
    if (id) {
      queryGetFullPrivateEventById({ variables: { eventId: id } });
    }
  }, [id, queryGetFullPrivateEventById]);

  useEffect(() => {
    if (event) {
      eventState.createEvent = onlineMeetingTransformer(event);
    }
    if (error) {
      warningToast(t('errors_standard'));
      navigate(eventsRoute);
    }
  }, [event]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickHome = async () => {
    navigate(eventsRoute);
  };

  const showLoader = loading || !event;

  return {
    t,
    handleClickHome,
    handleChangeStep: onlineMeetingActions.handleChangeStep,
    showLoader,
    yodaFormMethods,
    stepIndexer: onlineMeetingActions.stepIndexer,
    id,
    buttonsConfig,
    stepsList,
    event,
  };
};

export default useCreateOnlineMeeting;
