export enum TacticStatus{
  APPROVED = 'Approved',
  DELETED = 'Deleted',
  DRAFT = '', // DRAFT IS EMPTY STRING VALUE BECAUSE VEEVA HAS A EMPTY VALUE FOR DRAFT TACTIC - BIOGENLINC-3701
}

export enum CepStatus{
  ONGOING = 'Ongoing',
  FEEDBACK = 'Feedback',
  APPROVED = 'Approved',
  DRAFT = 'Draft',
  DELETED = 'Deleted',
}
