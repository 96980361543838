import { AlertColor } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { VeevaSyncStatus } from 'modules/events/basics/enums/events.enums';
import useVeevaStatusBar from 'modules/events/components/VeevaStatusBar/VeevaStatusBar.hook';
import VeevaStatusBarProps from 'modules/events/components/VeevaStatusBar/VeevaStatusBar.types';

import Alert from 'yoda-ui/components/Alert';
import Box from 'yoda-ui/components/Box';
import Collapse from 'yoda-ui/components/Collapse';
import CollapseIcon from 'yoda-ui/components/Icons/CollapseIcon';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const VeevaStatusBar: FC<VeevaStatusBarProps> = ({
  lastVeevaSync = null,
  lastVeevaSyncErrors = null,
  veevaSyncStatus = VeevaSyncStatus.PENDING,
}) => {
  const { t } = useTranslation();
  const {
    status,
    alertStatus,
    lastSyncDate,
    open,
    setOpen,
  } = useVeevaStatusBar(lastVeevaSync, veevaSyncStatus || VeevaSyncStatus.PENDING);

  const handleClick = () => {
    setOpen(!open);
  };

  const veevaStatusLabel = `${t('event_veeva_sync_status_header_label')}: ${status}`;
  const veevaLastSyncDateLabel = lastVeevaSync
    ? ` - ${t('event_veeva_sync_status_last_synchronization_date_label')} : ${lastSyncDate} `
    : null;

  if (veevaSyncStatus !== VeevaSyncStatus.ERROR) {
    return (
      <Box>
        <Alert
          severity={ alertStatus as AlertColor }>
          { veevaStatusLabel }
          { veevaLastSyncDateLabel }
        </Alert>
      </Box>
    );
  }

  return (
    <Box>
      <Alert severity={ alertStatus as AlertColor } action={
        <CollapseIcon
          sx={ { marginTop: -YodaSpacing.xxxSmall } }
          expand={ open }
          onClick={ handleClick }
          aria-expanded={ open }
          aria-label={ t('veeva_status_bar_show_more') }
          data-testid="collapse-icon"
        />
      }>
        { veevaStatusLabel }
        { veevaLastSyncDateLabel }
        {
          lastVeevaSyncErrors && (
            <Collapse
              in={ open }
              timeout="auto"
            >
              <Box padding={ YodaSpacing.xxxSmall }>
                { lastVeevaSyncErrors.map((errors) => <p key={ errors }>- { errors } </p>) }
              </Box>
            </Collapse>
          )
        }
      </Alert>

    </Box>
  );
};

export default VeevaStatusBar;
