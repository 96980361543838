import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';

import { SpeakersFormProps } from './Speakers.types';
import QuickForm from 'components/QuickForm';
import { FormShape, ListShape } from 'components/QuickForm/QuickForm.enums';

import EditSpeakerProfile from 'modules/events/components/EditSpeakerProfile';
import EditSpeakerProfileModal from 'modules/events/components/EditSpeakerProfile/EditSpeakerProfileModal';
import ManageSpeakers from 'modules/events/components/ManageSpeakers/ManageSpeakers';
import useSpeakers from 'modules/events/components/Speakers/Speakers.hook';
import { ActionButton, ButtonVariant } from 'yoda-ui/components/BiogenLincDS/Buttons';
import { Icons } from 'yoda-ui/components/Icons/Icon';

const Speakers: FC<SpeakersFormProps> = ({
  defaultValue,
  listConfig,
  name,
  speakersFormConfig: formConfig,
  validation,
  disabled,
}) => {
  const {
    transformSpeakerToCard,
    transformToState,
    t,
    onManageSpeakersModalOpen,
    showManageSpeakersModal,
    onManageSpeakersModalClose,
    openEditModal,
    setOpenEditModal,
    handleEditSpeaker,
    selectedSpeaker,
  } = useSpeakers();

  const flags = useFlags();

  const manageSpeakerButton = flags.eventsBiogenlinc4427ManageSpeakers ? (
    <>
      <ActionButton
        icon={ Icons.campaign }
        label={ t('event_manage_speakers_title') }
        variant={ ButtonVariant.primary }
        onClick={ onManageSpeakersModalOpen }
      />
      <ManageSpeakers isOpen={ showManageSpeakersModal } onClose={ onManageSpeakersModalClose }></ManageSpeakers>
    </>
  ) : <EditSpeakerProfile />;

  return (
    <>
      <QuickForm
        defaultValue={ defaultValue }
        form={ { shape: FormShape.formAsyncSelect, formConfig } }
        list={ { dragAndDrop: true, shape: ListShape.listRow, listConfig } }
        name={ name }
        transformToCard={ transformSpeakerToCard }
        transformToState={ transformToState }
        validation={ validation }
        disabled={ disabled }
        handleEditItem={ flags.eventsBiogenlinc4427ManageSpeakers ? handleEditSpeaker : undefined }
      />

      {
        disabled ? null
          : manageSpeakerButton
      }

      <EditSpeakerProfileModal
        open={ openEditModal }
        setOpen={ setOpenEditModal }
        selectedSpeaker={ selectedSpeaker || undefined }
        editAttributeVisibility={ flags.eventsBiogenlinc4427ManageSpeakers }
      />

    </>
  );
};

export default Speakers;
