import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import {
  SessionInformationConfig,
  SessionInformationField,
  UseSessionInformationConfigProps,
} from './SessionInformation.types';
import { TimeZone } from 'basics/enums/timeZoneList.enums';
import { timezoneOptions } from 'basics/options/timezone.options';
import { Format, Presence, Source } from 'generated/event.types';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import eventFormatOptions from 'modules/events/basics/options/eventFormat.options';
import eventLocaleOptions from 'modules/events/basics/options/eventLocale.options';
import { PhyisicalEventStateType } from 'modules/events/basics/types/events.types';

import useSessionInformationSchema from 'modules/events/components/SessionInformation/SessionInformation.schema';
import eventState from 'modules/events/states/events.states';

const useSessionInformationConfig = ({ startTimeOptions, endTimeOptions }: UseSessionInformationConfigProps) => {
  const { t } = useTranslation();
  const validations = useSessionInformationSchema();

  const eventStateValue = useSnapshot(eventState) as PhyisicalEventStateType;
  const eventPresence = eventStateValue.createEvent?.presence;

  const localeOptions = eventLocaleOptions(eventStateValue.createEvent?.market);

  const sessionConfig: SessionInformationConfig = {
    [SessionInformationField.dateRange]: {
      startText: t('timeSlotBooking_startDate_label'),
      endText: t('timeSlotBooking_endDate_label'),
      name: commonFields.sessionDateRange,
      disablePast: true,
      validation: validations.dateRange,
      startRequired: eventPresence === Presence.physical,
      endRequired: false,
      defaultValue: eventStateValue.createEvent?.data?.session?.dateRange,
      disabled: eventStateValue.createEvent?.source === Source.veeva,
    },
    [SessionInformationField.timeZone]: {
      label: t('timeSlotBooking_timeZone_label'),
      name: commonFields.sessionTimezone,
      options: timezoneOptions,
      required: eventPresence === Presence.physical,
      defaultValue: eventStateValue.createEvent?.data?.session?.timeZone || TimeZone.UTC,
      validation: validations.timeZone,
    },
    [SessionInformationField.startTime]: {
      label: t('timeSlotBooking_startTime_label'),
      name: commonFields.sessionStartTime,
      placeholder: t('actions_select_placeholder'),
      options: startTimeOptions,
      required: eventPresence === Presence.physical,
      disableClearable: true,
      defaultValue: eventStateValue.createEvent?.data?.session?.startTime,
      validation: validations.startTime,
      disabled: eventStateValue.createEvent?.source === Source.veeva,
    },
    [SessionInformationField.endTime]: {
      label: t('timeSlotBooking_endTime_label'),
      name: commonFields.sessionEndTime,
      placeholder: t('actions_select_placeholder'),
      options: endTimeOptions,
      required: eventPresence === Presence.physical,
      disableClearable: true,
      defaultValue: eventStateValue.createEvent?.data?.session?.endTime,
      validation: validations.endTime,
      disabled: eventStateValue.createEvent?.source === Source.veeva,
    },
    eventFormat: {
      label: t('event_creation_modal_event_format_field_label'),
      name: commonFields.sessionEventFormat,
      placeholder: t('event_creation_modal_event_format_field_placeholder'),
      options: eventFormatOptions(eventPresence),
      required: eventPresence === Presence.physical,
      defaultValue: eventStateValue.createEvent?.format || Format.congress,
      validation: validations.eventFormat,
      disableClearable: true,
    },
    locale: {
      label: t('event_creation_modal_locale_field_label'),
      name: commonFields.sessionLocale,
      placeholder: t('event_creation_modal_locale_field_placeholder'),
      options: localeOptions,
      required: eventPresence === Presence.physical,
      disabled: localeOptions.length === 1,
      defaultValue: eventStateValue.createEvent?.locale || localeOptions[0]?.id,
      validation: validations.locale,
      disableClearable: true,
    },
    location: {
      label: t('event_creation_location_field_label'),
      name: commonFields.sessionLocation,
      placeholder: t('event_creation_location_field_placeholder'),
      required: true,
      defaultValue: eventStateValue.createEvent.data?.eventLocation,
      validation: validations.location,
    },
    seats: {
      label: t('event_creation_seats_field_label'),
      name: commonFields.sessionSeats,
      placeholder: t('event_creation_seats_field_placeholder'),
      required: true,
      defaultValue: eventStateValue.createEvent.data?.seats as unknown as string || '0',
      validation: validations.seats,
      min: 0,
      max: 1000,
      type: 'number',
    },
    registrationEndDate: {
      label: t('event_registration_end_date'),
      name: commonFields.registrationEndDate,
      minDate: new Date(),
      defaultValue: eventPresence === Presence.physical
        ? (eventStateValue as PhyisicalEventStateType).createEvent?.data?.registrationEndDate : undefined,
      validation: validations.registrationEndDate,
    },
  };
  return ({ sessionConfig, eventPresence });
};

export default useSessionInformationConfig;
