import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { errorToast } from 'basics/utils/toast';
import { RegisterParticipantInput } from 'generated/event.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export type UseInviteParticipantMutationResponse = {
  inviteParticipant: boolean;
};

export type UseInviteParticipantMutation = [
  (participant: RegisterParticipantInput) => Promise<boolean>,
  MutationResult<UseInviteParticipantMutationResponse>,
];

export const inviteParticipantMutationCall = gql`
mutation inviteParticipant($participant: RegisterParticipantInput!) {
  inviteParticipant(participant: $participant)
}`;

export const useInviteParticipantMutation = (): UseInviteParticipantMutation => {
  const { t } = useTranslation();
  const [invitePaticipantMutation, state] = useMutation<UseInviteParticipantMutationResponse>(inviteParticipantMutationCall);

  const executeMutation = useCallback(async (participant) => {
    try {
      const { data } = await invitePaticipantMutation({
        variables: { participant },
      });
      if (!data?.inviteParticipant) {
        errorToast(t('errors_standard'));
      }
      return !!data?.inviteParticipant;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error);
        Logger.logError({
          error,
          customContext,
          transactionName: 'inviteParticipant.ts - useInviteParticipantMutation',
        });
        errorToast(t('errors_standard'));
      }
      return false;
    }
  }, [invitePaticipantMutation, t]);

  return [executeMutation, state];
};
