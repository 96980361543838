import { SelectOption } from 'basics/options/options.types';
import { testUserList } from 'modules/events/basics/constants/eventTesting.constants';
import { TestUser } from 'modules/events/basics/types/eventTesting.types';

const useEventTestingUserListOptions = (): SelectOption[] => {
  const options = testUserList.map((user: TestUser) => ({
    id: user.email,
    label: user.email,
  }));

  return options;
};

export default useEventTestingUserListOptions;
