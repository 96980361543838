import { DimensionsImage } from 'basics/types/files.types';
import { getImageDimensions } from 'basics/utils/files/files.utils';

type ValidateImageWidthAndHeight = (args: DimensionsImage & {
  imageWidth: number;
  imageHeight: number;
}) => boolean;
export const validateImageWidthAndHeight: ValidateImageWidthAndHeight = ({
  imageWidth,
  imageHeight,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
}) => {
  const widthIsValid = imageWidth <= maxWidth && imageWidth >= minWidth;
  const heightIsValid = imageHeight <= maxHeight && imageHeight >= minHeight;
  return widthIsValid && heightIsValid;
};

type ValidateImagesDimensions = (args: {
  files: File[];
  dimensions: DimensionsImage;
}) => Promise<boolean>;
export const validateImagesDimensions: ValidateImagesDimensions = async ({ files, dimensions }) => {
  let allImagesDimensionsAreValid = true;
  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];
    // eslint-disable-next-line no-await-in-loop
    const { width: imageWidth, height: imageHeight } = await getImageDimensions(file);
    const imageDimensionsAreValid = validateImageWidthAndHeight({
      imageHeight,
      imageWidth,
      ...dimensions,
    });
    if (!imageDimensionsAreValid) {
      allImagesDimensionsAreValid = false;
      break;
    }
  }
  return allImagesDimensionsAreValid;
};
