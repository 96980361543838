import { mixed } from 'yup';

const useEventTestingSchema = () => {
  return {
    testUserList: mixed().required(),
  };
};

export default useEventTestingSchema;

