import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { buildDateRangePickerEndTimeOptions, buildDateRangePickerStartTimeOptions } from 'basics/options/time.options';
import { SessionInformationField } from 'modules/events/components/SessionInformation/SessionInformation.types';

import { useYodaCreateForm } from 'yoda-ui/yodaForm';

const useSessionInformation = () => {
  const { t } = useTranslation();

  const { formState, fieldsState, useWatchField, setValue } = useYodaCreateForm();
  const dateRange = useWatchField(SessionInformationField.dateRange)?.value;
  const startTime = useWatchField(SessionInformationField.startTime)?.value;

  const startTimeOptions = useMemo(() => (
    buildDateRangePickerStartTimeOptions({ dateRange, startTime, setValue, fieldsState })
  ), [dateRange, setValue, startTime, fieldsState]);

  const endTimeOptions = useMemo(() => (
    buildDateRangePickerEndTimeOptions({ dateRange, startTime, setValue, fieldsState })
  ),
  [dateRange, startTime, setValue, fieldsState]);

  return {
    t,
    formState,
    fieldsState,
    startTimeOptions,
    endTimeOptions,
  };
};

export default useSessionInformation;
