import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import NavbarContent from './NavbarContent';
import { useGetConfig } from 'basics/utils/getConfig.hook';
import AppBar from 'yoda-ui/components/AppBar';
import Box from 'yoda-ui/components/Box';
import Toolbar from 'yoda-ui/components/Toolbar';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const pathToDisableSelector = ['createOnlineMeeting', 'createPhysicalMeeting'];

const NavBar: FC = () => {
  const currentModuleConfig = useGetConfig();
  const location = useLocation();
  const currentURL = location.pathname;

  if (currentURL.includes('events') && pathToDisableSelector.find((subpath) => currentURL.includes(subpath))) {
    currentModuleConfig.enableCountrySelector = false;
  } else {
    currentModuleConfig.enableCountrySelector = true;
  }

  return (
    <AppBar position="fixed">
      <Box paddingX={ YodaSpacing.small }>
        <Toolbar>
          <Box display="flex" flex="column" justifyContent="space-between" width="100%">
            {
              <Box height="3rem">
                <img src={ currentModuleConfig?.logo } alt="biogen-logo" style={ { height: 'inherit' } } />
              </Box>
            }
            <Box display="flex" alignItems="center">
              <NavbarContent />
            </Box>
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
};

export default NavBar;
