import { gql, MutationHookOptions, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { assetFragment } from 'basics/graphql/fragments/commonData.fragments';
import { useGetConfig } from 'basics/utils/getConfig.hook';
import { succesToast, errorToast } from 'basics/utils/toast';
import { FileDataInput as FileDataInputEvents } from 'generated/event.types';
import { FileDataInput as FileDataInputMaya } from 'generated/maya.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export type UploadFileResponse = { id: string; url: string; size: number; name: string };

export type UploadFilesResultType = {
  uploadFiles: UploadFileResponse[];
};

export type UseUploadFilesMutation = [
  (
    file: File[],
    prefix: string,
    filesDataInput: FileDataInputEvents[] | FileDataInputMaya[],
    assetsArePublic?: boolean,
  ) => Promise<UploadFileResponse[] | undefined | null>,
  MutationResult<UploadFilesResultType>,
];

const uploadFilesMutationCall = gql`
mutation uploadFiles($files: [Upload!]!, $prefix: String!, $filesDataInput: [FileDataInput!]!, $assetsArePublic: Boolean) {
  uploadFiles(files: $files, prefix: $prefix, filesDataInput: $filesDataInput, assetsArePublic: $assetsArePublic) {
    ${assetFragment}
  }
}`;

export const useUploadFilesMutation = (): UseUploadFilesMutation => {
  const { t } = useTranslation();
  const { context } = useGetConfig();
  const [uploadFilesMutation, state] = useMutation<UploadFilesResultType>(
    uploadFilesMutationCall,
    {
      ...context,
      fetchPolicy: 'network-only',
    } as MutationHookOptions,
  );

  const executeMutation = useCallback(async (files, prefix, filesDataInput, assetsArePublic) => {
    try {
      const data = await uploadFilesMutation({
        variables: { files, prefix, filesDataInput, assetsArePublic },
      });
      succesToast('Your file has been successfully uploaded');
      return data.data?.uploadFiles;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error);
        Logger.logError({
          error,
          customContext,
          transactionName: 'uploadFile.ts - useUploadFilesMutation',
        });
        errorToast(t('errors_standard'));
      }
      return null;
    }
  }, [uploadFilesMutation, t]);

  return [executeMutation, state];
};
