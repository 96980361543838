import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FormGroup from 'components/FormGroup';
import { CommonGroups } from 'modules/events/basics/enums/commonForms.enums';
import TimeSlotBookingForm from 'modules/events/components/TimeSlotBooking';
import useTimeSlot from 'modules/events/groups/TimeSlotGroup/TimeSlotGroup.hook';

const TimeSlotGroup: FC = () => {
  const { t } = useTranslation();
  const { handleSave, fieldValue, isLoading, isValid, isInvalid, isPublished } = useTimeSlot();

  return (
    <FormGroup title={ t('events_timeSlot_card_title') } groupName={ CommonGroups.timeSlot }>
      <TimeSlotBookingForm
        onSave={ handleSave }
        values={ fieldValue }
        isValid={ isValid }
        isInvalid={ isInvalid }
        isLoading={ isLoading }
        disabled={ isPublished }
      />
    </FormGroup>
  );
};

export default TimeSlotGroup;
