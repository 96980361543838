import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { succesToast, errorToast } from 'basics/utils/toast';
import { Event } from 'modules/events/basics/types/events.types';
import { HybridMeetingInput } from 'modules/events/basics/types/hybridMeeting.types';
import { eventFragment } from 'modules/events/graphql/fragments/event.fragments';
import { hybridEventDataFragment } from 'modules/events/graphql/fragments/hybridEventData.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const publishHybridMeetingMutationCall = gql`
  mutation publishHybridMeetingMutation($hybridEventInput: HybridEventInput!) {
    publishHybridEvent(hybridEventInput: $hybridEventInput) {
      ${eventFragment}
      source
      data {
        ...on HybridEvent {
          ${hybridEventDataFragment}
        }
      }
    }
  }
`;

type ResultType = {
  publishHybridEvent: Event;
};

type UsePublishHybridMeetingMutation = [
  (hybridEventInput: HybridMeetingInput) => Promise<Event | undefined | null>,
  MutationResult<ResultType>,
];

export const usePublishHybridMeetingMutation = (): UsePublishHybridMeetingMutation => {
  const { t } = useTranslation();
  const [publishHybridMeeting, state] = useMutation<ResultType>(publishHybridMeetingMutationCall);
  const executeMutation = useCallback(async (hybridEventInput) => {
    try {
      const data = await publishHybridMeeting({
        variables: { hybridEventInput },
      });
      succesToast(t('toast_publish_event_success'));
      return data.data?.publishHybridEvent;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error, hybridEventInput);
        Logger.logError({
          error,
          customContext,
          transactionName: 'publishHybridMeeting.ts - usePublishHybridMeetingMutation',
        });
      }
      errorToast(t('toast_publish_event_fail'));
      return null;
    }
  }, [publishHybridMeeting, t]);

  return [executeMutation, state];
};
